/**
 * @fileoverview gRPC-Web generated client stub for jp.singcolor
 * @enhanceable
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck
import * as grpcWeb from 'grpc-web';
import * as google_protobuf_empty_pb from 'google-protobuf/google/protobuf/empty_pb';
import * as users_v1_pb from './users_v1_pb';
var UsersV1Client = /** @class */ (function () {
    function UsersV1Client(hostname, credentials, options) {
        this.methodDescriptorAppleLogin = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/AppleLogin', grpcWeb.MethodType.UNARY, users_v1_pb.AppleLoginV1Request, users_v1_pb.AppleLoginV1Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.AppleLoginV1Response.deserializeBinary);
        this.methodDescriptorLineLogin = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/LineLogin', grpcWeb.MethodType.UNARY, users_v1_pb.LineLoginV1Request, users_v1_pb.LineLoginV1Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.LineLoginV1Response.deserializeBinary);
        this.methodDescriptorLineLoginByCode = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/LineLoginByCode', grpcWeb.MethodType.UNARY, users_v1_pb.LineLoginByCodeV1Request, users_v1_pb.LineLoginByCodeV1Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.LineLoginByCodeV1Response.deserializeBinary);
        this.methodDescriptorGetLineLoginState = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/GetLineLoginState', grpcWeb.MethodType.UNARY, users_v1_pb.GetLineLoginStateV1Request, users_v1_pb.GetLineLoginStateV1Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.GetLineLoginStateV1Response.deserializeBinary);
        this.methodDescriptorGoogleLogin = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/GoogleLogin', grpcWeb.MethodType.UNARY, users_v1_pb.GoogleLoginV1Request, users_v1_pb.GoogleLoginV1Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.GoogleLoginV1Response.deserializeBinary);
        this.methodDescriptorCreateLoginToken = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/CreateLoginToken', grpcWeb.MethodType.UNARY, users_v1_pb.CreateLoginTokenV1Request, users_v1_pb.CreateLoginTokenV1Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.CreateLoginTokenV1Response.deserializeBinary);
        this.methodDescriptorLogout = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/Logout', grpcWeb.MethodType.UNARY, users_v1_pb.LogoutV1Request, users_v1_pb.LogoutV1Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.LogoutV1Response.deserializeBinary);
        this.methodDescriptorGetUser = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/GetUser', grpcWeb.MethodType.UNARY, users_v1_pb.GetUserV1Request, users_v1_pb.GetUserV1Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.GetUserV1Response.deserializeBinary);
        this.methodDescriptorCreateUser = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/CreateUser', grpcWeb.MethodType.UNARY, users_v1_pb.CreateUserV1Request, users_v1_pb.CreateUserV1Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.CreateUserV1Response.deserializeBinary);
        this.methodDescriptorDoneRegistration = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/DoneRegistration', grpcWeb.MethodType.UNARY, users_v1_pb.DoneRegistrationV1Request, users_v1_pb.DoneRegistrationV1Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.DoneRegistrationV1Response.deserializeBinary);
        this.methodDescriptorListPrefectures = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/ListPrefectures', grpcWeb.MethodType.UNARY, users_v1_pb.ListPrefecturesV1Request, users_v1_pb.ListPrefecturesV1Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.ListPrefecturesV1Response.deserializeBinary);
        this.methodDescriptorListTowns = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/ListTowns', grpcWeb.MethodType.UNARY, users_v1_pb.ListTownsV1Request, users_v1_pb.ListTownsV1Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.ListTownsV1Response.deserializeBinary);
        this.methodDescriptorUpdateUserTown = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/UpdateUserTown', grpcWeb.MethodType.UNARY, users_v1_pb.UpdateUserTownV1Request, users_v1_pb.UpdateUserTownV1Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.UpdateUserTownV1Response.deserializeBinary);
        this.methodDescriptorListFollowings = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/ListFollowings', grpcWeb.MethodType.UNARY, users_v1_pb.ListFollowingsRequest, users_v1_pb.ListFollowingsResponse, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.ListFollowingsResponse.deserializeBinary);
        this.methodDescriptorFollow = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/Follow', grpcWeb.MethodType.UNARY, users_v1_pb.FollowRequest, users_v1_pb.FollowResponse, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.FollowResponse.deserializeBinary);
        this.methodDescriptorUnfollow = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/Unfollow', grpcWeb.MethodType.UNARY, users_v1_pb.UnfollowRequest, users_v1_pb.UnfollowResponse, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.UnfollowResponse.deserializeBinary);
        this.methodDescriptorUpdateFollowNotificationSetting = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/UpdateFollowNotificationSetting', grpcWeb.MethodType.UNARY, users_v1_pb.UpdateFollowNotificationSettingRequest, users_v1_pb.UpdateFollowNotificationSettingResponse, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.UpdateFollowNotificationSettingResponse.deserializeBinary);
        this.methodDescriptorListFollowers = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/ListFollowers', grpcWeb.MethodType.UNARY, users_v1_pb.ListFollowersRequest, users_v1_pb.ListFollowersResponse, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.ListFollowersResponse.deserializeBinary);
        this.methodDescriptorListBlockings = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/ListBlockings', grpcWeb.MethodType.UNARY, users_v1_pb.ListBlockingsRequest, users_v1_pb.ListBlockingsResponse, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.ListBlockingsResponse.deserializeBinary);
        this.methodDescriptorDoBlock = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/DoBlock', grpcWeb.MethodType.UNARY, users_v1_pb.DoBlockRequest, users_v1_pb.DoBlockResponse, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.DoBlockResponse.deserializeBinary);
        this.methodDescriptorUnblock = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/Unblock', grpcWeb.MethodType.UNARY, users_v1_pb.UnblockRequest, users_v1_pb.UnblockResponse, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.UnblockResponse.deserializeBinary);
        this.methodDescriptorGetUserProfileV2 = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/GetUserProfileV2', grpcWeb.MethodType.UNARY, users_v1_pb.GetUserProfileV2Request, users_v1_pb.GetUserProfileV2Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.GetUserProfileV2Response.deserializeBinary);
        this.methodDescriptorGetUserSetting = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/GetUserSetting', grpcWeb.MethodType.UNARY, users_v1_pb.GetUserSettingV1Request, users_v1_pb.GetUserSettingV1Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.GetUserSettingV1Response.deserializeBinary);
        this.methodDescriptorUpdateUserSetting = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/UpdateUserSetting', grpcWeb.MethodType.UNARY, users_v1_pb.UpdateUserSettingV1Request, users_v1_pb.UpdateUserSettingV1Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.UpdateUserSettingV1Response.deserializeBinary);
        this.methodDescriptorUpdateUserSettingV2 = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/UpdateUserSettingV2', grpcWeb.MethodType.UNARY, users_v1_pb.UpdateUserSettingV2Request, users_v1_pb.UpdateUserSettingV2Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.UpdateUserSettingV2Response.deserializeBinary);
        this.methodDescriptorUpdateDevice = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/UpdateDevice', grpcWeb.MethodType.UNARY, users_v1_pb.UpdateDeviceV1Request, google_protobuf_empty_pb.Empty, function (request) {
            return request.serializeBinary();
        }, google_protobuf_empty_pb.Empty.deserializeBinary);
        this.methodDescriptorAddTwitterAccount = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/AddTwitterAccount', grpcWeb.MethodType.UNARY, users_v1_pb.AddTwitterAccountV1Request, users_v1_pb.AddTwitterAccountV1Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.AddTwitterAccountV1Response.deserializeBinary);
        this.methodDescriptorListTwitterAccounts = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/ListTwitterAccounts', grpcWeb.MethodType.UNARY, users_v1_pb.ListTwitterAccountsV1Request, users_v1_pb.ListTwitterAccountsV1Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.ListTwitterAccountsV1Response.deserializeBinary);
        this.methodDescriptorDeleteTwitterAccount = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/DeleteTwitterAccount', grpcWeb.MethodType.UNARY, users_v1_pb.DeleteTwitterAccountV1Request, google_protobuf_empty_pb.Empty, function (request) {
            return request.serializeBinary();
        }, google_protobuf_empty_pb.Empty.deserializeBinary);
        this.methodDescriptorGetUserTwitterSetting = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/GetUserTwitterSetting', grpcWeb.MethodType.UNARY, users_v1_pb.GetTwitterSettingV1Request, users_v1_pb.GetTwitterSettingV1Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.GetTwitterSettingV1Response.deserializeBinary);
        this.methodDescriptorGetCoinBalance = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/GetCoinBalance', grpcWeb.MethodType.UNARY, users_v1_pb.GetCoinBalanceV1Request, users_v1_pb.GetCoinBalanceV1Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.GetCoinBalanceV1Response.deserializeBinary);
        this.methodDescriptorGetStatisticsCurrentDiamond = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/GetStatisticsCurrentDiamond', grpcWeb.MethodType.UNARY, users_v1_pb.GetStatisticsCurrentDiamondV1Request, users_v1_pb.GetStatisticsCurrentDiamondV1Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.GetStatisticsCurrentDiamondV1Response.deserializeBinary);
        this.methodDescriptorGetMonthlyStatistics = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/GetMonthlyStatistics', grpcWeb.MethodType.UNARY, users_v1_pb.GetMonthlyStatisticsV1Request, users_v1_pb.GetMonthlyStatisticsV1Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.GetMonthlyStatisticsV1Response.deserializeBinary);
        this.methodDescriptorGetLiverPromotionMeter = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/GetLiverPromotionMeter', grpcWeb.MethodType.UNARY, users_v1_pb.GetLiverPromotionMeterV1Request, users_v1_pb.GetLiverPromotionMeterV1Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.GetLiverPromotionMeterV1Response.deserializeBinary);
        this.methodDescriptorSearchUsers = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/SearchUsers', grpcWeb.MethodType.UNARY, users_v1_pb.SearchUsersV1Request, users_v1_pb.SearchUsersV1Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.SearchUsersV1Response.deserializeBinary);
        this.methodDescriptorListLiverRankingTermTypes = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/ListLiverRankingTermTypes', grpcWeb.MethodType.UNARY, users_v1_pb.ListLiverRankingTermTypesV1Request, users_v1_pb.ListLiverRankingTermTypesV1Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.ListLiverRankingTermTypesV1Response.deserializeBinary);
        this.methodDescriptorListLiverRankingV2 = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/ListLiverRankingV2', grpcWeb.MethodType.UNARY, users_v1_pb.ListLiverRankingV2Request, users_v1_pb.ListLiverRankingV2Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.ListLiverRankingV2Response.deserializeBinary);
        this.methodDescriptorGetLoginBonus = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/GetLoginBonus', grpcWeb.MethodType.UNARY, users_v1_pb.GetLoginBonusV1Request, users_v1_pb.GetLoginBonusV1Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.GetLoginBonusV1Response.deserializeBinary);
        this.methodDescriptorRequestWithdrawV1 = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/RequestWithdrawV1', grpcWeb.MethodType.UNARY, users_v1_pb.RequestWithdrawV1Request, users_v1_pb.RequestWithdrawV1Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.RequestWithdrawV1Response.deserializeBinary);
        this.methodDescriptorCancelWithdrawV1 = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/CancelWithdrawV1', grpcWeb.MethodType.UNARY, users_v1_pb.CancelWithdrawV1Request, users_v1_pb.CancelWithdrawV1Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.CancelWithdrawV1Response.deserializeBinary);
        this.methodDescriptorGetWithdrawStatusV1 = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/GetWithdrawStatusV1', grpcWeb.MethodType.UNARY, users_v1_pb.GetWithdrawV1Request, users_v1_pb.GetWithdrawV1Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.GetWithdrawV1Response.deserializeBinary);
        this.methodDescriptorListWebPageV1 = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/ListWebPageV1', grpcWeb.MethodType.UNARY, users_v1_pb.ListWebPageV1Request, users_v1_pb.ListWebPageV1Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.ListWebPageV1Response.deserializeBinary);
        this.methodDescriptorGetUnreadNotificationInfo = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/GetUnreadNotificationInfo', grpcWeb.MethodType.UNARY, users_v1_pb.GetUnreadNotificationInfoV1Request, users_v1_pb.GetUnreadNotificationInfoV1Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.GetUnreadNotificationInfoV1Response.deserializeBinary);
        this.methodDescriptorReadNotification = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/ReadNotification', grpcWeb.MethodType.UNARY, users_v1_pb.ReadNotificationV1Request, users_v1_pb.ReadNotificationV1Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.ReadNotificationV1Response.deserializeBinary);
        this.methodDescriptorGetUserBanV1 = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/GetUserBanV1', grpcWeb.MethodType.UNARY, users_v1_pb.GetUserBanV1Request, users_v1_pb.GetUserBanV1Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.GetUserBanV1Response.deserializeBinary);
        this.methodDescriptorListRecommendedLSingers = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/ListRecommendedLSingers', grpcWeb.MethodType.UNARY, users_v1_pb.ListRecommendedLSingersV1Request, users_v1_pb.ListRecommendedLSingersV1Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.ListRecommendedLSingersV1Response.deserializeBinary);
        this.methodDescriptorGetTweetTimeline = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/GetTweetTimeline', grpcWeb.MethodType.UNARY, users_v1_pb.GetTweetTimelineV1Request, users_v1_pb.GetTweetTimelineV1Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.GetTweetTimelineV1Response.deserializeBinary);
        this.methodDescriptorGetRequestStoreReviewDisplayState = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/GetRequestStoreReviewDisplayState', grpcWeb.MethodType.UNARY, users_v1_pb.GetRequestStoreReviewDisplayStateV1Request, users_v1_pb.GetRequestStoreReviewDisplayStateV1Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.GetRequestStoreReviewDisplayStateV1Response.deserializeBinary);
        this.methodDescriptorSaveStoreReviewRequestAction = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/SaveStoreReviewRequestAction', grpcWeb.MethodType.UNARY, users_v1_pb.SaveStoreReviewRequestActionV1Request, users_v1_pb.SaveStoreReviewRequestActionV1Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.SaveStoreReviewRequestActionV1Response.deserializeBinary);
        this.methodDescriptorListAvailableWebFeatures = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/ListAvailableWebFeatures', grpcWeb.MethodType.UNARY, users_v1_pb.ListAvailableWebFeaturesV1Request, users_v1_pb.ListAvailableWebFeaturesV1Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.ListAvailableWebFeaturesV1Response.deserializeBinary);
        this.methodDescriptorListAvailableAppFeatures = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/ListAvailableAppFeatures', grpcWeb.MethodType.UNARY, users_v1_pb.ListAvailableAppFeaturesV1Request, users_v1_pb.ListAvailableAppFeaturesV1Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.ListAvailableAppFeaturesV1Response.deserializeBinary);
        this.methodDescriptorListPearlCupRanking = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/ListPearlCupRanking', grpcWeb.MethodType.UNARY, users_v1_pb.ListPearlCupRankingV1Request, users_v1_pb.ListPearlCupRankingV1Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.ListPearlCupRankingV1Response.deserializeBinary);
        this.methodDescriptorGetPearlPrizeItemDream = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/GetPearlPrizeItemDream', grpcWeb.MethodType.UNARY, users_v1_pb.GetPearlPrizeItemDreamV1Request, users_v1_pb.GetPearlPrizeItemDreamV1Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.GetPearlPrizeItemDreamV1Response.deserializeBinary);
        this.methodDescriptorListPearlCupPrizeItems = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/ListPearlCupPrizeItems', grpcWeb.MethodType.UNARY, users_v1_pb.ListPearlCupPrizeItemsV1Request, users_v1_pb.ListPearlCupPrizeItemsV1Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.ListPearlCupPrizeItemsV1Response.deserializeBinary);
        this.methodDescriptorUpdatePearlPrizeItemDream = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/UpdatePearlPrizeItemDream', grpcWeb.MethodType.UNARY, users_v1_pb.UpdatePearlPrizeItemDreamV1Request, users_v1_pb.UpdatePearlPrizeItemDreamV1Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.UpdatePearlPrizeItemDreamV1Response.deserializeBinary);
        this.methodDescriptorGetListenerMembershipInfo = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/GetListenerMembershipInfo', grpcWeb.MethodType.UNARY, users_v1_pb.GetListenerMembershipInfoV1Request, users_v1_pb.GetListenerMembershipInfoV1Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.GetListenerMembershipInfoV1Response.deserializeBinary);
        this.methodDescriptorGetLiverMembershipInfo = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/GetLiverMembershipInfo', grpcWeb.MethodType.UNARY, users_v1_pb.GetLiverMembershipInfoV1Request, users_v1_pb.GetLiverMembershipInfoV1Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.GetLiverMembershipInfoV1Response.deserializeBinary);
        this.methodDescriptorListBlockingAndBlockedUserIDs = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/ListBlockingAndBlockedUserIDs', grpcWeb.MethodType.UNARY, users_v1_pb.ListBlockingAndBlockedUserIDsV1Request, users_v1_pb.ListBlockingAndBlockedUserIDsV1Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.ListBlockingAndBlockedUserIDsV1Response.deserializeBinary);
        this.methodDescriptorGetLivePermissionReviewDialog = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/GetLivePermissionReviewDialog', grpcWeb.MethodType.UNARY, users_v1_pb.GetLivePermissionReviewDialogV1Request, users_v1_pb.GetLivePermissionReviewDialogV1Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.GetLivePermissionReviewDialogV1Response.deserializeBinary);
        this.methodDescriptorCreateModerator = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/CreateModerator', grpcWeb.MethodType.UNARY, users_v1_pb.CreateModeratorV1Request, users_v1_pb.CreateModeratorV1Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.CreateModeratorV1Response.deserializeBinary);
        this.methodDescriptorListModerators = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/ListModerators', grpcWeb.MethodType.UNARY, users_v1_pb.ListModeratorsV1Request, users_v1_pb.ListModeratorsV1Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.ListModeratorsV1Response.deserializeBinary);
        this.methodDescriptorTerminateModerator = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/TerminateModerator', grpcWeb.MethodType.UNARY, users_v1_pb.TerminateModeratorV1Request, users_v1_pb.TerminateModeratorV1Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.TerminateModeratorV1Response.deserializeBinary);
        this.methodDescriptorDoBlockByModerator = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/DoBlockByModerator', grpcWeb.MethodType.UNARY, users_v1_pb.DoBlockByModeratorV1Request, users_v1_pb.DoBlockByModeratorV1Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.DoBlockByModeratorV1Response.deserializeBinary);
        this.methodDescriptorUnblockByModerator = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/UnblockByModerator', grpcWeb.MethodType.UNARY, users_v1_pb.UnblockByModeratorV1Request, users_v1_pb.UnblockByModeratorV1Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.UnblockByModeratorV1Response.deserializeBinary);
        this.methodDescriptorGetModerator = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/GetModerator', grpcWeb.MethodType.UNARY, users_v1_pb.GetModeratorV1Request, users_v1_pb.GetModeratorV1Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.GetModeratorV1Response.deserializeBinary);
        this.methodDescriptorUpdateGender = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/UpdateGender', grpcWeb.MethodType.UNARY, users_v1_pb.UpdateGenderV1Request, users_v1_pb.UpdateGenderV1Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.UpdateGenderV1Response.deserializeBinary);
        this.methodDescriptorGetIsLiverBlockingListenerForModerator = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/GetIsLiverBlockingListenerForModerator', grpcWeb.MethodType.UNARY, users_v1_pb.GetIsLiverBlockingListenerForModeratorV1Request, users_v1_pb.GetIsLiverBlockingListenerForModeratorV1Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.GetIsLiverBlockingListenerForModeratorV1Response.deserializeBinary);
        this.methodDescriptorUpdateListenerSongFaveThumbnail = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/UpdateListenerSongFaveThumbnail', grpcWeb.MethodType.UNARY, users_v1_pb.UpdateListenerSongFaveThumbnailV1Request, users_v1_pb.UpdateListenerSongFaveThumbnailV1Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.UpdateListenerSongFaveThumbnailV1Response.deserializeBinary);
        this.methodDescriptorGetListenerSongFaveThumbnail = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/GetListenerSongFaveThumbnail', grpcWeb.MethodType.UNARY, users_v1_pb.GetListenerSongFaveThumbnailV1Request, users_v1_pb.GetListenerSongFaveThumbnailV1Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.GetListenerSongFaveThumbnailV1Response.deserializeBinary);
        this.methodDescriptorListLiverEvaluationCriterionImportance = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/ListLiverEvaluationCriterionImportance', grpcWeb.MethodType.UNARY, users_v1_pb.ListLiverEvaluationCriterionImportanceV1Request, users_v1_pb.ListLiverEvaluationCriterionImportanceV1Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.ListLiverEvaluationCriterionImportanceV1Response.deserializeBinary);
        this.methodDescriptorUpdateLiverEvaluationCriterionImportance = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/UpdateLiverEvaluationCriterionImportance', grpcWeb.MethodType.UNARY, users_v1_pb.UpdateLiverEvaluationCriterionImportanceV1Request, users_v1_pb.UpdateLiverEvaluationCriterionImportanceV1Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.UpdateLiverEvaluationCriterionImportanceV1Response.deserializeBinary);
        this.methodDescriptorListLiverEvaluationCriterionAvoidances = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/ListLiverEvaluationCriterionAvoidances', grpcWeb.MethodType.UNARY, users_v1_pb.ListLiverEvaluationCriterionAvoidancesV1Request, users_v1_pb.ListLiverEvaluationCriterionAvoidancesV1Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.ListLiverEvaluationCriterionAvoidancesV1Response.deserializeBinary);
        this.methodDescriptorUpdateLiverEvaluationCriterionAvoidances = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/UpdateLiverEvaluationCriterionAvoidances', grpcWeb.MethodType.UNARY, users_v1_pb.UpdateLiverEvaluationCriterionAvoidancesV1Request, users_v1_pb.UpdateLiverEvaluationCriterionAvoidancesV1Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.UpdateLiverEvaluationCriterionAvoidancesV1Response.deserializeBinary);
        this.methodDescriptorShouldUpdateLiverEvaluationCriterion = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/ShouldUpdateLiverEvaluationCriterion', grpcWeb.MethodType.UNARY, users_v1_pb.ShouldUpdateLiverEvaluationCriterionV1Request, users_v1_pb.ShouldUpdateLiverEvaluationCriterionV1Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.ShouldUpdateLiverEvaluationCriterionV1Response.deserializeBinary);
        this.methodDescriptorGetUserProfileListenerSongFaveThumbnail = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/GetUserProfileListenerSongFaveThumbnail', grpcWeb.MethodType.UNARY, users_v1_pb.GetUserProfileListenerSongFaveThumbnailV1Request, users_v1_pb.GetUserProfileListenerSongFaveThumbnailV1Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.GetUserProfileListenerSongFaveThumbnailV1Response.deserializeBinary);
        this.methodDescriptorUpdateUserViewingStance = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/UpdateUserViewingStance', grpcWeb.MethodType.UNARY, users_v1_pb.UpdateUserViewingStanceV1Request, users_v1_pb.UpdateUserViewingStanceV1Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.UpdateUserViewingStanceV1Response.deserializeBinary);
        this.methodDescriptorGetUserViewingStance = new grpcWeb.MethodDescriptor('/jp.singcolor.UsersV1/GetUserViewingStance', grpcWeb.MethodType.UNARY, users_v1_pb.GetUserViewingStanceV1Request, users_v1_pb.GetUserViewingStanceV1Response, function (request) {
            return request.serializeBinary();
        }, users_v1_pb.GetUserViewingStanceV1Response.deserializeBinary);
        if (!options)
            options = {};
        if (!credentials)
            credentials = {};
        options['format'] = 'binary';
        this.client_ = new grpcWeb.GrpcWebClientBase(options);
        this.hostname_ = hostname;
        this.credentials_ = credentials;
        this.options_ = options;
    }
    UsersV1Client.prototype.appleLogin = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/AppleLogin', request, metadata || {}, this.methodDescriptorAppleLogin, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/AppleLogin', request, metadata || {}, this.methodDescriptorAppleLogin);
    };
    UsersV1Client.prototype.lineLogin = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/LineLogin', request, metadata || {}, this.methodDescriptorLineLogin, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/LineLogin', request, metadata || {}, this.methodDescriptorLineLogin);
    };
    UsersV1Client.prototype.lineLoginByCode = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/LineLoginByCode', request, metadata || {}, this.methodDescriptorLineLoginByCode, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/LineLoginByCode', request, metadata || {}, this.methodDescriptorLineLoginByCode);
    };
    UsersV1Client.prototype.getLineLoginState = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/GetLineLoginState', request, metadata || {}, this.methodDescriptorGetLineLoginState, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/GetLineLoginState', request, metadata || {}, this.methodDescriptorGetLineLoginState);
    };
    UsersV1Client.prototype.googleLogin = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/GoogleLogin', request, metadata || {}, this.methodDescriptorGoogleLogin, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/GoogleLogin', request, metadata || {}, this.methodDescriptorGoogleLogin);
    };
    UsersV1Client.prototype.createLoginToken = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/CreateLoginToken', request, metadata || {}, this.methodDescriptorCreateLoginToken, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/CreateLoginToken', request, metadata || {}, this.methodDescriptorCreateLoginToken);
    };
    UsersV1Client.prototype.logout = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/Logout', request, metadata || {}, this.methodDescriptorLogout, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/Logout', request, metadata || {}, this.methodDescriptorLogout);
    };
    UsersV1Client.prototype.getUser = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/GetUser', request, metadata || {}, this.methodDescriptorGetUser, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/GetUser', request, metadata || {}, this.methodDescriptorGetUser);
    };
    UsersV1Client.prototype.createUser = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/CreateUser', request, metadata || {}, this.methodDescriptorCreateUser, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/CreateUser', request, metadata || {}, this.methodDescriptorCreateUser);
    };
    UsersV1Client.prototype.doneRegistration = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/DoneRegistration', request, metadata || {}, this.methodDescriptorDoneRegistration, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/DoneRegistration', request, metadata || {}, this.methodDescriptorDoneRegistration);
    };
    UsersV1Client.prototype.listPrefectures = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/ListPrefectures', request, metadata || {}, this.methodDescriptorListPrefectures, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/ListPrefectures', request, metadata || {}, this.methodDescriptorListPrefectures);
    };
    UsersV1Client.prototype.listTowns = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/ListTowns', request, metadata || {}, this.methodDescriptorListTowns, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/ListTowns', request, metadata || {}, this.methodDescriptorListTowns);
    };
    UsersV1Client.prototype.updateUserTown = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/UpdateUserTown', request, metadata || {}, this.methodDescriptorUpdateUserTown, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/UpdateUserTown', request, metadata || {}, this.methodDescriptorUpdateUserTown);
    };
    UsersV1Client.prototype.listFollowings = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/ListFollowings', request, metadata || {}, this.methodDescriptorListFollowings, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/ListFollowings', request, metadata || {}, this.methodDescriptorListFollowings);
    };
    UsersV1Client.prototype.follow = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/Follow', request, metadata || {}, this.methodDescriptorFollow, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/Follow', request, metadata || {}, this.methodDescriptorFollow);
    };
    UsersV1Client.prototype.unfollow = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/Unfollow', request, metadata || {}, this.methodDescriptorUnfollow, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/Unfollow', request, metadata || {}, this.methodDescriptorUnfollow);
    };
    UsersV1Client.prototype.updateFollowNotificationSetting = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/UpdateFollowNotificationSetting', request, metadata || {}, this.methodDescriptorUpdateFollowNotificationSetting, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/UpdateFollowNotificationSetting', request, metadata || {}, this.methodDescriptorUpdateFollowNotificationSetting);
    };
    UsersV1Client.prototype.listFollowers = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/ListFollowers', request, metadata || {}, this.methodDescriptorListFollowers, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/ListFollowers', request, metadata || {}, this.methodDescriptorListFollowers);
    };
    UsersV1Client.prototype.listBlockings = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/ListBlockings', request, metadata || {}, this.methodDescriptorListBlockings, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/ListBlockings', request, metadata || {}, this.methodDescriptorListBlockings);
    };
    UsersV1Client.prototype.doBlock = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/DoBlock', request, metadata || {}, this.methodDescriptorDoBlock, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/DoBlock', request, metadata || {}, this.methodDescriptorDoBlock);
    };
    UsersV1Client.prototype.unblock = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/Unblock', request, metadata || {}, this.methodDescriptorUnblock, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/Unblock', request, metadata || {}, this.methodDescriptorUnblock);
    };
    UsersV1Client.prototype.getUserProfileV2 = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/GetUserProfileV2', request, metadata || {}, this.methodDescriptorGetUserProfileV2, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/GetUserProfileV2', request, metadata || {}, this.methodDescriptorGetUserProfileV2);
    };
    UsersV1Client.prototype.getUserSetting = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/GetUserSetting', request, metadata || {}, this.methodDescriptorGetUserSetting, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/GetUserSetting', request, metadata || {}, this.methodDescriptorGetUserSetting);
    };
    UsersV1Client.prototype.updateUserSetting = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/UpdateUserSetting', request, metadata || {}, this.methodDescriptorUpdateUserSetting, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/UpdateUserSetting', request, metadata || {}, this.methodDescriptorUpdateUserSetting);
    };
    UsersV1Client.prototype.updateUserSettingV2 = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/UpdateUserSettingV2', request, metadata || {}, this.methodDescriptorUpdateUserSettingV2, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/UpdateUserSettingV2', request, metadata || {}, this.methodDescriptorUpdateUserSettingV2);
    };
    UsersV1Client.prototype.updateDevice = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/UpdateDevice', request, metadata || {}, this.methodDescriptorUpdateDevice, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/UpdateDevice', request, metadata || {}, this.methodDescriptorUpdateDevice);
    };
    UsersV1Client.prototype.addTwitterAccount = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/AddTwitterAccount', request, metadata || {}, this.methodDescriptorAddTwitterAccount, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/AddTwitterAccount', request, metadata || {}, this.methodDescriptorAddTwitterAccount);
    };
    UsersV1Client.prototype.listTwitterAccounts = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/ListTwitterAccounts', request, metadata || {}, this.methodDescriptorListTwitterAccounts, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/ListTwitterAccounts', request, metadata || {}, this.methodDescriptorListTwitterAccounts);
    };
    UsersV1Client.prototype.deleteTwitterAccount = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/DeleteTwitterAccount', request, metadata || {}, this.methodDescriptorDeleteTwitterAccount, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/DeleteTwitterAccount', request, metadata || {}, this.methodDescriptorDeleteTwitterAccount);
    };
    UsersV1Client.prototype.getUserTwitterSetting = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/GetUserTwitterSetting', request, metadata || {}, this.methodDescriptorGetUserTwitterSetting, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/GetUserTwitterSetting', request, metadata || {}, this.methodDescriptorGetUserTwitterSetting);
    };
    UsersV1Client.prototype.getCoinBalance = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/GetCoinBalance', request, metadata || {}, this.methodDescriptorGetCoinBalance, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/GetCoinBalance', request, metadata || {}, this.methodDescriptorGetCoinBalance);
    };
    UsersV1Client.prototype.getStatisticsCurrentDiamond = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/GetStatisticsCurrentDiamond', request, metadata || {}, this.methodDescriptorGetStatisticsCurrentDiamond, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/GetStatisticsCurrentDiamond', request, metadata || {}, this.methodDescriptorGetStatisticsCurrentDiamond);
    };
    UsersV1Client.prototype.getMonthlyStatistics = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/GetMonthlyStatistics', request, metadata || {}, this.methodDescriptorGetMonthlyStatistics, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/GetMonthlyStatistics', request, metadata || {}, this.methodDescriptorGetMonthlyStatistics);
    };
    UsersV1Client.prototype.getLiverPromotionMeter = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/GetLiverPromotionMeter', request, metadata || {}, this.methodDescriptorGetLiverPromotionMeter, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/GetLiverPromotionMeter', request, metadata || {}, this.methodDescriptorGetLiverPromotionMeter);
    };
    UsersV1Client.prototype.searchUsers = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/SearchUsers', request, metadata || {}, this.methodDescriptorSearchUsers, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/SearchUsers', request, metadata || {}, this.methodDescriptorSearchUsers);
    };
    UsersV1Client.prototype.listLiverRankingTermTypes = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/ListLiverRankingTermTypes', request, metadata || {}, this.methodDescriptorListLiverRankingTermTypes, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/ListLiverRankingTermTypes', request, metadata || {}, this.methodDescriptorListLiverRankingTermTypes);
    };
    UsersV1Client.prototype.listLiverRankingV2 = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/ListLiverRankingV2', request, metadata || {}, this.methodDescriptorListLiverRankingV2, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/ListLiverRankingV2', request, metadata || {}, this.methodDescriptorListLiverRankingV2);
    };
    UsersV1Client.prototype.getLoginBonus = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/GetLoginBonus', request, metadata || {}, this.methodDescriptorGetLoginBonus, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/GetLoginBonus', request, metadata || {}, this.methodDescriptorGetLoginBonus);
    };
    UsersV1Client.prototype.requestWithdrawV1 = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/RequestWithdrawV1', request, metadata || {}, this.methodDescriptorRequestWithdrawV1, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/RequestWithdrawV1', request, metadata || {}, this.methodDescriptorRequestWithdrawV1);
    };
    UsersV1Client.prototype.cancelWithdrawV1 = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/CancelWithdrawV1', request, metadata || {}, this.methodDescriptorCancelWithdrawV1, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/CancelWithdrawV1', request, metadata || {}, this.methodDescriptorCancelWithdrawV1);
    };
    UsersV1Client.prototype.getWithdrawStatusV1 = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/GetWithdrawStatusV1', request, metadata || {}, this.methodDescriptorGetWithdrawStatusV1, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/GetWithdrawStatusV1', request, metadata || {}, this.methodDescriptorGetWithdrawStatusV1);
    };
    UsersV1Client.prototype.listWebPageV1 = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/ListWebPageV1', request, metadata || {}, this.methodDescriptorListWebPageV1, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/ListWebPageV1', request, metadata || {}, this.methodDescriptorListWebPageV1);
    };
    UsersV1Client.prototype.getUnreadNotificationInfo = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/GetUnreadNotificationInfo', request, metadata || {}, this.methodDescriptorGetUnreadNotificationInfo, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/GetUnreadNotificationInfo', request, metadata || {}, this.methodDescriptorGetUnreadNotificationInfo);
    };
    UsersV1Client.prototype.readNotification = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/ReadNotification', request, metadata || {}, this.methodDescriptorReadNotification, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/ReadNotification', request, metadata || {}, this.methodDescriptorReadNotification);
    };
    UsersV1Client.prototype.getUserBanV1 = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/GetUserBanV1', request, metadata || {}, this.methodDescriptorGetUserBanV1, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/GetUserBanV1', request, metadata || {}, this.methodDescriptorGetUserBanV1);
    };
    UsersV1Client.prototype.listRecommendedLSingers = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/ListRecommendedLSingers', request, metadata || {}, this.methodDescriptorListRecommendedLSingers, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/ListRecommendedLSingers', request, metadata || {}, this.methodDescriptorListRecommendedLSingers);
    };
    UsersV1Client.prototype.getTweetTimeline = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/GetTweetTimeline', request, metadata || {}, this.methodDescriptorGetTweetTimeline, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/GetTweetTimeline', request, metadata || {}, this.methodDescriptorGetTweetTimeline);
    };
    UsersV1Client.prototype.getRequestStoreReviewDisplayState = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/GetRequestStoreReviewDisplayState', request, metadata || {}, this.methodDescriptorGetRequestStoreReviewDisplayState, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/GetRequestStoreReviewDisplayState', request, metadata || {}, this.methodDescriptorGetRequestStoreReviewDisplayState);
    };
    UsersV1Client.prototype.saveStoreReviewRequestAction = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/SaveStoreReviewRequestAction', request, metadata || {}, this.methodDescriptorSaveStoreReviewRequestAction, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/SaveStoreReviewRequestAction', request, metadata || {}, this.methodDescriptorSaveStoreReviewRequestAction);
    };
    UsersV1Client.prototype.listAvailableWebFeatures = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/ListAvailableWebFeatures', request, metadata || {}, this.methodDescriptorListAvailableWebFeatures, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/ListAvailableWebFeatures', request, metadata || {}, this.methodDescriptorListAvailableWebFeatures);
    };
    UsersV1Client.prototype.listAvailableAppFeatures = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/ListAvailableAppFeatures', request, metadata || {}, this.methodDescriptorListAvailableAppFeatures, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/ListAvailableAppFeatures', request, metadata || {}, this.methodDescriptorListAvailableAppFeatures);
    };
    UsersV1Client.prototype.listPearlCupRanking = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/ListPearlCupRanking', request, metadata || {}, this.methodDescriptorListPearlCupRanking, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/ListPearlCupRanking', request, metadata || {}, this.methodDescriptorListPearlCupRanking);
    };
    UsersV1Client.prototype.getPearlPrizeItemDream = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/GetPearlPrizeItemDream', request, metadata || {}, this.methodDescriptorGetPearlPrizeItemDream, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/GetPearlPrizeItemDream', request, metadata || {}, this.methodDescriptorGetPearlPrizeItemDream);
    };
    UsersV1Client.prototype.listPearlCupPrizeItems = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/ListPearlCupPrizeItems', request, metadata || {}, this.methodDescriptorListPearlCupPrizeItems, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/ListPearlCupPrizeItems', request, metadata || {}, this.methodDescriptorListPearlCupPrizeItems);
    };
    UsersV1Client.prototype.updatePearlPrizeItemDream = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/UpdatePearlPrizeItemDream', request, metadata || {}, this.methodDescriptorUpdatePearlPrizeItemDream, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/UpdatePearlPrizeItemDream', request, metadata || {}, this.methodDescriptorUpdatePearlPrizeItemDream);
    };
    UsersV1Client.prototype.getListenerMembershipInfo = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/GetListenerMembershipInfo', request, metadata || {}, this.methodDescriptorGetListenerMembershipInfo, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/GetListenerMembershipInfo', request, metadata || {}, this.methodDescriptorGetListenerMembershipInfo);
    };
    UsersV1Client.prototype.getLiverMembershipInfo = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/GetLiverMembershipInfo', request, metadata || {}, this.methodDescriptorGetLiverMembershipInfo, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/GetLiverMembershipInfo', request, metadata || {}, this.methodDescriptorGetLiverMembershipInfo);
    };
    UsersV1Client.prototype.listBlockingAndBlockedUserIDs = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/ListBlockingAndBlockedUserIDs', request, metadata || {}, this.methodDescriptorListBlockingAndBlockedUserIDs, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/ListBlockingAndBlockedUserIDs', request, metadata || {}, this.methodDescriptorListBlockingAndBlockedUserIDs);
    };
    UsersV1Client.prototype.getLivePermissionReviewDialog = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/GetLivePermissionReviewDialog', request, metadata || {}, this.methodDescriptorGetLivePermissionReviewDialog, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/GetLivePermissionReviewDialog', request, metadata || {}, this.methodDescriptorGetLivePermissionReviewDialog);
    };
    UsersV1Client.prototype.createModerator = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/CreateModerator', request, metadata || {}, this.methodDescriptorCreateModerator, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/CreateModerator', request, metadata || {}, this.methodDescriptorCreateModerator);
    };
    UsersV1Client.prototype.listModerators = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/ListModerators', request, metadata || {}, this.methodDescriptorListModerators, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/ListModerators', request, metadata || {}, this.methodDescriptorListModerators);
    };
    UsersV1Client.prototype.terminateModerator = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/TerminateModerator', request, metadata || {}, this.methodDescriptorTerminateModerator, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/TerminateModerator', request, metadata || {}, this.methodDescriptorTerminateModerator);
    };
    UsersV1Client.prototype.doBlockByModerator = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/DoBlockByModerator', request, metadata || {}, this.methodDescriptorDoBlockByModerator, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/DoBlockByModerator', request, metadata || {}, this.methodDescriptorDoBlockByModerator);
    };
    UsersV1Client.prototype.unblockByModerator = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/UnblockByModerator', request, metadata || {}, this.methodDescriptorUnblockByModerator, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/UnblockByModerator', request, metadata || {}, this.methodDescriptorUnblockByModerator);
    };
    UsersV1Client.prototype.getModerator = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/GetModerator', request, metadata || {}, this.methodDescriptorGetModerator, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/GetModerator', request, metadata || {}, this.methodDescriptorGetModerator);
    };
    UsersV1Client.prototype.updateGender = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/UpdateGender', request, metadata || {}, this.methodDescriptorUpdateGender, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/UpdateGender', request, metadata || {}, this.methodDescriptorUpdateGender);
    };
    UsersV1Client.prototype.getIsLiverBlockingListenerForModerator = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/GetIsLiverBlockingListenerForModerator', request, metadata || {}, this.methodDescriptorGetIsLiverBlockingListenerForModerator, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/GetIsLiverBlockingListenerForModerator', request, metadata || {}, this.methodDescriptorGetIsLiverBlockingListenerForModerator);
    };
    UsersV1Client.prototype.updateListenerSongFaveThumbnail = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/UpdateListenerSongFaveThumbnail', request, metadata || {}, this.methodDescriptorUpdateListenerSongFaveThumbnail, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/UpdateListenerSongFaveThumbnail', request, metadata || {}, this.methodDescriptorUpdateListenerSongFaveThumbnail);
    };
    UsersV1Client.prototype.getListenerSongFaveThumbnail = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/GetListenerSongFaveThumbnail', request, metadata || {}, this.methodDescriptorGetListenerSongFaveThumbnail, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/GetListenerSongFaveThumbnail', request, metadata || {}, this.methodDescriptorGetListenerSongFaveThumbnail);
    };
    UsersV1Client.prototype.listLiverEvaluationCriterionImportance = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/ListLiverEvaluationCriterionImportance', request, metadata || {}, this.methodDescriptorListLiverEvaluationCriterionImportance, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/ListLiverEvaluationCriterionImportance', request, metadata || {}, this.methodDescriptorListLiverEvaluationCriterionImportance);
    };
    UsersV1Client.prototype.updateLiverEvaluationCriterionImportance = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/UpdateLiverEvaluationCriterionImportance', request, metadata || {}, this.methodDescriptorUpdateLiverEvaluationCriterionImportance, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/UpdateLiverEvaluationCriterionImportance', request, metadata || {}, this.methodDescriptorUpdateLiverEvaluationCriterionImportance);
    };
    UsersV1Client.prototype.listLiverEvaluationCriterionAvoidances = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/ListLiverEvaluationCriterionAvoidances', request, metadata || {}, this.methodDescriptorListLiverEvaluationCriterionAvoidances, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/ListLiverEvaluationCriterionAvoidances', request, metadata || {}, this.methodDescriptorListLiverEvaluationCriterionAvoidances);
    };
    UsersV1Client.prototype.updateLiverEvaluationCriterionAvoidances = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/UpdateLiverEvaluationCriterionAvoidances', request, metadata || {}, this.methodDescriptorUpdateLiverEvaluationCriterionAvoidances, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/UpdateLiverEvaluationCriterionAvoidances', request, metadata || {}, this.methodDescriptorUpdateLiverEvaluationCriterionAvoidances);
    };
    UsersV1Client.prototype.shouldUpdateLiverEvaluationCriterion = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/ShouldUpdateLiverEvaluationCriterion', request, metadata || {}, this.methodDescriptorShouldUpdateLiverEvaluationCriterion, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/ShouldUpdateLiverEvaluationCriterion', request, metadata || {}, this.methodDescriptorShouldUpdateLiverEvaluationCriterion);
    };
    UsersV1Client.prototype.getUserProfileListenerSongFaveThumbnail = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/GetUserProfileListenerSongFaveThumbnail', request, metadata || {}, this.methodDescriptorGetUserProfileListenerSongFaveThumbnail, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/GetUserProfileListenerSongFaveThumbnail', request, metadata || {}, this.methodDescriptorGetUserProfileListenerSongFaveThumbnail);
    };
    UsersV1Client.prototype.updateUserViewingStance = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/UpdateUserViewingStance', request, metadata || {}, this.methodDescriptorUpdateUserViewingStance, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/UpdateUserViewingStance', request, metadata || {}, this.methodDescriptorUpdateUserViewingStance);
    };
    UsersV1Client.prototype.getUserViewingStance = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.UsersV1/GetUserViewingStance', request, metadata || {}, this.methodDescriptorGetUserViewingStance, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.UsersV1/GetUserViewingStance', request, metadata || {}, this.methodDescriptorGetUserViewingStance);
    };
    return UsersV1Client;
}());
export { UsersV1Client };
