"use strict";
// source: lives_v1.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();
var coins_entity_pb = require('./coins_entity_pb.js');
goog.object.extend(proto, coins_entity_pb);
var karaoke_entity_pb = require('./karaoke_entity_pb.js');
goog.object.extend(proto, karaoke_entity_pb);
var lives_entity_pb = require('./lives_entity_pb.js');
goog.object.extend(proto, lives_entity_pb);
var page_entity_pb = require('./page_entity_pb.js');
goog.object.extend(proto, page_entity_pb);
var users_entity_pb = require('./users_entity_pb.js');
goog.object.extend(proto, users_entity_pb);
var validate_validate_pb = require('./validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
goog.exportSymbol('proto.jp.singcolor.BanListenerCommentByModeratorV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.BanListenerCommentByModeratorV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.BanListenerCommentV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.BanListenerCommentV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.BanListenerReqCaptureByModeratorV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.BanListenerReqCaptureByModeratorV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.BanListenerReqCaptureV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.BanListenerReqCaptureV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.CreateLiveCommentV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.CreateLiveCommentV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.EnterLiveV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.EnterLiveV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.FinishLiveV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.FinishLiveV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.FinishSingV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.FinishSingV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.GetBannedListenerCommentV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.GetBannedListenerCommentV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.GetBannedListenerReqCaptureV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.GetBannedListenerReqCaptureV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.GetLiveAvailableTimeRangeV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.GetLiveAvailableTimeRangeV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.GetLivePermissionV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.GetLivePermissionV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.GetLiveResultBasicInfoV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.GetLiveResultBasicInfoV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.GetLiveResultBasicInfoV2Request', null, global);
goog.exportSymbol('proto.jp.singcolor.GetLiveResultBasicInfoV2Response', null, global);
goog.exportSymbol('proto.jp.singcolor.GetLiveResultGiftV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.GetLiveResultGiftV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.GetLiveV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.GetLiveV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.GetNextFavePointsProgressV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.GetNextFavePointsProgressV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.GetRankOfRankingEventV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.GetRankOfRankingEventV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.GetRankOfRankingEventV1Response.IconClickTransitionDestination', null, global);
goog.exportSymbol('proto.jp.singcolor.GetSuperLikeLiveTimingV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.GetSuperLikeLiveTimingV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.GetUserDashboardInfoV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.GetUserDashboardInfoV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.GetUserOnAirLiveV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.GetUserOnAirLiveV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.HeartbeatLiveV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.HeartbeatLiveV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.HeartbeatLiveViewingV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.HeartbeatLiveViewingV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.LeaveLiveV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.LeaveLiveV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListClosingTimesV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListClosingTimesV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListFanInfoSongFaveTabsV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListFanInfoSongFaveTabsV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListFollowingLivesV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListFollowingLivesV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListGiftTabsV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListGiftTabsV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListGiftsForDownloadV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListGiftsForDownloadV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListGiftsV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListGiftsV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListInitialClosingTimesV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListInitialClosingTimesV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListLiveHistoriesV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListLiveHistoriesV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListLiveTimelineBannersV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListLiveTimelineBannersV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListLiveViewingSessionsV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListLiveViewingSessionsV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListLivesV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListLivesV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListLivesV1Response.SpecificLiveTimelineAdditionalInfoCase', null, global);
goog.exportSymbol('proto.jp.singcolor.ListPfFavePointsRankingListenersV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListPfFavePointsRankingListenersV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListTimelineInfosV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListTimelineInfosV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.PickUpSongRandomlyV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.PickUpSongRandomlyV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.PlayYoutubeV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.PlayYoutubeV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.PrepareLiveV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.PrepareLiveV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.PrepareLiveV1Response.LiveAvailableStatus', null, global);
goog.exportSymbol('proto.jp.singcolor.PrepareLiveV1Response.LiveAvailableStatus.Status', null, global);
goog.exportSymbol('proto.jp.singcolor.PreviewLiveV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.PreviewLiveV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.RequestSingV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.RequestSingV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.SendGiftV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.SendGiftV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.SendHeartfulMentionV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.SendHeartfulMentionV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.StartLiveV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.StartLiveV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.StartSingV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.StartSingV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.StreamingQuality', null, global);
goog.exportSymbol('proto.jp.singcolor.SuperLikeLiveV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.SuperLikeLiveV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.UnbanListenerCommentByModeratorV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.UnbanListenerCommentByModeratorV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.UnbanListenerCommentV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.UnbanListenerCommentV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.UnbanListenerReqCaptureByModeratorV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.UnbanListenerReqCaptureByModeratorV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.UnbanListenerReqCaptureV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.UnbanListenerReqCaptureV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateLiveBackgroundV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateLiveBackgroundV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateLiveTitleV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateUserLiveClosingTimeV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateUserLiveClosingTimeV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateUserLivePostEnterCommentV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateUserLivePostEnterCommentV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateUserLivePreEnterCommentV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateUserLivePreEnterCommentV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateUserLiveRadioModeV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateUserLiveRadioModeV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateUserLiveShareTwitterAccountV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateUserLiveShareTwitterAccountV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ValidateLivePublicationSettingV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ValidateLivePublicationSettingV1Response', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetLiveV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetLiveV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetLiveV1Request.displayName = 'proto.jp.singcolor.GetLiveV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetLiveV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetLiveV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetLiveV1Response.displayName = 'proto.jp.singcolor.GetLiveV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetUserOnAirLiveV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetUserOnAirLiveV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetUserOnAirLiveV1Request.displayName = 'proto.jp.singcolor.GetUserOnAirLiveV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetUserOnAirLiveV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetUserOnAirLiveV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetUserOnAirLiveV1Response.displayName = 'proto.jp.singcolor.GetUserOnAirLiveV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListLivesV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListLivesV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListLivesV1Request.displayName = 'proto.jp.singcolor.ListLivesV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListLivesV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListLivesV1Response.repeatedFields_, proto.jp.singcolor.ListLivesV1Response.oneofGroups_);
};
goog.inherits(proto.jp.singcolor.ListLivesV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListLivesV1Response.displayName = 'proto.jp.singcolor.ListLivesV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListTimelineInfosV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListTimelineInfosV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListTimelineInfosV1Request.displayName = 'proto.jp.singcolor.ListTimelineInfosV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListTimelineInfosV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListTimelineInfosV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListTimelineInfosV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListTimelineInfosV1Response.displayName = 'proto.jp.singcolor.ListTimelineInfosV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListFollowingLivesV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListFollowingLivesV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListFollowingLivesV1Request.displayName = 'proto.jp.singcolor.ListFollowingLivesV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListFollowingLivesV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListFollowingLivesV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListFollowingLivesV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListFollowingLivesV1Response.displayName = 'proto.jp.singcolor.ListFollowingLivesV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.PrepareLiveV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.PrepareLiveV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.PrepareLiveV1Request.displayName = 'proto.jp.singcolor.PrepareLiveV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.PrepareLiveV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.PrepareLiveV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.PrepareLiveV1Response.displayName = 'proto.jp.singcolor.PrepareLiveV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.PrepareLiveV1Response.LiveAvailableStatus = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.PrepareLiveV1Response.LiveAvailableStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.PrepareLiveV1Response.LiveAvailableStatus.displayName = 'proto.jp.singcolor.PrepareLiveV1Response.LiveAvailableStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateUserLiveRadioModeV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateUserLiveRadioModeV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateUserLiveRadioModeV1Request.displayName = 'proto.jp.singcolor.UpdateUserLiveRadioModeV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateUserLiveRadioModeV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateUserLiveRadioModeV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateUserLiveRadioModeV1Response.displayName = 'proto.jp.singcolor.UpdateUserLiveRadioModeV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateUserLivePreEnterCommentV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateUserLivePreEnterCommentV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateUserLivePreEnterCommentV1Request.displayName = 'proto.jp.singcolor.UpdateUserLivePreEnterCommentV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateUserLivePreEnterCommentV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateUserLivePreEnterCommentV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateUserLivePreEnterCommentV1Response.displayName = 'proto.jp.singcolor.UpdateUserLivePreEnterCommentV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateUserLivePostEnterCommentV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateUserLivePostEnterCommentV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateUserLivePostEnterCommentV1Request.displayName = 'proto.jp.singcolor.UpdateUserLivePostEnterCommentV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateUserLivePostEnterCommentV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateUserLivePostEnterCommentV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateUserLivePostEnterCommentV1Response.displayName = 'proto.jp.singcolor.UpdateUserLivePostEnterCommentV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateUserLiveShareTwitterAccountV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateUserLiveShareTwitterAccountV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateUserLiveShareTwitterAccountV1Request.displayName = 'proto.jp.singcolor.UpdateUserLiveShareTwitterAccountV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateUserLiveShareTwitterAccountV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateUserLiveShareTwitterAccountV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateUserLiveShareTwitterAccountV1Response.displayName = 'proto.jp.singcolor.UpdateUserLiveShareTwitterAccountV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateUserLiveClosingTimeV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateUserLiveClosingTimeV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateUserLiveClosingTimeV1Request.displayName = 'proto.jp.singcolor.UpdateUserLiveClosingTimeV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateUserLiveClosingTimeV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateUserLiveClosingTimeV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateUserLiveClosingTimeV1Response.displayName = 'proto.jp.singcolor.UpdateUserLiveClosingTimeV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ValidateLivePublicationSettingV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ValidateLivePublicationSettingV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ValidateLivePublicationSettingV1Request.displayName = 'proto.jp.singcolor.ValidateLivePublicationSettingV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ValidateLivePublicationSettingV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ValidateLivePublicationSettingV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ValidateLivePublicationSettingV1Response.displayName = 'proto.jp.singcolor.ValidateLivePublicationSettingV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.StartLiveV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.StartLiveV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.StartLiveV1Request.displayName = 'proto.jp.singcolor.StartLiveV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.StartLiveV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.StartLiveV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.StartLiveV1Response.displayName = 'proto.jp.singcolor.StartLiveV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateLiveTitleV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateLiveTitleV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateLiveTitleV1Response.displayName = 'proto.jp.singcolor.UpdateLiveTitleV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateLiveBackgroundV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateLiveBackgroundV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateLiveBackgroundV1Request.displayName = 'proto.jp.singcolor.UpdateLiveBackgroundV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateLiveBackgroundV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateLiveBackgroundV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateLiveBackgroundV1Response.displayName = 'proto.jp.singcolor.UpdateLiveBackgroundV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.FinishLiveV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.FinishLiveV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.FinishLiveV1Request.displayName = 'proto.jp.singcolor.FinishLiveV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.FinishLiveV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.FinishLiveV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.FinishLiveV1Response.displayName = 'proto.jp.singcolor.FinishLiveV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.PreviewLiveV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.PreviewLiveV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.PreviewLiveV1Request.displayName = 'proto.jp.singcolor.PreviewLiveV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.PreviewLiveV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.PreviewLiveV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.PreviewLiveV1Response.displayName = 'proto.jp.singcolor.PreviewLiveV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.EnterLiveV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.EnterLiveV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.EnterLiveV1Request.displayName = 'proto.jp.singcolor.EnterLiveV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.EnterLiveV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.EnterLiveV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.EnterLiveV1Response.displayName = 'proto.jp.singcolor.EnterLiveV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.LeaveLiveV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.LeaveLiveV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.LeaveLiveV1Request.displayName = 'proto.jp.singcolor.LeaveLiveV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.LeaveLiveV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.LeaveLiveV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.LeaveLiveV1Response.displayName = 'proto.jp.singcolor.LeaveLiveV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.HeartbeatLiveV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.HeartbeatLiveV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.HeartbeatLiveV1Request.displayName = 'proto.jp.singcolor.HeartbeatLiveV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.HeartbeatLiveV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.HeartbeatLiveV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.HeartbeatLiveV1Response.displayName = 'proto.jp.singcolor.HeartbeatLiveV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.HeartbeatLiveViewingV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.HeartbeatLiveViewingV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.HeartbeatLiveViewingV1Request.displayName = 'proto.jp.singcolor.HeartbeatLiveViewingV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.HeartbeatLiveViewingV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.HeartbeatLiveViewingV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.HeartbeatLiveViewingV1Response.displayName = 'proto.jp.singcolor.HeartbeatLiveViewingV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.CreateLiveCommentV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.CreateLiveCommentV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.CreateLiveCommentV1Request.displayName = 'proto.jp.singcolor.CreateLiveCommentV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.CreateLiveCommentV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.CreateLiveCommentV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.CreateLiveCommentV1Response.displayName = 'proto.jp.singcolor.CreateLiveCommentV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.SuperLikeLiveV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.SuperLikeLiveV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.SuperLikeLiveV1Request.displayName = 'proto.jp.singcolor.SuperLikeLiveV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.SuperLikeLiveV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.SuperLikeLiveV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.SuperLikeLiveV1Response.displayName = 'proto.jp.singcolor.SuperLikeLiveV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetSuperLikeLiveTimingV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetSuperLikeLiveTimingV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetSuperLikeLiveTimingV1Request.displayName = 'proto.jp.singcolor.GetSuperLikeLiveTimingV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetSuperLikeLiveTimingV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetSuperLikeLiveTimingV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetSuperLikeLiveTimingV1Response.displayName = 'proto.jp.singcolor.GetSuperLikeLiveTimingV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListLiveViewingSessionsV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListLiveViewingSessionsV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListLiveViewingSessionsV1Request.displayName = 'proto.jp.singcolor.ListLiveViewingSessionsV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListLiveViewingSessionsV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListLiveViewingSessionsV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListLiveViewingSessionsV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListLiveViewingSessionsV1Response.displayName = 'proto.jp.singcolor.ListLiveViewingSessionsV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListLiveHistoriesV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListLiveHistoriesV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListLiveHistoriesV1Request.displayName = 'proto.jp.singcolor.ListLiveHistoriesV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListLiveHistoriesV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListLiveHistoriesV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListLiveHistoriesV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListLiveHistoriesV1Response.displayName = 'proto.jp.singcolor.ListLiveHistoriesV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.StartSingV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.StartSingV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.StartSingV1Request.displayName = 'proto.jp.singcolor.StartSingV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.StartSingV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.StartSingV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.StartSingV1Response.displayName = 'proto.jp.singcolor.StartSingV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.FinishSingV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.FinishSingV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.FinishSingV1Request.displayName = 'proto.jp.singcolor.FinishSingV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.FinishSingV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.FinishSingV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.FinishSingV1Response.displayName = 'proto.jp.singcolor.FinishSingV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.RequestSingV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.RequestSingV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.RequestSingV1Request.displayName = 'proto.jp.singcolor.RequestSingV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.RequestSingV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.RequestSingV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.RequestSingV1Response.displayName = 'proto.jp.singcolor.RequestSingV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListGiftTabsV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListGiftTabsV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListGiftTabsV1Request.displayName = 'proto.jp.singcolor.ListGiftTabsV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListGiftTabsV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListGiftTabsV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListGiftTabsV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListGiftTabsV1Response.displayName = 'proto.jp.singcolor.ListGiftTabsV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListGiftsV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListGiftsV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListGiftsV1Request.displayName = 'proto.jp.singcolor.ListGiftsV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListGiftsV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListGiftsV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListGiftsV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListGiftsV1Response.displayName = 'proto.jp.singcolor.ListGiftsV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListGiftsForDownloadV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListGiftsForDownloadV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListGiftsForDownloadV1Request.displayName = 'proto.jp.singcolor.ListGiftsForDownloadV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListGiftsForDownloadV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListGiftsForDownloadV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListGiftsForDownloadV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListGiftsForDownloadV1Response.displayName = 'proto.jp.singcolor.ListGiftsForDownloadV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListClosingTimesV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListClosingTimesV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListClosingTimesV1Request.displayName = 'proto.jp.singcolor.ListClosingTimesV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListClosingTimesV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListClosingTimesV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListClosingTimesV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListClosingTimesV1Response.displayName = 'proto.jp.singcolor.ListClosingTimesV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListInitialClosingTimesV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListInitialClosingTimesV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListInitialClosingTimesV1Request.displayName = 'proto.jp.singcolor.ListInitialClosingTimesV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListInitialClosingTimesV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListInitialClosingTimesV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListInitialClosingTimesV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListInitialClosingTimesV1Response.displayName = 'proto.jp.singcolor.ListInitialClosingTimesV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.SendGiftV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.SendGiftV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.SendGiftV1Request.displayName = 'proto.jp.singcolor.SendGiftV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.SendGiftV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.SendGiftV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.SendGiftV1Response.displayName = 'proto.jp.singcolor.SendGiftV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Request.displayName = 'proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Response.displayName = 'proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListFanInfoSongFaveTabsV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListFanInfoSongFaveTabsV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListFanInfoSongFaveTabsV1Request.displayName = 'proto.jp.singcolor.ListFanInfoSongFaveTabsV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListFanInfoSongFaveTabsV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListFanInfoSongFaveTabsV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListFanInfoSongFaveTabsV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListFanInfoSongFaveTabsV1Response.displayName = 'proto.jp.singcolor.ListFanInfoSongFaveTabsV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Request.displayName = 'proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Response.displayName = 'proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListPfFavePointsRankingListenersV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListPfFavePointsRankingListenersV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListPfFavePointsRankingListenersV1Request.displayName = 'proto.jp.singcolor.ListPfFavePointsRankingListenersV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListPfFavePointsRankingListenersV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListPfFavePointsRankingListenersV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListPfFavePointsRankingListenersV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListPfFavePointsRankingListenersV1Response.displayName = 'proto.jp.singcolor.ListPfFavePointsRankingListenersV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetLiveResultBasicInfoV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetLiveResultBasicInfoV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetLiveResultBasicInfoV1Request.displayName = 'proto.jp.singcolor.GetLiveResultBasicInfoV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetLiveResultBasicInfoV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.GetLiveResultBasicInfoV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.GetLiveResultBasicInfoV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetLiveResultBasicInfoV1Response.displayName = 'proto.jp.singcolor.GetLiveResultBasicInfoV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetLiveResultBasicInfoV2Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetLiveResultBasicInfoV2Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetLiveResultBasicInfoV2Request.displayName = 'proto.jp.singcolor.GetLiveResultBasicInfoV2Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetLiveResultBasicInfoV2Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.GetLiveResultBasicInfoV2Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.GetLiveResultBasicInfoV2Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetLiveResultBasicInfoV2Response.displayName = 'proto.jp.singcolor.GetLiveResultBasicInfoV2Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetLiveResultGiftV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetLiveResultGiftV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetLiveResultGiftV1Request.displayName = 'proto.jp.singcolor.GetLiveResultGiftV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetLiveResultGiftV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.GetLiveResultGiftV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.GetLiveResultGiftV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetLiveResultGiftV1Response.displayName = 'proto.jp.singcolor.GetLiveResultGiftV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetUserDashboardInfoV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetUserDashboardInfoV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetUserDashboardInfoV1Request.displayName = 'proto.jp.singcolor.GetUserDashboardInfoV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetUserDashboardInfoV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetUserDashboardInfoV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetUserDashboardInfoV1Response.displayName = 'proto.jp.singcolor.GetUserDashboardInfoV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetLivePermissionV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetLivePermissionV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetLivePermissionV1Request.displayName = 'proto.jp.singcolor.GetLivePermissionV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetLivePermissionV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetLivePermissionV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetLivePermissionV1Response.displayName = 'proto.jp.singcolor.GetLivePermissionV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetLiveAvailableTimeRangeV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetLiveAvailableTimeRangeV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetLiveAvailableTimeRangeV1Request.displayName = 'proto.jp.singcolor.GetLiveAvailableTimeRangeV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetLiveAvailableTimeRangeV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetLiveAvailableTimeRangeV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetLiveAvailableTimeRangeV1Response.displayName = 'proto.jp.singcolor.GetLiveAvailableTimeRangeV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.BanListenerCommentV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.BanListenerCommentV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.BanListenerCommentV1Request.displayName = 'proto.jp.singcolor.BanListenerCommentV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.BanListenerCommentV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.BanListenerCommentV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.BanListenerCommentV1Response.displayName = 'proto.jp.singcolor.BanListenerCommentV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UnbanListenerCommentV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UnbanListenerCommentV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UnbanListenerCommentV1Request.displayName = 'proto.jp.singcolor.UnbanListenerCommentV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UnbanListenerCommentV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UnbanListenerCommentV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UnbanListenerCommentV1Response.displayName = 'proto.jp.singcolor.UnbanListenerCommentV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetBannedListenerCommentV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetBannedListenerCommentV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetBannedListenerCommentV1Request.displayName = 'proto.jp.singcolor.GetBannedListenerCommentV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetBannedListenerCommentV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetBannedListenerCommentV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetBannedListenerCommentV1Response.displayName = 'proto.jp.singcolor.GetBannedListenerCommentV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.BanListenerReqCaptureV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.BanListenerReqCaptureV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.BanListenerReqCaptureV1Request.displayName = 'proto.jp.singcolor.BanListenerReqCaptureV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.BanListenerReqCaptureV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.BanListenerReqCaptureV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.BanListenerReqCaptureV1Response.displayName = 'proto.jp.singcolor.BanListenerReqCaptureV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UnbanListenerReqCaptureV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UnbanListenerReqCaptureV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UnbanListenerReqCaptureV1Request.displayName = 'proto.jp.singcolor.UnbanListenerReqCaptureV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UnbanListenerReqCaptureV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UnbanListenerReqCaptureV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UnbanListenerReqCaptureV1Response.displayName = 'proto.jp.singcolor.UnbanListenerReqCaptureV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetBannedListenerReqCaptureV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetBannedListenerReqCaptureV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetBannedListenerReqCaptureV1Request.displayName = 'proto.jp.singcolor.GetBannedListenerReqCaptureV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetBannedListenerReqCaptureV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetBannedListenerReqCaptureV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetBannedListenerReqCaptureV1Response.displayName = 'proto.jp.singcolor.GetBannedListenerReqCaptureV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetNextFavePointsProgressV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetNextFavePointsProgressV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetNextFavePointsProgressV1Request.displayName = 'proto.jp.singcolor.GetNextFavePointsProgressV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetNextFavePointsProgressV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetNextFavePointsProgressV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetNextFavePointsProgressV1Response.displayName = 'proto.jp.singcolor.GetNextFavePointsProgressV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListLiveTimelineBannersV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListLiveTimelineBannersV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListLiveTimelineBannersV1Request.displayName = 'proto.jp.singcolor.ListLiveTimelineBannersV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListLiveTimelineBannersV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListLiveTimelineBannersV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListLiveTimelineBannersV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListLiveTimelineBannersV1Response.displayName = 'proto.jp.singcolor.ListLiveTimelineBannersV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetRankOfRankingEventV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetRankOfRankingEventV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetRankOfRankingEventV1Request.displayName = 'proto.jp.singcolor.GetRankOfRankingEventV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetRankOfRankingEventV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetRankOfRankingEventV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetRankOfRankingEventV1Response.displayName = 'proto.jp.singcolor.GetRankOfRankingEventV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.SendHeartfulMentionV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.SendHeartfulMentionV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.SendHeartfulMentionV1Request.displayName = 'proto.jp.singcolor.SendHeartfulMentionV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.SendHeartfulMentionV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.SendHeartfulMentionV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.SendHeartfulMentionV1Response.displayName = 'proto.jp.singcolor.SendHeartfulMentionV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.PlayYoutubeV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.PlayYoutubeV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.PlayYoutubeV1Request.displayName = 'proto.jp.singcolor.PlayYoutubeV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.PlayYoutubeV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.PlayYoutubeV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.PlayYoutubeV1Response.displayName = 'proto.jp.singcolor.PlayYoutubeV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.PickUpSongRandomlyV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.PickUpSongRandomlyV1Request.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.PickUpSongRandomlyV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.PickUpSongRandomlyV1Request.displayName = 'proto.jp.singcolor.PickUpSongRandomlyV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.PickUpSongRandomlyV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.PickUpSongRandomlyV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.PickUpSongRandomlyV1Response.displayName = 'proto.jp.singcolor.PickUpSongRandomlyV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.BanListenerCommentByModeratorV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.BanListenerCommentByModeratorV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.BanListenerCommentByModeratorV1Request.displayName = 'proto.jp.singcolor.BanListenerCommentByModeratorV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.BanListenerCommentByModeratorV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.BanListenerCommentByModeratorV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.BanListenerCommentByModeratorV1Response.displayName = 'proto.jp.singcolor.BanListenerCommentByModeratorV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UnbanListenerCommentByModeratorV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UnbanListenerCommentByModeratorV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UnbanListenerCommentByModeratorV1Request.displayName = 'proto.jp.singcolor.UnbanListenerCommentByModeratorV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UnbanListenerCommentByModeratorV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UnbanListenerCommentByModeratorV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UnbanListenerCommentByModeratorV1Response.displayName = 'proto.jp.singcolor.UnbanListenerCommentByModeratorV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.BanListenerReqCaptureByModeratorV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.BanListenerReqCaptureByModeratorV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.BanListenerReqCaptureByModeratorV1Request.displayName = 'proto.jp.singcolor.BanListenerReqCaptureByModeratorV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.BanListenerReqCaptureByModeratorV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.BanListenerReqCaptureByModeratorV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.BanListenerReqCaptureByModeratorV1Response.displayName = 'proto.jp.singcolor.BanListenerReqCaptureByModeratorV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UnbanListenerReqCaptureByModeratorV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UnbanListenerReqCaptureByModeratorV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UnbanListenerReqCaptureByModeratorV1Request.displayName = 'proto.jp.singcolor.UnbanListenerReqCaptureByModeratorV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UnbanListenerReqCaptureByModeratorV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UnbanListenerReqCaptureByModeratorV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UnbanListenerReqCaptureByModeratorV1Response.displayName = 'proto.jp.singcolor.UnbanListenerReqCaptureByModeratorV1Response';
}
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetLiveV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetLiveV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetLiveV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetLiveV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            liveId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetLiveV1Request}
 */
proto.jp.singcolor.GetLiveV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetLiveV1Request;
    return proto.jp.singcolor.GetLiveV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetLiveV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetLiveV1Request}
 */
proto.jp.singcolor.GetLiveV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiveId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetLiveV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetLiveV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetLiveV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetLiveV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiveId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string live_id = 1;
 * @return {string}
 */
proto.jp.singcolor.GetLiveV1Request.prototype.getLiveId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetLiveV1Request} returns this
 */
proto.jp.singcolor.GetLiveV1Request.prototype.setLiveId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetLiveV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetLiveV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetLiveV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetLiveV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            live: (f = msg.getLive()) && lives_entity_pb.Live.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetLiveV1Response}
 */
proto.jp.singcolor.GetLiveV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetLiveV1Response;
    return proto.jp.singcolor.GetLiveV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetLiveV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetLiveV1Response}
 */
proto.jp.singcolor.GetLiveV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new lives_entity_pb.Live;
                reader.readMessage(value, lives_entity_pb.Live.deserializeBinaryFromReader);
                msg.setLive(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetLiveV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetLiveV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetLiveV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetLiveV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLive();
    if (f != null) {
        writer.writeMessage(1, f, lives_entity_pb.Live.serializeBinaryToWriter);
    }
};
/**
 * optional Live live = 1;
 * @return {?proto.jp.singcolor.Live}
 */
proto.jp.singcolor.GetLiveV1Response.prototype.getLive = function () {
    return /** @type{?proto.jp.singcolor.Live} */ (jspb.Message.getWrapperField(this, lives_entity_pb.Live, 1));
};
/**
 * @param {?proto.jp.singcolor.Live|undefined} value
 * @return {!proto.jp.singcolor.GetLiveV1Response} returns this
*/
proto.jp.singcolor.GetLiveV1Response.prototype.setLive = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetLiveV1Response} returns this
 */
proto.jp.singcolor.GetLiveV1Response.prototype.clearLive = function () {
    return this.setLive(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetLiveV1Response.prototype.hasLive = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetUserOnAirLiveV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetUserOnAirLiveV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetUserOnAirLiveV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetUserOnAirLiveV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetUserOnAirLiveV1Request}
 */
proto.jp.singcolor.GetUserOnAirLiveV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetUserOnAirLiveV1Request;
    return proto.jp.singcolor.GetUserOnAirLiveV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetUserOnAirLiveV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetUserOnAirLiveV1Request}
 */
proto.jp.singcolor.GetUserOnAirLiveV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetUserOnAirLiveV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetUserOnAirLiveV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetUserOnAirLiveV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetUserOnAirLiveV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.GetUserOnAirLiveV1Request.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetUserOnAirLiveV1Request} returns this
 */
proto.jp.singcolor.GetUserOnAirLiveV1Request.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetUserOnAirLiveV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetUserOnAirLiveV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetUserOnAirLiveV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetUserOnAirLiveV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            live: (f = msg.getLive()) && lives_entity_pb.Live.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetUserOnAirLiveV1Response}
 */
proto.jp.singcolor.GetUserOnAirLiveV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetUserOnAirLiveV1Response;
    return proto.jp.singcolor.GetUserOnAirLiveV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetUserOnAirLiveV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetUserOnAirLiveV1Response}
 */
proto.jp.singcolor.GetUserOnAirLiveV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new lives_entity_pb.Live;
                reader.readMessage(value, lives_entity_pb.Live.deserializeBinaryFromReader);
                msg.setLive(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetUserOnAirLiveV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetUserOnAirLiveV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetUserOnAirLiveV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetUserOnAirLiveV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLive();
    if (f != null) {
        writer.writeMessage(1, f, lives_entity_pb.Live.serializeBinaryToWriter);
    }
};
/**
 * optional Live live = 1;
 * @return {?proto.jp.singcolor.Live}
 */
proto.jp.singcolor.GetUserOnAirLiveV1Response.prototype.getLive = function () {
    return /** @type{?proto.jp.singcolor.Live} */ (jspb.Message.getWrapperField(this, lives_entity_pb.Live, 1));
};
/**
 * @param {?proto.jp.singcolor.Live|undefined} value
 * @return {!proto.jp.singcolor.GetUserOnAirLiveV1Response} returns this
*/
proto.jp.singcolor.GetUserOnAirLiveV1Response.prototype.setLive = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetUserOnAirLiveV1Response} returns this
 */
proto.jp.singcolor.GetUserOnAirLiveV1Response.prototype.clearLive = function () {
    return this.setLive(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetUserOnAirLiveV1Response.prototype.hasLive = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListLivesV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListLivesV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListLivesV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListLivesV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            page: (f = msg.getPage()) && page_entity_pb.SimplePaginationRequest.toObject(includeInstance, f),
            timelineId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            randomizeSeed: jspb.Message.getFieldWithDefault(msg, 3, 0),
            gender: jspb.Message.getFieldWithDefault(msg, 4, 0),
            worldFilter: jspb.Message.getFieldWithDefault(msg, 5, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListLivesV1Request}
 */
proto.jp.singcolor.ListLivesV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListLivesV1Request;
    return proto.jp.singcolor.ListLivesV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListLivesV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListLivesV1Request}
 */
proto.jp.singcolor.ListLivesV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new page_entity_pb.SimplePaginationRequest;
                reader.readMessage(value, page_entity_pb.SimplePaginationRequest.deserializeBinaryFromReader);
                msg.setPage(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setTimelineId(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setRandomizeSeed(value);
                break;
            case 4:
                var value = /** @type {!proto.jp.singcolor.Gender} */ (reader.readEnum());
                msg.setGender(value);
                break;
            case 5:
                var value = /** @type {!proto.jp.singcolor.WorldFilter} */ (reader.readEnum());
                msg.setWorldFilter(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListLivesV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListLivesV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListLivesV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListLivesV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPage();
    if (f != null) {
        writer.writeMessage(1, f, page_entity_pb.SimplePaginationRequest.serializeBinaryToWriter);
    }
    f = message.getTimelineId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getRandomizeSeed();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
    f = message.getGender();
    if (f !== 0.0) {
        writer.writeEnum(4, f);
    }
    f = message.getWorldFilter();
    if (f !== 0.0) {
        writer.writeEnum(5, f);
    }
};
/**
 * optional SimplePaginationRequest page = 1;
 * @return {?proto.jp.singcolor.SimplePaginationRequest}
 */
proto.jp.singcolor.ListLivesV1Request.prototype.getPage = function () {
    return /** @type{?proto.jp.singcolor.SimplePaginationRequest} */ (jspb.Message.getWrapperField(this, page_entity_pb.SimplePaginationRequest, 1));
};
/**
 * @param {?proto.jp.singcolor.SimplePaginationRequest|undefined} value
 * @return {!proto.jp.singcolor.ListLivesV1Request} returns this
*/
proto.jp.singcolor.ListLivesV1Request.prototype.setPage = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ListLivesV1Request} returns this
 */
proto.jp.singcolor.ListLivesV1Request.prototype.clearPage = function () {
    return this.setPage(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ListLivesV1Request.prototype.hasPage = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional string timeline_id = 2;
 * @return {string}
 */
proto.jp.singcolor.ListLivesV1Request.prototype.getTimelineId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListLivesV1Request} returns this
 */
proto.jp.singcolor.ListLivesV1Request.prototype.setTimelineId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional int64 randomize_seed = 3;
 * @return {number}
 */
proto.jp.singcolor.ListLivesV1Request.prototype.getRandomizeSeed = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListLivesV1Request} returns this
 */
proto.jp.singcolor.ListLivesV1Request.prototype.setRandomizeSeed = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional Gender gender = 4;
 * @return {!proto.jp.singcolor.Gender}
 */
proto.jp.singcolor.ListLivesV1Request.prototype.getGender = function () {
    return /** @type {!proto.jp.singcolor.Gender} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {!proto.jp.singcolor.Gender} value
 * @return {!proto.jp.singcolor.ListLivesV1Request} returns this
 */
proto.jp.singcolor.ListLivesV1Request.prototype.setGender = function (value) {
    return jspb.Message.setProto3EnumField(this, 4, value);
};
/**
 * optional WorldFilter world_filter = 5;
 * @return {!proto.jp.singcolor.WorldFilter}
 */
proto.jp.singcolor.ListLivesV1Request.prototype.getWorldFilter = function () {
    return /** @type {!proto.jp.singcolor.WorldFilter} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {!proto.jp.singcolor.WorldFilter} value
 * @return {!proto.jp.singcolor.ListLivesV1Request} returns this
 */
proto.jp.singcolor.ListLivesV1Request.prototype.setWorldFilter = function (value) {
    return jspb.Message.setProto3EnumField(this, 5, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListLivesV1Response.repeatedFields_ = [1, 3];
/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.jp.singcolor.ListLivesV1Response.oneofGroups_ = [[5]];
/**
 * @enum {number}
 */
proto.jp.singcolor.ListLivesV1Response.SpecificLiveTimelineAdditionalInfoCase = {
    SPECIFIC_LIVE_TIMELINE_ADDITIONAL_INFO_NOT_SET: 0,
    RECOMMEND: 5
};
/**
 * @return {proto.jp.singcolor.ListLivesV1Response.SpecificLiveTimelineAdditionalInfoCase}
 */
proto.jp.singcolor.ListLivesV1Response.prototype.getSpecificLiveTimelineAdditionalInfoCase = function () {
    return /** @type {proto.jp.singcolor.ListLivesV1Response.SpecificLiveTimelineAdditionalInfoCase} */ (jspb.Message.computeOneofCase(this, proto.jp.singcolor.ListLivesV1Response.oneofGroups_[0]));
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListLivesV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListLivesV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListLivesV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListLivesV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            currenttimelinelivesList: jspb.Message.toObjectList(msg.getCurrenttimelinelivesList(), lives_entity_pb.LiveTimelineItem.toObject, includeInstance),
            currenttimeline: (f = msg.getCurrenttimeline()) && lives_entity_pb.LiveTimelineInfo.toObject(includeInstance, f),
            timelinesList: jspb.Message.toObjectList(msg.getTimelinesList(), lives_entity_pb.LiveTimelineInfo.toObject, includeInstance),
            page: (f = msg.getPage()) && page_entity_pb.SimplePaginationResult.toObject(includeInstance, f),
            recommend: (f = msg.getRecommend()) && lives_entity_pb.SpecificLiveTimelineAdditionalInfoRecommend.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListLivesV1Response}
 */
proto.jp.singcolor.ListLivesV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListLivesV1Response;
    return proto.jp.singcolor.ListLivesV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListLivesV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListLivesV1Response}
 */
proto.jp.singcolor.ListLivesV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new lives_entity_pb.LiveTimelineItem;
                reader.readMessage(value, lives_entity_pb.LiveTimelineItem.deserializeBinaryFromReader);
                msg.addCurrenttimelinelives(value);
                break;
            case 2:
                var value = new lives_entity_pb.LiveTimelineInfo;
                reader.readMessage(value, lives_entity_pb.LiveTimelineInfo.deserializeBinaryFromReader);
                msg.setCurrenttimeline(value);
                break;
            case 3:
                var value = new lives_entity_pb.LiveTimelineInfo;
                reader.readMessage(value, lives_entity_pb.LiveTimelineInfo.deserializeBinaryFromReader);
                msg.addTimelines(value);
                break;
            case 4:
                var value = new page_entity_pb.SimplePaginationResult;
                reader.readMessage(value, page_entity_pb.SimplePaginationResult.deserializeBinaryFromReader);
                msg.setPage(value);
                break;
            case 5:
                var value = new lives_entity_pb.SpecificLiveTimelineAdditionalInfoRecommend;
                reader.readMessage(value, lives_entity_pb.SpecificLiveTimelineAdditionalInfoRecommend.deserializeBinaryFromReader);
                msg.setRecommend(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListLivesV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListLivesV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListLivesV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListLivesV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getCurrenttimelinelivesList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, lives_entity_pb.LiveTimelineItem.serializeBinaryToWriter);
    }
    f = message.getCurrenttimeline();
    if (f != null) {
        writer.writeMessage(2, f, lives_entity_pb.LiveTimelineInfo.serializeBinaryToWriter);
    }
    f = message.getTimelinesList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(3, f, lives_entity_pb.LiveTimelineInfo.serializeBinaryToWriter);
    }
    f = message.getPage();
    if (f != null) {
        writer.writeMessage(4, f, page_entity_pb.SimplePaginationResult.serializeBinaryToWriter);
    }
    f = message.getRecommend();
    if (f != null) {
        writer.writeMessage(5, f, lives_entity_pb.SpecificLiveTimelineAdditionalInfoRecommend.serializeBinaryToWriter);
    }
};
/**
 * repeated LiveTimelineItem currentTimelineLives = 1;
 * @return {!Array<!proto.jp.singcolor.LiveTimelineItem>}
 */
proto.jp.singcolor.ListLivesV1Response.prototype.getCurrenttimelinelivesList = function () {
    return /** @type{!Array<!proto.jp.singcolor.LiveTimelineItem>} */ (jspb.Message.getRepeatedWrapperField(this, lives_entity_pb.LiveTimelineItem, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.LiveTimelineItem>} value
 * @return {!proto.jp.singcolor.ListLivesV1Response} returns this
*/
proto.jp.singcolor.ListLivesV1Response.prototype.setCurrenttimelinelivesList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.LiveTimelineItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.LiveTimelineItem}
 */
proto.jp.singcolor.ListLivesV1Response.prototype.addCurrenttimelinelives = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.LiveTimelineItem, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListLivesV1Response} returns this
 */
proto.jp.singcolor.ListLivesV1Response.prototype.clearCurrenttimelinelivesList = function () {
    return this.setCurrenttimelinelivesList([]);
};
/**
 * optional LiveTimelineInfo currentTimeline = 2;
 * @return {?proto.jp.singcolor.LiveTimelineInfo}
 */
proto.jp.singcolor.ListLivesV1Response.prototype.getCurrenttimeline = function () {
    return /** @type{?proto.jp.singcolor.LiveTimelineInfo} */ (jspb.Message.getWrapperField(this, lives_entity_pb.LiveTimelineInfo, 2));
};
/**
 * @param {?proto.jp.singcolor.LiveTimelineInfo|undefined} value
 * @return {!proto.jp.singcolor.ListLivesV1Response} returns this
*/
proto.jp.singcolor.ListLivesV1Response.prototype.setCurrenttimeline = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ListLivesV1Response} returns this
 */
proto.jp.singcolor.ListLivesV1Response.prototype.clearCurrenttimeline = function () {
    return this.setCurrenttimeline(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ListLivesV1Response.prototype.hasCurrenttimeline = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * repeated LiveTimelineInfo timelines = 3;
 * @return {!Array<!proto.jp.singcolor.LiveTimelineInfo>}
 */
proto.jp.singcolor.ListLivesV1Response.prototype.getTimelinesList = function () {
    return /** @type{!Array<!proto.jp.singcolor.LiveTimelineInfo>} */ (jspb.Message.getRepeatedWrapperField(this, lives_entity_pb.LiveTimelineInfo, 3));
};
/**
 * @param {!Array<!proto.jp.singcolor.LiveTimelineInfo>} value
 * @return {!proto.jp.singcolor.ListLivesV1Response} returns this
*/
proto.jp.singcolor.ListLivesV1Response.prototype.setTimelinesList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 3, value);
};
/**
 * @param {!proto.jp.singcolor.LiveTimelineInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.LiveTimelineInfo}
 */
proto.jp.singcolor.ListLivesV1Response.prototype.addTimelines = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.jp.singcolor.LiveTimelineInfo, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListLivesV1Response} returns this
 */
proto.jp.singcolor.ListLivesV1Response.prototype.clearTimelinesList = function () {
    return this.setTimelinesList([]);
};
/**
 * optional SimplePaginationResult page = 4;
 * @return {?proto.jp.singcolor.SimplePaginationResult}
 */
proto.jp.singcolor.ListLivesV1Response.prototype.getPage = function () {
    return /** @type{?proto.jp.singcolor.SimplePaginationResult} */ (jspb.Message.getWrapperField(this, page_entity_pb.SimplePaginationResult, 4));
};
/**
 * @param {?proto.jp.singcolor.SimplePaginationResult|undefined} value
 * @return {!proto.jp.singcolor.ListLivesV1Response} returns this
*/
proto.jp.singcolor.ListLivesV1Response.prototype.setPage = function (value) {
    return jspb.Message.setWrapperField(this, 4, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ListLivesV1Response} returns this
 */
proto.jp.singcolor.ListLivesV1Response.prototype.clearPage = function () {
    return this.setPage(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ListLivesV1Response.prototype.hasPage = function () {
    return jspb.Message.getField(this, 4) != null;
};
/**
 * optional SpecificLiveTimelineAdditionalInfoRecommend recommend = 5;
 * @return {?proto.jp.singcolor.SpecificLiveTimelineAdditionalInfoRecommend}
 */
proto.jp.singcolor.ListLivesV1Response.prototype.getRecommend = function () {
    return /** @type{?proto.jp.singcolor.SpecificLiveTimelineAdditionalInfoRecommend} */ (jspb.Message.getWrapperField(this, lives_entity_pb.SpecificLiveTimelineAdditionalInfoRecommend, 5));
};
/**
 * @param {?proto.jp.singcolor.SpecificLiveTimelineAdditionalInfoRecommend|undefined} value
 * @return {!proto.jp.singcolor.ListLivesV1Response} returns this
*/
proto.jp.singcolor.ListLivesV1Response.prototype.setRecommend = function (value) {
    return jspb.Message.setOneofWrapperField(this, 5, proto.jp.singcolor.ListLivesV1Response.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ListLivesV1Response} returns this
 */
proto.jp.singcolor.ListLivesV1Response.prototype.clearRecommend = function () {
    return this.setRecommend(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ListLivesV1Response.prototype.hasRecommend = function () {
    return jspb.Message.getField(this, 5) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListTimelineInfosV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListTimelineInfosV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListTimelineInfosV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListTimelineInfosV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            gender: jspb.Message.getFieldWithDefault(msg, 4, 0),
            worldFilter: jspb.Message.getFieldWithDefault(msg, 5, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListTimelineInfosV1Request}
 */
proto.jp.singcolor.ListTimelineInfosV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListTimelineInfosV1Request;
    return proto.jp.singcolor.ListTimelineInfosV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListTimelineInfosV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListTimelineInfosV1Request}
 */
proto.jp.singcolor.ListTimelineInfosV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 4:
                var value = /** @type {!proto.jp.singcolor.Gender} */ (reader.readEnum());
                msg.setGender(value);
                break;
            case 5:
                var value = /** @type {!proto.jp.singcolor.WorldFilter} */ (reader.readEnum());
                msg.setWorldFilter(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListTimelineInfosV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListTimelineInfosV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListTimelineInfosV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListTimelineInfosV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getGender();
    if (f !== 0.0) {
        writer.writeEnum(4, f);
    }
    f = message.getWorldFilter();
    if (f !== 0.0) {
        writer.writeEnum(5, f);
    }
};
/**
 * optional Gender gender = 4;
 * @return {!proto.jp.singcolor.Gender}
 */
proto.jp.singcolor.ListTimelineInfosV1Request.prototype.getGender = function () {
    return /** @type {!proto.jp.singcolor.Gender} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {!proto.jp.singcolor.Gender} value
 * @return {!proto.jp.singcolor.ListTimelineInfosV1Request} returns this
 */
proto.jp.singcolor.ListTimelineInfosV1Request.prototype.setGender = function (value) {
    return jspb.Message.setProto3EnumField(this, 4, value);
};
/**
 * optional WorldFilter world_filter = 5;
 * @return {!proto.jp.singcolor.WorldFilter}
 */
proto.jp.singcolor.ListTimelineInfosV1Request.prototype.getWorldFilter = function () {
    return /** @type {!proto.jp.singcolor.WorldFilter} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {!proto.jp.singcolor.WorldFilter} value
 * @return {!proto.jp.singcolor.ListTimelineInfosV1Request} returns this
 */
proto.jp.singcolor.ListTimelineInfosV1Request.prototype.setWorldFilter = function (value) {
    return jspb.Message.setProto3EnumField(this, 5, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListTimelineInfosV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListTimelineInfosV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListTimelineInfosV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListTimelineInfosV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListTimelineInfosV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            timelineInfosList: jspb.Message.toObjectList(msg.getTimelineInfosList(), lives_entity_pb.LiveTimelineInfo.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListTimelineInfosV1Response}
 */
proto.jp.singcolor.ListTimelineInfosV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListTimelineInfosV1Response;
    return proto.jp.singcolor.ListTimelineInfosV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListTimelineInfosV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListTimelineInfosV1Response}
 */
proto.jp.singcolor.ListTimelineInfosV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new lives_entity_pb.LiveTimelineInfo;
                reader.readMessage(value, lives_entity_pb.LiveTimelineInfo.deserializeBinaryFromReader);
                msg.addTimelineInfos(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListTimelineInfosV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListTimelineInfosV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListTimelineInfosV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListTimelineInfosV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getTimelineInfosList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, lives_entity_pb.LiveTimelineInfo.serializeBinaryToWriter);
    }
};
/**
 * repeated LiveTimelineInfo timeline_infos = 1;
 * @return {!Array<!proto.jp.singcolor.LiveTimelineInfo>}
 */
proto.jp.singcolor.ListTimelineInfosV1Response.prototype.getTimelineInfosList = function () {
    return /** @type{!Array<!proto.jp.singcolor.LiveTimelineInfo>} */ (jspb.Message.getRepeatedWrapperField(this, lives_entity_pb.LiveTimelineInfo, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.LiveTimelineInfo>} value
 * @return {!proto.jp.singcolor.ListTimelineInfosV1Response} returns this
*/
proto.jp.singcolor.ListTimelineInfosV1Response.prototype.setTimelineInfosList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.LiveTimelineInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.LiveTimelineInfo}
 */
proto.jp.singcolor.ListTimelineInfosV1Response.prototype.addTimelineInfos = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.LiveTimelineInfo, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListTimelineInfosV1Response} returns this
 */
proto.jp.singcolor.ListTimelineInfosV1Response.prototype.clearTimelineInfosList = function () {
    return this.setTimelineInfosList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListFollowingLivesV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListFollowingLivesV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListFollowingLivesV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListFollowingLivesV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            page: (f = msg.getPage()) && page_entity_pb.SimplePaginationRequest.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListFollowingLivesV1Request}
 */
proto.jp.singcolor.ListFollowingLivesV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListFollowingLivesV1Request;
    return proto.jp.singcolor.ListFollowingLivesV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListFollowingLivesV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListFollowingLivesV1Request}
 */
proto.jp.singcolor.ListFollowingLivesV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new page_entity_pb.SimplePaginationRequest;
                reader.readMessage(value, page_entity_pb.SimplePaginationRequest.deserializeBinaryFromReader);
                msg.setPage(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListFollowingLivesV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListFollowingLivesV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListFollowingLivesV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListFollowingLivesV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPage();
    if (f != null) {
        writer.writeMessage(1, f, page_entity_pb.SimplePaginationRequest.serializeBinaryToWriter);
    }
};
/**
 * optional SimplePaginationRequest page = 1;
 * @return {?proto.jp.singcolor.SimplePaginationRequest}
 */
proto.jp.singcolor.ListFollowingLivesV1Request.prototype.getPage = function () {
    return /** @type{?proto.jp.singcolor.SimplePaginationRequest} */ (jspb.Message.getWrapperField(this, page_entity_pb.SimplePaginationRequest, 1));
};
/**
 * @param {?proto.jp.singcolor.SimplePaginationRequest|undefined} value
 * @return {!proto.jp.singcolor.ListFollowingLivesV1Request} returns this
*/
proto.jp.singcolor.ListFollowingLivesV1Request.prototype.setPage = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ListFollowingLivesV1Request} returns this
 */
proto.jp.singcolor.ListFollowingLivesV1Request.prototype.clearPage = function () {
    return this.setPage(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ListFollowingLivesV1Request.prototype.hasPage = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListFollowingLivesV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListFollowingLivesV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListFollowingLivesV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListFollowingLivesV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListFollowingLivesV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            livesList: jspb.Message.toObjectList(msg.getLivesList(), lives_entity_pb.LiveTimelineItem.toObject, includeInstance),
            page: (f = msg.getPage()) && page_entity_pb.SimplePaginationResult.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListFollowingLivesV1Response}
 */
proto.jp.singcolor.ListFollowingLivesV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListFollowingLivesV1Response;
    return proto.jp.singcolor.ListFollowingLivesV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListFollowingLivesV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListFollowingLivesV1Response}
 */
proto.jp.singcolor.ListFollowingLivesV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new lives_entity_pb.LiveTimelineItem;
                reader.readMessage(value, lives_entity_pb.LiveTimelineItem.deserializeBinaryFromReader);
                msg.addLives(value);
                break;
            case 4:
                var value = new page_entity_pb.SimplePaginationResult;
                reader.readMessage(value, page_entity_pb.SimplePaginationResult.deserializeBinaryFromReader);
                msg.setPage(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListFollowingLivesV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListFollowingLivesV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListFollowingLivesV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListFollowingLivesV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLivesList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, lives_entity_pb.LiveTimelineItem.serializeBinaryToWriter);
    }
    f = message.getPage();
    if (f != null) {
        writer.writeMessage(4, f, page_entity_pb.SimplePaginationResult.serializeBinaryToWriter);
    }
};
/**
 * repeated LiveTimelineItem lives = 1;
 * @return {!Array<!proto.jp.singcolor.LiveTimelineItem>}
 */
proto.jp.singcolor.ListFollowingLivesV1Response.prototype.getLivesList = function () {
    return /** @type{!Array<!proto.jp.singcolor.LiveTimelineItem>} */ (jspb.Message.getRepeatedWrapperField(this, lives_entity_pb.LiveTimelineItem, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.LiveTimelineItem>} value
 * @return {!proto.jp.singcolor.ListFollowingLivesV1Response} returns this
*/
proto.jp.singcolor.ListFollowingLivesV1Response.prototype.setLivesList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.LiveTimelineItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.LiveTimelineItem}
 */
proto.jp.singcolor.ListFollowingLivesV1Response.prototype.addLives = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.LiveTimelineItem, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListFollowingLivesV1Response} returns this
 */
proto.jp.singcolor.ListFollowingLivesV1Response.prototype.clearLivesList = function () {
    return this.setLivesList([]);
};
/**
 * optional SimplePaginationResult page = 4;
 * @return {?proto.jp.singcolor.SimplePaginationResult}
 */
proto.jp.singcolor.ListFollowingLivesV1Response.prototype.getPage = function () {
    return /** @type{?proto.jp.singcolor.SimplePaginationResult} */ (jspb.Message.getWrapperField(this, page_entity_pb.SimplePaginationResult, 4));
};
/**
 * @param {?proto.jp.singcolor.SimplePaginationResult|undefined} value
 * @return {!proto.jp.singcolor.ListFollowingLivesV1Response} returns this
*/
proto.jp.singcolor.ListFollowingLivesV1Response.prototype.setPage = function (value) {
    return jspb.Message.setWrapperField(this, 4, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ListFollowingLivesV1Response} returns this
 */
proto.jp.singcolor.ListFollowingLivesV1Response.prototype.clearPage = function () {
    return this.setPage(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ListFollowingLivesV1Response.prototype.hasPage = function () {
    return jspb.Message.getField(this, 4) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.PrepareLiveV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.PrepareLiveV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.PrepareLiveV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.PrepareLiveV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.PrepareLiveV1Request}
 */
proto.jp.singcolor.PrepareLiveV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.PrepareLiveV1Request;
    return proto.jp.singcolor.PrepareLiveV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.PrepareLiveV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.PrepareLiveV1Request}
 */
proto.jp.singcolor.PrepareLiveV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.PrepareLiveV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.PrepareLiveV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.PrepareLiveV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.PrepareLiveV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.PrepareLiveV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.PrepareLiveV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.PrepareLiveV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.PrepareLiveV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            userLiveSetting: (f = msg.getUserLiveSetting()) && lives_entity_pb.UserLiveSetting.toObject(includeInstance, f),
            userLiveClosingTime: (f = msg.getUserLiveClosingTime()) && users_entity_pb.UserLiveClosingTime.toObject(includeInstance, f),
            userBirthday: (f = msg.getUserBirthday()) && users_entity_pb.UserBirthday.toObject(includeInstance, f),
            liveAvailableStatus: (f = msg.getLiveAvailableStatus()) && proto.jp.singcolor.PrepareLiveV1Response.LiveAvailableStatus.toObject(includeInstance, f),
            streamingQuality: jspb.Message.getFieldWithDefault(msg, 5, 0),
            liveBackground: (f = msg.getLiveBackground()) && lives_entity_pb.LiveBackground.toObject(includeInstance, f),
            liveId: jspb.Message.getFieldWithDefault(msg, 8, ""),
            onlineServer: (f = msg.getOnlineServer()) && lives_entity_pb.OnlineServer.toObject(includeInstance, f),
            streamingAppId: jspb.Message.getFieldWithDefault(msg, 10, 0),
            trtcRoomId: jspb.Message.getFieldWithDefault(msg, 11, ""),
            signature: jspb.Message.getFieldWithDefault(msg, 12, ""),
            trtcRtmpPushUrl: jspb.Message.getFieldWithDefault(msg, 13, ""),
            trtcRtmpStreamKey: jspb.Message.getFieldWithDefault(msg, 14, ""),
            verifySignature: jspb.Message.getFieldWithDefault(msg, 15, ""),
            cssViewingUrl: jspb.Message.getFieldWithDefault(msg, 16, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.PrepareLiveV1Response}
 */
proto.jp.singcolor.PrepareLiveV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.PrepareLiveV1Response;
    return proto.jp.singcolor.PrepareLiveV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.PrepareLiveV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.PrepareLiveV1Response}
 */
proto.jp.singcolor.PrepareLiveV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new lives_entity_pb.UserLiveSetting;
                reader.readMessage(value, lives_entity_pb.UserLiveSetting.deserializeBinaryFromReader);
                msg.setUserLiveSetting(value);
                break;
            case 2:
                var value = new users_entity_pb.UserLiveClosingTime;
                reader.readMessage(value, users_entity_pb.UserLiveClosingTime.deserializeBinaryFromReader);
                msg.setUserLiveClosingTime(value);
                break;
            case 3:
                var value = new users_entity_pb.UserBirthday;
                reader.readMessage(value, users_entity_pb.UserBirthday.deserializeBinaryFromReader);
                msg.setUserBirthday(value);
                break;
            case 4:
                var value = new proto.jp.singcolor.PrepareLiveV1Response.LiveAvailableStatus;
                reader.readMessage(value, proto.jp.singcolor.PrepareLiveV1Response.LiveAvailableStatus.deserializeBinaryFromReader);
                msg.setLiveAvailableStatus(value);
                break;
            case 5:
                var value = /** @type {!proto.jp.singcolor.StreamingQuality} */ (reader.readEnum());
                msg.setStreamingQuality(value);
                break;
            case 6:
                var value = new lives_entity_pb.LiveBackground;
                reader.readMessage(value, lives_entity_pb.LiveBackground.deserializeBinaryFromReader);
                msg.setLiveBackground(value);
                break;
            case 8:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiveId(value);
                break;
            case 9:
                var value = new lives_entity_pb.OnlineServer;
                reader.readMessage(value, lives_entity_pb.OnlineServer.deserializeBinaryFromReader);
                msg.setOnlineServer(value);
                break;
            case 10:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setStreamingAppId(value);
                break;
            case 11:
                var value = /** @type {string} */ (reader.readString());
                msg.setTrtcRoomId(value);
                break;
            case 12:
                var value = /** @type {string} */ (reader.readString());
                msg.setSignature(value);
                break;
            case 13:
                var value = /** @type {string} */ (reader.readString());
                msg.setTrtcRtmpPushUrl(value);
                break;
            case 14:
                var value = /** @type {string} */ (reader.readString());
                msg.setTrtcRtmpStreamKey(value);
                break;
            case 15:
                var value = /** @type {string} */ (reader.readString());
                msg.setVerifySignature(value);
                break;
            case 16:
                var value = /** @type {string} */ (reader.readString());
                msg.setCssViewingUrl(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.PrepareLiveV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.PrepareLiveV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.PrepareLiveV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.PrepareLiveV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserLiveSetting();
    if (f != null) {
        writer.writeMessage(1, f, lives_entity_pb.UserLiveSetting.serializeBinaryToWriter);
    }
    f = message.getUserLiveClosingTime();
    if (f != null) {
        writer.writeMessage(2, f, users_entity_pb.UserLiveClosingTime.serializeBinaryToWriter);
    }
    f = message.getUserBirthday();
    if (f != null) {
        writer.writeMessage(3, f, users_entity_pb.UserBirthday.serializeBinaryToWriter);
    }
    f = message.getLiveAvailableStatus();
    if (f != null) {
        writer.writeMessage(4, f, proto.jp.singcolor.PrepareLiveV1Response.LiveAvailableStatus.serializeBinaryToWriter);
    }
    f = message.getStreamingQuality();
    if (f !== 0.0) {
        writer.writeEnum(5, f);
    }
    f = message.getLiveBackground();
    if (f != null) {
        writer.writeMessage(6, f, lives_entity_pb.LiveBackground.serializeBinaryToWriter);
    }
    f = message.getLiveId();
    if (f.length > 0) {
        writer.writeString(8, f);
    }
    f = message.getOnlineServer();
    if (f != null) {
        writer.writeMessage(9, f, lives_entity_pb.OnlineServer.serializeBinaryToWriter);
    }
    f = message.getStreamingAppId();
    if (f !== 0) {
        writer.writeInt64(10, f);
    }
    f = message.getTrtcRoomId();
    if (f.length > 0) {
        writer.writeString(11, f);
    }
    f = message.getSignature();
    if (f.length > 0) {
        writer.writeString(12, f);
    }
    f = message.getTrtcRtmpPushUrl();
    if (f.length > 0) {
        writer.writeString(13, f);
    }
    f = message.getTrtcRtmpStreamKey();
    if (f.length > 0) {
        writer.writeString(14, f);
    }
    f = message.getVerifySignature();
    if (f.length > 0) {
        writer.writeString(15, f);
    }
    f = message.getCssViewingUrl();
    if (f.length > 0) {
        writer.writeString(16, f);
    }
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.PrepareLiveV1Response.LiveAvailableStatus.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.PrepareLiveV1Response.LiveAvailableStatus.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.PrepareLiveV1Response.LiveAvailableStatus} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.PrepareLiveV1Response.LiveAvailableStatus.toObject = function (includeInstance, msg) {
        var f, obj = {
            status: jspb.Message.getFieldWithDefault(msg, 1, 0),
            message: jspb.Message.getFieldWithDefault(msg, 2, ""),
            dialogTitle: jspb.Message.getFieldWithDefault(msg, 3, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.PrepareLiveV1Response.LiveAvailableStatus}
 */
proto.jp.singcolor.PrepareLiveV1Response.LiveAvailableStatus.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.PrepareLiveV1Response.LiveAvailableStatus;
    return proto.jp.singcolor.PrepareLiveV1Response.LiveAvailableStatus.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.PrepareLiveV1Response.LiveAvailableStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.PrepareLiveV1Response.LiveAvailableStatus}
 */
proto.jp.singcolor.PrepareLiveV1Response.LiveAvailableStatus.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {!proto.jp.singcolor.PrepareLiveV1Response.LiveAvailableStatus.Status} */ (reader.readEnum());
                msg.setStatus(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setMessage(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setDialogTitle(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.PrepareLiveV1Response.LiveAvailableStatus.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.PrepareLiveV1Response.LiveAvailableStatus.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.PrepareLiveV1Response.LiveAvailableStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.PrepareLiveV1Response.LiveAvailableStatus.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getStatus();
    if (f !== 0.0) {
        writer.writeEnum(1, f);
    }
    f = message.getMessage();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getDialogTitle();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
};
/**
 * @enum {number}
 */
proto.jp.singcolor.PrepareLiveV1Response.LiveAvailableStatus.Status = {
    STATUS_OK: 0,
    STATUS_NG_OUT_OF_TIME_RANGE: 2,
    STATUS_NG_OUT_OF_SUPPORT_OS: 3,
    STATUS_OK_TRIAL: 4
};
/**
 * optional Status status = 1;
 * @return {!proto.jp.singcolor.PrepareLiveV1Response.LiveAvailableStatus.Status}
 */
proto.jp.singcolor.PrepareLiveV1Response.LiveAvailableStatus.prototype.getStatus = function () {
    return /** @type {!proto.jp.singcolor.PrepareLiveV1Response.LiveAvailableStatus.Status} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {!proto.jp.singcolor.PrepareLiveV1Response.LiveAvailableStatus.Status} value
 * @return {!proto.jp.singcolor.PrepareLiveV1Response.LiveAvailableStatus} returns this
 */
proto.jp.singcolor.PrepareLiveV1Response.LiveAvailableStatus.prototype.setStatus = function (value) {
    return jspb.Message.setProto3EnumField(this, 1, value);
};
/**
 * optional string message = 2;
 * @return {string}
 */
proto.jp.singcolor.PrepareLiveV1Response.LiveAvailableStatus.prototype.getMessage = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.PrepareLiveV1Response.LiveAvailableStatus} returns this
 */
proto.jp.singcolor.PrepareLiveV1Response.LiveAvailableStatus.prototype.setMessage = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional string dialog_title = 3;
 * @return {string}
 */
proto.jp.singcolor.PrepareLiveV1Response.LiveAvailableStatus.prototype.getDialogTitle = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.PrepareLiveV1Response.LiveAvailableStatus} returns this
 */
proto.jp.singcolor.PrepareLiveV1Response.LiveAvailableStatus.prototype.setDialogTitle = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * optional UserLiveSetting user_live_setting = 1;
 * @return {?proto.jp.singcolor.UserLiveSetting}
 */
proto.jp.singcolor.PrepareLiveV1Response.prototype.getUserLiveSetting = function () {
    return /** @type{?proto.jp.singcolor.UserLiveSetting} */ (jspb.Message.getWrapperField(this, lives_entity_pb.UserLiveSetting, 1));
};
/**
 * @param {?proto.jp.singcolor.UserLiveSetting|undefined} value
 * @return {!proto.jp.singcolor.PrepareLiveV1Response} returns this
*/
proto.jp.singcolor.PrepareLiveV1Response.prototype.setUserLiveSetting = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.PrepareLiveV1Response} returns this
 */
proto.jp.singcolor.PrepareLiveV1Response.prototype.clearUserLiveSetting = function () {
    return this.setUserLiveSetting(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.PrepareLiveV1Response.prototype.hasUserLiveSetting = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional UserLiveClosingTime user_live_closing_time = 2;
 * @return {?proto.jp.singcolor.UserLiveClosingTime}
 */
proto.jp.singcolor.PrepareLiveV1Response.prototype.getUserLiveClosingTime = function () {
    return /** @type{?proto.jp.singcolor.UserLiveClosingTime} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserLiveClosingTime, 2));
};
/**
 * @param {?proto.jp.singcolor.UserLiveClosingTime|undefined} value
 * @return {!proto.jp.singcolor.PrepareLiveV1Response} returns this
*/
proto.jp.singcolor.PrepareLiveV1Response.prototype.setUserLiveClosingTime = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.PrepareLiveV1Response} returns this
 */
proto.jp.singcolor.PrepareLiveV1Response.prototype.clearUserLiveClosingTime = function () {
    return this.setUserLiveClosingTime(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.PrepareLiveV1Response.prototype.hasUserLiveClosingTime = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * optional UserBirthday user_birthday = 3;
 * @return {?proto.jp.singcolor.UserBirthday}
 */
proto.jp.singcolor.PrepareLiveV1Response.prototype.getUserBirthday = function () {
    return /** @type{?proto.jp.singcolor.UserBirthday} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserBirthday, 3));
};
/**
 * @param {?proto.jp.singcolor.UserBirthday|undefined} value
 * @return {!proto.jp.singcolor.PrepareLiveV1Response} returns this
*/
proto.jp.singcolor.PrepareLiveV1Response.prototype.setUserBirthday = function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.PrepareLiveV1Response} returns this
 */
proto.jp.singcolor.PrepareLiveV1Response.prototype.clearUserBirthday = function () {
    return this.setUserBirthday(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.PrepareLiveV1Response.prototype.hasUserBirthday = function () {
    return jspb.Message.getField(this, 3) != null;
};
/**
 * optional LiveAvailableStatus live_available_status = 4;
 * @return {?proto.jp.singcolor.PrepareLiveV1Response.LiveAvailableStatus}
 */
proto.jp.singcolor.PrepareLiveV1Response.prototype.getLiveAvailableStatus = function () {
    return /** @type{?proto.jp.singcolor.PrepareLiveV1Response.LiveAvailableStatus} */ (jspb.Message.getWrapperField(this, proto.jp.singcolor.PrepareLiveV1Response.LiveAvailableStatus, 4));
};
/**
 * @param {?proto.jp.singcolor.PrepareLiveV1Response.LiveAvailableStatus|undefined} value
 * @return {!proto.jp.singcolor.PrepareLiveV1Response} returns this
*/
proto.jp.singcolor.PrepareLiveV1Response.prototype.setLiveAvailableStatus = function (value) {
    return jspb.Message.setWrapperField(this, 4, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.PrepareLiveV1Response} returns this
 */
proto.jp.singcolor.PrepareLiveV1Response.prototype.clearLiveAvailableStatus = function () {
    return this.setLiveAvailableStatus(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.PrepareLiveV1Response.prototype.hasLiveAvailableStatus = function () {
    return jspb.Message.getField(this, 4) != null;
};
/**
 * optional StreamingQuality streaming_quality = 5;
 * @return {!proto.jp.singcolor.StreamingQuality}
 */
proto.jp.singcolor.PrepareLiveV1Response.prototype.getStreamingQuality = function () {
    return /** @type {!proto.jp.singcolor.StreamingQuality} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {!proto.jp.singcolor.StreamingQuality} value
 * @return {!proto.jp.singcolor.PrepareLiveV1Response} returns this
 */
proto.jp.singcolor.PrepareLiveV1Response.prototype.setStreamingQuality = function (value) {
    return jspb.Message.setProto3EnumField(this, 5, value);
};
/**
 * optional LiveBackground live_background = 6;
 * @return {?proto.jp.singcolor.LiveBackground}
 */
proto.jp.singcolor.PrepareLiveV1Response.prototype.getLiveBackground = function () {
    return /** @type{?proto.jp.singcolor.LiveBackground} */ (jspb.Message.getWrapperField(this, lives_entity_pb.LiveBackground, 6));
};
/**
 * @param {?proto.jp.singcolor.LiveBackground|undefined} value
 * @return {!proto.jp.singcolor.PrepareLiveV1Response} returns this
*/
proto.jp.singcolor.PrepareLiveV1Response.prototype.setLiveBackground = function (value) {
    return jspb.Message.setWrapperField(this, 6, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.PrepareLiveV1Response} returns this
 */
proto.jp.singcolor.PrepareLiveV1Response.prototype.clearLiveBackground = function () {
    return this.setLiveBackground(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.PrepareLiveV1Response.prototype.hasLiveBackground = function () {
    return jspb.Message.getField(this, 6) != null;
};
/**
 * optional string live_id = 8;
 * @return {string}
 */
proto.jp.singcolor.PrepareLiveV1Response.prototype.getLiveId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.PrepareLiveV1Response} returns this
 */
proto.jp.singcolor.PrepareLiveV1Response.prototype.setLiveId = function (value) {
    return jspb.Message.setProto3StringField(this, 8, value);
};
/**
 * optional OnlineServer online_server = 9;
 * @return {?proto.jp.singcolor.OnlineServer}
 */
proto.jp.singcolor.PrepareLiveV1Response.prototype.getOnlineServer = function () {
    return /** @type{?proto.jp.singcolor.OnlineServer} */ (jspb.Message.getWrapperField(this, lives_entity_pb.OnlineServer, 9));
};
/**
 * @param {?proto.jp.singcolor.OnlineServer|undefined} value
 * @return {!proto.jp.singcolor.PrepareLiveV1Response} returns this
*/
proto.jp.singcolor.PrepareLiveV1Response.prototype.setOnlineServer = function (value) {
    return jspb.Message.setWrapperField(this, 9, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.PrepareLiveV1Response} returns this
 */
proto.jp.singcolor.PrepareLiveV1Response.prototype.clearOnlineServer = function () {
    return this.setOnlineServer(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.PrepareLiveV1Response.prototype.hasOnlineServer = function () {
    return jspb.Message.getField(this, 9) != null;
};
/**
 * optional int64 streaming_app_id = 10;
 * @return {number}
 */
proto.jp.singcolor.PrepareLiveV1Response.prototype.getStreamingAppId = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.PrepareLiveV1Response} returns this
 */
proto.jp.singcolor.PrepareLiveV1Response.prototype.setStreamingAppId = function (value) {
    return jspb.Message.setProto3IntField(this, 10, value);
};
/**
 * optional string trtc_room_id = 11;
 * @return {string}
 */
proto.jp.singcolor.PrepareLiveV1Response.prototype.getTrtcRoomId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.PrepareLiveV1Response} returns this
 */
proto.jp.singcolor.PrepareLiveV1Response.prototype.setTrtcRoomId = function (value) {
    return jspb.Message.setProto3StringField(this, 11, value);
};
/**
 * optional string signature = 12;
 * @return {string}
 */
proto.jp.singcolor.PrepareLiveV1Response.prototype.getSignature = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.PrepareLiveV1Response} returns this
 */
proto.jp.singcolor.PrepareLiveV1Response.prototype.setSignature = function (value) {
    return jspb.Message.setProto3StringField(this, 12, value);
};
/**
 * optional string trtc_rtmp_push_url = 13;
 * @return {string}
 */
proto.jp.singcolor.PrepareLiveV1Response.prototype.getTrtcRtmpPushUrl = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.PrepareLiveV1Response} returns this
 */
proto.jp.singcolor.PrepareLiveV1Response.prototype.setTrtcRtmpPushUrl = function (value) {
    return jspb.Message.setProto3StringField(this, 13, value);
};
/**
 * optional string trtc_rtmp_stream_key = 14;
 * @return {string}
 */
proto.jp.singcolor.PrepareLiveV1Response.prototype.getTrtcRtmpStreamKey = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.PrepareLiveV1Response} returns this
 */
proto.jp.singcolor.PrepareLiveV1Response.prototype.setTrtcRtmpStreamKey = function (value) {
    return jspb.Message.setProto3StringField(this, 14, value);
};
/**
 * optional string verify_signature = 15;
 * @return {string}
 */
proto.jp.singcolor.PrepareLiveV1Response.prototype.getVerifySignature = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.PrepareLiveV1Response} returns this
 */
proto.jp.singcolor.PrepareLiveV1Response.prototype.setVerifySignature = function (value) {
    return jspb.Message.setProto3StringField(this, 15, value);
};
/**
 * optional string css_viewing_url = 16;
 * @return {string}
 */
proto.jp.singcolor.PrepareLiveV1Response.prototype.getCssViewingUrl = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.PrepareLiveV1Response} returns this
 */
proto.jp.singcolor.PrepareLiveV1Response.prototype.setCssViewingUrl = function (value) {
    return jspb.Message.setProto3StringField(this, 16, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateUserLiveRadioModeV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateUserLiveRadioModeV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateUserLiveRadioModeV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateUserLiveRadioModeV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            radioMode: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateUserLiveRadioModeV1Request}
 */
proto.jp.singcolor.UpdateUserLiveRadioModeV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateUserLiveRadioModeV1Request;
    return proto.jp.singcolor.UpdateUserLiveRadioModeV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateUserLiveRadioModeV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateUserLiveRadioModeV1Request}
 */
proto.jp.singcolor.UpdateUserLiveRadioModeV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setRadioMode(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateUserLiveRadioModeV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateUserLiveRadioModeV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateUserLiveRadioModeV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateUserLiveRadioModeV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getRadioMode();
    if (f) {
        writer.writeBool(1, f);
    }
};
/**
 * optional bool radio_mode = 1;
 * @return {boolean}
 */
proto.jp.singcolor.UpdateUserLiveRadioModeV1Request.prototype.getRadioMode = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.UpdateUserLiveRadioModeV1Request} returns this
 */
proto.jp.singcolor.UpdateUserLiveRadioModeV1Request.prototype.setRadioMode = function (value) {
    return jspb.Message.setProto3BooleanField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateUserLiveRadioModeV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateUserLiveRadioModeV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateUserLiveRadioModeV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateUserLiveRadioModeV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            userLiveSetting: (f = msg.getUserLiveSetting()) && lives_entity_pb.UserLiveSetting.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateUserLiveRadioModeV1Response}
 */
proto.jp.singcolor.UpdateUserLiveRadioModeV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateUserLiveRadioModeV1Response;
    return proto.jp.singcolor.UpdateUserLiveRadioModeV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateUserLiveRadioModeV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateUserLiveRadioModeV1Response}
 */
proto.jp.singcolor.UpdateUserLiveRadioModeV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new lives_entity_pb.UserLiveSetting;
                reader.readMessage(value, lives_entity_pb.UserLiveSetting.deserializeBinaryFromReader);
                msg.setUserLiveSetting(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateUserLiveRadioModeV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateUserLiveRadioModeV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateUserLiveRadioModeV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateUserLiveRadioModeV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserLiveSetting();
    if (f != null) {
        writer.writeMessage(1, f, lives_entity_pb.UserLiveSetting.serializeBinaryToWriter);
    }
};
/**
 * optional UserLiveSetting user_live_setting = 1;
 * @return {?proto.jp.singcolor.UserLiveSetting}
 */
proto.jp.singcolor.UpdateUserLiveRadioModeV1Response.prototype.getUserLiveSetting = function () {
    return /** @type{?proto.jp.singcolor.UserLiveSetting} */ (jspb.Message.getWrapperField(this, lives_entity_pb.UserLiveSetting, 1));
};
/**
 * @param {?proto.jp.singcolor.UserLiveSetting|undefined} value
 * @return {!proto.jp.singcolor.UpdateUserLiveRadioModeV1Response} returns this
*/
proto.jp.singcolor.UpdateUserLiveRadioModeV1Response.prototype.setUserLiveSetting = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.UpdateUserLiveRadioModeV1Response} returns this
 */
proto.jp.singcolor.UpdateUserLiveRadioModeV1Response.prototype.clearUserLiveSetting = function () {
    return this.setUserLiveSetting(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.UpdateUserLiveRadioModeV1Response.prototype.hasUserLiveSetting = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateUserLivePreEnterCommentV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateUserLivePreEnterCommentV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateUserLivePreEnterCommentV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateUserLivePreEnterCommentV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            body: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateUserLivePreEnterCommentV1Request}
 */
proto.jp.singcolor.UpdateUserLivePreEnterCommentV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateUserLivePreEnterCommentV1Request;
    return proto.jp.singcolor.UpdateUserLivePreEnterCommentV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateUserLivePreEnterCommentV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateUserLivePreEnterCommentV1Request}
 */
proto.jp.singcolor.UpdateUserLivePreEnterCommentV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setBody(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateUserLivePreEnterCommentV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateUserLivePreEnterCommentV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateUserLivePreEnterCommentV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateUserLivePreEnterCommentV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getBody();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string body = 1;
 * @return {string}
 */
proto.jp.singcolor.UpdateUserLivePreEnterCommentV1Request.prototype.getBody = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateUserLivePreEnterCommentV1Request} returns this
 */
proto.jp.singcolor.UpdateUserLivePreEnterCommentV1Request.prototype.setBody = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateUserLivePreEnterCommentV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateUserLivePreEnterCommentV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateUserLivePreEnterCommentV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateUserLivePreEnterCommentV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            userLiveSetting: (f = msg.getUserLiveSetting()) && lives_entity_pb.UserLiveSetting.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateUserLivePreEnterCommentV1Response}
 */
proto.jp.singcolor.UpdateUserLivePreEnterCommentV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateUserLivePreEnterCommentV1Response;
    return proto.jp.singcolor.UpdateUserLivePreEnterCommentV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateUserLivePreEnterCommentV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateUserLivePreEnterCommentV1Response}
 */
proto.jp.singcolor.UpdateUserLivePreEnterCommentV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new lives_entity_pb.UserLiveSetting;
                reader.readMessage(value, lives_entity_pb.UserLiveSetting.deserializeBinaryFromReader);
                msg.setUserLiveSetting(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateUserLivePreEnterCommentV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateUserLivePreEnterCommentV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateUserLivePreEnterCommentV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateUserLivePreEnterCommentV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserLiveSetting();
    if (f != null) {
        writer.writeMessage(1, f, lives_entity_pb.UserLiveSetting.serializeBinaryToWriter);
    }
};
/**
 * optional UserLiveSetting user_live_setting = 1;
 * @return {?proto.jp.singcolor.UserLiveSetting}
 */
proto.jp.singcolor.UpdateUserLivePreEnterCommentV1Response.prototype.getUserLiveSetting = function () {
    return /** @type{?proto.jp.singcolor.UserLiveSetting} */ (jspb.Message.getWrapperField(this, lives_entity_pb.UserLiveSetting, 1));
};
/**
 * @param {?proto.jp.singcolor.UserLiveSetting|undefined} value
 * @return {!proto.jp.singcolor.UpdateUserLivePreEnterCommentV1Response} returns this
*/
proto.jp.singcolor.UpdateUserLivePreEnterCommentV1Response.prototype.setUserLiveSetting = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.UpdateUserLivePreEnterCommentV1Response} returns this
 */
proto.jp.singcolor.UpdateUserLivePreEnterCommentV1Response.prototype.clearUserLiveSetting = function () {
    return this.setUserLiveSetting(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.UpdateUserLivePreEnterCommentV1Response.prototype.hasUserLiveSetting = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateUserLivePostEnterCommentV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateUserLivePostEnterCommentV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateUserLivePostEnterCommentV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateUserLivePostEnterCommentV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            body: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateUserLivePostEnterCommentV1Request}
 */
proto.jp.singcolor.UpdateUserLivePostEnterCommentV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateUserLivePostEnterCommentV1Request;
    return proto.jp.singcolor.UpdateUserLivePostEnterCommentV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateUserLivePostEnterCommentV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateUserLivePostEnterCommentV1Request}
 */
proto.jp.singcolor.UpdateUserLivePostEnterCommentV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setBody(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateUserLivePostEnterCommentV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateUserLivePostEnterCommentV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateUserLivePostEnterCommentV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateUserLivePostEnterCommentV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getBody();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string body = 1;
 * @return {string}
 */
proto.jp.singcolor.UpdateUserLivePostEnterCommentV1Request.prototype.getBody = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateUserLivePostEnterCommentV1Request} returns this
 */
proto.jp.singcolor.UpdateUserLivePostEnterCommentV1Request.prototype.setBody = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateUserLivePostEnterCommentV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateUserLivePostEnterCommentV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateUserLivePostEnterCommentV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateUserLivePostEnterCommentV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            userLiveSetting: (f = msg.getUserLiveSetting()) && lives_entity_pb.UserLiveSetting.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateUserLivePostEnterCommentV1Response}
 */
proto.jp.singcolor.UpdateUserLivePostEnterCommentV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateUserLivePostEnterCommentV1Response;
    return proto.jp.singcolor.UpdateUserLivePostEnterCommentV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateUserLivePostEnterCommentV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateUserLivePostEnterCommentV1Response}
 */
proto.jp.singcolor.UpdateUserLivePostEnterCommentV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new lives_entity_pb.UserLiveSetting;
                reader.readMessage(value, lives_entity_pb.UserLiveSetting.deserializeBinaryFromReader);
                msg.setUserLiveSetting(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateUserLivePostEnterCommentV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateUserLivePostEnterCommentV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateUserLivePostEnterCommentV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateUserLivePostEnterCommentV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserLiveSetting();
    if (f != null) {
        writer.writeMessage(1, f, lives_entity_pb.UserLiveSetting.serializeBinaryToWriter);
    }
};
/**
 * optional UserLiveSetting user_live_setting = 1;
 * @return {?proto.jp.singcolor.UserLiveSetting}
 */
proto.jp.singcolor.UpdateUserLivePostEnterCommentV1Response.prototype.getUserLiveSetting = function () {
    return /** @type{?proto.jp.singcolor.UserLiveSetting} */ (jspb.Message.getWrapperField(this, lives_entity_pb.UserLiveSetting, 1));
};
/**
 * @param {?proto.jp.singcolor.UserLiveSetting|undefined} value
 * @return {!proto.jp.singcolor.UpdateUserLivePostEnterCommentV1Response} returns this
*/
proto.jp.singcolor.UpdateUserLivePostEnterCommentV1Response.prototype.setUserLiveSetting = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.UpdateUserLivePostEnterCommentV1Response} returns this
 */
proto.jp.singcolor.UpdateUserLivePostEnterCommentV1Response.prototype.clearUserLiveSetting = function () {
    return this.setUserLiveSetting(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.UpdateUserLivePostEnterCommentV1Response.prototype.hasUserLiveSetting = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateUserLiveShareTwitterAccountV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateUserLiveShareTwitterAccountV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateUserLiveShareTwitterAccountV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateUserLiveShareTwitterAccountV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            shareTwitterUserId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateUserLiveShareTwitterAccountV1Request}
 */
proto.jp.singcolor.UpdateUserLiveShareTwitterAccountV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateUserLiveShareTwitterAccountV1Request;
    return proto.jp.singcolor.UpdateUserLiveShareTwitterAccountV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateUserLiveShareTwitterAccountV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateUserLiveShareTwitterAccountV1Request}
 */
proto.jp.singcolor.UpdateUserLiveShareTwitterAccountV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setShareTwitterUserId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateUserLiveShareTwitterAccountV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateUserLiveShareTwitterAccountV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateUserLiveShareTwitterAccountV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateUserLiveShareTwitterAccountV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getShareTwitterUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string share_twitter_user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.UpdateUserLiveShareTwitterAccountV1Request.prototype.getShareTwitterUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateUserLiveShareTwitterAccountV1Request} returns this
 */
proto.jp.singcolor.UpdateUserLiveShareTwitterAccountV1Request.prototype.setShareTwitterUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateUserLiveShareTwitterAccountV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateUserLiveShareTwitterAccountV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateUserLiveShareTwitterAccountV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateUserLiveShareTwitterAccountV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            userLiveSetting: (f = msg.getUserLiveSetting()) && lives_entity_pb.UserLiveSetting.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateUserLiveShareTwitterAccountV1Response}
 */
proto.jp.singcolor.UpdateUserLiveShareTwitterAccountV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateUserLiveShareTwitterAccountV1Response;
    return proto.jp.singcolor.UpdateUserLiveShareTwitterAccountV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateUserLiveShareTwitterAccountV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateUserLiveShareTwitterAccountV1Response}
 */
proto.jp.singcolor.UpdateUserLiveShareTwitterAccountV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new lives_entity_pb.UserLiveSetting;
                reader.readMessage(value, lives_entity_pb.UserLiveSetting.deserializeBinaryFromReader);
                msg.setUserLiveSetting(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateUserLiveShareTwitterAccountV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateUserLiveShareTwitterAccountV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateUserLiveShareTwitterAccountV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateUserLiveShareTwitterAccountV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserLiveSetting();
    if (f != null) {
        writer.writeMessage(1, f, lives_entity_pb.UserLiveSetting.serializeBinaryToWriter);
    }
};
/**
 * optional UserLiveSetting user_live_setting = 1;
 * @return {?proto.jp.singcolor.UserLiveSetting}
 */
proto.jp.singcolor.UpdateUserLiveShareTwitterAccountV1Response.prototype.getUserLiveSetting = function () {
    return /** @type{?proto.jp.singcolor.UserLiveSetting} */ (jspb.Message.getWrapperField(this, lives_entity_pb.UserLiveSetting, 1));
};
/**
 * @param {?proto.jp.singcolor.UserLiveSetting|undefined} value
 * @return {!proto.jp.singcolor.UpdateUserLiveShareTwitterAccountV1Response} returns this
*/
proto.jp.singcolor.UpdateUserLiveShareTwitterAccountV1Response.prototype.setUserLiveSetting = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.UpdateUserLiveShareTwitterAccountV1Response} returns this
 */
proto.jp.singcolor.UpdateUserLiveShareTwitterAccountV1Response.prototype.clearUserLiveSetting = function () {
    return this.setUserLiveSetting(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.UpdateUserLiveShareTwitterAccountV1Response.prototype.hasUserLiveSetting = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateUserLiveClosingTimeV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateUserLiveClosingTimeV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateUserLiveClosingTimeV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateUserLiveClosingTimeV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            closingTimeJst: jspb.Message.getFieldWithDefault(msg, 1, 0),
            switchClosingTimeDay: jspb.Message.getFieldWithDefault(msg, 2, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateUserLiveClosingTimeV1Request}
 */
proto.jp.singcolor.UpdateUserLiveClosingTimeV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateUserLiveClosingTimeV1Request;
    return proto.jp.singcolor.UpdateUserLiveClosingTimeV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateUserLiveClosingTimeV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateUserLiveClosingTimeV1Request}
 */
proto.jp.singcolor.UpdateUserLiveClosingTimeV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setClosingTimeJst(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setSwitchClosingTimeDay(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateUserLiveClosingTimeV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateUserLiveClosingTimeV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateUserLiveClosingTimeV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateUserLiveClosingTimeV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getClosingTimeJst();
    if (f !== 0) {
        writer.writeInt64(1, f);
    }
    f = message.getSwitchClosingTimeDay();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
};
/**
 * optional int64 closing_time_jst = 1;
 * @return {number}
 */
proto.jp.singcolor.UpdateUserLiveClosingTimeV1Request.prototype.getClosingTimeJst = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.UpdateUserLiveClosingTimeV1Request} returns this
 */
proto.jp.singcolor.UpdateUserLiveClosingTimeV1Request.prototype.setClosingTimeJst = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
};
/**
 * optional string switch_closing_time_day = 2;
 * @return {string}
 */
proto.jp.singcolor.UpdateUserLiveClosingTimeV1Request.prototype.getSwitchClosingTimeDay = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateUserLiveClosingTimeV1Request} returns this
 */
proto.jp.singcolor.UpdateUserLiveClosingTimeV1Request.prototype.setSwitchClosingTimeDay = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateUserLiveClosingTimeV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateUserLiveClosingTimeV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateUserLiveClosingTimeV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateUserLiveClosingTimeV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            userLiveClosingTime: (f = msg.getUserLiveClosingTime()) && users_entity_pb.UserLiveClosingTime.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateUserLiveClosingTimeV1Response}
 */
proto.jp.singcolor.UpdateUserLiveClosingTimeV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateUserLiveClosingTimeV1Response;
    return proto.jp.singcolor.UpdateUserLiveClosingTimeV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateUserLiveClosingTimeV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateUserLiveClosingTimeV1Response}
 */
proto.jp.singcolor.UpdateUserLiveClosingTimeV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.UserLiveClosingTime;
                reader.readMessage(value, users_entity_pb.UserLiveClosingTime.deserializeBinaryFromReader);
                msg.setUserLiveClosingTime(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateUserLiveClosingTimeV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateUserLiveClosingTimeV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateUserLiveClosingTimeV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateUserLiveClosingTimeV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserLiveClosingTime();
    if (f != null) {
        writer.writeMessage(1, f, users_entity_pb.UserLiveClosingTime.serializeBinaryToWriter);
    }
};
/**
 * optional UserLiveClosingTime user_live_closing_time = 1;
 * @return {?proto.jp.singcolor.UserLiveClosingTime}
 */
proto.jp.singcolor.UpdateUserLiveClosingTimeV1Response.prototype.getUserLiveClosingTime = function () {
    return /** @type{?proto.jp.singcolor.UserLiveClosingTime} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserLiveClosingTime, 1));
};
/**
 * @param {?proto.jp.singcolor.UserLiveClosingTime|undefined} value
 * @return {!proto.jp.singcolor.UpdateUserLiveClosingTimeV1Response} returns this
*/
proto.jp.singcolor.UpdateUserLiveClosingTimeV1Response.prototype.setUserLiveClosingTime = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.UpdateUserLiveClosingTimeV1Response} returns this
 */
proto.jp.singcolor.UpdateUserLiveClosingTimeV1Response.prototype.clearUserLiveClosingTime = function () {
    return this.setUserLiveClosingTime(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.UpdateUserLiveClosingTimeV1Response.prototype.hasUserLiveClosingTime = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ValidateLivePublicationSettingV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ValidateLivePublicationSettingV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ValidateLivePublicationSettingV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ValidateLivePublicationSettingV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            livePublicationSetting: (f = msg.getLivePublicationSetting()) && lives_entity_pb.LivePublicationSetting.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ValidateLivePublicationSettingV1Request}
 */
proto.jp.singcolor.ValidateLivePublicationSettingV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ValidateLivePublicationSettingV1Request;
    return proto.jp.singcolor.ValidateLivePublicationSettingV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ValidateLivePublicationSettingV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ValidateLivePublicationSettingV1Request}
 */
proto.jp.singcolor.ValidateLivePublicationSettingV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new lives_entity_pb.LivePublicationSetting;
                reader.readMessage(value, lives_entity_pb.LivePublicationSetting.deserializeBinaryFromReader);
                msg.setLivePublicationSetting(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ValidateLivePublicationSettingV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ValidateLivePublicationSettingV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ValidateLivePublicationSettingV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ValidateLivePublicationSettingV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLivePublicationSetting();
    if (f != null) {
        writer.writeMessage(1, f, lives_entity_pb.LivePublicationSetting.serializeBinaryToWriter);
    }
};
/**
 * optional LivePublicationSetting live_publication_setting = 1;
 * @return {?proto.jp.singcolor.LivePublicationSetting}
 */
proto.jp.singcolor.ValidateLivePublicationSettingV1Request.prototype.getLivePublicationSetting = function () {
    return /** @type{?proto.jp.singcolor.LivePublicationSetting} */ (jspb.Message.getWrapperField(this, lives_entity_pb.LivePublicationSetting, 1));
};
/**
 * @param {?proto.jp.singcolor.LivePublicationSetting|undefined} value
 * @return {!proto.jp.singcolor.ValidateLivePublicationSettingV1Request} returns this
*/
proto.jp.singcolor.ValidateLivePublicationSettingV1Request.prototype.setLivePublicationSetting = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ValidateLivePublicationSettingV1Request} returns this
 */
proto.jp.singcolor.ValidateLivePublicationSettingV1Request.prototype.clearLivePublicationSetting = function () {
    return this.setLivePublicationSetting(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ValidateLivePublicationSettingV1Request.prototype.hasLivePublicationSetting = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ValidateLivePublicationSettingV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ValidateLivePublicationSettingV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ValidateLivePublicationSettingV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ValidateLivePublicationSettingV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            isValid: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
            invalidReasonMessage: jspb.Message.getFieldWithDefault(msg, 2, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ValidateLivePublicationSettingV1Response}
 */
proto.jp.singcolor.ValidateLivePublicationSettingV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ValidateLivePublicationSettingV1Response;
    return proto.jp.singcolor.ValidateLivePublicationSettingV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ValidateLivePublicationSettingV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ValidateLivePublicationSettingV1Response}
 */
proto.jp.singcolor.ValidateLivePublicationSettingV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setIsValid(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setInvalidReasonMessage(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ValidateLivePublicationSettingV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ValidateLivePublicationSettingV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ValidateLivePublicationSettingV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ValidateLivePublicationSettingV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getIsValid();
    if (f) {
        writer.writeBool(1, f);
    }
    f = message.getInvalidReasonMessage();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
};
/**
 * optional bool is_valid = 1;
 * @return {boolean}
 */
proto.jp.singcolor.ValidateLivePublicationSettingV1Response.prototype.getIsValid = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.ValidateLivePublicationSettingV1Response} returns this
 */
proto.jp.singcolor.ValidateLivePublicationSettingV1Response.prototype.setIsValid = function (value) {
    return jspb.Message.setProto3BooleanField(this, 1, value);
};
/**
 * optional string invalid_reason_message = 2;
 * @return {string}
 */
proto.jp.singcolor.ValidateLivePublicationSettingV1Response.prototype.getInvalidReasonMessage = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ValidateLivePublicationSettingV1Response} returns this
 */
proto.jp.singcolor.ValidateLivePublicationSettingV1Response.prototype.setInvalidReasonMessage = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.StartLiveV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.StartLiveV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.StartLiveV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.StartLiveV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            title: jspb.Message.getFieldWithDefault(msg, 1, ""),
            voiceOnly: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
            liveBackgroundType: jspb.Message.getFieldWithDefault(msg, 3, 0),
            backgroundImage: msg.getBackgroundImage_asB64(),
            roomId: jspb.Message.getFieldWithDefault(msg, 5, ""),
            verifySignature: jspb.Message.getFieldWithDefault(msg, 6, ""),
            liveMode: jspb.Message.getFieldWithDefault(msg, 7, 0),
            livePublicationSetting: (f = msg.getLivePublicationSetting()) && lives_entity_pb.LivePublicationSetting.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.StartLiveV1Request}
 */
proto.jp.singcolor.StartLiveV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.StartLiveV1Request;
    return proto.jp.singcolor.StartLiveV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.StartLiveV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.StartLiveV1Request}
 */
proto.jp.singcolor.StartLiveV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setTitle(value);
                break;
            case 2:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setVoiceOnly(value);
                break;
            case 3:
                var value = /** @type {!proto.jp.singcolor.LiveBackgroundType} */ (reader.readEnum());
                msg.setLiveBackgroundType(value);
                break;
            case 4:
                var value = /** @type {!Uint8Array} */ (reader.readBytes());
                msg.setBackgroundImage(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());
                msg.setRoomId(value);
                break;
            case 6:
                var value = /** @type {string} */ (reader.readString());
                msg.setVerifySignature(value);
                break;
            case 7:
                var value = /** @type {!proto.jp.singcolor.LiveMode} */ (reader.readEnum());
                msg.setLiveMode(value);
                break;
            case 8:
                var value = new lives_entity_pb.LivePublicationSetting;
                reader.readMessage(value, lives_entity_pb.LivePublicationSetting.deserializeBinaryFromReader);
                msg.setLivePublicationSetting(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.StartLiveV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.StartLiveV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.StartLiveV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.StartLiveV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getTitle();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getVoiceOnly();
    if (f) {
        writer.writeBool(2, f);
    }
    f = message.getLiveBackgroundType();
    if (f !== 0.0) {
        writer.writeEnum(3, f);
    }
    f = message.getBackgroundImage_asU8();
    if (f.length > 0) {
        writer.writeBytes(4, f);
    }
    f = message.getRoomId();
    if (f.length > 0) {
        writer.writeString(5, f);
    }
    f = message.getVerifySignature();
    if (f.length > 0) {
        writer.writeString(6, f);
    }
    f = message.getLiveMode();
    if (f !== 0.0) {
        writer.writeEnum(7, f);
    }
    f = message.getLivePublicationSetting();
    if (f != null) {
        writer.writeMessage(8, f, lives_entity_pb.LivePublicationSetting.serializeBinaryToWriter);
    }
};
/**
 * optional string title = 1;
 * @return {string}
 */
proto.jp.singcolor.StartLiveV1Request.prototype.getTitle = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.StartLiveV1Request} returns this
 */
proto.jp.singcolor.StartLiveV1Request.prototype.setTitle = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional bool voice_only = 2;
 * @return {boolean}
 */
proto.jp.singcolor.StartLiveV1Request.prototype.getVoiceOnly = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.StartLiveV1Request} returns this
 */
proto.jp.singcolor.StartLiveV1Request.prototype.setVoiceOnly = function (value) {
    return jspb.Message.setProto3BooleanField(this, 2, value);
};
/**
 * optional LiveBackgroundType live_background_type = 3;
 * @return {!proto.jp.singcolor.LiveBackgroundType}
 */
proto.jp.singcolor.StartLiveV1Request.prototype.getLiveBackgroundType = function () {
    return /** @type {!proto.jp.singcolor.LiveBackgroundType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {!proto.jp.singcolor.LiveBackgroundType} value
 * @return {!proto.jp.singcolor.StartLiveV1Request} returns this
 */
proto.jp.singcolor.StartLiveV1Request.prototype.setLiveBackgroundType = function (value) {
    return jspb.Message.setProto3EnumField(this, 3, value);
};
/**
 * optional bytes background_image = 4;
 * @return {!(string|Uint8Array)}
 */
proto.jp.singcolor.StartLiveV1Request.prototype.getBackgroundImage = function () {
    return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};
/**
 * optional bytes background_image = 4;
 * This is a type-conversion wrapper around `getBackgroundImage()`
 * @return {string}
 */
proto.jp.singcolor.StartLiveV1Request.prototype.getBackgroundImage_asB64 = function () {
    return /** @type {string} */ (jspb.Message.bytesAsB64(this.getBackgroundImage()));
};
/**
 * optional bytes background_image = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getBackgroundImage()`
 * @return {!Uint8Array}
 */
proto.jp.singcolor.StartLiveV1Request.prototype.getBackgroundImage_asU8 = function () {
    return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getBackgroundImage()));
};
/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.jp.singcolor.StartLiveV1Request} returns this
 */
proto.jp.singcolor.StartLiveV1Request.prototype.setBackgroundImage = function (value) {
    return jspb.Message.setProto3BytesField(this, 4, value);
};
/**
 * optional string room_id = 5;
 * @return {string}
 */
proto.jp.singcolor.StartLiveV1Request.prototype.getRoomId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.StartLiveV1Request} returns this
 */
proto.jp.singcolor.StartLiveV1Request.prototype.setRoomId = function (value) {
    return jspb.Message.setProto3StringField(this, 5, value);
};
/**
 * optional string verify_signature = 6;
 * @return {string}
 */
proto.jp.singcolor.StartLiveV1Request.prototype.getVerifySignature = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.StartLiveV1Request} returns this
 */
proto.jp.singcolor.StartLiveV1Request.prototype.setVerifySignature = function (value) {
    return jspb.Message.setProto3StringField(this, 6, value);
};
/**
 * optional LiveMode live_mode = 7;
 * @return {!proto.jp.singcolor.LiveMode}
 */
proto.jp.singcolor.StartLiveV1Request.prototype.getLiveMode = function () {
    return /** @type {!proto.jp.singcolor.LiveMode} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};
/**
 * @param {!proto.jp.singcolor.LiveMode} value
 * @return {!proto.jp.singcolor.StartLiveV1Request} returns this
 */
proto.jp.singcolor.StartLiveV1Request.prototype.setLiveMode = function (value) {
    return jspb.Message.setProto3EnumField(this, 7, value);
};
/**
 * optional LivePublicationSetting live_publication_setting = 8;
 * @return {?proto.jp.singcolor.LivePublicationSetting}
 */
proto.jp.singcolor.StartLiveV1Request.prototype.getLivePublicationSetting = function () {
    return /** @type{?proto.jp.singcolor.LivePublicationSetting} */ (jspb.Message.getWrapperField(this, lives_entity_pb.LivePublicationSetting, 8));
};
/**
 * @param {?proto.jp.singcolor.LivePublicationSetting|undefined} value
 * @return {!proto.jp.singcolor.StartLiveV1Request} returns this
*/
proto.jp.singcolor.StartLiveV1Request.prototype.setLivePublicationSetting = function (value) {
    return jspb.Message.setWrapperField(this, 8, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.StartLiveV1Request} returns this
 */
proto.jp.singcolor.StartLiveV1Request.prototype.clearLivePublicationSetting = function () {
    return this.setLivePublicationSetting(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.StartLiveV1Request.prototype.hasLivePublicationSetting = function () {
    return jspb.Message.getField(this, 8) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.StartLiveV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.StartLiveV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.StartLiveV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.StartLiveV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            live: (f = msg.getLive()) && lives_entity_pb.Live.toObject(includeInstance, f),
            onlineServer: (f = msg.getOnlineServer()) && lives_entity_pb.OnlineServer.toObject(includeInstance, f),
            streamingAppId: jspb.Message.getFieldWithDefault(msg, 3, 0),
            trtcRoomId: jspb.Message.getFieldWithDefault(msg, 4, ""),
            signature: jspb.Message.getFieldWithDefault(msg, 5, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.StartLiveV1Response}
 */
proto.jp.singcolor.StartLiveV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.StartLiveV1Response;
    return proto.jp.singcolor.StartLiveV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.StartLiveV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.StartLiveV1Response}
 */
proto.jp.singcolor.StartLiveV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new lives_entity_pb.Live;
                reader.readMessage(value, lives_entity_pb.Live.deserializeBinaryFromReader);
                msg.setLive(value);
                break;
            case 2:
                var value = new lives_entity_pb.OnlineServer;
                reader.readMessage(value, lives_entity_pb.OnlineServer.deserializeBinaryFromReader);
                msg.setOnlineServer(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setStreamingAppId(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setTrtcRoomId(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());
                msg.setSignature(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.StartLiveV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.StartLiveV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.StartLiveV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.StartLiveV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLive();
    if (f != null) {
        writer.writeMessage(1, f, lives_entity_pb.Live.serializeBinaryToWriter);
    }
    f = message.getOnlineServer();
    if (f != null) {
        writer.writeMessage(2, f, lives_entity_pb.OnlineServer.serializeBinaryToWriter);
    }
    f = message.getStreamingAppId();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
    f = message.getTrtcRoomId();
    if (f.length > 0) {
        writer.writeString(4, f);
    }
    f = message.getSignature();
    if (f.length > 0) {
        writer.writeString(5, f);
    }
};
/**
 * optional Live live = 1;
 * @return {?proto.jp.singcolor.Live}
 */
proto.jp.singcolor.StartLiveV1Response.prototype.getLive = function () {
    return /** @type{?proto.jp.singcolor.Live} */ (jspb.Message.getWrapperField(this, lives_entity_pb.Live, 1));
};
/**
 * @param {?proto.jp.singcolor.Live|undefined} value
 * @return {!proto.jp.singcolor.StartLiveV1Response} returns this
*/
proto.jp.singcolor.StartLiveV1Response.prototype.setLive = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.StartLiveV1Response} returns this
 */
proto.jp.singcolor.StartLiveV1Response.prototype.clearLive = function () {
    return this.setLive(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.StartLiveV1Response.prototype.hasLive = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional OnlineServer online_server = 2;
 * @return {?proto.jp.singcolor.OnlineServer}
 */
proto.jp.singcolor.StartLiveV1Response.prototype.getOnlineServer = function () {
    return /** @type{?proto.jp.singcolor.OnlineServer} */ (jspb.Message.getWrapperField(this, lives_entity_pb.OnlineServer, 2));
};
/**
 * @param {?proto.jp.singcolor.OnlineServer|undefined} value
 * @return {!proto.jp.singcolor.StartLiveV1Response} returns this
*/
proto.jp.singcolor.StartLiveV1Response.prototype.setOnlineServer = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.StartLiveV1Response} returns this
 */
proto.jp.singcolor.StartLiveV1Response.prototype.clearOnlineServer = function () {
    return this.setOnlineServer(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.StartLiveV1Response.prototype.hasOnlineServer = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * optional int64 streaming_app_id = 3;
 * @return {number}
 */
proto.jp.singcolor.StartLiveV1Response.prototype.getStreamingAppId = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.StartLiveV1Response} returns this
 */
proto.jp.singcolor.StartLiveV1Response.prototype.setStreamingAppId = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional string trtc_room_id = 4;
 * @return {string}
 */
proto.jp.singcolor.StartLiveV1Response.prototype.getTrtcRoomId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.StartLiveV1Response} returns this
 */
proto.jp.singcolor.StartLiveV1Response.prototype.setTrtcRoomId = function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
};
/**
 * optional string signature = 5;
 * @return {string}
 */
proto.jp.singcolor.StartLiveV1Response.prototype.getSignature = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.StartLiveV1Response} returns this
 */
proto.jp.singcolor.StartLiveV1Response.prototype.setSignature = function (value) {
    return jspb.Message.setProto3StringField(this, 5, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateLiveTitleV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateLiveTitleV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateLiveTitleV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateLiveTitleV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            live: (f = msg.getLive()) && lives_entity_pb.Live.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateLiveTitleV1Response}
 */
proto.jp.singcolor.UpdateLiveTitleV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateLiveTitleV1Response;
    return proto.jp.singcolor.UpdateLiveTitleV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateLiveTitleV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateLiveTitleV1Response}
 */
proto.jp.singcolor.UpdateLiveTitleV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new lives_entity_pb.Live;
                reader.readMessage(value, lives_entity_pb.Live.deserializeBinaryFromReader);
                msg.setLive(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateLiveTitleV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateLiveTitleV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateLiveTitleV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateLiveTitleV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLive();
    if (f != null) {
        writer.writeMessage(1, f, lives_entity_pb.Live.serializeBinaryToWriter);
    }
};
/**
 * optional Live live = 1;
 * @return {?proto.jp.singcolor.Live}
 */
proto.jp.singcolor.UpdateLiveTitleV1Response.prototype.getLive = function () {
    return /** @type{?proto.jp.singcolor.Live} */ (jspb.Message.getWrapperField(this, lives_entity_pb.Live, 1));
};
/**
 * @param {?proto.jp.singcolor.Live|undefined} value
 * @return {!proto.jp.singcolor.UpdateLiveTitleV1Response} returns this
*/
proto.jp.singcolor.UpdateLiveTitleV1Response.prototype.setLive = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.UpdateLiveTitleV1Response} returns this
 */
proto.jp.singcolor.UpdateLiveTitleV1Response.prototype.clearLive = function () {
    return this.setLive(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.UpdateLiveTitleV1Response.prototype.hasLive = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateLiveBackgroundV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateLiveBackgroundV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateLiveBackgroundV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateLiveBackgroundV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            liveId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            liveBackgroundType: jspb.Message.getFieldWithDefault(msg, 2, 0),
            backgroundImage: msg.getBackgroundImage_asB64()
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateLiveBackgroundV1Request}
 */
proto.jp.singcolor.UpdateLiveBackgroundV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateLiveBackgroundV1Request;
    return proto.jp.singcolor.UpdateLiveBackgroundV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateLiveBackgroundV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateLiveBackgroundV1Request}
 */
proto.jp.singcolor.UpdateLiveBackgroundV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiveId(value);
                break;
            case 2:
                var value = /** @type {!proto.jp.singcolor.LiveBackgroundType} */ (reader.readEnum());
                msg.setLiveBackgroundType(value);
                break;
            case 3:
                var value = /** @type {!Uint8Array} */ (reader.readBytes());
                msg.setBackgroundImage(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateLiveBackgroundV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateLiveBackgroundV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateLiveBackgroundV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateLiveBackgroundV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiveId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getLiveBackgroundType();
    if (f !== 0.0) {
        writer.writeEnum(2, f);
    }
    f = message.getBackgroundImage_asU8();
    if (f.length > 0) {
        writer.writeBytes(3, f);
    }
};
/**
 * optional string live_id = 1;
 * @return {string}
 */
proto.jp.singcolor.UpdateLiveBackgroundV1Request.prototype.getLiveId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateLiveBackgroundV1Request} returns this
 */
proto.jp.singcolor.UpdateLiveBackgroundV1Request.prototype.setLiveId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional LiveBackgroundType live_background_type = 2;
 * @return {!proto.jp.singcolor.LiveBackgroundType}
 */
proto.jp.singcolor.UpdateLiveBackgroundV1Request.prototype.getLiveBackgroundType = function () {
    return /** @type {!proto.jp.singcolor.LiveBackgroundType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {!proto.jp.singcolor.LiveBackgroundType} value
 * @return {!proto.jp.singcolor.UpdateLiveBackgroundV1Request} returns this
 */
proto.jp.singcolor.UpdateLiveBackgroundV1Request.prototype.setLiveBackgroundType = function (value) {
    return jspb.Message.setProto3EnumField(this, 2, value);
};
/**
 * optional bytes background_image = 3;
 * @return {!(string|Uint8Array)}
 */
proto.jp.singcolor.UpdateLiveBackgroundV1Request.prototype.getBackgroundImage = function () {
    return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * optional bytes background_image = 3;
 * This is a type-conversion wrapper around `getBackgroundImage()`
 * @return {string}
 */
proto.jp.singcolor.UpdateLiveBackgroundV1Request.prototype.getBackgroundImage_asB64 = function () {
    return /** @type {string} */ (jspb.Message.bytesAsB64(this.getBackgroundImage()));
};
/**
 * optional bytes background_image = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getBackgroundImage()`
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateLiveBackgroundV1Request.prototype.getBackgroundImage_asU8 = function () {
    return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getBackgroundImage()));
};
/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.jp.singcolor.UpdateLiveBackgroundV1Request} returns this
 */
proto.jp.singcolor.UpdateLiveBackgroundV1Request.prototype.setBackgroundImage = function (value) {
    return jspb.Message.setProto3BytesField(this, 3, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateLiveBackgroundV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateLiveBackgroundV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateLiveBackgroundV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateLiveBackgroundV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            liveBackground: (f = msg.getLiveBackground()) && lives_entity_pb.LiveBackground.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateLiveBackgroundV1Response}
 */
proto.jp.singcolor.UpdateLiveBackgroundV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateLiveBackgroundV1Response;
    return proto.jp.singcolor.UpdateLiveBackgroundV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateLiveBackgroundV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateLiveBackgroundV1Response}
 */
proto.jp.singcolor.UpdateLiveBackgroundV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new lives_entity_pb.LiveBackground;
                reader.readMessage(value, lives_entity_pb.LiveBackground.deserializeBinaryFromReader);
                msg.setLiveBackground(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateLiveBackgroundV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateLiveBackgroundV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateLiveBackgroundV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateLiveBackgroundV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiveBackground();
    if (f != null) {
        writer.writeMessage(1, f, lives_entity_pb.LiveBackground.serializeBinaryToWriter);
    }
};
/**
 * optional LiveBackground live_background = 1;
 * @return {?proto.jp.singcolor.LiveBackground}
 */
proto.jp.singcolor.UpdateLiveBackgroundV1Response.prototype.getLiveBackground = function () {
    return /** @type{?proto.jp.singcolor.LiveBackground} */ (jspb.Message.getWrapperField(this, lives_entity_pb.LiveBackground, 1));
};
/**
 * @param {?proto.jp.singcolor.LiveBackground|undefined} value
 * @return {!proto.jp.singcolor.UpdateLiveBackgroundV1Response} returns this
*/
proto.jp.singcolor.UpdateLiveBackgroundV1Response.prototype.setLiveBackground = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.UpdateLiveBackgroundV1Response} returns this
 */
proto.jp.singcolor.UpdateLiveBackgroundV1Response.prototype.clearLiveBackground = function () {
    return this.setLiveBackground(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.UpdateLiveBackgroundV1Response.prototype.hasLiveBackground = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.FinishLiveV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.FinishLiveV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.FinishLiveV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.FinishLiveV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            liveId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.FinishLiveV1Request}
 */
proto.jp.singcolor.FinishLiveV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.FinishLiveV1Request;
    return proto.jp.singcolor.FinishLiveV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.FinishLiveV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.FinishLiveV1Request}
 */
proto.jp.singcolor.FinishLiveV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiveId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.FinishLiveV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.FinishLiveV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.FinishLiveV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.FinishLiveV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiveId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string live_id = 1;
 * @return {string}
 */
proto.jp.singcolor.FinishLiveV1Request.prototype.getLiveId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.FinishLiveV1Request} returns this
 */
proto.jp.singcolor.FinishLiveV1Request.prototype.setLiveId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.FinishLiveV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.FinishLiveV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.FinishLiveV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.FinishLiveV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            live: (f = msg.getLive()) && lives_entity_pb.Live.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.FinishLiveV1Response}
 */
proto.jp.singcolor.FinishLiveV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.FinishLiveV1Response;
    return proto.jp.singcolor.FinishLiveV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.FinishLiveV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.FinishLiveV1Response}
 */
proto.jp.singcolor.FinishLiveV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new lives_entity_pb.Live;
                reader.readMessage(value, lives_entity_pb.Live.deserializeBinaryFromReader);
                msg.setLive(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.FinishLiveV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.FinishLiveV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.FinishLiveV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.FinishLiveV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLive();
    if (f != null) {
        writer.writeMessage(1, f, lives_entity_pb.Live.serializeBinaryToWriter);
    }
};
/**
 * optional Live live = 1;
 * @return {?proto.jp.singcolor.Live}
 */
proto.jp.singcolor.FinishLiveV1Response.prototype.getLive = function () {
    return /** @type{?proto.jp.singcolor.Live} */ (jspb.Message.getWrapperField(this, lives_entity_pb.Live, 1));
};
/**
 * @param {?proto.jp.singcolor.Live|undefined} value
 * @return {!proto.jp.singcolor.FinishLiveV1Response} returns this
*/
proto.jp.singcolor.FinishLiveV1Response.prototype.setLive = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.FinishLiveV1Response} returns this
 */
proto.jp.singcolor.FinishLiveV1Response.prototype.clearLive = function () {
    return this.setLive(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.FinishLiveV1Response.prototype.hasLive = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.PreviewLiveV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.PreviewLiveV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.PreviewLiveV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.PreviewLiveV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            liveId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.PreviewLiveV1Request}
 */
proto.jp.singcolor.PreviewLiveV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.PreviewLiveV1Request;
    return proto.jp.singcolor.PreviewLiveV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.PreviewLiveV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.PreviewLiveV1Request}
 */
proto.jp.singcolor.PreviewLiveV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiveId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.PreviewLiveV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.PreviewLiveV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.PreviewLiveV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.PreviewLiveV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiveId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string live_id = 1;
 * @return {string}
 */
proto.jp.singcolor.PreviewLiveV1Request.prototype.getLiveId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.PreviewLiveV1Request} returns this
 */
proto.jp.singcolor.PreviewLiveV1Request.prototype.setLiveId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.PreviewLiveV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.PreviewLiveV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.PreviewLiveV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.PreviewLiveV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            liveViewingSessionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            live: (f = msg.getLive()) && lives_entity_pb.Live.toObject(includeInstance, f),
            song: (f = msg.getSong()) && karaoke_entity_pb.Song.toObject(includeInstance, f),
            onlineServer: (f = msg.getOnlineServer()) && lives_entity_pb.OnlineServer.toObject(includeInstance, f),
            streamingAppId: jspb.Message.getFieldWithDefault(msg, 5, 0),
            trtcRoomId: jspb.Message.getFieldWithDefault(msg, 6, ""),
            signature: jspb.Message.getFieldWithDefault(msg, 7, ""),
            userFollow: (f = msg.getUserFollow()) && users_entity_pb.UserFollow.toObject(includeInstance, f),
            singStartTimeInClient: jspb.Message.getFieldWithDefault(msg, 9, 0),
            liveLiverInfo: (f = msg.getLiveLiverInfo()) && lives_entity_pb.LiveLiverInfo.toObject(includeInstance, f),
            preEnterComment: jspb.Message.getFieldWithDefault(msg, 11, ""),
            useCss: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
            cssViewingUrl: jspb.Message.getFieldWithDefault(msg, 13, ""),
            enterPreviewSong: (f = msg.getEnterPreviewSong()) && lives_entity_pb.EnterPreviewSong.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.PreviewLiveV1Response}
 */
proto.jp.singcolor.PreviewLiveV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.PreviewLiveV1Response;
    return proto.jp.singcolor.PreviewLiveV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.PreviewLiveV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.PreviewLiveV1Response}
 */
proto.jp.singcolor.PreviewLiveV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiveViewingSessionId(value);
                break;
            case 2:
                var value = new lives_entity_pb.Live;
                reader.readMessage(value, lives_entity_pb.Live.deserializeBinaryFromReader);
                msg.setLive(value);
                break;
            case 3:
                var value = new karaoke_entity_pb.Song;
                reader.readMessage(value, karaoke_entity_pb.Song.deserializeBinaryFromReader);
                msg.setSong(value);
                break;
            case 4:
                var value = new lives_entity_pb.OnlineServer;
                reader.readMessage(value, lives_entity_pb.OnlineServer.deserializeBinaryFromReader);
                msg.setOnlineServer(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setStreamingAppId(value);
                break;
            case 6:
                var value = /** @type {string} */ (reader.readString());
                msg.setTrtcRoomId(value);
                break;
            case 7:
                var value = /** @type {string} */ (reader.readString());
                msg.setSignature(value);
                break;
            case 8:
                var value = new users_entity_pb.UserFollow;
                reader.readMessage(value, users_entity_pb.UserFollow.deserializeBinaryFromReader);
                msg.setUserFollow(value);
                break;
            case 9:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setSingStartTimeInClient(value);
                break;
            case 10:
                var value = new lives_entity_pb.LiveLiverInfo;
                reader.readMessage(value, lives_entity_pb.LiveLiverInfo.deserializeBinaryFromReader);
                msg.setLiveLiverInfo(value);
                break;
            case 11:
                var value = /** @type {string} */ (reader.readString());
                msg.setPreEnterComment(value);
                break;
            case 12:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setUseCss(value);
                break;
            case 13:
                var value = /** @type {string} */ (reader.readString());
                msg.setCssViewingUrl(value);
                break;
            case 14:
                var value = new lives_entity_pb.EnterPreviewSong;
                reader.readMessage(value, lives_entity_pb.EnterPreviewSong.deserializeBinaryFromReader);
                msg.setEnterPreviewSong(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.PreviewLiveV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.PreviewLiveV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.PreviewLiveV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.PreviewLiveV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiveViewingSessionId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getLive();
    if (f != null) {
        writer.writeMessage(2, f, lives_entity_pb.Live.serializeBinaryToWriter);
    }
    f = message.getSong();
    if (f != null) {
        writer.writeMessage(3, f, karaoke_entity_pb.Song.serializeBinaryToWriter);
    }
    f = message.getOnlineServer();
    if (f != null) {
        writer.writeMessage(4, f, lives_entity_pb.OnlineServer.serializeBinaryToWriter);
    }
    f = message.getStreamingAppId();
    if (f !== 0) {
        writer.writeInt64(5, f);
    }
    f = message.getTrtcRoomId();
    if (f.length > 0) {
        writer.writeString(6, f);
    }
    f = message.getSignature();
    if (f.length > 0) {
        writer.writeString(7, f);
    }
    f = message.getUserFollow();
    if (f != null) {
        writer.writeMessage(8, f, users_entity_pb.UserFollow.serializeBinaryToWriter);
    }
    f = message.getSingStartTimeInClient();
    if (f !== 0) {
        writer.writeInt64(9, f);
    }
    f = message.getLiveLiverInfo();
    if (f != null) {
        writer.writeMessage(10, f, lives_entity_pb.LiveLiverInfo.serializeBinaryToWriter);
    }
    f = message.getPreEnterComment();
    if (f.length > 0) {
        writer.writeString(11, f);
    }
    f = message.getUseCss();
    if (f) {
        writer.writeBool(12, f);
    }
    f = message.getCssViewingUrl();
    if (f.length > 0) {
        writer.writeString(13, f);
    }
    f = message.getEnterPreviewSong();
    if (f != null) {
        writer.writeMessage(14, f, lives_entity_pb.EnterPreviewSong.serializeBinaryToWriter);
    }
};
/**
 * optional string live_viewing_session_id = 1;
 * @return {string}
 */
proto.jp.singcolor.PreviewLiveV1Response.prototype.getLiveViewingSessionId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.PreviewLiveV1Response} returns this
 */
proto.jp.singcolor.PreviewLiveV1Response.prototype.setLiveViewingSessionId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional Live live = 2;
 * @return {?proto.jp.singcolor.Live}
 */
proto.jp.singcolor.PreviewLiveV1Response.prototype.getLive = function () {
    return /** @type{?proto.jp.singcolor.Live} */ (jspb.Message.getWrapperField(this, lives_entity_pb.Live, 2));
};
/**
 * @param {?proto.jp.singcolor.Live|undefined} value
 * @return {!proto.jp.singcolor.PreviewLiveV1Response} returns this
*/
proto.jp.singcolor.PreviewLiveV1Response.prototype.setLive = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.PreviewLiveV1Response} returns this
 */
proto.jp.singcolor.PreviewLiveV1Response.prototype.clearLive = function () {
    return this.setLive(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.PreviewLiveV1Response.prototype.hasLive = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * optional Song song = 3;
 * @return {?proto.jp.singcolor.Song}
 */
proto.jp.singcolor.PreviewLiveV1Response.prototype.getSong = function () {
    return /** @type{?proto.jp.singcolor.Song} */ (jspb.Message.getWrapperField(this, karaoke_entity_pb.Song, 3));
};
/**
 * @param {?proto.jp.singcolor.Song|undefined} value
 * @return {!proto.jp.singcolor.PreviewLiveV1Response} returns this
*/
proto.jp.singcolor.PreviewLiveV1Response.prototype.setSong = function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.PreviewLiveV1Response} returns this
 */
proto.jp.singcolor.PreviewLiveV1Response.prototype.clearSong = function () {
    return this.setSong(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.PreviewLiveV1Response.prototype.hasSong = function () {
    return jspb.Message.getField(this, 3) != null;
};
/**
 * optional OnlineServer online_server = 4;
 * @return {?proto.jp.singcolor.OnlineServer}
 */
proto.jp.singcolor.PreviewLiveV1Response.prototype.getOnlineServer = function () {
    return /** @type{?proto.jp.singcolor.OnlineServer} */ (jspb.Message.getWrapperField(this, lives_entity_pb.OnlineServer, 4));
};
/**
 * @param {?proto.jp.singcolor.OnlineServer|undefined} value
 * @return {!proto.jp.singcolor.PreviewLiveV1Response} returns this
*/
proto.jp.singcolor.PreviewLiveV1Response.prototype.setOnlineServer = function (value) {
    return jspb.Message.setWrapperField(this, 4, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.PreviewLiveV1Response} returns this
 */
proto.jp.singcolor.PreviewLiveV1Response.prototype.clearOnlineServer = function () {
    return this.setOnlineServer(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.PreviewLiveV1Response.prototype.hasOnlineServer = function () {
    return jspb.Message.getField(this, 4) != null;
};
/**
 * optional int64 streaming_app_id = 5;
 * @return {number}
 */
proto.jp.singcolor.PreviewLiveV1Response.prototype.getStreamingAppId = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.PreviewLiveV1Response} returns this
 */
proto.jp.singcolor.PreviewLiveV1Response.prototype.setStreamingAppId = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
/**
 * optional string trtc_room_id = 6;
 * @return {string}
 */
proto.jp.singcolor.PreviewLiveV1Response.prototype.getTrtcRoomId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.PreviewLiveV1Response} returns this
 */
proto.jp.singcolor.PreviewLiveV1Response.prototype.setTrtcRoomId = function (value) {
    return jspb.Message.setProto3StringField(this, 6, value);
};
/**
 * optional string signature = 7;
 * @return {string}
 */
proto.jp.singcolor.PreviewLiveV1Response.prototype.getSignature = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.PreviewLiveV1Response} returns this
 */
proto.jp.singcolor.PreviewLiveV1Response.prototype.setSignature = function (value) {
    return jspb.Message.setProto3StringField(this, 7, value);
};
/**
 * optional UserFollow user_follow = 8;
 * @return {?proto.jp.singcolor.UserFollow}
 */
proto.jp.singcolor.PreviewLiveV1Response.prototype.getUserFollow = function () {
    return /** @type{?proto.jp.singcolor.UserFollow} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserFollow, 8));
};
/**
 * @param {?proto.jp.singcolor.UserFollow|undefined} value
 * @return {!proto.jp.singcolor.PreviewLiveV1Response} returns this
*/
proto.jp.singcolor.PreviewLiveV1Response.prototype.setUserFollow = function (value) {
    return jspb.Message.setWrapperField(this, 8, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.PreviewLiveV1Response} returns this
 */
proto.jp.singcolor.PreviewLiveV1Response.prototype.clearUserFollow = function () {
    return this.setUserFollow(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.PreviewLiveV1Response.prototype.hasUserFollow = function () {
    return jspb.Message.getField(this, 8) != null;
};
/**
 * optional int64 sing_start_time_in_client = 9;
 * @return {number}
 */
proto.jp.singcolor.PreviewLiveV1Response.prototype.getSingStartTimeInClient = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.PreviewLiveV1Response} returns this
 */
proto.jp.singcolor.PreviewLiveV1Response.prototype.setSingStartTimeInClient = function (value) {
    return jspb.Message.setProto3IntField(this, 9, value);
};
/**
 * optional LiveLiverInfo live_liver_info = 10;
 * @return {?proto.jp.singcolor.LiveLiverInfo}
 */
proto.jp.singcolor.PreviewLiveV1Response.prototype.getLiveLiverInfo = function () {
    return /** @type{?proto.jp.singcolor.LiveLiverInfo} */ (jspb.Message.getWrapperField(this, lives_entity_pb.LiveLiverInfo, 10));
};
/**
 * @param {?proto.jp.singcolor.LiveLiverInfo|undefined} value
 * @return {!proto.jp.singcolor.PreviewLiveV1Response} returns this
*/
proto.jp.singcolor.PreviewLiveV1Response.prototype.setLiveLiverInfo = function (value) {
    return jspb.Message.setWrapperField(this, 10, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.PreviewLiveV1Response} returns this
 */
proto.jp.singcolor.PreviewLiveV1Response.prototype.clearLiveLiverInfo = function () {
    return this.setLiveLiverInfo(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.PreviewLiveV1Response.prototype.hasLiveLiverInfo = function () {
    return jspb.Message.getField(this, 10) != null;
};
/**
 * optional string pre_enter_comment = 11;
 * @return {string}
 */
proto.jp.singcolor.PreviewLiveV1Response.prototype.getPreEnterComment = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.PreviewLiveV1Response} returns this
 */
proto.jp.singcolor.PreviewLiveV1Response.prototype.setPreEnterComment = function (value) {
    return jspb.Message.setProto3StringField(this, 11, value);
};
/**
 * optional bool use_css = 12;
 * @return {boolean}
 */
proto.jp.singcolor.PreviewLiveV1Response.prototype.getUseCss = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.PreviewLiveV1Response} returns this
 */
proto.jp.singcolor.PreviewLiveV1Response.prototype.setUseCss = function (value) {
    return jspb.Message.setProto3BooleanField(this, 12, value);
};
/**
 * optional string css_viewing_url = 13;
 * @return {string}
 */
proto.jp.singcolor.PreviewLiveV1Response.prototype.getCssViewingUrl = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.PreviewLiveV1Response} returns this
 */
proto.jp.singcolor.PreviewLiveV1Response.prototype.setCssViewingUrl = function (value) {
    return jspb.Message.setProto3StringField(this, 13, value);
};
/**
 * optional EnterPreviewSong enter_preview_song = 14;
 * @return {?proto.jp.singcolor.EnterPreviewSong}
 */
proto.jp.singcolor.PreviewLiveV1Response.prototype.getEnterPreviewSong = function () {
    return /** @type{?proto.jp.singcolor.EnterPreviewSong} */ (jspb.Message.getWrapperField(this, lives_entity_pb.EnterPreviewSong, 14));
};
/**
 * @param {?proto.jp.singcolor.EnterPreviewSong|undefined} value
 * @return {!proto.jp.singcolor.PreviewLiveV1Response} returns this
*/
proto.jp.singcolor.PreviewLiveV1Response.prototype.setEnterPreviewSong = function (value) {
    return jspb.Message.setWrapperField(this, 14, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.PreviewLiveV1Response} returns this
 */
proto.jp.singcolor.PreviewLiveV1Response.prototype.clearEnterPreviewSong = function () {
    return this.setEnterPreviewSong(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.PreviewLiveV1Response.prototype.hasEnterPreviewSong = function () {
    return jspb.Message.getField(this, 14) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.EnterLiveV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.EnterLiveV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.EnterLiveV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.EnterLiveV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            liveId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.EnterLiveV1Request}
 */
proto.jp.singcolor.EnterLiveV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.EnterLiveV1Request;
    return proto.jp.singcolor.EnterLiveV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.EnterLiveV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.EnterLiveV1Request}
 */
proto.jp.singcolor.EnterLiveV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiveId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.EnterLiveV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.EnterLiveV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.EnterLiveV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.EnterLiveV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiveId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string live_id = 1;
 * @return {string}
 */
proto.jp.singcolor.EnterLiveV1Request.prototype.getLiveId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.EnterLiveV1Request} returns this
 */
proto.jp.singcolor.EnterLiveV1Request.prototype.setLiveId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.EnterLiveV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.EnterLiveV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.EnterLiveV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.EnterLiveV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            liveViewingSessionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            live: (f = msg.getLive()) && lives_entity_pb.Live.toObject(includeInstance, f),
            song: (f = msg.getSong()) && karaoke_entity_pb.Song.toObject(includeInstance, f),
            onlineServer: (f = msg.getOnlineServer()) && lives_entity_pb.OnlineServer.toObject(includeInstance, f),
            streamingAppId: jspb.Message.getFieldWithDefault(msg, 5, 0),
            trtcRoomId: jspb.Message.getFieldWithDefault(msg, 6, ""),
            signature: jspb.Message.getFieldWithDefault(msg, 7, ""),
            canSendHeartfulMention: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
            singStartTimeInClient: jspb.Message.getFieldWithDefault(msg, 9, 0),
            postEnterComment: jspb.Message.getFieldWithDefault(msg, 10, ""),
            reqCapturePossibility: (f = msg.getReqCapturePossibility()) && lives_entity_pb.ReqCapturePossibility.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.EnterLiveV1Response}
 */
proto.jp.singcolor.EnterLiveV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.EnterLiveV1Response;
    return proto.jp.singcolor.EnterLiveV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.EnterLiveV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.EnterLiveV1Response}
 */
proto.jp.singcolor.EnterLiveV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiveViewingSessionId(value);
                break;
            case 2:
                var value = new lives_entity_pb.Live;
                reader.readMessage(value, lives_entity_pb.Live.deserializeBinaryFromReader);
                msg.setLive(value);
                break;
            case 3:
                var value = new karaoke_entity_pb.Song;
                reader.readMessage(value, karaoke_entity_pb.Song.deserializeBinaryFromReader);
                msg.setSong(value);
                break;
            case 4:
                var value = new lives_entity_pb.OnlineServer;
                reader.readMessage(value, lives_entity_pb.OnlineServer.deserializeBinaryFromReader);
                msg.setOnlineServer(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setStreamingAppId(value);
                break;
            case 6:
                var value = /** @type {string} */ (reader.readString());
                msg.setTrtcRoomId(value);
                break;
            case 7:
                var value = /** @type {string} */ (reader.readString());
                msg.setSignature(value);
                break;
            case 8:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setCanSendHeartfulMention(value);
                break;
            case 9:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setSingStartTimeInClient(value);
                break;
            case 10:
                var value = /** @type {string} */ (reader.readString());
                msg.setPostEnterComment(value);
                break;
            case 11:
                var value = new lives_entity_pb.ReqCapturePossibility;
                reader.readMessage(value, lives_entity_pb.ReqCapturePossibility.deserializeBinaryFromReader);
                msg.setReqCapturePossibility(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.EnterLiveV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.EnterLiveV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.EnterLiveV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.EnterLiveV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiveViewingSessionId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getLive();
    if (f != null) {
        writer.writeMessage(2, f, lives_entity_pb.Live.serializeBinaryToWriter);
    }
    f = message.getSong();
    if (f != null) {
        writer.writeMessage(3, f, karaoke_entity_pb.Song.serializeBinaryToWriter);
    }
    f = message.getOnlineServer();
    if (f != null) {
        writer.writeMessage(4, f, lives_entity_pb.OnlineServer.serializeBinaryToWriter);
    }
    f = message.getStreamingAppId();
    if (f !== 0) {
        writer.writeInt64(5, f);
    }
    f = message.getTrtcRoomId();
    if (f.length > 0) {
        writer.writeString(6, f);
    }
    f = message.getSignature();
    if (f.length > 0) {
        writer.writeString(7, f);
    }
    f = message.getCanSendHeartfulMention();
    if (f) {
        writer.writeBool(8, f);
    }
    f = message.getSingStartTimeInClient();
    if (f !== 0) {
        writer.writeInt64(9, f);
    }
    f = message.getPostEnterComment();
    if (f.length > 0) {
        writer.writeString(10, f);
    }
    f = message.getReqCapturePossibility();
    if (f != null) {
        writer.writeMessage(11, f, lives_entity_pb.ReqCapturePossibility.serializeBinaryToWriter);
    }
};
/**
 * optional string live_viewing_session_id = 1;
 * @return {string}
 */
proto.jp.singcolor.EnterLiveV1Response.prototype.getLiveViewingSessionId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.EnterLiveV1Response} returns this
 */
proto.jp.singcolor.EnterLiveV1Response.prototype.setLiveViewingSessionId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional Live live = 2;
 * @return {?proto.jp.singcolor.Live}
 */
proto.jp.singcolor.EnterLiveV1Response.prototype.getLive = function () {
    return /** @type{?proto.jp.singcolor.Live} */ (jspb.Message.getWrapperField(this, lives_entity_pb.Live, 2));
};
/**
 * @param {?proto.jp.singcolor.Live|undefined} value
 * @return {!proto.jp.singcolor.EnterLiveV1Response} returns this
*/
proto.jp.singcolor.EnterLiveV1Response.prototype.setLive = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.EnterLiveV1Response} returns this
 */
proto.jp.singcolor.EnterLiveV1Response.prototype.clearLive = function () {
    return this.setLive(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.EnterLiveV1Response.prototype.hasLive = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * optional Song song = 3;
 * @return {?proto.jp.singcolor.Song}
 */
proto.jp.singcolor.EnterLiveV1Response.prototype.getSong = function () {
    return /** @type{?proto.jp.singcolor.Song} */ (jspb.Message.getWrapperField(this, karaoke_entity_pb.Song, 3));
};
/**
 * @param {?proto.jp.singcolor.Song|undefined} value
 * @return {!proto.jp.singcolor.EnterLiveV1Response} returns this
*/
proto.jp.singcolor.EnterLiveV1Response.prototype.setSong = function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.EnterLiveV1Response} returns this
 */
proto.jp.singcolor.EnterLiveV1Response.prototype.clearSong = function () {
    return this.setSong(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.EnterLiveV1Response.prototype.hasSong = function () {
    return jspb.Message.getField(this, 3) != null;
};
/**
 * optional OnlineServer online_server = 4;
 * @return {?proto.jp.singcolor.OnlineServer}
 */
proto.jp.singcolor.EnterLiveV1Response.prototype.getOnlineServer = function () {
    return /** @type{?proto.jp.singcolor.OnlineServer} */ (jspb.Message.getWrapperField(this, lives_entity_pb.OnlineServer, 4));
};
/**
 * @param {?proto.jp.singcolor.OnlineServer|undefined} value
 * @return {!proto.jp.singcolor.EnterLiveV1Response} returns this
*/
proto.jp.singcolor.EnterLiveV1Response.prototype.setOnlineServer = function (value) {
    return jspb.Message.setWrapperField(this, 4, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.EnterLiveV1Response} returns this
 */
proto.jp.singcolor.EnterLiveV1Response.prototype.clearOnlineServer = function () {
    return this.setOnlineServer(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.EnterLiveV1Response.prototype.hasOnlineServer = function () {
    return jspb.Message.getField(this, 4) != null;
};
/**
 * optional int64 streaming_app_id = 5;
 * @return {number}
 */
proto.jp.singcolor.EnterLiveV1Response.prototype.getStreamingAppId = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.EnterLiveV1Response} returns this
 */
proto.jp.singcolor.EnterLiveV1Response.prototype.setStreamingAppId = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
/**
 * optional string trtc_room_id = 6;
 * @return {string}
 */
proto.jp.singcolor.EnterLiveV1Response.prototype.getTrtcRoomId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.EnterLiveV1Response} returns this
 */
proto.jp.singcolor.EnterLiveV1Response.prototype.setTrtcRoomId = function (value) {
    return jspb.Message.setProto3StringField(this, 6, value);
};
/**
 * optional string signature = 7;
 * @return {string}
 */
proto.jp.singcolor.EnterLiveV1Response.prototype.getSignature = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.EnterLiveV1Response} returns this
 */
proto.jp.singcolor.EnterLiveV1Response.prototype.setSignature = function (value) {
    return jspb.Message.setProto3StringField(this, 7, value);
};
/**
 * optional bool can_send_heartful_mention = 8;
 * @return {boolean}
 */
proto.jp.singcolor.EnterLiveV1Response.prototype.getCanSendHeartfulMention = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.EnterLiveV1Response} returns this
 */
proto.jp.singcolor.EnterLiveV1Response.prototype.setCanSendHeartfulMention = function (value) {
    return jspb.Message.setProto3BooleanField(this, 8, value);
};
/**
 * optional int64 sing_start_time_in_client = 9;
 * @return {number}
 */
proto.jp.singcolor.EnterLiveV1Response.prototype.getSingStartTimeInClient = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.EnterLiveV1Response} returns this
 */
proto.jp.singcolor.EnterLiveV1Response.prototype.setSingStartTimeInClient = function (value) {
    return jspb.Message.setProto3IntField(this, 9, value);
};
/**
 * optional string post_enter_comment = 10;
 * @return {string}
 */
proto.jp.singcolor.EnterLiveV1Response.prototype.getPostEnterComment = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.EnterLiveV1Response} returns this
 */
proto.jp.singcolor.EnterLiveV1Response.prototype.setPostEnterComment = function (value) {
    return jspb.Message.setProto3StringField(this, 10, value);
};
/**
 * optional ReqCapturePossibility req_capture_possibility = 11;
 * @return {?proto.jp.singcolor.ReqCapturePossibility}
 */
proto.jp.singcolor.EnterLiveV1Response.prototype.getReqCapturePossibility = function () {
    return /** @type{?proto.jp.singcolor.ReqCapturePossibility} */ (jspb.Message.getWrapperField(this, lives_entity_pb.ReqCapturePossibility, 11));
};
/**
 * @param {?proto.jp.singcolor.ReqCapturePossibility|undefined} value
 * @return {!proto.jp.singcolor.EnterLiveV1Response} returns this
*/
proto.jp.singcolor.EnterLiveV1Response.prototype.setReqCapturePossibility = function (value) {
    return jspb.Message.setWrapperField(this, 11, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.EnterLiveV1Response} returns this
 */
proto.jp.singcolor.EnterLiveV1Response.prototype.clearReqCapturePossibility = function () {
    return this.setReqCapturePossibility(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.EnterLiveV1Response.prototype.hasReqCapturePossibility = function () {
    return jspb.Message.getField(this, 11) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.LeaveLiveV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.LeaveLiveV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.LeaveLiveV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.LeaveLiveV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            liveId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.LeaveLiveV1Request}
 */
proto.jp.singcolor.LeaveLiveV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.LeaveLiveV1Request;
    return proto.jp.singcolor.LeaveLiveV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.LeaveLiveV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.LeaveLiveV1Request}
 */
proto.jp.singcolor.LeaveLiveV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiveId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.LeaveLiveV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.LeaveLiveV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.LeaveLiveV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LeaveLiveV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiveId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string live_id = 1;
 * @return {string}
 */
proto.jp.singcolor.LeaveLiveV1Request.prototype.getLiveId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.LeaveLiveV1Request} returns this
 */
proto.jp.singcolor.LeaveLiveV1Request.prototype.setLiveId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.LeaveLiveV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.LeaveLiveV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.LeaveLiveV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.LeaveLiveV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            live: (f = msg.getLive()) && lives_entity_pb.Live.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.LeaveLiveV1Response}
 */
proto.jp.singcolor.LeaveLiveV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.LeaveLiveV1Response;
    return proto.jp.singcolor.LeaveLiveV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.LeaveLiveV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.LeaveLiveV1Response}
 */
proto.jp.singcolor.LeaveLiveV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new lives_entity_pb.Live;
                reader.readMessage(value, lives_entity_pb.Live.deserializeBinaryFromReader);
                msg.setLive(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.LeaveLiveV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.LeaveLiveV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.LeaveLiveV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LeaveLiveV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLive();
    if (f != null) {
        writer.writeMessage(1, f, lives_entity_pb.Live.serializeBinaryToWriter);
    }
};
/**
 * optional Live live = 1;
 * @return {?proto.jp.singcolor.Live}
 */
proto.jp.singcolor.LeaveLiveV1Response.prototype.getLive = function () {
    return /** @type{?proto.jp.singcolor.Live} */ (jspb.Message.getWrapperField(this, lives_entity_pb.Live, 1));
};
/**
 * @param {?proto.jp.singcolor.Live|undefined} value
 * @return {!proto.jp.singcolor.LeaveLiveV1Response} returns this
*/
proto.jp.singcolor.LeaveLiveV1Response.prototype.setLive = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LeaveLiveV1Response} returns this
 */
proto.jp.singcolor.LeaveLiveV1Response.prototype.clearLive = function () {
    return this.setLive(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LeaveLiveV1Response.prototype.hasLive = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.HeartbeatLiveV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.HeartbeatLiveV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.HeartbeatLiveV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.HeartbeatLiveV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            liveId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.HeartbeatLiveV1Request}
 */
proto.jp.singcolor.HeartbeatLiveV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.HeartbeatLiveV1Request;
    return proto.jp.singcolor.HeartbeatLiveV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.HeartbeatLiveV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.HeartbeatLiveV1Request}
 */
proto.jp.singcolor.HeartbeatLiveV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiveId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.HeartbeatLiveV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.HeartbeatLiveV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.HeartbeatLiveV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.HeartbeatLiveV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiveId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string live_id = 1;
 * @return {string}
 */
proto.jp.singcolor.HeartbeatLiveV1Request.prototype.getLiveId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.HeartbeatLiveV1Request} returns this
 */
proto.jp.singcolor.HeartbeatLiveV1Request.prototype.setLiveId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.HeartbeatLiveV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.HeartbeatLiveV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.HeartbeatLiveV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.HeartbeatLiveV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            live: (f = msg.getLive()) && lives_entity_pb.Live.toObject(includeInstance, f),
            song: (f = msg.getSong()) && karaoke_entity_pb.Song.toObject(includeInstance, f),
            liveLiverInfo: (f = msg.getLiveLiverInfo()) && lives_entity_pb.LiveLiverInfo.toObject(includeInstance, f),
            latestGiftMasterUpdatedAt: jspb.Message.getFieldWithDefault(msg, 4, 0),
            latestGiftPublishChangedAt: jspb.Message.getFieldWithDefault(msg, 5, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.HeartbeatLiveV1Response}
 */
proto.jp.singcolor.HeartbeatLiveV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.HeartbeatLiveV1Response;
    return proto.jp.singcolor.HeartbeatLiveV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.HeartbeatLiveV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.HeartbeatLiveV1Response}
 */
proto.jp.singcolor.HeartbeatLiveV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new lives_entity_pb.Live;
                reader.readMessage(value, lives_entity_pb.Live.deserializeBinaryFromReader);
                msg.setLive(value);
                break;
            case 2:
                var value = new karaoke_entity_pb.Song;
                reader.readMessage(value, karaoke_entity_pb.Song.deserializeBinaryFromReader);
                msg.setSong(value);
                break;
            case 3:
                var value = new lives_entity_pb.LiveLiverInfo;
                reader.readMessage(value, lives_entity_pb.LiveLiverInfo.deserializeBinaryFromReader);
                msg.setLiveLiverInfo(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setLatestGiftMasterUpdatedAt(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setLatestGiftPublishChangedAt(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.HeartbeatLiveV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.HeartbeatLiveV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.HeartbeatLiveV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.HeartbeatLiveV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLive();
    if (f != null) {
        writer.writeMessage(1, f, lives_entity_pb.Live.serializeBinaryToWriter);
    }
    f = message.getSong();
    if (f != null) {
        writer.writeMessage(2, f, karaoke_entity_pb.Song.serializeBinaryToWriter);
    }
    f = message.getLiveLiverInfo();
    if (f != null) {
        writer.writeMessage(3, f, lives_entity_pb.LiveLiverInfo.serializeBinaryToWriter);
    }
    f = message.getLatestGiftMasterUpdatedAt();
    if (f !== 0) {
        writer.writeInt64(4, f);
    }
    f = message.getLatestGiftPublishChangedAt();
    if (f !== 0) {
        writer.writeInt64(5, f);
    }
};
/**
 * optional Live live = 1;
 * @return {?proto.jp.singcolor.Live}
 */
proto.jp.singcolor.HeartbeatLiveV1Response.prototype.getLive = function () {
    return /** @type{?proto.jp.singcolor.Live} */ (jspb.Message.getWrapperField(this, lives_entity_pb.Live, 1));
};
/**
 * @param {?proto.jp.singcolor.Live|undefined} value
 * @return {!proto.jp.singcolor.HeartbeatLiveV1Response} returns this
*/
proto.jp.singcolor.HeartbeatLiveV1Response.prototype.setLive = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.HeartbeatLiveV1Response} returns this
 */
proto.jp.singcolor.HeartbeatLiveV1Response.prototype.clearLive = function () {
    return this.setLive(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.HeartbeatLiveV1Response.prototype.hasLive = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional Song song = 2;
 * @return {?proto.jp.singcolor.Song}
 */
proto.jp.singcolor.HeartbeatLiveV1Response.prototype.getSong = function () {
    return /** @type{?proto.jp.singcolor.Song} */ (jspb.Message.getWrapperField(this, karaoke_entity_pb.Song, 2));
};
/**
 * @param {?proto.jp.singcolor.Song|undefined} value
 * @return {!proto.jp.singcolor.HeartbeatLiveV1Response} returns this
*/
proto.jp.singcolor.HeartbeatLiveV1Response.prototype.setSong = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.HeartbeatLiveV1Response} returns this
 */
proto.jp.singcolor.HeartbeatLiveV1Response.prototype.clearSong = function () {
    return this.setSong(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.HeartbeatLiveV1Response.prototype.hasSong = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * optional LiveLiverInfo live_liver_info = 3;
 * @return {?proto.jp.singcolor.LiveLiverInfo}
 */
proto.jp.singcolor.HeartbeatLiveV1Response.prototype.getLiveLiverInfo = function () {
    return /** @type{?proto.jp.singcolor.LiveLiverInfo} */ (jspb.Message.getWrapperField(this, lives_entity_pb.LiveLiverInfo, 3));
};
/**
 * @param {?proto.jp.singcolor.LiveLiverInfo|undefined} value
 * @return {!proto.jp.singcolor.HeartbeatLiveV1Response} returns this
*/
proto.jp.singcolor.HeartbeatLiveV1Response.prototype.setLiveLiverInfo = function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.HeartbeatLiveV1Response} returns this
 */
proto.jp.singcolor.HeartbeatLiveV1Response.prototype.clearLiveLiverInfo = function () {
    return this.setLiveLiverInfo(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.HeartbeatLiveV1Response.prototype.hasLiveLiverInfo = function () {
    return jspb.Message.getField(this, 3) != null;
};
/**
 * optional int64 latest_gift_master_updated_at = 4;
 * @return {number}
 */
proto.jp.singcolor.HeartbeatLiveV1Response.prototype.getLatestGiftMasterUpdatedAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.HeartbeatLiveV1Response} returns this
 */
proto.jp.singcolor.HeartbeatLiveV1Response.prototype.setLatestGiftMasterUpdatedAt = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * optional int64 latest_gift_publish_changed_at = 5;
 * @return {number}
 */
proto.jp.singcolor.HeartbeatLiveV1Response.prototype.getLatestGiftPublishChangedAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.HeartbeatLiveV1Response} returns this
 */
proto.jp.singcolor.HeartbeatLiveV1Response.prototype.setLatestGiftPublishChangedAt = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.HeartbeatLiveViewingV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.HeartbeatLiveViewingV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.HeartbeatLiveViewingV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.HeartbeatLiveViewingV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            liveId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.HeartbeatLiveViewingV1Request}
 */
proto.jp.singcolor.HeartbeatLiveViewingV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.HeartbeatLiveViewingV1Request;
    return proto.jp.singcolor.HeartbeatLiveViewingV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.HeartbeatLiveViewingV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.HeartbeatLiveViewingV1Request}
 */
proto.jp.singcolor.HeartbeatLiveViewingV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiveId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.HeartbeatLiveViewingV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.HeartbeatLiveViewingV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.HeartbeatLiveViewingV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.HeartbeatLiveViewingV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiveId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string live_id = 1;
 * @return {string}
 */
proto.jp.singcolor.HeartbeatLiveViewingV1Request.prototype.getLiveId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.HeartbeatLiveViewingV1Request} returns this
 */
proto.jp.singcolor.HeartbeatLiveViewingV1Request.prototype.setLiveId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.HeartbeatLiveViewingV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.HeartbeatLiveViewingV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.HeartbeatLiveViewingV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.HeartbeatLiveViewingV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            live: (f = msg.getLive()) && lives_entity_pb.Live.toObject(includeInstance, f),
            song: (f = msg.getSong()) && karaoke_entity_pb.Song.toObject(includeInstance, f),
            liveLiverInfo: (f = msg.getLiveLiverInfo()) && lives_entity_pb.LiveLiverInfo.toObject(includeInstance, f),
            canSendHeartfulMention: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
            singStartTimeInClient: jspb.Message.getFieldWithDefault(msg, 5, 0),
            latestGiftMasterUpdatedAt: jspb.Message.getFieldWithDefault(msg, 6, 0),
            latestGiftPublishChangedAt: jspb.Message.getFieldWithDefault(msg, 7, 0),
            reqCapturePossibility: (f = msg.getReqCapturePossibility()) && lives_entity_pb.ReqCapturePossibility.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.HeartbeatLiveViewingV1Response}
 */
proto.jp.singcolor.HeartbeatLiveViewingV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.HeartbeatLiveViewingV1Response;
    return proto.jp.singcolor.HeartbeatLiveViewingV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.HeartbeatLiveViewingV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.HeartbeatLiveViewingV1Response}
 */
proto.jp.singcolor.HeartbeatLiveViewingV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new lives_entity_pb.Live;
                reader.readMessage(value, lives_entity_pb.Live.deserializeBinaryFromReader);
                msg.setLive(value);
                break;
            case 2:
                var value = new karaoke_entity_pb.Song;
                reader.readMessage(value, karaoke_entity_pb.Song.deserializeBinaryFromReader);
                msg.setSong(value);
                break;
            case 3:
                var value = new lives_entity_pb.LiveLiverInfo;
                reader.readMessage(value, lives_entity_pb.LiveLiverInfo.deserializeBinaryFromReader);
                msg.setLiveLiverInfo(value);
                break;
            case 4:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setCanSendHeartfulMention(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setSingStartTimeInClient(value);
                break;
            case 6:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setLatestGiftMasterUpdatedAt(value);
                break;
            case 7:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setLatestGiftPublishChangedAt(value);
                break;
            case 8:
                var value = new lives_entity_pb.ReqCapturePossibility;
                reader.readMessage(value, lives_entity_pb.ReqCapturePossibility.deserializeBinaryFromReader);
                msg.setReqCapturePossibility(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.HeartbeatLiveViewingV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.HeartbeatLiveViewingV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.HeartbeatLiveViewingV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.HeartbeatLiveViewingV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLive();
    if (f != null) {
        writer.writeMessage(1, f, lives_entity_pb.Live.serializeBinaryToWriter);
    }
    f = message.getSong();
    if (f != null) {
        writer.writeMessage(2, f, karaoke_entity_pb.Song.serializeBinaryToWriter);
    }
    f = message.getLiveLiverInfo();
    if (f != null) {
        writer.writeMessage(3, f, lives_entity_pb.LiveLiverInfo.serializeBinaryToWriter);
    }
    f = message.getCanSendHeartfulMention();
    if (f) {
        writer.writeBool(4, f);
    }
    f = message.getSingStartTimeInClient();
    if (f !== 0) {
        writer.writeInt64(5, f);
    }
    f = message.getLatestGiftMasterUpdatedAt();
    if (f !== 0) {
        writer.writeInt64(6, f);
    }
    f = message.getLatestGiftPublishChangedAt();
    if (f !== 0) {
        writer.writeInt64(7, f);
    }
    f = message.getReqCapturePossibility();
    if (f != null) {
        writer.writeMessage(8, f, lives_entity_pb.ReqCapturePossibility.serializeBinaryToWriter);
    }
};
/**
 * optional Live live = 1;
 * @return {?proto.jp.singcolor.Live}
 */
proto.jp.singcolor.HeartbeatLiveViewingV1Response.prototype.getLive = function () {
    return /** @type{?proto.jp.singcolor.Live} */ (jspb.Message.getWrapperField(this, lives_entity_pb.Live, 1));
};
/**
 * @param {?proto.jp.singcolor.Live|undefined} value
 * @return {!proto.jp.singcolor.HeartbeatLiveViewingV1Response} returns this
*/
proto.jp.singcolor.HeartbeatLiveViewingV1Response.prototype.setLive = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.HeartbeatLiveViewingV1Response} returns this
 */
proto.jp.singcolor.HeartbeatLiveViewingV1Response.prototype.clearLive = function () {
    return this.setLive(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.HeartbeatLiveViewingV1Response.prototype.hasLive = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional Song song = 2;
 * @return {?proto.jp.singcolor.Song}
 */
proto.jp.singcolor.HeartbeatLiveViewingV1Response.prototype.getSong = function () {
    return /** @type{?proto.jp.singcolor.Song} */ (jspb.Message.getWrapperField(this, karaoke_entity_pb.Song, 2));
};
/**
 * @param {?proto.jp.singcolor.Song|undefined} value
 * @return {!proto.jp.singcolor.HeartbeatLiveViewingV1Response} returns this
*/
proto.jp.singcolor.HeartbeatLiveViewingV1Response.prototype.setSong = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.HeartbeatLiveViewingV1Response} returns this
 */
proto.jp.singcolor.HeartbeatLiveViewingV1Response.prototype.clearSong = function () {
    return this.setSong(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.HeartbeatLiveViewingV1Response.prototype.hasSong = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * optional LiveLiverInfo live_liver_info = 3;
 * @return {?proto.jp.singcolor.LiveLiverInfo}
 */
proto.jp.singcolor.HeartbeatLiveViewingV1Response.prototype.getLiveLiverInfo = function () {
    return /** @type{?proto.jp.singcolor.LiveLiverInfo} */ (jspb.Message.getWrapperField(this, lives_entity_pb.LiveLiverInfo, 3));
};
/**
 * @param {?proto.jp.singcolor.LiveLiverInfo|undefined} value
 * @return {!proto.jp.singcolor.HeartbeatLiveViewingV1Response} returns this
*/
proto.jp.singcolor.HeartbeatLiveViewingV1Response.prototype.setLiveLiverInfo = function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.HeartbeatLiveViewingV1Response} returns this
 */
proto.jp.singcolor.HeartbeatLiveViewingV1Response.prototype.clearLiveLiverInfo = function () {
    return this.setLiveLiverInfo(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.HeartbeatLiveViewingV1Response.prototype.hasLiveLiverInfo = function () {
    return jspb.Message.getField(this, 3) != null;
};
/**
 * optional bool can_send_heartful_mention = 4;
 * @return {boolean}
 */
proto.jp.singcolor.HeartbeatLiveViewingV1Response.prototype.getCanSendHeartfulMention = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.HeartbeatLiveViewingV1Response} returns this
 */
proto.jp.singcolor.HeartbeatLiveViewingV1Response.prototype.setCanSendHeartfulMention = function (value) {
    return jspb.Message.setProto3BooleanField(this, 4, value);
};
/**
 * optional int64 sing_start_time_in_client = 5;
 * @return {number}
 */
proto.jp.singcolor.HeartbeatLiveViewingV1Response.prototype.getSingStartTimeInClient = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.HeartbeatLiveViewingV1Response} returns this
 */
proto.jp.singcolor.HeartbeatLiveViewingV1Response.prototype.setSingStartTimeInClient = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
/**
 * optional int64 latest_gift_master_updated_at = 6;
 * @return {number}
 */
proto.jp.singcolor.HeartbeatLiveViewingV1Response.prototype.getLatestGiftMasterUpdatedAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.HeartbeatLiveViewingV1Response} returns this
 */
proto.jp.singcolor.HeartbeatLiveViewingV1Response.prototype.setLatestGiftMasterUpdatedAt = function (value) {
    return jspb.Message.setProto3IntField(this, 6, value);
};
/**
 * optional int64 latest_gift_publish_changed_at = 7;
 * @return {number}
 */
proto.jp.singcolor.HeartbeatLiveViewingV1Response.prototype.getLatestGiftPublishChangedAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.HeartbeatLiveViewingV1Response} returns this
 */
proto.jp.singcolor.HeartbeatLiveViewingV1Response.prototype.setLatestGiftPublishChangedAt = function (value) {
    return jspb.Message.setProto3IntField(this, 7, value);
};
/**
 * optional ReqCapturePossibility req_capture_possibility = 8;
 * @return {?proto.jp.singcolor.ReqCapturePossibility}
 */
proto.jp.singcolor.HeartbeatLiveViewingV1Response.prototype.getReqCapturePossibility = function () {
    return /** @type{?proto.jp.singcolor.ReqCapturePossibility} */ (jspb.Message.getWrapperField(this, lives_entity_pb.ReqCapturePossibility, 8));
};
/**
 * @param {?proto.jp.singcolor.ReqCapturePossibility|undefined} value
 * @return {!proto.jp.singcolor.HeartbeatLiveViewingV1Response} returns this
*/
proto.jp.singcolor.HeartbeatLiveViewingV1Response.prototype.setReqCapturePossibility = function (value) {
    return jspb.Message.setWrapperField(this, 8, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.HeartbeatLiveViewingV1Response} returns this
 */
proto.jp.singcolor.HeartbeatLiveViewingV1Response.prototype.clearReqCapturePossibility = function () {
    return this.setReqCapturePossibility(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.HeartbeatLiveViewingV1Response.prototype.hasReqCapturePossibility = function () {
    return jspb.Message.getField(this, 8) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.CreateLiveCommentV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.CreateLiveCommentV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.CreateLiveCommentV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.CreateLiveCommentV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            liveId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            body: jspb.Message.getFieldWithDefault(msg, 2, ""),
            mentionUserId: jspb.Message.getFieldWithDefault(msg, 3, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.CreateLiveCommentV1Request}
 */
proto.jp.singcolor.CreateLiveCommentV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.CreateLiveCommentV1Request;
    return proto.jp.singcolor.CreateLiveCommentV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.CreateLiveCommentV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.CreateLiveCommentV1Request}
 */
proto.jp.singcolor.CreateLiveCommentV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiveId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setBody(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setMentionUserId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.CreateLiveCommentV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.CreateLiveCommentV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.CreateLiveCommentV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.CreateLiveCommentV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiveId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getBody();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getMentionUserId();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
};
/**
 * optional string live_id = 1;
 * @return {string}
 */
proto.jp.singcolor.CreateLiveCommentV1Request.prototype.getLiveId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.CreateLiveCommentV1Request} returns this
 */
proto.jp.singcolor.CreateLiveCommentV1Request.prototype.setLiveId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string body = 2;
 * @return {string}
 */
proto.jp.singcolor.CreateLiveCommentV1Request.prototype.getBody = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.CreateLiveCommentV1Request} returns this
 */
proto.jp.singcolor.CreateLiveCommentV1Request.prototype.setBody = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional string mention_user_id = 3;
 * @return {string}
 */
proto.jp.singcolor.CreateLiveCommentV1Request.prototype.getMentionUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.CreateLiveCommentV1Request} returns this
 */
proto.jp.singcolor.CreateLiveCommentV1Request.prototype.setMentionUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.CreateLiveCommentV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.CreateLiveCommentV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.CreateLiveCommentV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.CreateLiveCommentV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            liveComment: (f = msg.getLiveComment()) && lives_entity_pb.LiveComment.toObject(includeInstance, f),
            mentionUser: (f = msg.getMentionUser()) && users_entity_pb.User.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.CreateLiveCommentV1Response}
 */
proto.jp.singcolor.CreateLiveCommentV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.CreateLiveCommentV1Response;
    return proto.jp.singcolor.CreateLiveCommentV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.CreateLiveCommentV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.CreateLiveCommentV1Response}
 */
proto.jp.singcolor.CreateLiveCommentV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new lives_entity_pb.LiveComment;
                reader.readMessage(value, lives_entity_pb.LiveComment.deserializeBinaryFromReader);
                msg.setLiveComment(value);
                break;
            case 2:
                var value = new users_entity_pb.User;
                reader.readMessage(value, users_entity_pb.User.deserializeBinaryFromReader);
                msg.setMentionUser(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.CreateLiveCommentV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.CreateLiveCommentV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.CreateLiveCommentV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.CreateLiveCommentV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiveComment();
    if (f != null) {
        writer.writeMessage(1, f, lives_entity_pb.LiveComment.serializeBinaryToWriter);
    }
    f = message.getMentionUser();
    if (f != null) {
        writer.writeMessage(2, f, users_entity_pb.User.serializeBinaryToWriter);
    }
};
/**
 * optional LiveComment live_comment = 1;
 * @return {?proto.jp.singcolor.LiveComment}
 */
proto.jp.singcolor.CreateLiveCommentV1Response.prototype.getLiveComment = function () {
    return /** @type{?proto.jp.singcolor.LiveComment} */ (jspb.Message.getWrapperField(this, lives_entity_pb.LiveComment, 1));
};
/**
 * @param {?proto.jp.singcolor.LiveComment|undefined} value
 * @return {!proto.jp.singcolor.CreateLiveCommentV1Response} returns this
*/
proto.jp.singcolor.CreateLiveCommentV1Response.prototype.setLiveComment = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.CreateLiveCommentV1Response} returns this
 */
proto.jp.singcolor.CreateLiveCommentV1Response.prototype.clearLiveComment = function () {
    return this.setLiveComment(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.CreateLiveCommentV1Response.prototype.hasLiveComment = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional User mention_user = 2;
 * @return {?proto.jp.singcolor.User}
 */
proto.jp.singcolor.CreateLiveCommentV1Response.prototype.getMentionUser = function () {
    return /** @type{?proto.jp.singcolor.User} */ (jspb.Message.getWrapperField(this, users_entity_pb.User, 2));
};
/**
 * @param {?proto.jp.singcolor.User|undefined} value
 * @return {!proto.jp.singcolor.CreateLiveCommentV1Response} returns this
*/
proto.jp.singcolor.CreateLiveCommentV1Response.prototype.setMentionUser = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.CreateLiveCommentV1Response} returns this
 */
proto.jp.singcolor.CreateLiveCommentV1Response.prototype.clearMentionUser = function () {
    return this.setMentionUser(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.CreateLiveCommentV1Response.prototype.hasMentionUser = function () {
    return jspb.Message.getField(this, 2) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.SuperLikeLiveV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.SuperLikeLiveV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.SuperLikeLiveV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.SuperLikeLiveV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            liveId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            isHatsuplus: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.SuperLikeLiveV1Request}
 */
proto.jp.singcolor.SuperLikeLiveV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.SuperLikeLiveV1Request;
    return proto.jp.singcolor.SuperLikeLiveV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.SuperLikeLiveV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.SuperLikeLiveV1Request}
 */
proto.jp.singcolor.SuperLikeLiveV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiveId(value);
                break;
            case 2:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setIsHatsuplus(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.SuperLikeLiveV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.SuperLikeLiveV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.SuperLikeLiveV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.SuperLikeLiveV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiveId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getIsHatsuplus();
    if (f) {
        writer.writeBool(2, f);
    }
};
/**
 * optional string live_id = 1;
 * @return {string}
 */
proto.jp.singcolor.SuperLikeLiveV1Request.prototype.getLiveId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.SuperLikeLiveV1Request} returns this
 */
proto.jp.singcolor.SuperLikeLiveV1Request.prototype.setLiveId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional bool is_hatsuplus = 2;
 * @return {boolean}
 */
proto.jp.singcolor.SuperLikeLiveV1Request.prototype.getIsHatsuplus = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.SuperLikeLiveV1Request} returns this
 */
proto.jp.singcolor.SuperLikeLiveV1Request.prototype.setIsHatsuplus = function (value) {
    return jspb.Message.setProto3BooleanField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.SuperLikeLiveV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.SuperLikeLiveV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.SuperLikeLiveV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.SuperLikeLiveV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            gotCoinAmount: (f = msg.getGotCoinAmount()) && coins_entity_pb.CoinAmount.toObject(includeInstance, f),
            coinBalance: (f = msg.getCoinBalance()) && coins_entity_pb.CoinBalance.toObject(includeInstance, f),
            specificLiverCoin: (f = msg.getSpecificLiverCoin()) && coins_entity_pb.SpecificLiverCoin.toObject(includeInstance, f),
            isSubUserSanctioned: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.SuperLikeLiveV1Response}
 */
proto.jp.singcolor.SuperLikeLiveV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.SuperLikeLiveV1Response;
    return proto.jp.singcolor.SuperLikeLiveV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.SuperLikeLiveV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.SuperLikeLiveV1Response}
 */
proto.jp.singcolor.SuperLikeLiveV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new coins_entity_pb.CoinAmount;
                reader.readMessage(value, coins_entity_pb.CoinAmount.deserializeBinaryFromReader);
                msg.setGotCoinAmount(value);
                break;
            case 2:
                var value = new coins_entity_pb.CoinBalance;
                reader.readMessage(value, coins_entity_pb.CoinBalance.deserializeBinaryFromReader);
                msg.setCoinBalance(value);
                break;
            case 3:
                var value = new coins_entity_pb.SpecificLiverCoin;
                reader.readMessage(value, coins_entity_pb.SpecificLiverCoin.deserializeBinaryFromReader);
                msg.setSpecificLiverCoin(value);
                break;
            case 4:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setIsSubUserSanctioned(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.SuperLikeLiveV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.SuperLikeLiveV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.SuperLikeLiveV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.SuperLikeLiveV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getGotCoinAmount();
    if (f != null) {
        writer.writeMessage(1, f, coins_entity_pb.CoinAmount.serializeBinaryToWriter);
    }
    f = message.getCoinBalance();
    if (f != null) {
        writer.writeMessage(2, f, coins_entity_pb.CoinBalance.serializeBinaryToWriter);
    }
    f = message.getSpecificLiverCoin();
    if (f != null) {
        writer.writeMessage(3, f, coins_entity_pb.SpecificLiverCoin.serializeBinaryToWriter);
    }
    f = message.getIsSubUserSanctioned();
    if (f) {
        writer.writeBool(4, f);
    }
};
/**
 * optional CoinAmount got_coin_amount = 1;
 * @return {?proto.jp.singcolor.CoinAmount}
 */
proto.jp.singcolor.SuperLikeLiveV1Response.prototype.getGotCoinAmount = function () {
    return /** @type{?proto.jp.singcolor.CoinAmount} */ (jspb.Message.getWrapperField(this, coins_entity_pb.CoinAmount, 1));
};
/**
 * @param {?proto.jp.singcolor.CoinAmount|undefined} value
 * @return {!proto.jp.singcolor.SuperLikeLiveV1Response} returns this
*/
proto.jp.singcolor.SuperLikeLiveV1Response.prototype.setGotCoinAmount = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.SuperLikeLiveV1Response} returns this
 */
proto.jp.singcolor.SuperLikeLiveV1Response.prototype.clearGotCoinAmount = function () {
    return this.setGotCoinAmount(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.SuperLikeLiveV1Response.prototype.hasGotCoinAmount = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional CoinBalance coin_balance = 2;
 * @return {?proto.jp.singcolor.CoinBalance}
 */
proto.jp.singcolor.SuperLikeLiveV1Response.prototype.getCoinBalance = function () {
    return /** @type{?proto.jp.singcolor.CoinBalance} */ (jspb.Message.getWrapperField(this, coins_entity_pb.CoinBalance, 2));
};
/**
 * @param {?proto.jp.singcolor.CoinBalance|undefined} value
 * @return {!proto.jp.singcolor.SuperLikeLiveV1Response} returns this
*/
proto.jp.singcolor.SuperLikeLiveV1Response.prototype.setCoinBalance = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.SuperLikeLiveV1Response} returns this
 */
proto.jp.singcolor.SuperLikeLiveV1Response.prototype.clearCoinBalance = function () {
    return this.setCoinBalance(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.SuperLikeLiveV1Response.prototype.hasCoinBalance = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * optional SpecificLiverCoin specific_liver_coin = 3;
 * @return {?proto.jp.singcolor.SpecificLiverCoin}
 */
proto.jp.singcolor.SuperLikeLiveV1Response.prototype.getSpecificLiverCoin = function () {
    return /** @type{?proto.jp.singcolor.SpecificLiverCoin} */ (jspb.Message.getWrapperField(this, coins_entity_pb.SpecificLiverCoin, 3));
};
/**
 * @param {?proto.jp.singcolor.SpecificLiverCoin|undefined} value
 * @return {!proto.jp.singcolor.SuperLikeLiveV1Response} returns this
*/
proto.jp.singcolor.SuperLikeLiveV1Response.prototype.setSpecificLiverCoin = function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.SuperLikeLiveV1Response} returns this
 */
proto.jp.singcolor.SuperLikeLiveV1Response.prototype.clearSpecificLiverCoin = function () {
    return this.setSpecificLiverCoin(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.SuperLikeLiveV1Response.prototype.hasSpecificLiverCoin = function () {
    return jspb.Message.getField(this, 3) != null;
};
/**
 * optional bool is_sub_user_sanctioned = 4;
 * @return {boolean}
 */
proto.jp.singcolor.SuperLikeLiveV1Response.prototype.getIsSubUserSanctioned = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.SuperLikeLiveV1Response} returns this
 */
proto.jp.singcolor.SuperLikeLiveV1Response.prototype.setIsSubUserSanctioned = function (value) {
    return jspb.Message.setProto3BooleanField(this, 4, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetSuperLikeLiveTimingV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetSuperLikeLiveTimingV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetSuperLikeLiveTimingV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetSuperLikeLiveTimingV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            liveId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            liveViewingSessionId: jspb.Message.getFieldWithDefault(msg, 2, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetSuperLikeLiveTimingV1Request}
 */
proto.jp.singcolor.GetSuperLikeLiveTimingV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetSuperLikeLiveTimingV1Request;
    return proto.jp.singcolor.GetSuperLikeLiveTimingV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetSuperLikeLiveTimingV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetSuperLikeLiveTimingV1Request}
 */
proto.jp.singcolor.GetSuperLikeLiveTimingV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiveId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiveViewingSessionId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetSuperLikeLiveTimingV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetSuperLikeLiveTimingV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetSuperLikeLiveTimingV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetSuperLikeLiveTimingV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiveId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getLiveViewingSessionId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
};
/**
 * optional string live_id = 1;
 * @return {string}
 */
proto.jp.singcolor.GetSuperLikeLiveTimingV1Request.prototype.getLiveId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetSuperLikeLiveTimingV1Request} returns this
 */
proto.jp.singcolor.GetSuperLikeLiveTimingV1Request.prototype.setLiveId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string live_viewing_session_id = 2;
 * @return {string}
 */
proto.jp.singcolor.GetSuperLikeLiveTimingV1Request.prototype.getLiveViewingSessionId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetSuperLikeLiveTimingV1Request} returns this
 */
proto.jp.singcolor.GetSuperLikeLiveTimingV1Request.prototype.setLiveViewingSessionId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetSuperLikeLiveTimingV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetSuperLikeLiveTimingV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetSuperLikeLiveTimingV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetSuperLikeLiveTimingV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            availableAt: jspb.Message.getFieldWithDefault(msg, 1, 0),
            secondsUntilNext: jspb.Message.getFieldWithDefault(msg, 2, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetSuperLikeLiveTimingV1Response}
 */
proto.jp.singcolor.GetSuperLikeLiveTimingV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetSuperLikeLiveTimingV1Response;
    return proto.jp.singcolor.GetSuperLikeLiveTimingV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetSuperLikeLiveTimingV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetSuperLikeLiveTimingV1Response}
 */
proto.jp.singcolor.GetSuperLikeLiveTimingV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setAvailableAt(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setSecondsUntilNext(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetSuperLikeLiveTimingV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetSuperLikeLiveTimingV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetSuperLikeLiveTimingV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetSuperLikeLiveTimingV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getAvailableAt();
    if (f !== 0) {
        writer.writeInt64(1, f);
    }
    f = message.getSecondsUntilNext();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
};
/**
 * optional int64 available_at = 1;
 * @return {number}
 */
proto.jp.singcolor.GetSuperLikeLiveTimingV1Response.prototype.getAvailableAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetSuperLikeLiveTimingV1Response} returns this
 */
proto.jp.singcolor.GetSuperLikeLiveTimingV1Response.prototype.setAvailableAt = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
};
/**
 * optional int64 seconds_until_next = 2;
 * @return {number}
 */
proto.jp.singcolor.GetSuperLikeLiveTimingV1Response.prototype.getSecondsUntilNext = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetSuperLikeLiveTimingV1Response} returns this
 */
proto.jp.singcolor.GetSuperLikeLiveTimingV1Response.prototype.setSecondsUntilNext = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListLiveViewingSessionsV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListLiveViewingSessionsV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListLiveViewingSessionsV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListLiveViewingSessionsV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            liveId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListLiveViewingSessionsV1Request}
 */
proto.jp.singcolor.ListLiveViewingSessionsV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListLiveViewingSessionsV1Request;
    return proto.jp.singcolor.ListLiveViewingSessionsV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListLiveViewingSessionsV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListLiveViewingSessionsV1Request}
 */
proto.jp.singcolor.ListLiveViewingSessionsV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiveId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListLiveViewingSessionsV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListLiveViewingSessionsV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListLiveViewingSessionsV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListLiveViewingSessionsV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiveId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string live_id = 1;
 * @return {string}
 */
proto.jp.singcolor.ListLiveViewingSessionsV1Request.prototype.getLiveId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListLiveViewingSessionsV1Request} returns this
 */
proto.jp.singcolor.ListLiveViewingSessionsV1Request.prototype.setLiveId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListLiveViewingSessionsV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListLiveViewingSessionsV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListLiveViewingSessionsV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListLiveViewingSessionsV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListLiveViewingSessionsV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            liveViewingSessionsList: jspb.Message.toObjectList(msg.getLiveViewingSessionsList(), lives_entity_pb.LiveViewingSession.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListLiveViewingSessionsV1Response}
 */
proto.jp.singcolor.ListLiveViewingSessionsV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListLiveViewingSessionsV1Response;
    return proto.jp.singcolor.ListLiveViewingSessionsV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListLiveViewingSessionsV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListLiveViewingSessionsV1Response}
 */
proto.jp.singcolor.ListLiveViewingSessionsV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new lives_entity_pb.LiveViewingSession;
                reader.readMessage(value, lives_entity_pb.LiveViewingSession.deserializeBinaryFromReader);
                msg.addLiveViewingSessions(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListLiveViewingSessionsV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListLiveViewingSessionsV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListLiveViewingSessionsV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListLiveViewingSessionsV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiveViewingSessionsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, lives_entity_pb.LiveViewingSession.serializeBinaryToWriter);
    }
};
/**
 * repeated LiveViewingSession live_viewing_sessions = 1;
 * @return {!Array<!proto.jp.singcolor.LiveViewingSession>}
 */
proto.jp.singcolor.ListLiveViewingSessionsV1Response.prototype.getLiveViewingSessionsList = function () {
    return /** @type{!Array<!proto.jp.singcolor.LiveViewingSession>} */ (jspb.Message.getRepeatedWrapperField(this, lives_entity_pb.LiveViewingSession, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.LiveViewingSession>} value
 * @return {!proto.jp.singcolor.ListLiveViewingSessionsV1Response} returns this
*/
proto.jp.singcolor.ListLiveViewingSessionsV1Response.prototype.setLiveViewingSessionsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.LiveViewingSession=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.LiveViewingSession}
 */
proto.jp.singcolor.ListLiveViewingSessionsV1Response.prototype.addLiveViewingSessions = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.LiveViewingSession, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListLiveViewingSessionsV1Response} returns this
 */
proto.jp.singcolor.ListLiveViewingSessionsV1Response.prototype.clearLiveViewingSessionsList = function () {
    return this.setLiveViewingSessionsList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListLiveHistoriesV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListLiveHistoriesV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListLiveHistoriesV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListLiveHistoriesV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            pageToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
            limit: jspb.Message.getFieldWithDefault(msg, 2, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListLiveHistoriesV1Request}
 */
proto.jp.singcolor.ListLiveHistoriesV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListLiveHistoriesV1Request;
    return proto.jp.singcolor.ListLiveHistoriesV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListLiveHistoriesV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListLiveHistoriesV1Request}
 */
proto.jp.singcolor.ListLiveHistoriesV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setPageToken(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setLimit(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListLiveHistoriesV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListLiveHistoriesV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListLiveHistoriesV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListLiveHistoriesV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPageToken();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getLimit();
    if (f !== 0) {
        writer.writeUint64(2, f);
    }
};
/**
 * optional string page_token = 1;
 * @return {string}
 */
proto.jp.singcolor.ListLiveHistoriesV1Request.prototype.getPageToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListLiveHistoriesV1Request} returns this
 */
proto.jp.singcolor.ListLiveHistoriesV1Request.prototype.setPageToken = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional uint64 limit = 2;
 * @return {number}
 */
proto.jp.singcolor.ListLiveHistoriesV1Request.prototype.getLimit = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListLiveHistoriesV1Request} returns this
 */
proto.jp.singcolor.ListLiveHistoriesV1Request.prototype.setLimit = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListLiveHistoriesV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListLiveHistoriesV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListLiveHistoriesV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListLiveHistoriesV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListLiveHistoriesV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            liveHistoriesList: jspb.Message.toObjectList(msg.getLiveHistoriesList(), lives_entity_pb.LiveHistory.toObject, includeInstance),
            nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
            hasNext: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListLiveHistoriesV1Response}
 */
proto.jp.singcolor.ListLiveHistoriesV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListLiveHistoriesV1Response;
    return proto.jp.singcolor.ListLiveHistoriesV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListLiveHistoriesV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListLiveHistoriesV1Response}
 */
proto.jp.singcolor.ListLiveHistoriesV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new lives_entity_pb.LiveHistory;
                reader.readMessage(value, lives_entity_pb.LiveHistory.deserializeBinaryFromReader);
                msg.addLiveHistories(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setNextPageToken(value);
                break;
            case 3:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setHasNext(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListLiveHistoriesV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListLiveHistoriesV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListLiveHistoriesV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListLiveHistoriesV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiveHistoriesList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, lives_entity_pb.LiveHistory.serializeBinaryToWriter);
    }
    f = message.getNextPageToken();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getHasNext();
    if (f) {
        writer.writeBool(3, f);
    }
};
/**
 * repeated LiveHistory live_histories = 1;
 * @return {!Array<!proto.jp.singcolor.LiveHistory>}
 */
proto.jp.singcolor.ListLiveHistoriesV1Response.prototype.getLiveHistoriesList = function () {
    return /** @type{!Array<!proto.jp.singcolor.LiveHistory>} */ (jspb.Message.getRepeatedWrapperField(this, lives_entity_pb.LiveHistory, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.LiveHistory>} value
 * @return {!proto.jp.singcolor.ListLiveHistoriesV1Response} returns this
*/
proto.jp.singcolor.ListLiveHistoriesV1Response.prototype.setLiveHistoriesList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.LiveHistory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.LiveHistory}
 */
proto.jp.singcolor.ListLiveHistoriesV1Response.prototype.addLiveHistories = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.LiveHistory, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListLiveHistoriesV1Response} returns this
 */
proto.jp.singcolor.ListLiveHistoriesV1Response.prototype.clearLiveHistoriesList = function () {
    return this.setLiveHistoriesList([]);
};
/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.jp.singcolor.ListLiveHistoriesV1Response.prototype.getNextPageToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListLiveHistoriesV1Response} returns this
 */
proto.jp.singcolor.ListLiveHistoriesV1Response.prototype.setNextPageToken = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional bool has_next = 3;
 * @return {boolean}
 */
proto.jp.singcolor.ListLiveHistoriesV1Response.prototype.getHasNext = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.ListLiveHistoriesV1Response} returns this
 */
proto.jp.singcolor.ListLiveHistoriesV1Response.prototype.setHasNext = function (value) {
    return jspb.Message.setProto3BooleanField(this, 3, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.StartSingV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.StartSingV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.StartSingV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.StartSingV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            liveId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            songId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            singStartTimeInClient: jspb.Message.getFieldWithDefault(msg, 3, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.StartSingV1Request}
 */
proto.jp.singcolor.StartSingV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.StartSingV1Request;
    return proto.jp.singcolor.StartSingV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.StartSingV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.StartSingV1Request}
 */
proto.jp.singcolor.StartSingV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiveId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setSongId(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setSingStartTimeInClient(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.StartSingV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.StartSingV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.StartSingV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.StartSingV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiveId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getSongId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getSingStartTimeInClient();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
};
/**
 * optional string live_id = 1;
 * @return {string}
 */
proto.jp.singcolor.StartSingV1Request.prototype.getLiveId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.StartSingV1Request} returns this
 */
proto.jp.singcolor.StartSingV1Request.prototype.setLiveId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string song_id = 2;
 * @return {string}
 */
proto.jp.singcolor.StartSingV1Request.prototype.getSongId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.StartSingV1Request} returns this
 */
proto.jp.singcolor.StartSingV1Request.prototype.setSongId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional int64 sing_start_time_in_client = 3;
 * @return {number}
 */
proto.jp.singcolor.StartSingV1Request.prototype.getSingStartTimeInClient = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.StartSingV1Request} returns this
 */
proto.jp.singcolor.StartSingV1Request.prototype.setSingStartTimeInClient = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.StartSingV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.StartSingV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.StartSingV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.StartSingV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            live: (f = msg.getLive()) && lives_entity_pb.Live.toObject(includeInstance, f),
            song: (f = msg.getSong()) && karaoke_entity_pb.Song.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.StartSingV1Response}
 */
proto.jp.singcolor.StartSingV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.StartSingV1Response;
    return proto.jp.singcolor.StartSingV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.StartSingV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.StartSingV1Response}
 */
proto.jp.singcolor.StartSingV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new lives_entity_pb.Live;
                reader.readMessage(value, lives_entity_pb.Live.deserializeBinaryFromReader);
                msg.setLive(value);
                break;
            case 2:
                var value = new karaoke_entity_pb.Song;
                reader.readMessage(value, karaoke_entity_pb.Song.deserializeBinaryFromReader);
                msg.setSong(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.StartSingV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.StartSingV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.StartSingV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.StartSingV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLive();
    if (f != null) {
        writer.writeMessage(1, f, lives_entity_pb.Live.serializeBinaryToWriter);
    }
    f = message.getSong();
    if (f != null) {
        writer.writeMessage(2, f, karaoke_entity_pb.Song.serializeBinaryToWriter);
    }
};
/**
 * optional Live live = 1;
 * @return {?proto.jp.singcolor.Live}
 */
proto.jp.singcolor.StartSingV1Response.prototype.getLive = function () {
    return /** @type{?proto.jp.singcolor.Live} */ (jspb.Message.getWrapperField(this, lives_entity_pb.Live, 1));
};
/**
 * @param {?proto.jp.singcolor.Live|undefined} value
 * @return {!proto.jp.singcolor.StartSingV1Response} returns this
*/
proto.jp.singcolor.StartSingV1Response.prototype.setLive = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.StartSingV1Response} returns this
 */
proto.jp.singcolor.StartSingV1Response.prototype.clearLive = function () {
    return this.setLive(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.StartSingV1Response.prototype.hasLive = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional Song song = 2;
 * @return {?proto.jp.singcolor.Song}
 */
proto.jp.singcolor.StartSingV1Response.prototype.getSong = function () {
    return /** @type{?proto.jp.singcolor.Song} */ (jspb.Message.getWrapperField(this, karaoke_entity_pb.Song, 2));
};
/**
 * @param {?proto.jp.singcolor.Song|undefined} value
 * @return {!proto.jp.singcolor.StartSingV1Response} returns this
*/
proto.jp.singcolor.StartSingV1Response.prototype.setSong = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.StartSingV1Response} returns this
 */
proto.jp.singcolor.StartSingV1Response.prototype.clearSong = function () {
    return this.setSong(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.StartSingV1Response.prototype.hasSong = function () {
    return jspb.Message.getField(this, 2) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.FinishSingV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.FinishSingV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.FinishSingV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.FinishSingV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            liveId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            songId: jspb.Message.getFieldWithDefault(msg, 2, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.FinishSingV1Request}
 */
proto.jp.singcolor.FinishSingV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.FinishSingV1Request;
    return proto.jp.singcolor.FinishSingV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.FinishSingV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.FinishSingV1Request}
 */
proto.jp.singcolor.FinishSingV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiveId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setSongId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.FinishSingV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.FinishSingV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.FinishSingV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.FinishSingV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiveId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getSongId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
};
/**
 * optional string live_id = 1;
 * @return {string}
 */
proto.jp.singcolor.FinishSingV1Request.prototype.getLiveId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.FinishSingV1Request} returns this
 */
proto.jp.singcolor.FinishSingV1Request.prototype.setLiveId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string song_id = 2;
 * @return {string}
 */
proto.jp.singcolor.FinishSingV1Request.prototype.getSongId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.FinishSingV1Request} returns this
 */
proto.jp.singcolor.FinishSingV1Request.prototype.setSongId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.FinishSingV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.FinishSingV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.FinishSingV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.FinishSingV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            live: (f = msg.getLive()) && lives_entity_pb.Live.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.FinishSingV1Response}
 */
proto.jp.singcolor.FinishSingV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.FinishSingV1Response;
    return proto.jp.singcolor.FinishSingV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.FinishSingV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.FinishSingV1Response}
 */
proto.jp.singcolor.FinishSingV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new lives_entity_pb.Live;
                reader.readMessage(value, lives_entity_pb.Live.deserializeBinaryFromReader);
                msg.setLive(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.FinishSingV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.FinishSingV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.FinishSingV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.FinishSingV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLive();
    if (f != null) {
        writer.writeMessage(1, f, lives_entity_pb.Live.serializeBinaryToWriter);
    }
};
/**
 * optional Live live = 1;
 * @return {?proto.jp.singcolor.Live}
 */
proto.jp.singcolor.FinishSingV1Response.prototype.getLive = function () {
    return /** @type{?proto.jp.singcolor.Live} */ (jspb.Message.getWrapperField(this, lives_entity_pb.Live, 1));
};
/**
 * @param {?proto.jp.singcolor.Live|undefined} value
 * @return {!proto.jp.singcolor.FinishSingV1Response} returns this
*/
proto.jp.singcolor.FinishSingV1Response.prototype.setLive = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.FinishSingV1Response} returns this
 */
proto.jp.singcolor.FinishSingV1Response.prototype.clearLive = function () {
    return this.setLive(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.FinishSingV1Response.prototype.hasLive = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.RequestSingV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.RequestSingV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.RequestSingV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.RequestSingV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            liveId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            songId: jspb.Message.getFieldWithDefault(msg, 2, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.RequestSingV1Request}
 */
proto.jp.singcolor.RequestSingV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.RequestSingV1Request;
    return proto.jp.singcolor.RequestSingV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.RequestSingV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.RequestSingV1Request}
 */
proto.jp.singcolor.RequestSingV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiveId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setSongId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.RequestSingV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.RequestSingV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.RequestSingV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.RequestSingV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiveId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getSongId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
};
/**
 * optional string live_id = 1;
 * @return {string}
 */
proto.jp.singcolor.RequestSingV1Request.prototype.getLiveId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.RequestSingV1Request} returns this
 */
proto.jp.singcolor.RequestSingV1Request.prototype.setLiveId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string song_id = 2;
 * @return {string}
 */
proto.jp.singcolor.RequestSingV1Request.prototype.getSongId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.RequestSingV1Request} returns this
 */
proto.jp.singcolor.RequestSingV1Request.prototype.setSongId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.RequestSingV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.RequestSingV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.RequestSingV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.RequestSingV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            song: (f = msg.getSong()) && karaoke_entity_pb.Song.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.RequestSingV1Response}
 */
proto.jp.singcolor.RequestSingV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.RequestSingV1Response;
    return proto.jp.singcolor.RequestSingV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.RequestSingV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.RequestSingV1Response}
 */
proto.jp.singcolor.RequestSingV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new karaoke_entity_pb.Song;
                reader.readMessage(value, karaoke_entity_pb.Song.deserializeBinaryFromReader);
                msg.setSong(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.RequestSingV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.RequestSingV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.RequestSingV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.RequestSingV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getSong();
    if (f != null) {
        writer.writeMessage(1, f, karaoke_entity_pb.Song.serializeBinaryToWriter);
    }
};
/**
 * optional Song song = 1;
 * @return {?proto.jp.singcolor.Song}
 */
proto.jp.singcolor.RequestSingV1Response.prototype.getSong = function () {
    return /** @type{?proto.jp.singcolor.Song} */ (jspb.Message.getWrapperField(this, karaoke_entity_pb.Song, 1));
};
/**
 * @param {?proto.jp.singcolor.Song|undefined} value
 * @return {!proto.jp.singcolor.RequestSingV1Response} returns this
*/
proto.jp.singcolor.RequestSingV1Response.prototype.setSong = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.RequestSingV1Response} returns this
 */
proto.jp.singcolor.RequestSingV1Response.prototype.clearSong = function () {
    return this.setSong(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.RequestSingV1Response.prototype.hasSong = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListGiftTabsV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListGiftTabsV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListGiftTabsV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListGiftTabsV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListGiftTabsV1Request}
 */
proto.jp.singcolor.ListGiftTabsV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListGiftTabsV1Request;
    return proto.jp.singcolor.ListGiftTabsV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListGiftTabsV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListGiftTabsV1Request}
 */
proto.jp.singcolor.ListGiftTabsV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListGiftTabsV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListGiftTabsV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListGiftTabsV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListGiftTabsV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListGiftTabsV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListGiftTabsV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListGiftTabsV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListGiftTabsV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListGiftTabsV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            giftTabsList: jspb.Message.toObjectList(msg.getGiftTabsList(), lives_entity_pb.GiftTab.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListGiftTabsV1Response}
 */
proto.jp.singcolor.ListGiftTabsV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListGiftTabsV1Response;
    return proto.jp.singcolor.ListGiftTabsV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListGiftTabsV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListGiftTabsV1Response}
 */
proto.jp.singcolor.ListGiftTabsV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new lives_entity_pb.GiftTab;
                reader.readMessage(value, lives_entity_pb.GiftTab.deserializeBinaryFromReader);
                msg.addGiftTabs(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListGiftTabsV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListGiftTabsV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListGiftTabsV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListGiftTabsV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getGiftTabsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, lives_entity_pb.GiftTab.serializeBinaryToWriter);
    }
};
/**
 * repeated GiftTab gift_tabs = 1;
 * @return {!Array<!proto.jp.singcolor.GiftTab>}
 */
proto.jp.singcolor.ListGiftTabsV1Response.prototype.getGiftTabsList = function () {
    return /** @type{!Array<!proto.jp.singcolor.GiftTab>} */ (jspb.Message.getRepeatedWrapperField(this, lives_entity_pb.GiftTab, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.GiftTab>} value
 * @return {!proto.jp.singcolor.ListGiftTabsV1Response} returns this
*/
proto.jp.singcolor.ListGiftTabsV1Response.prototype.setGiftTabsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.GiftTab=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.GiftTab}
 */
proto.jp.singcolor.ListGiftTabsV1Response.prototype.addGiftTabs = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.GiftTab, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListGiftTabsV1Response} returns this
 */
proto.jp.singcolor.ListGiftTabsV1Response.prototype.clearGiftTabsList = function () {
    return this.setGiftTabsList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListGiftsV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListGiftsV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListGiftsV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListGiftsV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListGiftsV1Request}
 */
proto.jp.singcolor.ListGiftsV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListGiftsV1Request;
    return proto.jp.singcolor.ListGiftsV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListGiftsV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListGiftsV1Request}
 */
proto.jp.singcolor.ListGiftsV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListGiftsV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListGiftsV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListGiftsV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListGiftsV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListGiftsV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListGiftsV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListGiftsV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListGiftsV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListGiftsV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            giftsList: jspb.Message.toObjectList(msg.getGiftsList(), lives_entity_pb.Gift.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListGiftsV1Response}
 */
proto.jp.singcolor.ListGiftsV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListGiftsV1Response;
    return proto.jp.singcolor.ListGiftsV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListGiftsV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListGiftsV1Response}
 */
proto.jp.singcolor.ListGiftsV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new lives_entity_pb.Gift;
                reader.readMessage(value, lives_entity_pb.Gift.deserializeBinaryFromReader);
                msg.addGifts(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListGiftsV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListGiftsV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListGiftsV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListGiftsV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getGiftsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, lives_entity_pb.Gift.serializeBinaryToWriter);
    }
};
/**
 * repeated Gift gifts = 1;
 * @return {!Array<!proto.jp.singcolor.Gift>}
 */
proto.jp.singcolor.ListGiftsV1Response.prototype.getGiftsList = function () {
    return /** @type{!Array<!proto.jp.singcolor.Gift>} */ (jspb.Message.getRepeatedWrapperField(this, lives_entity_pb.Gift, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.Gift>} value
 * @return {!proto.jp.singcolor.ListGiftsV1Response} returns this
*/
proto.jp.singcolor.ListGiftsV1Response.prototype.setGiftsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.Gift=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.Gift}
 */
proto.jp.singcolor.ListGiftsV1Response.prototype.addGifts = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.Gift, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListGiftsV1Response} returns this
 */
proto.jp.singcolor.ListGiftsV1Response.prototype.clearGiftsList = function () {
    return this.setGiftsList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListGiftsForDownloadV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListGiftsForDownloadV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListGiftsForDownloadV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListGiftsForDownloadV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListGiftsForDownloadV1Request}
 */
proto.jp.singcolor.ListGiftsForDownloadV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListGiftsForDownloadV1Request;
    return proto.jp.singcolor.ListGiftsForDownloadV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListGiftsForDownloadV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListGiftsForDownloadV1Request}
 */
proto.jp.singcolor.ListGiftsForDownloadV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListGiftsForDownloadV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListGiftsForDownloadV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListGiftsForDownloadV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListGiftsForDownloadV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListGiftsForDownloadV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListGiftsForDownloadV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListGiftsForDownloadV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListGiftsForDownloadV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListGiftsForDownloadV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            giftsList: jspb.Message.toObjectList(msg.getGiftsList(), lives_entity_pb.Gift.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListGiftsForDownloadV1Response}
 */
proto.jp.singcolor.ListGiftsForDownloadV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListGiftsForDownloadV1Response;
    return proto.jp.singcolor.ListGiftsForDownloadV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListGiftsForDownloadV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListGiftsForDownloadV1Response}
 */
proto.jp.singcolor.ListGiftsForDownloadV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new lives_entity_pb.Gift;
                reader.readMessage(value, lives_entity_pb.Gift.deserializeBinaryFromReader);
                msg.addGifts(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListGiftsForDownloadV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListGiftsForDownloadV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListGiftsForDownloadV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListGiftsForDownloadV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getGiftsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, lives_entity_pb.Gift.serializeBinaryToWriter);
    }
};
/**
 * repeated Gift gifts = 1;
 * @return {!Array<!proto.jp.singcolor.Gift>}
 */
proto.jp.singcolor.ListGiftsForDownloadV1Response.prototype.getGiftsList = function () {
    return /** @type{!Array<!proto.jp.singcolor.Gift>} */ (jspb.Message.getRepeatedWrapperField(this, lives_entity_pb.Gift, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.Gift>} value
 * @return {!proto.jp.singcolor.ListGiftsForDownloadV1Response} returns this
*/
proto.jp.singcolor.ListGiftsForDownloadV1Response.prototype.setGiftsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.Gift=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.Gift}
 */
proto.jp.singcolor.ListGiftsForDownloadV1Response.prototype.addGifts = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.Gift, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListGiftsForDownloadV1Response} returns this
 */
proto.jp.singcolor.ListGiftsForDownloadV1Response.prototype.clearGiftsList = function () {
    return this.setGiftsList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListClosingTimesV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListClosingTimesV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListClosingTimesV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListClosingTimesV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListClosingTimesV1Request}
 */
proto.jp.singcolor.ListClosingTimesV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListClosingTimesV1Request;
    return proto.jp.singcolor.ListClosingTimesV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListClosingTimesV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListClosingTimesV1Request}
 */
proto.jp.singcolor.ListClosingTimesV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListClosingTimesV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListClosingTimesV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListClosingTimesV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListClosingTimesV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListClosingTimesV1Response.repeatedFields_ = [2];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListClosingTimesV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListClosingTimesV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListClosingTimesV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListClosingTimesV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            closingTime: (f = msg.getClosingTime()) && users_entity_pb.UserLiveClosingTime.toObject(includeInstance, f),
            availableClosingTimesList: jspb.Message.toObjectList(msg.getAvailableClosingTimesList(), users_entity_pb.AvailableClosingTime.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListClosingTimesV1Response}
 */
proto.jp.singcolor.ListClosingTimesV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListClosingTimesV1Response;
    return proto.jp.singcolor.ListClosingTimesV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListClosingTimesV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListClosingTimesV1Response}
 */
proto.jp.singcolor.ListClosingTimesV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.UserLiveClosingTime;
                reader.readMessage(value, users_entity_pb.UserLiveClosingTime.deserializeBinaryFromReader);
                msg.setClosingTime(value);
                break;
            case 2:
                var value = new users_entity_pb.AvailableClosingTime;
                reader.readMessage(value, users_entity_pb.AvailableClosingTime.deserializeBinaryFromReader);
                msg.addAvailableClosingTimes(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListClosingTimesV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListClosingTimesV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListClosingTimesV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListClosingTimesV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getClosingTime();
    if (f != null) {
        writer.writeMessage(1, f, users_entity_pb.UserLiveClosingTime.serializeBinaryToWriter);
    }
    f = message.getAvailableClosingTimesList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(2, f, users_entity_pb.AvailableClosingTime.serializeBinaryToWriter);
    }
};
/**
 * optional UserLiveClosingTime closing_time = 1;
 * @return {?proto.jp.singcolor.UserLiveClosingTime}
 */
proto.jp.singcolor.ListClosingTimesV1Response.prototype.getClosingTime = function () {
    return /** @type{?proto.jp.singcolor.UserLiveClosingTime} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserLiveClosingTime, 1));
};
/**
 * @param {?proto.jp.singcolor.UserLiveClosingTime|undefined} value
 * @return {!proto.jp.singcolor.ListClosingTimesV1Response} returns this
*/
proto.jp.singcolor.ListClosingTimesV1Response.prototype.setClosingTime = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ListClosingTimesV1Response} returns this
 */
proto.jp.singcolor.ListClosingTimesV1Response.prototype.clearClosingTime = function () {
    return this.setClosingTime(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ListClosingTimesV1Response.prototype.hasClosingTime = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * repeated AvailableClosingTime available_closing_times = 2;
 * @return {!Array<!proto.jp.singcolor.AvailableClosingTime>}
 */
proto.jp.singcolor.ListClosingTimesV1Response.prototype.getAvailableClosingTimesList = function () {
    return /** @type{!Array<!proto.jp.singcolor.AvailableClosingTime>} */ (jspb.Message.getRepeatedWrapperField(this, users_entity_pb.AvailableClosingTime, 2));
};
/**
 * @param {!Array<!proto.jp.singcolor.AvailableClosingTime>} value
 * @return {!proto.jp.singcolor.ListClosingTimesV1Response} returns this
*/
proto.jp.singcolor.ListClosingTimesV1Response.prototype.setAvailableClosingTimesList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 2, value);
};
/**
 * @param {!proto.jp.singcolor.AvailableClosingTime=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.AvailableClosingTime}
 */
proto.jp.singcolor.ListClosingTimesV1Response.prototype.addAvailableClosingTimes = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.jp.singcolor.AvailableClosingTime, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListClosingTimesV1Response} returns this
 */
proto.jp.singcolor.ListClosingTimesV1Response.prototype.clearAvailableClosingTimesList = function () {
    return this.setAvailableClosingTimesList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListInitialClosingTimesV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListInitialClosingTimesV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListInitialClosingTimesV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListInitialClosingTimesV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListInitialClosingTimesV1Request}
 */
proto.jp.singcolor.ListInitialClosingTimesV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListInitialClosingTimesV1Request;
    return proto.jp.singcolor.ListInitialClosingTimesV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListInitialClosingTimesV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListInitialClosingTimesV1Request}
 */
proto.jp.singcolor.ListInitialClosingTimesV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListInitialClosingTimesV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListInitialClosingTimesV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListInitialClosingTimesV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListInitialClosingTimesV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListInitialClosingTimesV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListInitialClosingTimesV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListInitialClosingTimesV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListInitialClosingTimesV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListInitialClosingTimesV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            listClosingTimeJstList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListInitialClosingTimesV1Response}
 */
proto.jp.singcolor.ListInitialClosingTimesV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListInitialClosingTimesV1Response;
    return proto.jp.singcolor.ListInitialClosingTimesV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListInitialClosingTimesV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListInitialClosingTimesV1Response}
 */
proto.jp.singcolor.ListInitialClosingTimesV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
                msg.setListClosingTimeJstList(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListInitialClosingTimesV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListInitialClosingTimesV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListInitialClosingTimesV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListInitialClosingTimesV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getListClosingTimeJstList();
    if (f.length > 0) {
        writer.writePackedInt64(1, f);
    }
};
/**
 * repeated int64 list_closing_time_jst = 1;
 * @return {!Array<number>}
 */
proto.jp.singcolor.ListInitialClosingTimesV1Response.prototype.getListClosingTimeJstList = function () {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};
/**
 * @param {!Array<number>} value
 * @return {!proto.jp.singcolor.ListInitialClosingTimesV1Response} returns this
 */
proto.jp.singcolor.ListInitialClosingTimesV1Response.prototype.setListClosingTimeJstList = function (value) {
    return jspb.Message.setField(this, 1, value || []);
};
/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.ListInitialClosingTimesV1Response} returns this
 */
proto.jp.singcolor.ListInitialClosingTimesV1Response.prototype.addListClosingTimeJst = function (value, opt_index) {
    return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListInitialClosingTimesV1Response} returns this
 */
proto.jp.singcolor.ListInitialClosingTimesV1Response.prototype.clearListClosingTimeJstList = function () {
    return this.setListClosingTimeJstList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.SendGiftV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.SendGiftV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.SendGiftV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.SendGiftV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            liveId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            giftId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            count: jspb.Message.getFieldWithDefault(msg, 3, 0),
            smallGiftType: jspb.Message.getFieldWithDefault(msg, 4, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.SendGiftV1Request}
 */
proto.jp.singcolor.SendGiftV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.SendGiftV1Request;
    return proto.jp.singcolor.SendGiftV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.SendGiftV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.SendGiftV1Request}
 */
proto.jp.singcolor.SendGiftV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiveId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setGiftId(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setCount(value);
                break;
            case 4:
                var value = /** @type {!proto.jp.singcolor.SmallGiftType} */ (reader.readEnum());
                msg.setSmallGiftType(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.SendGiftV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.SendGiftV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.SendGiftV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.SendGiftV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiveId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getGiftId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getCount();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
    f = message.getSmallGiftType();
    if (f !== 0.0) {
        writer.writeEnum(4, f);
    }
};
/**
 * optional string live_id = 1;
 * @return {string}
 */
proto.jp.singcolor.SendGiftV1Request.prototype.getLiveId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.SendGiftV1Request} returns this
 */
proto.jp.singcolor.SendGiftV1Request.prototype.setLiveId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string gift_id = 2;
 * @return {string}
 */
proto.jp.singcolor.SendGiftV1Request.prototype.getGiftId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.SendGiftV1Request} returns this
 */
proto.jp.singcolor.SendGiftV1Request.prototype.setGiftId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional int64 count = 3;
 * @return {number}
 */
proto.jp.singcolor.SendGiftV1Request.prototype.getCount = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.SendGiftV1Request} returns this
 */
proto.jp.singcolor.SendGiftV1Request.prototype.setCount = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional SmallGiftType small_gift_type = 4;
 * @return {!proto.jp.singcolor.SmallGiftType}
 */
proto.jp.singcolor.SendGiftV1Request.prototype.getSmallGiftType = function () {
    return /** @type {!proto.jp.singcolor.SmallGiftType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {!proto.jp.singcolor.SmallGiftType} value
 * @return {!proto.jp.singcolor.SendGiftV1Request} returns this
 */
proto.jp.singcolor.SendGiftV1Request.prototype.setSmallGiftType = function (value) {
    return jspb.Message.setProto3EnumField(this, 4, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.SendGiftV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.SendGiftV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.SendGiftV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.SendGiftV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            gift: (f = msg.getGift()) && lives_entity_pb.Gift.toObject(includeInstance, f),
            coinBalance: (f = msg.getCoinBalance()) && coins_entity_pb.CoinBalance.toObject(includeInstance, f),
            specificLiverCoin: (f = msg.getSpecificLiverCoin()) && coins_entity_pb.SpecificLiverCoin.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.SendGiftV1Response}
 */
proto.jp.singcolor.SendGiftV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.SendGiftV1Response;
    return proto.jp.singcolor.SendGiftV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.SendGiftV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.SendGiftV1Response}
 */
proto.jp.singcolor.SendGiftV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new lives_entity_pb.Gift;
                reader.readMessage(value, lives_entity_pb.Gift.deserializeBinaryFromReader);
                msg.setGift(value);
                break;
            case 2:
                var value = new coins_entity_pb.CoinBalance;
                reader.readMessage(value, coins_entity_pb.CoinBalance.deserializeBinaryFromReader);
                msg.setCoinBalance(value);
                break;
            case 3:
                var value = new coins_entity_pb.SpecificLiverCoin;
                reader.readMessage(value, coins_entity_pb.SpecificLiverCoin.deserializeBinaryFromReader);
                msg.setSpecificLiverCoin(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.SendGiftV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.SendGiftV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.SendGiftV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.SendGiftV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getGift();
    if (f != null) {
        writer.writeMessage(1, f, lives_entity_pb.Gift.serializeBinaryToWriter);
    }
    f = message.getCoinBalance();
    if (f != null) {
        writer.writeMessage(2, f, coins_entity_pb.CoinBalance.serializeBinaryToWriter);
    }
    f = message.getSpecificLiverCoin();
    if (f != null) {
        writer.writeMessage(3, f, coins_entity_pb.SpecificLiverCoin.serializeBinaryToWriter);
    }
};
/**
 * optional Gift gift = 1;
 * @return {?proto.jp.singcolor.Gift}
 */
proto.jp.singcolor.SendGiftV1Response.prototype.getGift = function () {
    return /** @type{?proto.jp.singcolor.Gift} */ (jspb.Message.getWrapperField(this, lives_entity_pb.Gift, 1));
};
/**
 * @param {?proto.jp.singcolor.Gift|undefined} value
 * @return {!proto.jp.singcolor.SendGiftV1Response} returns this
*/
proto.jp.singcolor.SendGiftV1Response.prototype.setGift = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.SendGiftV1Response} returns this
 */
proto.jp.singcolor.SendGiftV1Response.prototype.clearGift = function () {
    return this.setGift(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.SendGiftV1Response.prototype.hasGift = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional CoinBalance coin_balance = 2;
 * @return {?proto.jp.singcolor.CoinBalance}
 */
proto.jp.singcolor.SendGiftV1Response.prototype.getCoinBalance = function () {
    return /** @type{?proto.jp.singcolor.CoinBalance} */ (jspb.Message.getWrapperField(this, coins_entity_pb.CoinBalance, 2));
};
/**
 * @param {?proto.jp.singcolor.CoinBalance|undefined} value
 * @return {!proto.jp.singcolor.SendGiftV1Response} returns this
*/
proto.jp.singcolor.SendGiftV1Response.prototype.setCoinBalance = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.SendGiftV1Response} returns this
 */
proto.jp.singcolor.SendGiftV1Response.prototype.clearCoinBalance = function () {
    return this.setCoinBalance(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.SendGiftV1Response.prototype.hasCoinBalance = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * optional SpecificLiverCoin specific_liver_coin = 3;
 * @return {?proto.jp.singcolor.SpecificLiverCoin}
 */
proto.jp.singcolor.SendGiftV1Response.prototype.getSpecificLiverCoin = function () {
    return /** @type{?proto.jp.singcolor.SpecificLiverCoin} */ (jspb.Message.getWrapperField(this, coins_entity_pb.SpecificLiverCoin, 3));
};
/**
 * @param {?proto.jp.singcolor.SpecificLiverCoin|undefined} value
 * @return {!proto.jp.singcolor.SendGiftV1Response} returns this
*/
proto.jp.singcolor.SendGiftV1Response.prototype.setSpecificLiverCoin = function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.SendGiftV1Response} returns this
 */
proto.jp.singcolor.SendGiftV1Response.prototype.clearSpecificLiverCoin = function () {
    return this.setSpecificLiverCoin(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.SendGiftV1Response.prototype.hasSpecificLiverCoin = function () {
    return jspb.Message.getField(this, 3) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            year: jspb.Message.getFieldWithDefault(msg, 2, 0),
            month: jspb.Message.getFieldWithDefault(msg, 3, 0),
            termOfMonth: jspb.Message.getFieldWithDefault(msg, 4, 0),
            page: jspb.Message.getFieldWithDefault(msg, 5, 0),
            limit: jspb.Message.getFieldWithDefault(msg, 6, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Request}
 */
proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Request;
    return proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Request}
 */
proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setYear(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setMonth(value);
                break;
            case 4:
                var value = /** @type {!proto.jp.singcolor.TermOfMonth} */ (reader.readEnum());
                msg.setTermOfMonth(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setPage(value);
                break;
            case 6:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setLimit(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getYear();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getMonth();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
    f = message.getTermOfMonth();
    if (f !== 0.0) {
        writer.writeEnum(4, f);
    }
    f = message.getPage();
    if (f !== 0) {
        writer.writeUint64(5, f);
    }
    f = message.getLimit();
    if (f !== 0) {
        writer.writeUint64(6, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Request.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Request} returns this
 */
proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Request.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional int64 year = 2;
 * @return {number}
 */
proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Request.prototype.getYear = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Request} returns this
 */
proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Request.prototype.setYear = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional int64 month = 3;
 * @return {number}
 */
proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Request.prototype.getMonth = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Request} returns this
 */
proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Request.prototype.setMonth = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional TermOfMonth term_of_month = 4;
 * @return {!proto.jp.singcolor.TermOfMonth}
 */
proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Request.prototype.getTermOfMonth = function () {
    return /** @type {!proto.jp.singcolor.TermOfMonth} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {!proto.jp.singcolor.TermOfMonth} value
 * @return {!proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Request} returns this
 */
proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Request.prototype.setTermOfMonth = function (value) {
    return jspb.Message.setProto3EnumField(this, 4, value);
};
/**
 * optional uint64 page = 5;
 * @return {number}
 */
proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Request.prototype.getPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Request} returns this
 */
proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Request.prototype.setPage = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
/**
 * optional uint64 limit = 6;
 * @return {number}
 */
proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Request.prototype.getLimit = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Request} returns this
 */
proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Request.prototype.setLimit = function (value) {
    return jspb.Message.setProto3IntField(this, 6, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Response.repeatedFields_ = [1, 5];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            tabsList: jspb.Message.toObjectList(msg.getTabsList(), lives_entity_pb.ListFanInfoFaveBadgeTab.toObject, includeInstance),
            targetYear: jspb.Message.getFieldWithDefault(msg, 2, 0),
            targetMonth: jspb.Message.getFieldWithDefault(msg, 3, 0),
            targetTermOfMonth: jspb.Message.getFieldWithDefault(msg, 4, 0),
            usersList: jspb.Message.toObjectList(msg.getUsersList(), lives_entity_pb.ListFanInfoFaveBadgeUser.toObject, includeInstance),
            currentPage: jspb.Message.getFieldWithDefault(msg, 6, 0),
            prevPage: jspb.Message.getFieldWithDefault(msg, 7, 0),
            nextPage: jspb.Message.getFieldWithDefault(msg, 8, 0),
            totalPage: jspb.Message.getFieldWithDefault(msg, 9, 0),
            hasNext: jspb.Message.getBooleanFieldWithDefault(msg, 10, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Response}
 */
proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Response;
    return proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Response}
 */
proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new lives_entity_pb.ListFanInfoFaveBadgeTab;
                reader.readMessage(value, lives_entity_pb.ListFanInfoFaveBadgeTab.deserializeBinaryFromReader);
                msg.addTabs(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setTargetYear(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setTargetMonth(value);
                break;
            case 4:
                var value = /** @type {!proto.jp.singcolor.TermOfMonth} */ (reader.readEnum());
                msg.setTargetTermOfMonth(value);
                break;
            case 5:
                var value = new lives_entity_pb.ListFanInfoFaveBadgeUser;
                reader.readMessage(value, lives_entity_pb.ListFanInfoFaveBadgeUser.deserializeBinaryFromReader);
                msg.addUsers(value);
                break;
            case 6:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setCurrentPage(value);
                break;
            case 7:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setPrevPage(value);
                break;
            case 8:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setNextPage(value);
                break;
            case 9:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setTotalPage(value);
                break;
            case 10:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setHasNext(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getTabsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, lives_entity_pb.ListFanInfoFaveBadgeTab.serializeBinaryToWriter);
    }
    f = message.getTargetYear();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getTargetMonth();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
    f = message.getTargetTermOfMonth();
    if (f !== 0.0) {
        writer.writeEnum(4, f);
    }
    f = message.getUsersList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(5, f, lives_entity_pb.ListFanInfoFaveBadgeUser.serializeBinaryToWriter);
    }
    f = message.getCurrentPage();
    if (f !== 0) {
        writer.writeUint64(6, f);
    }
    f = message.getPrevPage();
    if (f !== 0) {
        writer.writeUint64(7, f);
    }
    f = message.getNextPage();
    if (f !== 0) {
        writer.writeUint64(8, f);
    }
    f = message.getTotalPage();
    if (f !== 0) {
        writer.writeUint64(9, f);
    }
    f = message.getHasNext();
    if (f) {
        writer.writeBool(10, f);
    }
};
/**
 * repeated ListFanInfoFaveBadgeTab tabs = 1;
 * @return {!Array<!proto.jp.singcolor.ListFanInfoFaveBadgeTab>}
 */
proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Response.prototype.getTabsList = function () {
    return /** @type{!Array<!proto.jp.singcolor.ListFanInfoFaveBadgeTab>} */ (jspb.Message.getRepeatedWrapperField(this, lives_entity_pb.ListFanInfoFaveBadgeTab, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.ListFanInfoFaveBadgeTab>} value
 * @return {!proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Response} returns this
*/
proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Response.prototype.setTabsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.ListFanInfoFaveBadgeTab=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.ListFanInfoFaveBadgeTab}
 */
proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Response.prototype.addTabs = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.ListFanInfoFaveBadgeTab, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Response} returns this
 */
proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Response.prototype.clearTabsList = function () {
    return this.setTabsList([]);
};
/**
 * optional int64 target_year = 2;
 * @return {number}
 */
proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Response.prototype.getTargetYear = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Response} returns this
 */
proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Response.prototype.setTargetYear = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional int64 target_month = 3;
 * @return {number}
 */
proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Response.prototype.getTargetMonth = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Response} returns this
 */
proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Response.prototype.setTargetMonth = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional TermOfMonth target_term_of_month = 4;
 * @return {!proto.jp.singcolor.TermOfMonth}
 */
proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Response.prototype.getTargetTermOfMonth = function () {
    return /** @type {!proto.jp.singcolor.TermOfMonth} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {!proto.jp.singcolor.TermOfMonth} value
 * @return {!proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Response} returns this
 */
proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Response.prototype.setTargetTermOfMonth = function (value) {
    return jspb.Message.setProto3EnumField(this, 4, value);
};
/**
 * repeated ListFanInfoFaveBadgeUser users = 5;
 * @return {!Array<!proto.jp.singcolor.ListFanInfoFaveBadgeUser>}
 */
proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Response.prototype.getUsersList = function () {
    return /** @type{!Array<!proto.jp.singcolor.ListFanInfoFaveBadgeUser>} */ (jspb.Message.getRepeatedWrapperField(this, lives_entity_pb.ListFanInfoFaveBadgeUser, 5));
};
/**
 * @param {!Array<!proto.jp.singcolor.ListFanInfoFaveBadgeUser>} value
 * @return {!proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Response} returns this
*/
proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Response.prototype.setUsersList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 5, value);
};
/**
 * @param {!proto.jp.singcolor.ListFanInfoFaveBadgeUser=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.ListFanInfoFaveBadgeUser}
 */
proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Response.prototype.addUsers = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.jp.singcolor.ListFanInfoFaveBadgeUser, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Response} returns this
 */
proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Response.prototype.clearUsersList = function () {
    return this.setUsersList([]);
};
/**
 * optional uint64 current_page = 6;
 * @return {number}
 */
proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Response.prototype.getCurrentPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Response} returns this
 */
proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Response.prototype.setCurrentPage = function (value) {
    return jspb.Message.setProto3IntField(this, 6, value);
};
/**
 * optional uint64 prev_page = 7;
 * @return {number}
 */
proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Response.prototype.getPrevPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Response} returns this
 */
proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Response.prototype.setPrevPage = function (value) {
    return jspb.Message.setProto3IntField(this, 7, value);
};
/**
 * optional uint64 next_page = 8;
 * @return {number}
 */
proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Response.prototype.getNextPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Response} returns this
 */
proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Response.prototype.setNextPage = function (value) {
    return jspb.Message.setProto3IntField(this, 8, value);
};
/**
 * optional uint64 total_page = 9;
 * @return {number}
 */
proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Response.prototype.getTotalPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Response} returns this
 */
proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Response.prototype.setTotalPage = function (value) {
    return jspb.Message.setProto3IntField(this, 9, value);
};
/**
 * optional bool has_next = 10;
 * @return {boolean}
 */
proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Response.prototype.getHasNext = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Response} returns this
 */
proto.jp.singcolor.ListFanInfoFaveBadgeTabsV1Response.prototype.setHasNext = function (value) {
    return jspb.Message.setProto3BooleanField(this, 10, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListFanInfoSongFaveTabsV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListFanInfoSongFaveTabsV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListFanInfoSongFaveTabsV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListFanInfoSongFaveTabsV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            year: jspb.Message.getFieldWithDefault(msg, 2, 0),
            month: jspb.Message.getFieldWithDefault(msg, 3, 0),
            page: jspb.Message.getFieldWithDefault(msg, 4, 0),
            limit: jspb.Message.getFieldWithDefault(msg, 5, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListFanInfoSongFaveTabsV1Request}
 */
proto.jp.singcolor.ListFanInfoSongFaveTabsV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListFanInfoSongFaveTabsV1Request;
    return proto.jp.singcolor.ListFanInfoSongFaveTabsV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListFanInfoSongFaveTabsV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListFanInfoSongFaveTabsV1Request}
 */
proto.jp.singcolor.ListFanInfoSongFaveTabsV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setYear(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setMonth(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setPage(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setLimit(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListFanInfoSongFaveTabsV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListFanInfoSongFaveTabsV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListFanInfoSongFaveTabsV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListFanInfoSongFaveTabsV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getYear();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getMonth();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
    f = message.getPage();
    if (f !== 0) {
        writer.writeUint64(4, f);
    }
    f = message.getLimit();
    if (f !== 0) {
        writer.writeUint64(5, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.ListFanInfoSongFaveTabsV1Request.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListFanInfoSongFaveTabsV1Request} returns this
 */
proto.jp.singcolor.ListFanInfoSongFaveTabsV1Request.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional int64 year = 2;
 * @return {number}
 */
proto.jp.singcolor.ListFanInfoSongFaveTabsV1Request.prototype.getYear = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListFanInfoSongFaveTabsV1Request} returns this
 */
proto.jp.singcolor.ListFanInfoSongFaveTabsV1Request.prototype.setYear = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional int64 month = 3;
 * @return {number}
 */
proto.jp.singcolor.ListFanInfoSongFaveTabsV1Request.prototype.getMonth = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListFanInfoSongFaveTabsV1Request} returns this
 */
proto.jp.singcolor.ListFanInfoSongFaveTabsV1Request.prototype.setMonth = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional uint64 page = 4;
 * @return {number}
 */
proto.jp.singcolor.ListFanInfoSongFaveTabsV1Request.prototype.getPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListFanInfoSongFaveTabsV1Request} returns this
 */
proto.jp.singcolor.ListFanInfoSongFaveTabsV1Request.prototype.setPage = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * optional uint64 limit = 5;
 * @return {number}
 */
proto.jp.singcolor.ListFanInfoSongFaveTabsV1Request.prototype.getLimit = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListFanInfoSongFaveTabsV1Request} returns this
 */
proto.jp.singcolor.ListFanInfoSongFaveTabsV1Request.prototype.setLimit = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListFanInfoSongFaveTabsV1Response.repeatedFields_ = [1, 4];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListFanInfoSongFaveTabsV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListFanInfoSongFaveTabsV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListFanInfoSongFaveTabsV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListFanInfoSongFaveTabsV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            tabsList: jspb.Message.toObjectList(msg.getTabsList(), lives_entity_pb.ListFanInfoSongFaveTab.toObject, includeInstance),
            targetYear: jspb.Message.getFieldWithDefault(msg, 2, 0),
            targetMonth: jspb.Message.getFieldWithDefault(msg, 3, 0),
            usersList: jspb.Message.toObjectList(msg.getUsersList(), lives_entity_pb.ListFanInfoSongFaveUser.toObject, includeInstance),
            currentPage: jspb.Message.getFieldWithDefault(msg, 5, 0),
            prevPage: jspb.Message.getFieldWithDefault(msg, 6, 0),
            nextPage: jspb.Message.getFieldWithDefault(msg, 7, 0),
            totalPage: jspb.Message.getFieldWithDefault(msg, 8, 0),
            hasNext: jspb.Message.getBooleanFieldWithDefault(msg, 9, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListFanInfoSongFaveTabsV1Response}
 */
proto.jp.singcolor.ListFanInfoSongFaveTabsV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListFanInfoSongFaveTabsV1Response;
    return proto.jp.singcolor.ListFanInfoSongFaveTabsV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListFanInfoSongFaveTabsV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListFanInfoSongFaveTabsV1Response}
 */
proto.jp.singcolor.ListFanInfoSongFaveTabsV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new lives_entity_pb.ListFanInfoSongFaveTab;
                reader.readMessage(value, lives_entity_pb.ListFanInfoSongFaveTab.deserializeBinaryFromReader);
                msg.addTabs(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setTargetYear(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setTargetMonth(value);
                break;
            case 4:
                var value = new lives_entity_pb.ListFanInfoSongFaveUser;
                reader.readMessage(value, lives_entity_pb.ListFanInfoSongFaveUser.deserializeBinaryFromReader);
                msg.addUsers(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setCurrentPage(value);
                break;
            case 6:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setPrevPage(value);
                break;
            case 7:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setNextPage(value);
                break;
            case 8:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setTotalPage(value);
                break;
            case 9:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setHasNext(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListFanInfoSongFaveTabsV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListFanInfoSongFaveTabsV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListFanInfoSongFaveTabsV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListFanInfoSongFaveTabsV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getTabsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, lives_entity_pb.ListFanInfoSongFaveTab.serializeBinaryToWriter);
    }
    f = message.getTargetYear();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getTargetMonth();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
    f = message.getUsersList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(4, f, lives_entity_pb.ListFanInfoSongFaveUser.serializeBinaryToWriter);
    }
    f = message.getCurrentPage();
    if (f !== 0) {
        writer.writeUint64(5, f);
    }
    f = message.getPrevPage();
    if (f !== 0) {
        writer.writeUint64(6, f);
    }
    f = message.getNextPage();
    if (f !== 0) {
        writer.writeUint64(7, f);
    }
    f = message.getTotalPage();
    if (f !== 0) {
        writer.writeUint64(8, f);
    }
    f = message.getHasNext();
    if (f) {
        writer.writeBool(9, f);
    }
};
/**
 * repeated ListFanInfoSongFaveTab tabs = 1;
 * @return {!Array<!proto.jp.singcolor.ListFanInfoSongFaveTab>}
 */
proto.jp.singcolor.ListFanInfoSongFaveTabsV1Response.prototype.getTabsList = function () {
    return /** @type{!Array<!proto.jp.singcolor.ListFanInfoSongFaveTab>} */ (jspb.Message.getRepeatedWrapperField(this, lives_entity_pb.ListFanInfoSongFaveTab, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.ListFanInfoSongFaveTab>} value
 * @return {!proto.jp.singcolor.ListFanInfoSongFaveTabsV1Response} returns this
*/
proto.jp.singcolor.ListFanInfoSongFaveTabsV1Response.prototype.setTabsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.ListFanInfoSongFaveTab=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.ListFanInfoSongFaveTab}
 */
proto.jp.singcolor.ListFanInfoSongFaveTabsV1Response.prototype.addTabs = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.ListFanInfoSongFaveTab, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListFanInfoSongFaveTabsV1Response} returns this
 */
proto.jp.singcolor.ListFanInfoSongFaveTabsV1Response.prototype.clearTabsList = function () {
    return this.setTabsList([]);
};
/**
 * optional int64 target_year = 2;
 * @return {number}
 */
proto.jp.singcolor.ListFanInfoSongFaveTabsV1Response.prototype.getTargetYear = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListFanInfoSongFaveTabsV1Response} returns this
 */
proto.jp.singcolor.ListFanInfoSongFaveTabsV1Response.prototype.setTargetYear = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional int64 target_month = 3;
 * @return {number}
 */
proto.jp.singcolor.ListFanInfoSongFaveTabsV1Response.prototype.getTargetMonth = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListFanInfoSongFaveTabsV1Response} returns this
 */
proto.jp.singcolor.ListFanInfoSongFaveTabsV1Response.prototype.setTargetMonth = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * repeated ListFanInfoSongFaveUser users = 4;
 * @return {!Array<!proto.jp.singcolor.ListFanInfoSongFaveUser>}
 */
proto.jp.singcolor.ListFanInfoSongFaveTabsV1Response.prototype.getUsersList = function () {
    return /** @type{!Array<!proto.jp.singcolor.ListFanInfoSongFaveUser>} */ (jspb.Message.getRepeatedWrapperField(this, lives_entity_pb.ListFanInfoSongFaveUser, 4));
};
/**
 * @param {!Array<!proto.jp.singcolor.ListFanInfoSongFaveUser>} value
 * @return {!proto.jp.singcolor.ListFanInfoSongFaveTabsV1Response} returns this
*/
proto.jp.singcolor.ListFanInfoSongFaveTabsV1Response.prototype.setUsersList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 4, value);
};
/**
 * @param {!proto.jp.singcolor.ListFanInfoSongFaveUser=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.ListFanInfoSongFaveUser}
 */
proto.jp.singcolor.ListFanInfoSongFaveTabsV1Response.prototype.addUsers = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.jp.singcolor.ListFanInfoSongFaveUser, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListFanInfoSongFaveTabsV1Response} returns this
 */
proto.jp.singcolor.ListFanInfoSongFaveTabsV1Response.prototype.clearUsersList = function () {
    return this.setUsersList([]);
};
/**
 * optional uint64 current_page = 5;
 * @return {number}
 */
proto.jp.singcolor.ListFanInfoSongFaveTabsV1Response.prototype.getCurrentPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListFanInfoSongFaveTabsV1Response} returns this
 */
proto.jp.singcolor.ListFanInfoSongFaveTabsV1Response.prototype.setCurrentPage = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
/**
 * optional uint64 prev_page = 6;
 * @return {number}
 */
proto.jp.singcolor.ListFanInfoSongFaveTabsV1Response.prototype.getPrevPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListFanInfoSongFaveTabsV1Response} returns this
 */
proto.jp.singcolor.ListFanInfoSongFaveTabsV1Response.prototype.setPrevPage = function (value) {
    return jspb.Message.setProto3IntField(this, 6, value);
};
/**
 * optional uint64 next_page = 7;
 * @return {number}
 */
proto.jp.singcolor.ListFanInfoSongFaveTabsV1Response.prototype.getNextPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListFanInfoSongFaveTabsV1Response} returns this
 */
proto.jp.singcolor.ListFanInfoSongFaveTabsV1Response.prototype.setNextPage = function (value) {
    return jspb.Message.setProto3IntField(this, 7, value);
};
/**
 * optional uint64 total_page = 8;
 * @return {number}
 */
proto.jp.singcolor.ListFanInfoSongFaveTabsV1Response.prototype.getTotalPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListFanInfoSongFaveTabsV1Response} returns this
 */
proto.jp.singcolor.ListFanInfoSongFaveTabsV1Response.prototype.setTotalPage = function (value) {
    return jspb.Message.setProto3IntField(this, 8, value);
};
/**
 * optional bool has_next = 9;
 * @return {boolean}
 */
proto.jp.singcolor.ListFanInfoSongFaveTabsV1Response.prototype.getHasNext = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.ListFanInfoSongFaveTabsV1Response} returns this
 */
proto.jp.singcolor.ListFanInfoSongFaveTabsV1Response.prototype.setHasNext = function (value) {
    return jspb.Message.setProto3BooleanField(this, 9, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            listenerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            liverId: jspb.Message.getFieldWithDefault(msg, 2, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Request}
 */
proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Request;
    return proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Request}
 */
proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setListenerId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiverId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getListenerId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getLiverId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
};
/**
 * optional string listener_id = 1;
 * @return {string}
 */
proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Request.prototype.getListenerId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Request} returns this
 */
proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Request.prototype.setListenerId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string liver_id = 2;
 * @return {string}
 */
proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Request.prototype.getLiverId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Request} returns this
 */
proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Request.prototype.setLiverId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            userItem: (f = msg.getUserItem()) && users_entity_pb.UserItem.toObject(includeInstance, f),
            userFollow: (f = msg.getUserFollow()) && users_entity_pb.UserFollow.toObject(includeInstance, f),
            fanType: jspb.Message.getFieldWithDefault(msg, 3, 0),
            monthlyFavePoints: jspb.Message.getFieldWithDefault(msg, 4, 0),
            termlyFavePoints: jspb.Message.getFieldWithDefault(msg, 5, 0),
            pfFavePoints: jspb.Message.getFieldWithDefault(msg, 6, 0),
            percentageOfAchievementPattern1: jspb.Message.getFieldWithDefault(msg, 7, 0),
            percentageOfAchievementPattern2: jspb.Message.getFieldWithDefault(msg, 8, 0),
            percentageOfAchievementPattern3: jspb.Message.getFieldWithDefault(msg, 9, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Response}
 */
proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Response;
    return proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Response}
 */
proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.UserItem;
                reader.readMessage(value, users_entity_pb.UserItem.deserializeBinaryFromReader);
                msg.setUserItem(value);
                break;
            case 2:
                var value = new users_entity_pb.UserFollow;
                reader.readMessage(value, users_entity_pb.UserFollow.deserializeBinaryFromReader);
                msg.setUserFollow(value);
                break;
            case 3:
                var value = /** @type {!proto.jp.singcolor.FanType} */ (reader.readEnum());
                msg.setFanType(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setMonthlyFavePoints(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setTermlyFavePoints(value);
                break;
            case 6:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setPfFavePoints(value);
                break;
            case 7:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setPercentageOfAchievementPattern1(value);
                break;
            case 8:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setPercentageOfAchievementPattern2(value);
                break;
            case 9:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setPercentageOfAchievementPattern3(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserItem();
    if (f != null) {
        writer.writeMessage(1, f, users_entity_pb.UserItem.serializeBinaryToWriter);
    }
    f = message.getUserFollow();
    if (f != null) {
        writer.writeMessage(2, f, users_entity_pb.UserFollow.serializeBinaryToWriter);
    }
    f = message.getFanType();
    if (f !== 0.0) {
        writer.writeEnum(3, f);
    }
    f = message.getMonthlyFavePoints();
    if (f !== 0) {
        writer.writeInt64(4, f);
    }
    f = message.getTermlyFavePoints();
    if (f !== 0) {
        writer.writeInt64(5, f);
    }
    f = message.getPfFavePoints();
    if (f !== 0) {
        writer.writeInt64(6, f);
    }
    f = message.getPercentageOfAchievementPattern1();
    if (f !== 0) {
        writer.writeInt64(7, f);
    }
    f = message.getPercentageOfAchievementPattern2();
    if (f !== 0) {
        writer.writeInt64(8, f);
    }
    f = message.getPercentageOfAchievementPattern3();
    if (f !== 0) {
        writer.writeInt64(9, f);
    }
};
/**
 * optional UserItem user_item = 1;
 * @return {?proto.jp.singcolor.UserItem}
 */
proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Response.prototype.getUserItem = function () {
    return /** @type{?proto.jp.singcolor.UserItem} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserItem, 1));
};
/**
 * @param {?proto.jp.singcolor.UserItem|undefined} value
 * @return {!proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Response} returns this
*/
proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Response.prototype.setUserItem = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Response} returns this
 */
proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Response.prototype.clearUserItem = function () {
    return this.setUserItem(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Response.prototype.hasUserItem = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional UserFollow user_follow = 2;
 * @return {?proto.jp.singcolor.UserFollow}
 */
proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Response.prototype.getUserFollow = function () {
    return /** @type{?proto.jp.singcolor.UserFollow} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserFollow, 2));
};
/**
 * @param {?proto.jp.singcolor.UserFollow|undefined} value
 * @return {!proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Response} returns this
*/
proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Response.prototype.setUserFollow = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Response} returns this
 */
proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Response.prototype.clearUserFollow = function () {
    return this.setUserFollow(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Response.prototype.hasUserFollow = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * optional FanType fan_type = 3;
 * @return {!proto.jp.singcolor.FanType}
 */
proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Response.prototype.getFanType = function () {
    return /** @type {!proto.jp.singcolor.FanType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {!proto.jp.singcolor.FanType} value
 * @return {!proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Response} returns this
 */
proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Response.prototype.setFanType = function (value) {
    return jspb.Message.setProto3EnumField(this, 3, value);
};
/**
 * optional int64 monthly_fave_points = 4;
 * @return {number}
 */
proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Response.prototype.getMonthlyFavePoints = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Response} returns this
 */
proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Response.prototype.setMonthlyFavePoints = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * optional int64 termly_fave_points = 5;
 * @return {number}
 */
proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Response.prototype.getTermlyFavePoints = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Response} returns this
 */
proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Response.prototype.setTermlyFavePoints = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
/**
 * optional int64 pf_fave_points = 6;
 * @return {number}
 */
proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Response.prototype.getPfFavePoints = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Response} returns this
 */
proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Response.prototype.setPfFavePoints = function (value) {
    return jspb.Message.setProto3IntField(this, 6, value);
};
/**
 * optional int64 percentage_of_achievement_pattern1 = 7;
 * @return {number}
 */
proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Response.prototype.getPercentageOfAchievementPattern1 = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Response} returns this
 */
proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Response.prototype.setPercentageOfAchievementPattern1 = function (value) {
    return jspb.Message.setProto3IntField(this, 7, value);
};
/**
 * optional int64 percentage_of_achievement_pattern2 = 8;
 * @return {number}
 */
proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Response.prototype.getPercentageOfAchievementPattern2 = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Response} returns this
 */
proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Response.prototype.setPercentageOfAchievementPattern2 = function (value) {
    return jspb.Message.setProto3IntField(this, 8, value);
};
/**
 * optional int64 percentage_of_achievement_pattern3 = 9;
 * @return {number}
 */
proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Response.prototype.getPercentageOfAchievementPattern3 = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Response} returns this
 */
proto.jp.singcolor.GetMonthlyLiveListenerInfoV1Response.prototype.setPercentageOfAchievementPattern3 = function (value) {
    return jspb.Message.setProto3IntField(this, 9, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListPfFavePointsRankingListenersV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListPfFavePointsRankingListenersV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListPfFavePointsRankingListenersV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListPfFavePointsRankingListenersV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            pageToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
            limit: jspb.Message.getFieldWithDefault(msg, 2, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListPfFavePointsRankingListenersV1Request}
 */
proto.jp.singcolor.ListPfFavePointsRankingListenersV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListPfFavePointsRankingListenersV1Request;
    return proto.jp.singcolor.ListPfFavePointsRankingListenersV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListPfFavePointsRankingListenersV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListPfFavePointsRankingListenersV1Request}
 */
proto.jp.singcolor.ListPfFavePointsRankingListenersV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setPageToken(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setLimit(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListPfFavePointsRankingListenersV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListPfFavePointsRankingListenersV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListPfFavePointsRankingListenersV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListPfFavePointsRankingListenersV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPageToken();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getLimit();
    if (f !== 0) {
        writer.writeUint64(2, f);
    }
};
/**
 * optional string page_token = 1;
 * @return {string}
 */
proto.jp.singcolor.ListPfFavePointsRankingListenersV1Request.prototype.getPageToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListPfFavePointsRankingListenersV1Request} returns this
 */
proto.jp.singcolor.ListPfFavePointsRankingListenersV1Request.prototype.setPageToken = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional uint64 limit = 2;
 * @return {number}
 */
proto.jp.singcolor.ListPfFavePointsRankingListenersV1Request.prototype.getLimit = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListPfFavePointsRankingListenersV1Request} returns this
 */
proto.jp.singcolor.ListPfFavePointsRankingListenersV1Request.prototype.setLimit = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListPfFavePointsRankingListenersV1Response.repeatedFields_ = [3];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListPfFavePointsRankingListenersV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListPfFavePointsRankingListenersV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListPfFavePointsRankingListenersV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListPfFavePointsRankingListenersV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            startTerm: (f = msg.getStartTerm()) && lives_entity_pb.PfFavePointsRankingTerm.toObject(includeInstance, f),
            endTerm: (f = msg.getEndTerm()) && lives_entity_pb.PfFavePointsRankingTerm.toObject(includeInstance, f),
            pfFavePointsRankingUsersList: jspb.Message.toObjectList(msg.getPfFavePointsRankingUsersList(), lives_entity_pb.PfFavePointsRankingUser.toObject, includeInstance),
            nextPageToken: jspb.Message.getFieldWithDefault(msg, 4, ""),
            hasNext: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListPfFavePointsRankingListenersV1Response}
 */
proto.jp.singcolor.ListPfFavePointsRankingListenersV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListPfFavePointsRankingListenersV1Response;
    return proto.jp.singcolor.ListPfFavePointsRankingListenersV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListPfFavePointsRankingListenersV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListPfFavePointsRankingListenersV1Response}
 */
proto.jp.singcolor.ListPfFavePointsRankingListenersV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new lives_entity_pb.PfFavePointsRankingTerm;
                reader.readMessage(value, lives_entity_pb.PfFavePointsRankingTerm.deserializeBinaryFromReader);
                msg.setStartTerm(value);
                break;
            case 2:
                var value = new lives_entity_pb.PfFavePointsRankingTerm;
                reader.readMessage(value, lives_entity_pb.PfFavePointsRankingTerm.deserializeBinaryFromReader);
                msg.setEndTerm(value);
                break;
            case 3:
                var value = new lives_entity_pb.PfFavePointsRankingUser;
                reader.readMessage(value, lives_entity_pb.PfFavePointsRankingUser.deserializeBinaryFromReader);
                msg.addPfFavePointsRankingUsers(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setNextPageToken(value);
                break;
            case 5:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setHasNext(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListPfFavePointsRankingListenersV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListPfFavePointsRankingListenersV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListPfFavePointsRankingListenersV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListPfFavePointsRankingListenersV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getStartTerm();
    if (f != null) {
        writer.writeMessage(1, f, lives_entity_pb.PfFavePointsRankingTerm.serializeBinaryToWriter);
    }
    f = message.getEndTerm();
    if (f != null) {
        writer.writeMessage(2, f, lives_entity_pb.PfFavePointsRankingTerm.serializeBinaryToWriter);
    }
    f = message.getPfFavePointsRankingUsersList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(3, f, lives_entity_pb.PfFavePointsRankingUser.serializeBinaryToWriter);
    }
    f = message.getNextPageToken();
    if (f.length > 0) {
        writer.writeString(4, f);
    }
    f = message.getHasNext();
    if (f) {
        writer.writeBool(5, f);
    }
};
/**
 * optional PfFavePointsRankingTerm start_term = 1;
 * @return {?proto.jp.singcolor.PfFavePointsRankingTerm}
 */
proto.jp.singcolor.ListPfFavePointsRankingListenersV1Response.prototype.getStartTerm = function () {
    return /** @type{?proto.jp.singcolor.PfFavePointsRankingTerm} */ (jspb.Message.getWrapperField(this, lives_entity_pb.PfFavePointsRankingTerm, 1));
};
/**
 * @param {?proto.jp.singcolor.PfFavePointsRankingTerm|undefined} value
 * @return {!proto.jp.singcolor.ListPfFavePointsRankingListenersV1Response} returns this
*/
proto.jp.singcolor.ListPfFavePointsRankingListenersV1Response.prototype.setStartTerm = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ListPfFavePointsRankingListenersV1Response} returns this
 */
proto.jp.singcolor.ListPfFavePointsRankingListenersV1Response.prototype.clearStartTerm = function () {
    return this.setStartTerm(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ListPfFavePointsRankingListenersV1Response.prototype.hasStartTerm = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional PfFavePointsRankingTerm end_term = 2;
 * @return {?proto.jp.singcolor.PfFavePointsRankingTerm}
 */
proto.jp.singcolor.ListPfFavePointsRankingListenersV1Response.prototype.getEndTerm = function () {
    return /** @type{?proto.jp.singcolor.PfFavePointsRankingTerm} */ (jspb.Message.getWrapperField(this, lives_entity_pb.PfFavePointsRankingTerm, 2));
};
/**
 * @param {?proto.jp.singcolor.PfFavePointsRankingTerm|undefined} value
 * @return {!proto.jp.singcolor.ListPfFavePointsRankingListenersV1Response} returns this
*/
proto.jp.singcolor.ListPfFavePointsRankingListenersV1Response.prototype.setEndTerm = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ListPfFavePointsRankingListenersV1Response} returns this
 */
proto.jp.singcolor.ListPfFavePointsRankingListenersV1Response.prototype.clearEndTerm = function () {
    return this.setEndTerm(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ListPfFavePointsRankingListenersV1Response.prototype.hasEndTerm = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * repeated PfFavePointsRankingUser pf_fave_points_ranking_users = 3;
 * @return {!Array<!proto.jp.singcolor.PfFavePointsRankingUser>}
 */
proto.jp.singcolor.ListPfFavePointsRankingListenersV1Response.prototype.getPfFavePointsRankingUsersList = function () {
    return /** @type{!Array<!proto.jp.singcolor.PfFavePointsRankingUser>} */ (jspb.Message.getRepeatedWrapperField(this, lives_entity_pb.PfFavePointsRankingUser, 3));
};
/**
 * @param {!Array<!proto.jp.singcolor.PfFavePointsRankingUser>} value
 * @return {!proto.jp.singcolor.ListPfFavePointsRankingListenersV1Response} returns this
*/
proto.jp.singcolor.ListPfFavePointsRankingListenersV1Response.prototype.setPfFavePointsRankingUsersList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 3, value);
};
/**
 * @param {!proto.jp.singcolor.PfFavePointsRankingUser=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.PfFavePointsRankingUser}
 */
proto.jp.singcolor.ListPfFavePointsRankingListenersV1Response.prototype.addPfFavePointsRankingUsers = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.jp.singcolor.PfFavePointsRankingUser, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListPfFavePointsRankingListenersV1Response} returns this
 */
proto.jp.singcolor.ListPfFavePointsRankingListenersV1Response.prototype.clearPfFavePointsRankingUsersList = function () {
    return this.setPfFavePointsRankingUsersList([]);
};
/**
 * optional string next_page_token = 4;
 * @return {string}
 */
proto.jp.singcolor.ListPfFavePointsRankingListenersV1Response.prototype.getNextPageToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListPfFavePointsRankingListenersV1Response} returns this
 */
proto.jp.singcolor.ListPfFavePointsRankingListenersV1Response.prototype.setNextPageToken = function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
};
/**
 * optional bool has_next = 5;
 * @return {boolean}
 */
proto.jp.singcolor.ListPfFavePointsRankingListenersV1Response.prototype.getHasNext = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.ListPfFavePointsRankingListenersV1Response} returns this
 */
proto.jp.singcolor.ListPfFavePointsRankingListenersV1Response.prototype.setHasNext = function (value) {
    return jspb.Message.setProto3BooleanField(this, 5, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetLiveResultBasicInfoV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetLiveResultBasicInfoV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetLiveResultBasicInfoV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetLiveResultBasicInfoV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            liveId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetLiveResultBasicInfoV1Request}
 */
proto.jp.singcolor.GetLiveResultBasicInfoV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetLiveResultBasicInfoV1Request;
    return proto.jp.singcolor.GetLiveResultBasicInfoV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetLiveResultBasicInfoV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetLiveResultBasicInfoV1Request}
 */
proto.jp.singcolor.GetLiveResultBasicInfoV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiveId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetLiveResultBasicInfoV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetLiveResultBasicInfoV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetLiveResultBasicInfoV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetLiveResultBasicInfoV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiveId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string live_id = 1;
 * @return {string}
 */
proto.jp.singcolor.GetLiveResultBasicInfoV1Request.prototype.getLiveId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetLiveResultBasicInfoV1Request} returns this
 */
proto.jp.singcolor.GetLiveResultBasicInfoV1Request.prototype.setLiveId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.GetLiveResultBasicInfoV1Response.repeatedFields_ = [6, 7, 8, 9, 10, 11, 12, 13];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetLiveResultBasicInfoV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetLiveResultBasicInfoV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetLiveResultBasicInfoV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetLiveResultBasicInfoV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            liveId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            liveDurationMs: jspb.Message.getFieldWithDefault(msg, 2, 0),
            numberOfSongsSung: jspb.Message.getFieldWithDefault(msg, 3, 0),
            numberOfComments: jspb.Message.getFieldWithDefault(msg, 4, 0),
            numberOfSuperLikes: jspb.Message.getFieldWithDefault(msg, 5, 0),
            liveResultFavePointsList: jspb.Message.toObjectList(msg.getLiveResultFavePointsList(), lives_entity_pb.LiveResultFavePoint.toObject, includeInstance),
            firstLookListenersList: jspb.Message.toObjectList(msg.getFirstLookListenersList(), users_entity_pb.User.toObject, includeInstance),
            newSongFaveListenersList: jspb.Message.toObjectList(msg.getNewSongFaveListenersList(), users_entity_pb.User.toObject, includeInstance),
            listenersEarnedFaveBadgeList: jspb.Message.toObjectList(msg.getListenersEarnedFaveBadgeList(), users_entity_pb.User.toObject, includeInstance),
            listenersEarnedFavePointList: jspb.Message.toObjectList(msg.getListenersEarnedFavePointList(), users_entity_pb.User.toObject, includeInstance),
            listenersSentEmojiCommentList: jspb.Message.toObjectList(msg.getListenersSentEmojiCommentList(), users_entity_pb.User.toObject, includeInstance),
            otherListenersList: jspb.Message.toObjectList(msg.getOtherListenersList(), users_entity_pb.User.toObject, includeInstance),
            setListList: jspb.Message.toObjectList(msg.getSetListList(), karaoke_entity_pb.Song.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetLiveResultBasicInfoV1Response}
 */
proto.jp.singcolor.GetLiveResultBasicInfoV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetLiveResultBasicInfoV1Response;
    return proto.jp.singcolor.GetLiveResultBasicInfoV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetLiveResultBasicInfoV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetLiveResultBasicInfoV1Response}
 */
proto.jp.singcolor.GetLiveResultBasicInfoV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiveId(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setLiveDurationMs(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setNumberOfSongsSung(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setNumberOfComments(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setNumberOfSuperLikes(value);
                break;
            case 6:
                var value = new lives_entity_pb.LiveResultFavePoint;
                reader.readMessage(value, lives_entity_pb.LiveResultFavePoint.deserializeBinaryFromReader);
                msg.addLiveResultFavePoints(value);
                break;
            case 7:
                var value = new users_entity_pb.User;
                reader.readMessage(value, users_entity_pb.User.deserializeBinaryFromReader);
                msg.addFirstLookListeners(value);
                break;
            case 8:
                var value = new users_entity_pb.User;
                reader.readMessage(value, users_entity_pb.User.deserializeBinaryFromReader);
                msg.addNewSongFaveListeners(value);
                break;
            case 9:
                var value = new users_entity_pb.User;
                reader.readMessage(value, users_entity_pb.User.deserializeBinaryFromReader);
                msg.addListenersEarnedFaveBadge(value);
                break;
            case 10:
                var value = new users_entity_pb.User;
                reader.readMessage(value, users_entity_pb.User.deserializeBinaryFromReader);
                msg.addListenersEarnedFavePoint(value);
                break;
            case 11:
                var value = new users_entity_pb.User;
                reader.readMessage(value, users_entity_pb.User.deserializeBinaryFromReader);
                msg.addListenersSentEmojiComment(value);
                break;
            case 12:
                var value = new users_entity_pb.User;
                reader.readMessage(value, users_entity_pb.User.deserializeBinaryFromReader);
                msg.addOtherListeners(value);
                break;
            case 13:
                var value = new karaoke_entity_pb.Song;
                reader.readMessage(value, karaoke_entity_pb.Song.deserializeBinaryFromReader);
                msg.addSetList(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetLiveResultBasicInfoV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetLiveResultBasicInfoV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetLiveResultBasicInfoV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetLiveResultBasicInfoV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiveId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getLiveDurationMs();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getNumberOfSongsSung();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
    f = message.getNumberOfComments();
    if (f !== 0) {
        writer.writeInt64(4, f);
    }
    f = message.getNumberOfSuperLikes();
    if (f !== 0) {
        writer.writeInt64(5, f);
    }
    f = message.getLiveResultFavePointsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(6, f, lives_entity_pb.LiveResultFavePoint.serializeBinaryToWriter);
    }
    f = message.getFirstLookListenersList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(7, f, users_entity_pb.User.serializeBinaryToWriter);
    }
    f = message.getNewSongFaveListenersList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(8, f, users_entity_pb.User.serializeBinaryToWriter);
    }
    f = message.getListenersEarnedFaveBadgeList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(9, f, users_entity_pb.User.serializeBinaryToWriter);
    }
    f = message.getListenersEarnedFavePointList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(10, f, users_entity_pb.User.serializeBinaryToWriter);
    }
    f = message.getListenersSentEmojiCommentList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(11, f, users_entity_pb.User.serializeBinaryToWriter);
    }
    f = message.getOtherListenersList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(12, f, users_entity_pb.User.serializeBinaryToWriter);
    }
    f = message.getSetListList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(13, f, karaoke_entity_pb.Song.serializeBinaryToWriter);
    }
};
/**
 * optional string live_id = 1;
 * @return {string}
 */
proto.jp.singcolor.GetLiveResultBasicInfoV1Response.prototype.getLiveId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetLiveResultBasicInfoV1Response} returns this
 */
proto.jp.singcolor.GetLiveResultBasicInfoV1Response.prototype.setLiveId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional int64 live_duration_ms = 2;
 * @return {number}
 */
proto.jp.singcolor.GetLiveResultBasicInfoV1Response.prototype.getLiveDurationMs = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetLiveResultBasicInfoV1Response} returns this
 */
proto.jp.singcolor.GetLiveResultBasicInfoV1Response.prototype.setLiveDurationMs = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional int64 number_of_songs_sung = 3;
 * @return {number}
 */
proto.jp.singcolor.GetLiveResultBasicInfoV1Response.prototype.getNumberOfSongsSung = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetLiveResultBasicInfoV1Response} returns this
 */
proto.jp.singcolor.GetLiveResultBasicInfoV1Response.prototype.setNumberOfSongsSung = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional int64 number_of_comments = 4;
 * @return {number}
 */
proto.jp.singcolor.GetLiveResultBasicInfoV1Response.prototype.getNumberOfComments = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetLiveResultBasicInfoV1Response} returns this
 */
proto.jp.singcolor.GetLiveResultBasicInfoV1Response.prototype.setNumberOfComments = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * optional int64 number_of_super_likes = 5;
 * @return {number}
 */
proto.jp.singcolor.GetLiveResultBasicInfoV1Response.prototype.getNumberOfSuperLikes = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetLiveResultBasicInfoV1Response} returns this
 */
proto.jp.singcolor.GetLiveResultBasicInfoV1Response.prototype.setNumberOfSuperLikes = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
/**
 * repeated LiveResultFavePoint live_result_fave_points = 6;
 * @return {!Array<!proto.jp.singcolor.LiveResultFavePoint>}
 */
proto.jp.singcolor.GetLiveResultBasicInfoV1Response.prototype.getLiveResultFavePointsList = function () {
    return /** @type{!Array<!proto.jp.singcolor.LiveResultFavePoint>} */ (jspb.Message.getRepeatedWrapperField(this, lives_entity_pb.LiveResultFavePoint, 6));
};
/**
 * @param {!Array<!proto.jp.singcolor.LiveResultFavePoint>} value
 * @return {!proto.jp.singcolor.GetLiveResultBasicInfoV1Response} returns this
*/
proto.jp.singcolor.GetLiveResultBasicInfoV1Response.prototype.setLiveResultFavePointsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 6, value);
};
/**
 * @param {!proto.jp.singcolor.LiveResultFavePoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.LiveResultFavePoint}
 */
proto.jp.singcolor.GetLiveResultBasicInfoV1Response.prototype.addLiveResultFavePoints = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.jp.singcolor.LiveResultFavePoint, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.GetLiveResultBasicInfoV1Response} returns this
 */
proto.jp.singcolor.GetLiveResultBasicInfoV1Response.prototype.clearLiveResultFavePointsList = function () {
    return this.setLiveResultFavePointsList([]);
};
/**
 * repeated User first_look_listeners = 7;
 * @return {!Array<!proto.jp.singcolor.User>}
 */
proto.jp.singcolor.GetLiveResultBasicInfoV1Response.prototype.getFirstLookListenersList = function () {
    return /** @type{!Array<!proto.jp.singcolor.User>} */ (jspb.Message.getRepeatedWrapperField(this, users_entity_pb.User, 7));
};
/**
 * @param {!Array<!proto.jp.singcolor.User>} value
 * @return {!proto.jp.singcolor.GetLiveResultBasicInfoV1Response} returns this
*/
proto.jp.singcolor.GetLiveResultBasicInfoV1Response.prototype.setFirstLookListenersList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 7, value);
};
/**
 * @param {!proto.jp.singcolor.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.User}
 */
proto.jp.singcolor.GetLiveResultBasicInfoV1Response.prototype.addFirstLookListeners = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.jp.singcolor.User, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.GetLiveResultBasicInfoV1Response} returns this
 */
proto.jp.singcolor.GetLiveResultBasicInfoV1Response.prototype.clearFirstLookListenersList = function () {
    return this.setFirstLookListenersList([]);
};
/**
 * repeated User new_song_fave_listeners = 8;
 * @return {!Array<!proto.jp.singcolor.User>}
 */
proto.jp.singcolor.GetLiveResultBasicInfoV1Response.prototype.getNewSongFaveListenersList = function () {
    return /** @type{!Array<!proto.jp.singcolor.User>} */ (jspb.Message.getRepeatedWrapperField(this, users_entity_pb.User, 8));
};
/**
 * @param {!Array<!proto.jp.singcolor.User>} value
 * @return {!proto.jp.singcolor.GetLiveResultBasicInfoV1Response} returns this
*/
proto.jp.singcolor.GetLiveResultBasicInfoV1Response.prototype.setNewSongFaveListenersList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 8, value);
};
/**
 * @param {!proto.jp.singcolor.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.User}
 */
proto.jp.singcolor.GetLiveResultBasicInfoV1Response.prototype.addNewSongFaveListeners = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.jp.singcolor.User, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.GetLiveResultBasicInfoV1Response} returns this
 */
proto.jp.singcolor.GetLiveResultBasicInfoV1Response.prototype.clearNewSongFaveListenersList = function () {
    return this.setNewSongFaveListenersList([]);
};
/**
 * repeated User listeners_earned_fave_badge = 9;
 * @return {!Array<!proto.jp.singcolor.User>}
 */
proto.jp.singcolor.GetLiveResultBasicInfoV1Response.prototype.getListenersEarnedFaveBadgeList = function () {
    return /** @type{!Array<!proto.jp.singcolor.User>} */ (jspb.Message.getRepeatedWrapperField(this, users_entity_pb.User, 9));
};
/**
 * @param {!Array<!proto.jp.singcolor.User>} value
 * @return {!proto.jp.singcolor.GetLiveResultBasicInfoV1Response} returns this
*/
proto.jp.singcolor.GetLiveResultBasicInfoV1Response.prototype.setListenersEarnedFaveBadgeList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 9, value);
};
/**
 * @param {!proto.jp.singcolor.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.User}
 */
proto.jp.singcolor.GetLiveResultBasicInfoV1Response.prototype.addListenersEarnedFaveBadge = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.jp.singcolor.User, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.GetLiveResultBasicInfoV1Response} returns this
 */
proto.jp.singcolor.GetLiveResultBasicInfoV1Response.prototype.clearListenersEarnedFaveBadgeList = function () {
    return this.setListenersEarnedFaveBadgeList([]);
};
/**
 * repeated User listeners_earned_fave_point = 10;
 * @return {!Array<!proto.jp.singcolor.User>}
 */
proto.jp.singcolor.GetLiveResultBasicInfoV1Response.prototype.getListenersEarnedFavePointList = function () {
    return /** @type{!Array<!proto.jp.singcolor.User>} */ (jspb.Message.getRepeatedWrapperField(this, users_entity_pb.User, 10));
};
/**
 * @param {!Array<!proto.jp.singcolor.User>} value
 * @return {!proto.jp.singcolor.GetLiveResultBasicInfoV1Response} returns this
*/
proto.jp.singcolor.GetLiveResultBasicInfoV1Response.prototype.setListenersEarnedFavePointList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 10, value);
};
/**
 * @param {!proto.jp.singcolor.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.User}
 */
proto.jp.singcolor.GetLiveResultBasicInfoV1Response.prototype.addListenersEarnedFavePoint = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.jp.singcolor.User, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.GetLiveResultBasicInfoV1Response} returns this
 */
proto.jp.singcolor.GetLiveResultBasicInfoV1Response.prototype.clearListenersEarnedFavePointList = function () {
    return this.setListenersEarnedFavePointList([]);
};
/**
 * repeated User listeners_sent_emoji_comment = 11;
 * @return {!Array<!proto.jp.singcolor.User>}
 */
proto.jp.singcolor.GetLiveResultBasicInfoV1Response.prototype.getListenersSentEmojiCommentList = function () {
    return /** @type{!Array<!proto.jp.singcolor.User>} */ (jspb.Message.getRepeatedWrapperField(this, users_entity_pb.User, 11));
};
/**
 * @param {!Array<!proto.jp.singcolor.User>} value
 * @return {!proto.jp.singcolor.GetLiveResultBasicInfoV1Response} returns this
*/
proto.jp.singcolor.GetLiveResultBasicInfoV1Response.prototype.setListenersSentEmojiCommentList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 11, value);
};
/**
 * @param {!proto.jp.singcolor.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.User}
 */
proto.jp.singcolor.GetLiveResultBasicInfoV1Response.prototype.addListenersSentEmojiComment = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.jp.singcolor.User, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.GetLiveResultBasicInfoV1Response} returns this
 */
proto.jp.singcolor.GetLiveResultBasicInfoV1Response.prototype.clearListenersSentEmojiCommentList = function () {
    return this.setListenersSentEmojiCommentList([]);
};
/**
 * repeated User other_listeners = 12;
 * @return {!Array<!proto.jp.singcolor.User>}
 */
proto.jp.singcolor.GetLiveResultBasicInfoV1Response.prototype.getOtherListenersList = function () {
    return /** @type{!Array<!proto.jp.singcolor.User>} */ (jspb.Message.getRepeatedWrapperField(this, users_entity_pb.User, 12));
};
/**
 * @param {!Array<!proto.jp.singcolor.User>} value
 * @return {!proto.jp.singcolor.GetLiveResultBasicInfoV1Response} returns this
*/
proto.jp.singcolor.GetLiveResultBasicInfoV1Response.prototype.setOtherListenersList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 12, value);
};
/**
 * @param {!proto.jp.singcolor.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.User}
 */
proto.jp.singcolor.GetLiveResultBasicInfoV1Response.prototype.addOtherListeners = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.jp.singcolor.User, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.GetLiveResultBasicInfoV1Response} returns this
 */
proto.jp.singcolor.GetLiveResultBasicInfoV1Response.prototype.clearOtherListenersList = function () {
    return this.setOtherListenersList([]);
};
/**
 * repeated Song set_list = 13;
 * @return {!Array<!proto.jp.singcolor.Song>}
 */
proto.jp.singcolor.GetLiveResultBasicInfoV1Response.prototype.getSetListList = function () {
    return /** @type{!Array<!proto.jp.singcolor.Song>} */ (jspb.Message.getRepeatedWrapperField(this, karaoke_entity_pb.Song, 13));
};
/**
 * @param {!Array<!proto.jp.singcolor.Song>} value
 * @return {!proto.jp.singcolor.GetLiveResultBasicInfoV1Response} returns this
*/
proto.jp.singcolor.GetLiveResultBasicInfoV1Response.prototype.setSetListList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 13, value);
};
/**
 * @param {!proto.jp.singcolor.Song=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.Song}
 */
proto.jp.singcolor.GetLiveResultBasicInfoV1Response.prototype.addSetList = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.jp.singcolor.Song, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.GetLiveResultBasicInfoV1Response} returns this
 */
proto.jp.singcolor.GetLiveResultBasicInfoV1Response.prototype.clearSetListList = function () {
    return this.setSetListList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetLiveResultBasicInfoV2Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetLiveResultBasicInfoV2Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetLiveResultBasicInfoV2Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetLiveResultBasicInfoV2Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            liveId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetLiveResultBasicInfoV2Request}
 */
proto.jp.singcolor.GetLiveResultBasicInfoV2Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetLiveResultBasicInfoV2Request;
    return proto.jp.singcolor.GetLiveResultBasicInfoV2Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetLiveResultBasicInfoV2Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetLiveResultBasicInfoV2Request}
 */
proto.jp.singcolor.GetLiveResultBasicInfoV2Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiveId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetLiveResultBasicInfoV2Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetLiveResultBasicInfoV2Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetLiveResultBasicInfoV2Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetLiveResultBasicInfoV2Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiveId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string live_id = 1;
 * @return {string}
 */
proto.jp.singcolor.GetLiveResultBasicInfoV2Request.prototype.getLiveId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetLiveResultBasicInfoV2Request} returns this
 */
proto.jp.singcolor.GetLiveResultBasicInfoV2Request.prototype.setLiveId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.GetLiveResultBasicInfoV2Response.repeatedFields_ = [6, 7, 8, 9, 10, 11, 12, 13];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetLiveResultBasicInfoV2Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetLiveResultBasicInfoV2Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetLiveResultBasicInfoV2Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetLiveResultBasicInfoV2Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            liveId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            liveDurationMs: jspb.Message.getFieldWithDefault(msg, 2, 0),
            numberOfSongsSung: jspb.Message.getFieldWithDefault(msg, 3, 0),
            numberOfComments: jspb.Message.getFieldWithDefault(msg, 4, 0),
            numberOfSuperLikes: jspb.Message.getFieldWithDefault(msg, 5, 0),
            liveResultFavePointsList: jspb.Message.toObjectList(msg.getLiveResultFavePointsList(), lives_entity_pb.LiveResultFavePoint.toObject, includeInstance),
            firstLookListenersList: jspb.Message.toObjectList(msg.getFirstLookListenersList(), users_entity_pb.UserWithListenerSongFaveThumbnail.toObject, includeInstance),
            newSongFaveListenersList: jspb.Message.toObjectList(msg.getNewSongFaveListenersList(), users_entity_pb.UserWithListenerSongFaveThumbnail.toObject, includeInstance),
            listenersEarnedFaveBadgeList: jspb.Message.toObjectList(msg.getListenersEarnedFaveBadgeList(), users_entity_pb.UserWithListenerSongFaveThumbnail.toObject, includeInstance),
            listenersEarnedFavePointList: jspb.Message.toObjectList(msg.getListenersEarnedFavePointList(), users_entity_pb.UserWithListenerSongFaveThumbnail.toObject, includeInstance),
            listenersSentEmojiCommentList: jspb.Message.toObjectList(msg.getListenersSentEmojiCommentList(), users_entity_pb.UserWithListenerSongFaveThumbnail.toObject, includeInstance),
            otherListenersList: jspb.Message.toObjectList(msg.getOtherListenersList(), users_entity_pb.UserWithListenerSongFaveThumbnail.toObject, includeInstance),
            setListList: jspb.Message.toObjectList(msg.getSetListList(), karaoke_entity_pb.Song.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetLiveResultBasicInfoV2Response}
 */
proto.jp.singcolor.GetLiveResultBasicInfoV2Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetLiveResultBasicInfoV2Response;
    return proto.jp.singcolor.GetLiveResultBasicInfoV2Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetLiveResultBasicInfoV2Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetLiveResultBasicInfoV2Response}
 */
proto.jp.singcolor.GetLiveResultBasicInfoV2Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiveId(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setLiveDurationMs(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setNumberOfSongsSung(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setNumberOfComments(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setNumberOfSuperLikes(value);
                break;
            case 6:
                var value = new lives_entity_pb.LiveResultFavePoint;
                reader.readMessage(value, lives_entity_pb.LiveResultFavePoint.deserializeBinaryFromReader);
                msg.addLiveResultFavePoints(value);
                break;
            case 7:
                var value = new users_entity_pb.UserWithListenerSongFaveThumbnail;
                reader.readMessage(value, users_entity_pb.UserWithListenerSongFaveThumbnail.deserializeBinaryFromReader);
                msg.addFirstLookListeners(value);
                break;
            case 8:
                var value = new users_entity_pb.UserWithListenerSongFaveThumbnail;
                reader.readMessage(value, users_entity_pb.UserWithListenerSongFaveThumbnail.deserializeBinaryFromReader);
                msg.addNewSongFaveListeners(value);
                break;
            case 9:
                var value = new users_entity_pb.UserWithListenerSongFaveThumbnail;
                reader.readMessage(value, users_entity_pb.UserWithListenerSongFaveThumbnail.deserializeBinaryFromReader);
                msg.addListenersEarnedFaveBadge(value);
                break;
            case 10:
                var value = new users_entity_pb.UserWithListenerSongFaveThumbnail;
                reader.readMessage(value, users_entity_pb.UserWithListenerSongFaveThumbnail.deserializeBinaryFromReader);
                msg.addListenersEarnedFavePoint(value);
                break;
            case 11:
                var value = new users_entity_pb.UserWithListenerSongFaveThumbnail;
                reader.readMessage(value, users_entity_pb.UserWithListenerSongFaveThumbnail.deserializeBinaryFromReader);
                msg.addListenersSentEmojiComment(value);
                break;
            case 12:
                var value = new users_entity_pb.UserWithListenerSongFaveThumbnail;
                reader.readMessage(value, users_entity_pb.UserWithListenerSongFaveThumbnail.deserializeBinaryFromReader);
                msg.addOtherListeners(value);
                break;
            case 13:
                var value = new karaoke_entity_pb.Song;
                reader.readMessage(value, karaoke_entity_pb.Song.deserializeBinaryFromReader);
                msg.addSetList(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetLiveResultBasicInfoV2Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetLiveResultBasicInfoV2Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetLiveResultBasicInfoV2Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetLiveResultBasicInfoV2Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiveId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getLiveDurationMs();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getNumberOfSongsSung();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
    f = message.getNumberOfComments();
    if (f !== 0) {
        writer.writeInt64(4, f);
    }
    f = message.getNumberOfSuperLikes();
    if (f !== 0) {
        writer.writeInt64(5, f);
    }
    f = message.getLiveResultFavePointsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(6, f, lives_entity_pb.LiveResultFavePoint.serializeBinaryToWriter);
    }
    f = message.getFirstLookListenersList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(7, f, users_entity_pb.UserWithListenerSongFaveThumbnail.serializeBinaryToWriter);
    }
    f = message.getNewSongFaveListenersList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(8, f, users_entity_pb.UserWithListenerSongFaveThumbnail.serializeBinaryToWriter);
    }
    f = message.getListenersEarnedFaveBadgeList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(9, f, users_entity_pb.UserWithListenerSongFaveThumbnail.serializeBinaryToWriter);
    }
    f = message.getListenersEarnedFavePointList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(10, f, users_entity_pb.UserWithListenerSongFaveThumbnail.serializeBinaryToWriter);
    }
    f = message.getListenersSentEmojiCommentList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(11, f, users_entity_pb.UserWithListenerSongFaveThumbnail.serializeBinaryToWriter);
    }
    f = message.getOtherListenersList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(12, f, users_entity_pb.UserWithListenerSongFaveThumbnail.serializeBinaryToWriter);
    }
    f = message.getSetListList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(13, f, karaoke_entity_pb.Song.serializeBinaryToWriter);
    }
};
/**
 * optional string live_id = 1;
 * @return {string}
 */
proto.jp.singcolor.GetLiveResultBasicInfoV2Response.prototype.getLiveId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetLiveResultBasicInfoV2Response} returns this
 */
proto.jp.singcolor.GetLiveResultBasicInfoV2Response.prototype.setLiveId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional int64 live_duration_ms = 2;
 * @return {number}
 */
proto.jp.singcolor.GetLiveResultBasicInfoV2Response.prototype.getLiveDurationMs = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetLiveResultBasicInfoV2Response} returns this
 */
proto.jp.singcolor.GetLiveResultBasicInfoV2Response.prototype.setLiveDurationMs = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional int64 number_of_songs_sung = 3;
 * @return {number}
 */
proto.jp.singcolor.GetLiveResultBasicInfoV2Response.prototype.getNumberOfSongsSung = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetLiveResultBasicInfoV2Response} returns this
 */
proto.jp.singcolor.GetLiveResultBasicInfoV2Response.prototype.setNumberOfSongsSung = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional int64 number_of_comments = 4;
 * @return {number}
 */
proto.jp.singcolor.GetLiveResultBasicInfoV2Response.prototype.getNumberOfComments = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetLiveResultBasicInfoV2Response} returns this
 */
proto.jp.singcolor.GetLiveResultBasicInfoV2Response.prototype.setNumberOfComments = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * optional int64 number_of_super_likes = 5;
 * @return {number}
 */
proto.jp.singcolor.GetLiveResultBasicInfoV2Response.prototype.getNumberOfSuperLikes = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetLiveResultBasicInfoV2Response} returns this
 */
proto.jp.singcolor.GetLiveResultBasicInfoV2Response.prototype.setNumberOfSuperLikes = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
/**
 * repeated LiveResultFavePoint live_result_fave_points = 6;
 * @return {!Array<!proto.jp.singcolor.LiveResultFavePoint>}
 */
proto.jp.singcolor.GetLiveResultBasicInfoV2Response.prototype.getLiveResultFavePointsList = function () {
    return /** @type{!Array<!proto.jp.singcolor.LiveResultFavePoint>} */ (jspb.Message.getRepeatedWrapperField(this, lives_entity_pb.LiveResultFavePoint, 6));
};
/**
 * @param {!Array<!proto.jp.singcolor.LiveResultFavePoint>} value
 * @return {!proto.jp.singcolor.GetLiveResultBasicInfoV2Response} returns this
*/
proto.jp.singcolor.GetLiveResultBasicInfoV2Response.prototype.setLiveResultFavePointsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 6, value);
};
/**
 * @param {!proto.jp.singcolor.LiveResultFavePoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.LiveResultFavePoint}
 */
proto.jp.singcolor.GetLiveResultBasicInfoV2Response.prototype.addLiveResultFavePoints = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.jp.singcolor.LiveResultFavePoint, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.GetLiveResultBasicInfoV2Response} returns this
 */
proto.jp.singcolor.GetLiveResultBasicInfoV2Response.prototype.clearLiveResultFavePointsList = function () {
    return this.setLiveResultFavePointsList([]);
};
/**
 * repeated UserWithListenerSongFaveThumbnail first_look_listeners = 7;
 * @return {!Array<!proto.jp.singcolor.UserWithListenerSongFaveThumbnail>}
 */
proto.jp.singcolor.GetLiveResultBasicInfoV2Response.prototype.getFirstLookListenersList = function () {
    return /** @type{!Array<!proto.jp.singcolor.UserWithListenerSongFaveThumbnail>} */ (jspb.Message.getRepeatedWrapperField(this, users_entity_pb.UserWithListenerSongFaveThumbnail, 7));
};
/**
 * @param {!Array<!proto.jp.singcolor.UserWithListenerSongFaveThumbnail>} value
 * @return {!proto.jp.singcolor.GetLiveResultBasicInfoV2Response} returns this
*/
proto.jp.singcolor.GetLiveResultBasicInfoV2Response.prototype.setFirstLookListenersList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 7, value);
};
/**
 * @param {!proto.jp.singcolor.UserWithListenerSongFaveThumbnail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.UserWithListenerSongFaveThumbnail}
 */
proto.jp.singcolor.GetLiveResultBasicInfoV2Response.prototype.addFirstLookListeners = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.jp.singcolor.UserWithListenerSongFaveThumbnail, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.GetLiveResultBasicInfoV2Response} returns this
 */
proto.jp.singcolor.GetLiveResultBasicInfoV2Response.prototype.clearFirstLookListenersList = function () {
    return this.setFirstLookListenersList([]);
};
/**
 * repeated UserWithListenerSongFaveThumbnail new_song_fave_listeners = 8;
 * @return {!Array<!proto.jp.singcolor.UserWithListenerSongFaveThumbnail>}
 */
proto.jp.singcolor.GetLiveResultBasicInfoV2Response.prototype.getNewSongFaveListenersList = function () {
    return /** @type{!Array<!proto.jp.singcolor.UserWithListenerSongFaveThumbnail>} */ (jspb.Message.getRepeatedWrapperField(this, users_entity_pb.UserWithListenerSongFaveThumbnail, 8));
};
/**
 * @param {!Array<!proto.jp.singcolor.UserWithListenerSongFaveThumbnail>} value
 * @return {!proto.jp.singcolor.GetLiveResultBasicInfoV2Response} returns this
*/
proto.jp.singcolor.GetLiveResultBasicInfoV2Response.prototype.setNewSongFaveListenersList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 8, value);
};
/**
 * @param {!proto.jp.singcolor.UserWithListenerSongFaveThumbnail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.UserWithListenerSongFaveThumbnail}
 */
proto.jp.singcolor.GetLiveResultBasicInfoV2Response.prototype.addNewSongFaveListeners = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.jp.singcolor.UserWithListenerSongFaveThumbnail, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.GetLiveResultBasicInfoV2Response} returns this
 */
proto.jp.singcolor.GetLiveResultBasicInfoV2Response.prototype.clearNewSongFaveListenersList = function () {
    return this.setNewSongFaveListenersList([]);
};
/**
 * repeated UserWithListenerSongFaveThumbnail listeners_earned_fave_badge = 9;
 * @return {!Array<!proto.jp.singcolor.UserWithListenerSongFaveThumbnail>}
 */
proto.jp.singcolor.GetLiveResultBasicInfoV2Response.prototype.getListenersEarnedFaveBadgeList = function () {
    return /** @type{!Array<!proto.jp.singcolor.UserWithListenerSongFaveThumbnail>} */ (jspb.Message.getRepeatedWrapperField(this, users_entity_pb.UserWithListenerSongFaveThumbnail, 9));
};
/**
 * @param {!Array<!proto.jp.singcolor.UserWithListenerSongFaveThumbnail>} value
 * @return {!proto.jp.singcolor.GetLiveResultBasicInfoV2Response} returns this
*/
proto.jp.singcolor.GetLiveResultBasicInfoV2Response.prototype.setListenersEarnedFaveBadgeList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 9, value);
};
/**
 * @param {!proto.jp.singcolor.UserWithListenerSongFaveThumbnail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.UserWithListenerSongFaveThumbnail}
 */
proto.jp.singcolor.GetLiveResultBasicInfoV2Response.prototype.addListenersEarnedFaveBadge = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.jp.singcolor.UserWithListenerSongFaveThumbnail, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.GetLiveResultBasicInfoV2Response} returns this
 */
proto.jp.singcolor.GetLiveResultBasicInfoV2Response.prototype.clearListenersEarnedFaveBadgeList = function () {
    return this.setListenersEarnedFaveBadgeList([]);
};
/**
 * repeated UserWithListenerSongFaveThumbnail listeners_earned_fave_point = 10;
 * @return {!Array<!proto.jp.singcolor.UserWithListenerSongFaveThumbnail>}
 */
proto.jp.singcolor.GetLiveResultBasicInfoV2Response.prototype.getListenersEarnedFavePointList = function () {
    return /** @type{!Array<!proto.jp.singcolor.UserWithListenerSongFaveThumbnail>} */ (jspb.Message.getRepeatedWrapperField(this, users_entity_pb.UserWithListenerSongFaveThumbnail, 10));
};
/**
 * @param {!Array<!proto.jp.singcolor.UserWithListenerSongFaveThumbnail>} value
 * @return {!proto.jp.singcolor.GetLiveResultBasicInfoV2Response} returns this
*/
proto.jp.singcolor.GetLiveResultBasicInfoV2Response.prototype.setListenersEarnedFavePointList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 10, value);
};
/**
 * @param {!proto.jp.singcolor.UserWithListenerSongFaveThumbnail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.UserWithListenerSongFaveThumbnail}
 */
proto.jp.singcolor.GetLiveResultBasicInfoV2Response.prototype.addListenersEarnedFavePoint = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.jp.singcolor.UserWithListenerSongFaveThumbnail, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.GetLiveResultBasicInfoV2Response} returns this
 */
proto.jp.singcolor.GetLiveResultBasicInfoV2Response.prototype.clearListenersEarnedFavePointList = function () {
    return this.setListenersEarnedFavePointList([]);
};
/**
 * repeated UserWithListenerSongFaveThumbnail listeners_sent_emoji_comment = 11;
 * @return {!Array<!proto.jp.singcolor.UserWithListenerSongFaveThumbnail>}
 */
proto.jp.singcolor.GetLiveResultBasicInfoV2Response.prototype.getListenersSentEmojiCommentList = function () {
    return /** @type{!Array<!proto.jp.singcolor.UserWithListenerSongFaveThumbnail>} */ (jspb.Message.getRepeatedWrapperField(this, users_entity_pb.UserWithListenerSongFaveThumbnail, 11));
};
/**
 * @param {!Array<!proto.jp.singcolor.UserWithListenerSongFaveThumbnail>} value
 * @return {!proto.jp.singcolor.GetLiveResultBasicInfoV2Response} returns this
*/
proto.jp.singcolor.GetLiveResultBasicInfoV2Response.prototype.setListenersSentEmojiCommentList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 11, value);
};
/**
 * @param {!proto.jp.singcolor.UserWithListenerSongFaveThumbnail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.UserWithListenerSongFaveThumbnail}
 */
proto.jp.singcolor.GetLiveResultBasicInfoV2Response.prototype.addListenersSentEmojiComment = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.jp.singcolor.UserWithListenerSongFaveThumbnail, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.GetLiveResultBasicInfoV2Response} returns this
 */
proto.jp.singcolor.GetLiveResultBasicInfoV2Response.prototype.clearListenersSentEmojiCommentList = function () {
    return this.setListenersSentEmojiCommentList([]);
};
/**
 * repeated UserWithListenerSongFaveThumbnail other_listeners = 12;
 * @return {!Array<!proto.jp.singcolor.UserWithListenerSongFaveThumbnail>}
 */
proto.jp.singcolor.GetLiveResultBasicInfoV2Response.prototype.getOtherListenersList = function () {
    return /** @type{!Array<!proto.jp.singcolor.UserWithListenerSongFaveThumbnail>} */ (jspb.Message.getRepeatedWrapperField(this, users_entity_pb.UserWithListenerSongFaveThumbnail, 12));
};
/**
 * @param {!Array<!proto.jp.singcolor.UserWithListenerSongFaveThumbnail>} value
 * @return {!proto.jp.singcolor.GetLiveResultBasicInfoV2Response} returns this
*/
proto.jp.singcolor.GetLiveResultBasicInfoV2Response.prototype.setOtherListenersList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 12, value);
};
/**
 * @param {!proto.jp.singcolor.UserWithListenerSongFaveThumbnail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.UserWithListenerSongFaveThumbnail}
 */
proto.jp.singcolor.GetLiveResultBasicInfoV2Response.prototype.addOtherListeners = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.jp.singcolor.UserWithListenerSongFaveThumbnail, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.GetLiveResultBasicInfoV2Response} returns this
 */
proto.jp.singcolor.GetLiveResultBasicInfoV2Response.prototype.clearOtherListenersList = function () {
    return this.setOtherListenersList([]);
};
/**
 * repeated Song set_list = 13;
 * @return {!Array<!proto.jp.singcolor.Song>}
 */
proto.jp.singcolor.GetLiveResultBasicInfoV2Response.prototype.getSetListList = function () {
    return /** @type{!Array<!proto.jp.singcolor.Song>} */ (jspb.Message.getRepeatedWrapperField(this, karaoke_entity_pb.Song, 13));
};
/**
 * @param {!Array<!proto.jp.singcolor.Song>} value
 * @return {!proto.jp.singcolor.GetLiveResultBasicInfoV2Response} returns this
*/
proto.jp.singcolor.GetLiveResultBasicInfoV2Response.prototype.setSetListList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 13, value);
};
/**
 * @param {!proto.jp.singcolor.Song=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.Song}
 */
proto.jp.singcolor.GetLiveResultBasicInfoV2Response.prototype.addSetList = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.jp.singcolor.Song, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.GetLiveResultBasicInfoV2Response} returns this
 */
proto.jp.singcolor.GetLiveResultBasicInfoV2Response.prototype.clearSetListList = function () {
    return this.setSetListList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetLiveResultGiftV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetLiveResultGiftV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetLiveResultGiftV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetLiveResultGiftV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            liveId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetLiveResultGiftV1Request}
 */
proto.jp.singcolor.GetLiveResultGiftV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetLiveResultGiftV1Request;
    return proto.jp.singcolor.GetLiveResultGiftV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetLiveResultGiftV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetLiveResultGiftV1Request}
 */
proto.jp.singcolor.GetLiveResultGiftV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiveId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetLiveResultGiftV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetLiveResultGiftV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetLiveResultGiftV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetLiveResultGiftV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiveId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string live_id = 1;
 * @return {string}
 */
proto.jp.singcolor.GetLiveResultGiftV1Request.prototype.getLiveId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetLiveResultGiftV1Request} returns this
 */
proto.jp.singcolor.GetLiveResultGiftV1Request.prototype.setLiveId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.GetLiveResultGiftV1Response.repeatedFields_ = [2];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetLiveResultGiftV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetLiveResultGiftV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetLiveResultGiftV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetLiveResultGiftV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            liveId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            listenersSentGiftsList: jspb.Message.toObjectList(msg.getListenersSentGiftsList(), lives_entity_pb.LiveResultGiftListener.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetLiveResultGiftV1Response}
 */
proto.jp.singcolor.GetLiveResultGiftV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetLiveResultGiftV1Response;
    return proto.jp.singcolor.GetLiveResultGiftV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetLiveResultGiftV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetLiveResultGiftV1Response}
 */
proto.jp.singcolor.GetLiveResultGiftV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiveId(value);
                break;
            case 2:
                var value = new lives_entity_pb.LiveResultGiftListener;
                reader.readMessage(value, lives_entity_pb.LiveResultGiftListener.deserializeBinaryFromReader);
                msg.addListenersSentGifts(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetLiveResultGiftV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetLiveResultGiftV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetLiveResultGiftV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetLiveResultGiftV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiveId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getListenersSentGiftsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(2, f, lives_entity_pb.LiveResultGiftListener.serializeBinaryToWriter);
    }
};
/**
 * optional string live_id = 1;
 * @return {string}
 */
proto.jp.singcolor.GetLiveResultGiftV1Response.prototype.getLiveId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetLiveResultGiftV1Response} returns this
 */
proto.jp.singcolor.GetLiveResultGiftV1Response.prototype.setLiveId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * repeated LiveResultGiftListener listeners_sent_gifts = 2;
 * @return {!Array<!proto.jp.singcolor.LiveResultGiftListener>}
 */
proto.jp.singcolor.GetLiveResultGiftV1Response.prototype.getListenersSentGiftsList = function () {
    return /** @type{!Array<!proto.jp.singcolor.LiveResultGiftListener>} */ (jspb.Message.getRepeatedWrapperField(this, lives_entity_pb.LiveResultGiftListener, 2));
};
/**
 * @param {!Array<!proto.jp.singcolor.LiveResultGiftListener>} value
 * @return {!proto.jp.singcolor.GetLiveResultGiftV1Response} returns this
*/
proto.jp.singcolor.GetLiveResultGiftV1Response.prototype.setListenersSentGiftsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 2, value);
};
/**
 * @param {!proto.jp.singcolor.LiveResultGiftListener=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.LiveResultGiftListener}
 */
proto.jp.singcolor.GetLiveResultGiftV1Response.prototype.addListenersSentGifts = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.jp.singcolor.LiveResultGiftListener, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.GetLiveResultGiftV1Response} returns this
 */
proto.jp.singcolor.GetLiveResultGiftV1Response.prototype.clearListenersSentGiftsList = function () {
    return this.setListenersSentGiftsList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetUserDashboardInfoV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetUserDashboardInfoV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetUserDashboardInfoV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetUserDashboardInfoV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetUserDashboardInfoV1Request}
 */
proto.jp.singcolor.GetUserDashboardInfoV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetUserDashboardInfoV1Request;
    return proto.jp.singcolor.GetUserDashboardInfoV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetUserDashboardInfoV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetUserDashboardInfoV1Request}
 */
proto.jp.singcolor.GetUserDashboardInfoV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetUserDashboardInfoV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetUserDashboardInfoV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetUserDashboardInfoV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetUserDashboardInfoV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.GetUserDashboardInfoV1Request.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetUserDashboardInfoV1Request} returns this
 */
proto.jp.singcolor.GetUserDashboardInfoV1Request.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetUserDashboardInfoV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetUserDashboardInfoV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetUserDashboardInfoV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetUserDashboardInfoV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            monthlyNumberOfSongFave: jspb.Message.getFieldWithDefault(msg, 1, 0),
            monthlyFavePoints: jspb.Message.getFieldWithDefault(msg, 2, 0),
            monthlyFaveBonusRate: jspb.Message.getFieldWithDefault(msg, 3, ""),
            pfFavePoints: jspb.Message.getFieldWithDefault(msg, 4, 0),
            monthlyFavePointsNext: jspb.Message.getFieldWithDefault(msg, 5, 0),
            monthlyNumberOfSongFaveNext: jspb.Message.getFieldWithDefault(msg, 6, 0),
            faveBonusTitle: jspb.Message.getFieldWithDefault(msg, 7, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetUserDashboardInfoV1Response}
 */
proto.jp.singcolor.GetUserDashboardInfoV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetUserDashboardInfoV1Response;
    return proto.jp.singcolor.GetUserDashboardInfoV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetUserDashboardInfoV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetUserDashboardInfoV1Response}
 */
proto.jp.singcolor.GetUserDashboardInfoV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setMonthlyNumberOfSongFave(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setMonthlyFavePoints(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setMonthlyFaveBonusRate(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setPfFavePoints(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setMonthlyFavePointsNext(value);
                break;
            case 6:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setMonthlyNumberOfSongFaveNext(value);
                break;
            case 7:
                var value = /** @type {!proto.jp.singcolor.FaveBonusTitle} */ (reader.readEnum());
                msg.setFaveBonusTitle(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetUserDashboardInfoV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetUserDashboardInfoV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetUserDashboardInfoV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetUserDashboardInfoV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getMonthlyNumberOfSongFave();
    if (f !== 0) {
        writer.writeInt64(1, f);
    }
    f = message.getMonthlyFavePoints();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getMonthlyFaveBonusRate();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
    f = message.getPfFavePoints();
    if (f !== 0) {
        writer.writeInt64(4, f);
    }
    f = message.getMonthlyFavePointsNext();
    if (f !== 0) {
        writer.writeInt64(5, f);
    }
    f = message.getMonthlyNumberOfSongFaveNext();
    if (f !== 0) {
        writer.writeInt64(6, f);
    }
    f = message.getFaveBonusTitle();
    if (f !== 0.0) {
        writer.writeEnum(7, f);
    }
};
/**
 * optional int64 monthly_number_of_song_fave = 1;
 * @return {number}
 */
proto.jp.singcolor.GetUserDashboardInfoV1Response.prototype.getMonthlyNumberOfSongFave = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetUserDashboardInfoV1Response} returns this
 */
proto.jp.singcolor.GetUserDashboardInfoV1Response.prototype.setMonthlyNumberOfSongFave = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
};
/**
 * optional int64 monthly_fave_points = 2;
 * @return {number}
 */
proto.jp.singcolor.GetUserDashboardInfoV1Response.prototype.getMonthlyFavePoints = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetUserDashboardInfoV1Response} returns this
 */
proto.jp.singcolor.GetUserDashboardInfoV1Response.prototype.setMonthlyFavePoints = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional string monthly_fave_bonus_rate = 3;
 * @return {string}
 */
proto.jp.singcolor.GetUserDashboardInfoV1Response.prototype.getMonthlyFaveBonusRate = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetUserDashboardInfoV1Response} returns this
 */
proto.jp.singcolor.GetUserDashboardInfoV1Response.prototype.setMonthlyFaveBonusRate = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * optional int64 pf_fave_points = 4;
 * @return {number}
 */
proto.jp.singcolor.GetUserDashboardInfoV1Response.prototype.getPfFavePoints = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetUserDashboardInfoV1Response} returns this
 */
proto.jp.singcolor.GetUserDashboardInfoV1Response.prototype.setPfFavePoints = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * optional int64 monthly_fave_points_next = 5;
 * @return {number}
 */
proto.jp.singcolor.GetUserDashboardInfoV1Response.prototype.getMonthlyFavePointsNext = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetUserDashboardInfoV1Response} returns this
 */
proto.jp.singcolor.GetUserDashboardInfoV1Response.prototype.setMonthlyFavePointsNext = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
/**
 * optional int64 monthly_number_of_song_fave_next = 6;
 * @return {number}
 */
proto.jp.singcolor.GetUserDashboardInfoV1Response.prototype.getMonthlyNumberOfSongFaveNext = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetUserDashboardInfoV1Response} returns this
 */
proto.jp.singcolor.GetUserDashboardInfoV1Response.prototype.setMonthlyNumberOfSongFaveNext = function (value) {
    return jspb.Message.setProto3IntField(this, 6, value);
};
/**
 * optional FaveBonusTitle fave_bonus_title = 7;
 * @return {!proto.jp.singcolor.FaveBonusTitle}
 */
proto.jp.singcolor.GetUserDashboardInfoV1Response.prototype.getFaveBonusTitle = function () {
    return /** @type {!proto.jp.singcolor.FaveBonusTitle} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};
/**
 * @param {!proto.jp.singcolor.FaveBonusTitle} value
 * @return {!proto.jp.singcolor.GetUserDashboardInfoV1Response} returns this
 */
proto.jp.singcolor.GetUserDashboardInfoV1Response.prototype.setFaveBonusTitle = function (value) {
    return jspb.Message.setProto3EnumField(this, 7, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetLivePermissionV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetLivePermissionV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetLivePermissionV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetLivePermissionV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            liverId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetLivePermissionV1Request}
 */
proto.jp.singcolor.GetLivePermissionV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetLivePermissionV1Request;
    return proto.jp.singcolor.GetLivePermissionV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetLivePermissionV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetLivePermissionV1Request}
 */
proto.jp.singcolor.GetLivePermissionV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiverId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetLivePermissionV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetLivePermissionV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetLivePermissionV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetLivePermissionV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiverId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string liver_id = 1;
 * @return {string}
 */
proto.jp.singcolor.GetLivePermissionV1Request.prototype.getLiverId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetLivePermissionV1Request} returns this
 */
proto.jp.singcolor.GetLivePermissionV1Request.prototype.setLiverId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetLivePermissionV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetLivePermissionV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetLivePermissionV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetLivePermissionV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            permitted: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
            hasLinkedTwitterAccount: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
            livePermission: (f = msg.getLivePermission()) && lives_entity_pb.LivePermission.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetLivePermissionV1Response}
 */
proto.jp.singcolor.GetLivePermissionV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetLivePermissionV1Response;
    return proto.jp.singcolor.GetLivePermissionV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetLivePermissionV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetLivePermissionV1Response}
 */
proto.jp.singcolor.GetLivePermissionV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setPermitted(value);
                break;
            case 2:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setHasLinkedTwitterAccount(value);
                break;
            case 3:
                var value = new lives_entity_pb.LivePermission;
                reader.readMessage(value, lives_entity_pb.LivePermission.deserializeBinaryFromReader);
                msg.setLivePermission(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetLivePermissionV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetLivePermissionV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetLivePermissionV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetLivePermissionV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPermitted();
    if (f) {
        writer.writeBool(1, f);
    }
    f = message.getHasLinkedTwitterAccount();
    if (f) {
        writer.writeBool(2, f);
    }
    f = message.getLivePermission();
    if (f != null) {
        writer.writeMessage(3, f, lives_entity_pb.LivePermission.serializeBinaryToWriter);
    }
};
/**
 * optional bool permitted = 1;
 * @return {boolean}
 */
proto.jp.singcolor.GetLivePermissionV1Response.prototype.getPermitted = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.GetLivePermissionV1Response} returns this
 */
proto.jp.singcolor.GetLivePermissionV1Response.prototype.setPermitted = function (value) {
    return jspb.Message.setProto3BooleanField(this, 1, value);
};
/**
 * optional bool has_linked_twitter_account = 2;
 * @return {boolean}
 */
proto.jp.singcolor.GetLivePermissionV1Response.prototype.getHasLinkedTwitterAccount = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.GetLivePermissionV1Response} returns this
 */
proto.jp.singcolor.GetLivePermissionV1Response.prototype.setHasLinkedTwitterAccount = function (value) {
    return jspb.Message.setProto3BooleanField(this, 2, value);
};
/**
 * optional LivePermission live_permission = 3;
 * @return {?proto.jp.singcolor.LivePermission}
 */
proto.jp.singcolor.GetLivePermissionV1Response.prototype.getLivePermission = function () {
    return /** @type{?proto.jp.singcolor.LivePermission} */ (jspb.Message.getWrapperField(this, lives_entity_pb.LivePermission, 3));
};
/**
 * @param {?proto.jp.singcolor.LivePermission|undefined} value
 * @return {!proto.jp.singcolor.GetLivePermissionV1Response} returns this
*/
proto.jp.singcolor.GetLivePermissionV1Response.prototype.setLivePermission = function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetLivePermissionV1Response} returns this
 */
proto.jp.singcolor.GetLivePermissionV1Response.prototype.clearLivePermission = function () {
    return this.setLivePermission(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetLivePermissionV1Response.prototype.hasLivePermission = function () {
    return jspb.Message.getField(this, 3) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetLiveAvailableTimeRangeV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetLiveAvailableTimeRangeV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetLiveAvailableTimeRangeV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetLiveAvailableTimeRangeV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetLiveAvailableTimeRangeV1Request}
 */
proto.jp.singcolor.GetLiveAvailableTimeRangeV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetLiveAvailableTimeRangeV1Request;
    return proto.jp.singcolor.GetLiveAvailableTimeRangeV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetLiveAvailableTimeRangeV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetLiveAvailableTimeRangeV1Request}
 */
proto.jp.singcolor.GetLiveAvailableTimeRangeV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetLiveAvailableTimeRangeV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetLiveAvailableTimeRangeV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetLiveAvailableTimeRangeV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetLiveAvailableTimeRangeV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetLiveAvailableTimeRangeV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetLiveAvailableTimeRangeV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetLiveAvailableTimeRangeV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetLiveAvailableTimeRangeV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            available: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
            nextStartAt: jspb.Message.getFieldWithDefault(msg, 2, 0),
            nextEndAt: jspb.Message.getFieldWithDefault(msg, 3, 0),
            message: jspb.Message.getFieldWithDefault(msg, 4, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetLiveAvailableTimeRangeV1Response}
 */
proto.jp.singcolor.GetLiveAvailableTimeRangeV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetLiveAvailableTimeRangeV1Response;
    return proto.jp.singcolor.GetLiveAvailableTimeRangeV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetLiveAvailableTimeRangeV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetLiveAvailableTimeRangeV1Response}
 */
proto.jp.singcolor.GetLiveAvailableTimeRangeV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setAvailable(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setNextStartAt(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setNextEndAt(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setMessage(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetLiveAvailableTimeRangeV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetLiveAvailableTimeRangeV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetLiveAvailableTimeRangeV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetLiveAvailableTimeRangeV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getAvailable();
    if (f) {
        writer.writeBool(1, f);
    }
    f = message.getNextStartAt();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getNextEndAt();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
    f = message.getMessage();
    if (f.length > 0) {
        writer.writeString(4, f);
    }
};
/**
 * optional bool available = 1;
 * @return {boolean}
 */
proto.jp.singcolor.GetLiveAvailableTimeRangeV1Response.prototype.getAvailable = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.GetLiveAvailableTimeRangeV1Response} returns this
 */
proto.jp.singcolor.GetLiveAvailableTimeRangeV1Response.prototype.setAvailable = function (value) {
    return jspb.Message.setProto3BooleanField(this, 1, value);
};
/**
 * optional int64 next_start_at = 2;
 * @return {number}
 */
proto.jp.singcolor.GetLiveAvailableTimeRangeV1Response.prototype.getNextStartAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetLiveAvailableTimeRangeV1Response} returns this
 */
proto.jp.singcolor.GetLiveAvailableTimeRangeV1Response.prototype.setNextStartAt = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional int64 next_end_at = 3;
 * @return {number}
 */
proto.jp.singcolor.GetLiveAvailableTimeRangeV1Response.prototype.getNextEndAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetLiveAvailableTimeRangeV1Response} returns this
 */
proto.jp.singcolor.GetLiveAvailableTimeRangeV1Response.prototype.setNextEndAt = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional string message = 4;
 * @return {string}
 */
proto.jp.singcolor.GetLiveAvailableTimeRangeV1Response.prototype.getMessage = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetLiveAvailableTimeRangeV1Response} returns this
 */
proto.jp.singcolor.GetLiveAvailableTimeRangeV1Response.prototype.setMessage = function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.BanListenerCommentV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.BanListenerCommentV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.BanListenerCommentV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.BanListenerCommentV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            listenerId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.BanListenerCommentV1Request}
 */
proto.jp.singcolor.BanListenerCommentV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.BanListenerCommentV1Request;
    return proto.jp.singcolor.BanListenerCommentV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.BanListenerCommentV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.BanListenerCommentV1Request}
 */
proto.jp.singcolor.BanListenerCommentV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setListenerId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.BanListenerCommentV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.BanListenerCommentV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.BanListenerCommentV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.BanListenerCommentV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getListenerId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string listener_id = 1;
 * @return {string}
 */
proto.jp.singcolor.BanListenerCommentV1Request.prototype.getListenerId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.BanListenerCommentV1Request} returns this
 */
proto.jp.singcolor.BanListenerCommentV1Request.prototype.setListenerId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.BanListenerCommentV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.BanListenerCommentV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.BanListenerCommentV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.BanListenerCommentV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.BanListenerCommentV1Response}
 */
proto.jp.singcolor.BanListenerCommentV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.BanListenerCommentV1Response;
    return proto.jp.singcolor.BanListenerCommentV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.BanListenerCommentV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.BanListenerCommentV1Response}
 */
proto.jp.singcolor.BanListenerCommentV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.BanListenerCommentV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.BanListenerCommentV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.BanListenerCommentV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.BanListenerCommentV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UnbanListenerCommentV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UnbanListenerCommentV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UnbanListenerCommentV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UnbanListenerCommentV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            listenerId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UnbanListenerCommentV1Request}
 */
proto.jp.singcolor.UnbanListenerCommentV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UnbanListenerCommentV1Request;
    return proto.jp.singcolor.UnbanListenerCommentV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UnbanListenerCommentV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UnbanListenerCommentV1Request}
 */
proto.jp.singcolor.UnbanListenerCommentV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setListenerId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UnbanListenerCommentV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UnbanListenerCommentV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UnbanListenerCommentV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UnbanListenerCommentV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getListenerId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string listener_id = 1;
 * @return {string}
 */
proto.jp.singcolor.UnbanListenerCommentV1Request.prototype.getListenerId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UnbanListenerCommentV1Request} returns this
 */
proto.jp.singcolor.UnbanListenerCommentV1Request.prototype.setListenerId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UnbanListenerCommentV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UnbanListenerCommentV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UnbanListenerCommentV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UnbanListenerCommentV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UnbanListenerCommentV1Response}
 */
proto.jp.singcolor.UnbanListenerCommentV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UnbanListenerCommentV1Response;
    return proto.jp.singcolor.UnbanListenerCommentV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UnbanListenerCommentV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UnbanListenerCommentV1Response}
 */
proto.jp.singcolor.UnbanListenerCommentV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UnbanListenerCommentV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UnbanListenerCommentV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UnbanListenerCommentV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UnbanListenerCommentV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetBannedListenerCommentV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetBannedListenerCommentV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetBannedListenerCommentV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetBannedListenerCommentV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            liverId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            listenerId: jspb.Message.getFieldWithDefault(msg, 2, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetBannedListenerCommentV1Request}
 */
proto.jp.singcolor.GetBannedListenerCommentV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetBannedListenerCommentV1Request;
    return proto.jp.singcolor.GetBannedListenerCommentV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetBannedListenerCommentV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetBannedListenerCommentV1Request}
 */
proto.jp.singcolor.GetBannedListenerCommentV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiverId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setListenerId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetBannedListenerCommentV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetBannedListenerCommentV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetBannedListenerCommentV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetBannedListenerCommentV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiverId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getListenerId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
};
/**
 * optional string liver_id = 1;
 * @return {string}
 */
proto.jp.singcolor.GetBannedListenerCommentV1Request.prototype.getLiverId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetBannedListenerCommentV1Request} returns this
 */
proto.jp.singcolor.GetBannedListenerCommentV1Request.prototype.setLiverId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string listener_id = 2;
 * @return {string}
 */
proto.jp.singcolor.GetBannedListenerCommentV1Request.prototype.getListenerId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetBannedListenerCommentV1Request} returns this
 */
proto.jp.singcolor.GetBannedListenerCommentV1Request.prototype.setListenerId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetBannedListenerCommentV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetBannedListenerCommentV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetBannedListenerCommentV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetBannedListenerCommentV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            liverId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            listenerId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            isBanned: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetBannedListenerCommentV1Response}
 */
proto.jp.singcolor.GetBannedListenerCommentV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetBannedListenerCommentV1Response;
    return proto.jp.singcolor.GetBannedListenerCommentV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetBannedListenerCommentV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetBannedListenerCommentV1Response}
 */
proto.jp.singcolor.GetBannedListenerCommentV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiverId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setListenerId(value);
                break;
            case 3:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setIsBanned(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetBannedListenerCommentV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetBannedListenerCommentV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetBannedListenerCommentV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetBannedListenerCommentV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiverId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getListenerId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getIsBanned();
    if (f) {
        writer.writeBool(3, f);
    }
};
/**
 * optional string liver_id = 1;
 * @return {string}
 */
proto.jp.singcolor.GetBannedListenerCommentV1Response.prototype.getLiverId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetBannedListenerCommentV1Response} returns this
 */
proto.jp.singcolor.GetBannedListenerCommentV1Response.prototype.setLiverId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string listener_id = 2;
 * @return {string}
 */
proto.jp.singcolor.GetBannedListenerCommentV1Response.prototype.getListenerId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetBannedListenerCommentV1Response} returns this
 */
proto.jp.singcolor.GetBannedListenerCommentV1Response.prototype.setListenerId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional bool is_banned = 3;
 * @return {boolean}
 */
proto.jp.singcolor.GetBannedListenerCommentV1Response.prototype.getIsBanned = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.GetBannedListenerCommentV1Response} returns this
 */
proto.jp.singcolor.GetBannedListenerCommentV1Response.prototype.setIsBanned = function (value) {
    return jspb.Message.setProto3BooleanField(this, 3, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.BanListenerReqCaptureV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.BanListenerReqCaptureV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.BanListenerReqCaptureV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.BanListenerReqCaptureV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            listenerId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.BanListenerReqCaptureV1Request}
 */
proto.jp.singcolor.BanListenerReqCaptureV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.BanListenerReqCaptureV1Request;
    return proto.jp.singcolor.BanListenerReqCaptureV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.BanListenerReqCaptureV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.BanListenerReqCaptureV1Request}
 */
proto.jp.singcolor.BanListenerReqCaptureV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setListenerId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.BanListenerReqCaptureV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.BanListenerReqCaptureV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.BanListenerReqCaptureV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.BanListenerReqCaptureV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getListenerId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string listener_id = 1;
 * @return {string}
 */
proto.jp.singcolor.BanListenerReqCaptureV1Request.prototype.getListenerId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.BanListenerReqCaptureV1Request} returns this
 */
proto.jp.singcolor.BanListenerReqCaptureV1Request.prototype.setListenerId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.BanListenerReqCaptureV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.BanListenerReqCaptureV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.BanListenerReqCaptureV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.BanListenerReqCaptureV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.BanListenerReqCaptureV1Response}
 */
proto.jp.singcolor.BanListenerReqCaptureV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.BanListenerReqCaptureV1Response;
    return proto.jp.singcolor.BanListenerReqCaptureV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.BanListenerReqCaptureV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.BanListenerReqCaptureV1Response}
 */
proto.jp.singcolor.BanListenerReqCaptureV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.BanListenerReqCaptureV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.BanListenerReqCaptureV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.BanListenerReqCaptureV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.BanListenerReqCaptureV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UnbanListenerReqCaptureV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UnbanListenerReqCaptureV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UnbanListenerReqCaptureV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UnbanListenerReqCaptureV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            listenerId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UnbanListenerReqCaptureV1Request}
 */
proto.jp.singcolor.UnbanListenerReqCaptureV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UnbanListenerReqCaptureV1Request;
    return proto.jp.singcolor.UnbanListenerReqCaptureV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UnbanListenerReqCaptureV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UnbanListenerReqCaptureV1Request}
 */
proto.jp.singcolor.UnbanListenerReqCaptureV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setListenerId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UnbanListenerReqCaptureV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UnbanListenerReqCaptureV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UnbanListenerReqCaptureV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UnbanListenerReqCaptureV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getListenerId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string listener_id = 1;
 * @return {string}
 */
proto.jp.singcolor.UnbanListenerReqCaptureV1Request.prototype.getListenerId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UnbanListenerReqCaptureV1Request} returns this
 */
proto.jp.singcolor.UnbanListenerReqCaptureV1Request.prototype.setListenerId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UnbanListenerReqCaptureV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UnbanListenerReqCaptureV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UnbanListenerReqCaptureV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UnbanListenerReqCaptureV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UnbanListenerReqCaptureV1Response}
 */
proto.jp.singcolor.UnbanListenerReqCaptureV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UnbanListenerReqCaptureV1Response;
    return proto.jp.singcolor.UnbanListenerReqCaptureV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UnbanListenerReqCaptureV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UnbanListenerReqCaptureV1Response}
 */
proto.jp.singcolor.UnbanListenerReqCaptureV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UnbanListenerReqCaptureV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UnbanListenerReqCaptureV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UnbanListenerReqCaptureV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UnbanListenerReqCaptureV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetBannedListenerReqCaptureV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetBannedListenerReqCaptureV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetBannedListenerReqCaptureV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetBannedListenerReqCaptureV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            liverId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            listenerId: jspb.Message.getFieldWithDefault(msg, 2, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetBannedListenerReqCaptureV1Request}
 */
proto.jp.singcolor.GetBannedListenerReqCaptureV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetBannedListenerReqCaptureV1Request;
    return proto.jp.singcolor.GetBannedListenerReqCaptureV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetBannedListenerReqCaptureV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetBannedListenerReqCaptureV1Request}
 */
proto.jp.singcolor.GetBannedListenerReqCaptureV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiverId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setListenerId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetBannedListenerReqCaptureV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetBannedListenerReqCaptureV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetBannedListenerReqCaptureV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetBannedListenerReqCaptureV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiverId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getListenerId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
};
/**
 * optional string liver_id = 1;
 * @return {string}
 */
proto.jp.singcolor.GetBannedListenerReqCaptureV1Request.prototype.getLiverId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetBannedListenerReqCaptureV1Request} returns this
 */
proto.jp.singcolor.GetBannedListenerReqCaptureV1Request.prototype.setLiverId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string listener_id = 2;
 * @return {string}
 */
proto.jp.singcolor.GetBannedListenerReqCaptureV1Request.prototype.getListenerId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetBannedListenerReqCaptureV1Request} returns this
 */
proto.jp.singcolor.GetBannedListenerReqCaptureV1Request.prototype.setListenerId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetBannedListenerReqCaptureV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetBannedListenerReqCaptureV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetBannedListenerReqCaptureV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetBannedListenerReqCaptureV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            liverId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            listenerId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            isBanned: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetBannedListenerReqCaptureV1Response}
 */
proto.jp.singcolor.GetBannedListenerReqCaptureV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetBannedListenerReqCaptureV1Response;
    return proto.jp.singcolor.GetBannedListenerReqCaptureV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetBannedListenerReqCaptureV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetBannedListenerReqCaptureV1Response}
 */
proto.jp.singcolor.GetBannedListenerReqCaptureV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiverId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setListenerId(value);
                break;
            case 3:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setIsBanned(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetBannedListenerReqCaptureV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetBannedListenerReqCaptureV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetBannedListenerReqCaptureV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetBannedListenerReqCaptureV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiverId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getListenerId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getIsBanned();
    if (f) {
        writer.writeBool(3, f);
    }
};
/**
 * optional string liver_id = 1;
 * @return {string}
 */
proto.jp.singcolor.GetBannedListenerReqCaptureV1Response.prototype.getLiverId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetBannedListenerReqCaptureV1Response} returns this
 */
proto.jp.singcolor.GetBannedListenerReqCaptureV1Response.prototype.setLiverId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string listener_id = 2;
 * @return {string}
 */
proto.jp.singcolor.GetBannedListenerReqCaptureV1Response.prototype.getListenerId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetBannedListenerReqCaptureV1Response} returns this
 */
proto.jp.singcolor.GetBannedListenerReqCaptureV1Response.prototype.setListenerId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional bool is_banned = 3;
 * @return {boolean}
 */
proto.jp.singcolor.GetBannedListenerReqCaptureV1Response.prototype.getIsBanned = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.GetBannedListenerReqCaptureV1Response} returns this
 */
proto.jp.singcolor.GetBannedListenerReqCaptureV1Response.prototype.setIsBanned = function (value) {
    return jspb.Message.setProto3BooleanField(this, 3, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetNextFavePointsProgressV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetNextFavePointsProgressV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetNextFavePointsProgressV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetNextFavePointsProgressV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            liverId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            listenerId: jspb.Message.getFieldWithDefault(msg, 2, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetNextFavePointsProgressV1Request}
 */
proto.jp.singcolor.GetNextFavePointsProgressV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetNextFavePointsProgressV1Request;
    return proto.jp.singcolor.GetNextFavePointsProgressV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetNextFavePointsProgressV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetNextFavePointsProgressV1Request}
 */
proto.jp.singcolor.GetNextFavePointsProgressV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiverId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setListenerId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetNextFavePointsProgressV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetNextFavePointsProgressV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetNextFavePointsProgressV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetNextFavePointsProgressV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiverId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getListenerId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
};
/**
 * optional string liver_id = 1;
 * @return {string}
 */
proto.jp.singcolor.GetNextFavePointsProgressV1Request.prototype.getLiverId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetNextFavePointsProgressV1Request} returns this
 */
proto.jp.singcolor.GetNextFavePointsProgressV1Request.prototype.setLiverId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string listener_id = 2;
 * @return {string}
 */
proto.jp.singcolor.GetNextFavePointsProgressV1Request.prototype.getListenerId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetNextFavePointsProgressV1Request} returns this
 */
proto.jp.singcolor.GetNextFavePointsProgressV1Request.prototype.setListenerId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetNextFavePointsProgressV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetNextFavePointsProgressV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetNextFavePointsProgressV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetNextFavePointsProgressV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            nextFavePoints: jspb.Message.getFieldWithDefault(msg, 1, 0),
            currentUsedCoin: jspb.Message.getFieldWithDefault(msg, 2, 0),
            currentViewingTimeDuration: jspb.Message.getFieldWithDefault(msg, 3, 0),
            currentMore5timesSuperLikeDays: jspb.Message.getFieldWithDefault(msg, 4, 0),
            beforeUsedCoin: jspb.Message.getFieldWithDefault(msg, 5, 0),
            beforeViewingTimeDuration: jspb.Message.getFieldWithDefault(msg, 6, 0),
            beforeMore5timesSuperLikeDays: jspb.Message.getFieldWithDefault(msg, 7, 0),
            pattern1NextRequiredUsedCoin: jspb.Message.getFieldWithDefault(msg, 8, 0),
            pattern1NextRequiredViewingTimeDuration: jspb.Message.getFieldWithDefault(msg, 9, 0),
            pattern1NextRequiredMore5timesSuperLikeDays: jspb.Message.getFieldWithDefault(msg, 10, 0),
            pattern2NextRequiredUsedCoin: jspb.Message.getFieldWithDefault(msg, 11, 0),
            pattern2NextRequiredViewingTimeDuration: jspb.Message.getFieldWithDefault(msg, 12, 0),
            pattern2NextRequiredMore5timesSuperLikeDays: jspb.Message.getFieldWithDefault(msg, 13, 0),
            pattern3NextRequiredUsedCoin: jspb.Message.getFieldWithDefault(msg, 14, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetNextFavePointsProgressV1Response}
 */
proto.jp.singcolor.GetNextFavePointsProgressV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetNextFavePointsProgressV1Response;
    return proto.jp.singcolor.GetNextFavePointsProgressV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetNextFavePointsProgressV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetNextFavePointsProgressV1Response}
 */
proto.jp.singcolor.GetNextFavePointsProgressV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setNextFavePoints(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setCurrentUsedCoin(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setCurrentViewingTimeDuration(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setCurrentMore5timesSuperLikeDays(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setBeforeUsedCoin(value);
                break;
            case 6:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setBeforeViewingTimeDuration(value);
                break;
            case 7:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setBeforeMore5timesSuperLikeDays(value);
                break;
            case 8:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setPattern1NextRequiredUsedCoin(value);
                break;
            case 9:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setPattern1NextRequiredViewingTimeDuration(value);
                break;
            case 10:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setPattern1NextRequiredMore5timesSuperLikeDays(value);
                break;
            case 11:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setPattern2NextRequiredUsedCoin(value);
                break;
            case 12:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setPattern2NextRequiredViewingTimeDuration(value);
                break;
            case 13:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setPattern2NextRequiredMore5timesSuperLikeDays(value);
                break;
            case 14:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setPattern3NextRequiredUsedCoin(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetNextFavePointsProgressV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetNextFavePointsProgressV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetNextFavePointsProgressV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetNextFavePointsProgressV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getNextFavePoints();
    if (f !== 0) {
        writer.writeInt64(1, f);
    }
    f = message.getCurrentUsedCoin();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getCurrentViewingTimeDuration();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
    f = message.getCurrentMore5timesSuperLikeDays();
    if (f !== 0) {
        writer.writeInt64(4, f);
    }
    f = message.getBeforeUsedCoin();
    if (f !== 0) {
        writer.writeInt64(5, f);
    }
    f = message.getBeforeViewingTimeDuration();
    if (f !== 0) {
        writer.writeInt64(6, f);
    }
    f = message.getBeforeMore5timesSuperLikeDays();
    if (f !== 0) {
        writer.writeInt64(7, f);
    }
    f = message.getPattern1NextRequiredUsedCoin();
    if (f !== 0) {
        writer.writeInt64(8, f);
    }
    f = message.getPattern1NextRequiredViewingTimeDuration();
    if (f !== 0) {
        writer.writeInt64(9, f);
    }
    f = message.getPattern1NextRequiredMore5timesSuperLikeDays();
    if (f !== 0) {
        writer.writeInt64(10, f);
    }
    f = message.getPattern2NextRequiredUsedCoin();
    if (f !== 0) {
        writer.writeInt64(11, f);
    }
    f = message.getPattern2NextRequiredViewingTimeDuration();
    if (f !== 0) {
        writer.writeInt64(12, f);
    }
    f = message.getPattern2NextRequiredMore5timesSuperLikeDays();
    if (f !== 0) {
        writer.writeInt64(13, f);
    }
    f = message.getPattern3NextRequiredUsedCoin();
    if (f !== 0) {
        writer.writeInt64(14, f);
    }
};
/**
 * optional int64 next_fave_points = 1;
 * @return {number}
 */
proto.jp.singcolor.GetNextFavePointsProgressV1Response.prototype.getNextFavePoints = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetNextFavePointsProgressV1Response} returns this
 */
proto.jp.singcolor.GetNextFavePointsProgressV1Response.prototype.setNextFavePoints = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
};
/**
 * optional int64 current_used_coin = 2;
 * @return {number}
 */
proto.jp.singcolor.GetNextFavePointsProgressV1Response.prototype.getCurrentUsedCoin = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetNextFavePointsProgressV1Response} returns this
 */
proto.jp.singcolor.GetNextFavePointsProgressV1Response.prototype.setCurrentUsedCoin = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional int64 current_viewing_time_duration = 3;
 * @return {number}
 */
proto.jp.singcolor.GetNextFavePointsProgressV1Response.prototype.getCurrentViewingTimeDuration = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetNextFavePointsProgressV1Response} returns this
 */
proto.jp.singcolor.GetNextFavePointsProgressV1Response.prototype.setCurrentViewingTimeDuration = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional int64 current_more_5times_super_like_days = 4;
 * @return {number}
 */
proto.jp.singcolor.GetNextFavePointsProgressV1Response.prototype.getCurrentMore5timesSuperLikeDays = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetNextFavePointsProgressV1Response} returns this
 */
proto.jp.singcolor.GetNextFavePointsProgressV1Response.prototype.setCurrentMore5timesSuperLikeDays = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * optional int64 before_used_coin = 5;
 * @return {number}
 */
proto.jp.singcolor.GetNextFavePointsProgressV1Response.prototype.getBeforeUsedCoin = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetNextFavePointsProgressV1Response} returns this
 */
proto.jp.singcolor.GetNextFavePointsProgressV1Response.prototype.setBeforeUsedCoin = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
/**
 * optional int64 before_viewing_time_duration = 6;
 * @return {number}
 */
proto.jp.singcolor.GetNextFavePointsProgressV1Response.prototype.getBeforeViewingTimeDuration = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetNextFavePointsProgressV1Response} returns this
 */
proto.jp.singcolor.GetNextFavePointsProgressV1Response.prototype.setBeforeViewingTimeDuration = function (value) {
    return jspb.Message.setProto3IntField(this, 6, value);
};
/**
 * optional int64 before_more_5times_super_like_days = 7;
 * @return {number}
 */
proto.jp.singcolor.GetNextFavePointsProgressV1Response.prototype.getBeforeMore5timesSuperLikeDays = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetNextFavePointsProgressV1Response} returns this
 */
proto.jp.singcolor.GetNextFavePointsProgressV1Response.prototype.setBeforeMore5timesSuperLikeDays = function (value) {
    return jspb.Message.setProto3IntField(this, 7, value);
};
/**
 * optional int64 pattern1_next_required_used_coin = 8;
 * @return {number}
 */
proto.jp.singcolor.GetNextFavePointsProgressV1Response.prototype.getPattern1NextRequiredUsedCoin = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetNextFavePointsProgressV1Response} returns this
 */
proto.jp.singcolor.GetNextFavePointsProgressV1Response.prototype.setPattern1NextRequiredUsedCoin = function (value) {
    return jspb.Message.setProto3IntField(this, 8, value);
};
/**
 * optional int64 pattern1_next_required_viewing_time_duration = 9;
 * @return {number}
 */
proto.jp.singcolor.GetNextFavePointsProgressV1Response.prototype.getPattern1NextRequiredViewingTimeDuration = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetNextFavePointsProgressV1Response} returns this
 */
proto.jp.singcolor.GetNextFavePointsProgressV1Response.prototype.setPattern1NextRequiredViewingTimeDuration = function (value) {
    return jspb.Message.setProto3IntField(this, 9, value);
};
/**
 * optional int64 pattern1_next_required_more_5times_super_like_days = 10;
 * @return {number}
 */
proto.jp.singcolor.GetNextFavePointsProgressV1Response.prototype.getPattern1NextRequiredMore5timesSuperLikeDays = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetNextFavePointsProgressV1Response} returns this
 */
proto.jp.singcolor.GetNextFavePointsProgressV1Response.prototype.setPattern1NextRequiredMore5timesSuperLikeDays = function (value) {
    return jspb.Message.setProto3IntField(this, 10, value);
};
/**
 * optional int64 pattern2_next_required_used_coin = 11;
 * @return {number}
 */
proto.jp.singcolor.GetNextFavePointsProgressV1Response.prototype.getPattern2NextRequiredUsedCoin = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetNextFavePointsProgressV1Response} returns this
 */
proto.jp.singcolor.GetNextFavePointsProgressV1Response.prototype.setPattern2NextRequiredUsedCoin = function (value) {
    return jspb.Message.setProto3IntField(this, 11, value);
};
/**
 * optional int64 pattern2_next_required_viewing_time_duration = 12;
 * @return {number}
 */
proto.jp.singcolor.GetNextFavePointsProgressV1Response.prototype.getPattern2NextRequiredViewingTimeDuration = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetNextFavePointsProgressV1Response} returns this
 */
proto.jp.singcolor.GetNextFavePointsProgressV1Response.prototype.setPattern2NextRequiredViewingTimeDuration = function (value) {
    return jspb.Message.setProto3IntField(this, 12, value);
};
/**
 * optional int64 pattern2_next_required_more_5times_super_like_days = 13;
 * @return {number}
 */
proto.jp.singcolor.GetNextFavePointsProgressV1Response.prototype.getPattern2NextRequiredMore5timesSuperLikeDays = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetNextFavePointsProgressV1Response} returns this
 */
proto.jp.singcolor.GetNextFavePointsProgressV1Response.prototype.setPattern2NextRequiredMore5timesSuperLikeDays = function (value) {
    return jspb.Message.setProto3IntField(this, 13, value);
};
/**
 * optional int64 pattern3_next_required_used_coin = 14;
 * @return {number}
 */
proto.jp.singcolor.GetNextFavePointsProgressV1Response.prototype.getPattern3NextRequiredUsedCoin = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetNextFavePointsProgressV1Response} returns this
 */
proto.jp.singcolor.GetNextFavePointsProgressV1Response.prototype.setPattern3NextRequiredUsedCoin = function (value) {
    return jspb.Message.setProto3IntField(this, 14, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListLiveTimelineBannersV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListLiveTimelineBannersV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListLiveTimelineBannersV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListLiveTimelineBannersV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            timelineId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            gender: jspb.Message.getFieldWithDefault(msg, 2, 0),
            worldFilter: jspb.Message.getFieldWithDefault(msg, 3, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListLiveTimelineBannersV1Request}
 */
proto.jp.singcolor.ListLiveTimelineBannersV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListLiveTimelineBannersV1Request;
    return proto.jp.singcolor.ListLiveTimelineBannersV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListLiveTimelineBannersV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListLiveTimelineBannersV1Request}
 */
proto.jp.singcolor.ListLiveTimelineBannersV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setTimelineId(value);
                break;
            case 2:
                var value = /** @type {!proto.jp.singcolor.Gender} */ (reader.readEnum());
                msg.setGender(value);
                break;
            case 3:
                var value = /** @type {!proto.jp.singcolor.WorldFilter} */ (reader.readEnum());
                msg.setWorldFilter(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListLiveTimelineBannersV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListLiveTimelineBannersV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListLiveTimelineBannersV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListLiveTimelineBannersV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getTimelineId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getGender();
    if (f !== 0.0) {
        writer.writeEnum(2, f);
    }
    f = message.getWorldFilter();
    if (f !== 0.0) {
        writer.writeEnum(3, f);
    }
};
/**
 * optional string timeline_id = 1;
 * @return {string}
 */
proto.jp.singcolor.ListLiveTimelineBannersV1Request.prototype.getTimelineId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListLiveTimelineBannersV1Request} returns this
 */
proto.jp.singcolor.ListLiveTimelineBannersV1Request.prototype.setTimelineId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional Gender gender = 2;
 * @return {!proto.jp.singcolor.Gender}
 */
proto.jp.singcolor.ListLiveTimelineBannersV1Request.prototype.getGender = function () {
    return /** @type {!proto.jp.singcolor.Gender} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {!proto.jp.singcolor.Gender} value
 * @return {!proto.jp.singcolor.ListLiveTimelineBannersV1Request} returns this
 */
proto.jp.singcolor.ListLiveTimelineBannersV1Request.prototype.setGender = function (value) {
    return jspb.Message.setProto3EnumField(this, 2, value);
};
/**
 * optional WorldFilter world_filter = 3;
 * @return {!proto.jp.singcolor.WorldFilter}
 */
proto.jp.singcolor.ListLiveTimelineBannersV1Request.prototype.getWorldFilter = function () {
    return /** @type {!proto.jp.singcolor.WorldFilter} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {!proto.jp.singcolor.WorldFilter} value
 * @return {!proto.jp.singcolor.ListLiveTimelineBannersV1Request} returns this
 */
proto.jp.singcolor.ListLiveTimelineBannersV1Request.prototype.setWorldFilter = function (value) {
    return jspb.Message.setProto3EnumField(this, 3, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListLiveTimelineBannersV1Response.repeatedFields_ = [5];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListLiveTimelineBannersV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListLiveTimelineBannersV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListLiveTimelineBannersV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListLiveTimelineBannersV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            bannersList: jspb.Message.toObjectList(msg.getBannersList(), lives_entity_pb.LiveTimelineBanner.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListLiveTimelineBannersV1Response}
 */
proto.jp.singcolor.ListLiveTimelineBannersV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListLiveTimelineBannersV1Response;
    return proto.jp.singcolor.ListLiveTimelineBannersV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListLiveTimelineBannersV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListLiveTimelineBannersV1Response}
 */
proto.jp.singcolor.ListLiveTimelineBannersV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 5:
                var value = new lives_entity_pb.LiveTimelineBanner;
                reader.readMessage(value, lives_entity_pb.LiveTimelineBanner.deserializeBinaryFromReader);
                msg.addBanners(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListLiveTimelineBannersV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListLiveTimelineBannersV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListLiveTimelineBannersV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListLiveTimelineBannersV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getBannersList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(5, f, lives_entity_pb.LiveTimelineBanner.serializeBinaryToWriter);
    }
};
/**
 * repeated LiveTimelineBanner banners = 5;
 * @return {!Array<!proto.jp.singcolor.LiveTimelineBanner>}
 */
proto.jp.singcolor.ListLiveTimelineBannersV1Response.prototype.getBannersList = function () {
    return /** @type{!Array<!proto.jp.singcolor.LiveTimelineBanner>} */ (jspb.Message.getRepeatedWrapperField(this, lives_entity_pb.LiveTimelineBanner, 5));
};
/**
 * @param {!Array<!proto.jp.singcolor.LiveTimelineBanner>} value
 * @return {!proto.jp.singcolor.ListLiveTimelineBannersV1Response} returns this
*/
proto.jp.singcolor.ListLiveTimelineBannersV1Response.prototype.setBannersList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 5, value);
};
/**
 * @param {!proto.jp.singcolor.LiveTimelineBanner=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.LiveTimelineBanner}
 */
proto.jp.singcolor.ListLiveTimelineBannersV1Response.prototype.addBanners = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.jp.singcolor.LiveTimelineBanner, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListLiveTimelineBannersV1Response} returns this
 */
proto.jp.singcolor.ListLiveTimelineBannersV1Response.prototype.clearBannersList = function () {
    return this.setBannersList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetRankOfRankingEventV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetRankOfRankingEventV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetRankOfRankingEventV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetRankOfRankingEventV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            liveId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetRankOfRankingEventV1Request}
 */
proto.jp.singcolor.GetRankOfRankingEventV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetRankOfRankingEventV1Request;
    return proto.jp.singcolor.GetRankOfRankingEventV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetRankOfRankingEventV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetRankOfRankingEventV1Request}
 */
proto.jp.singcolor.GetRankOfRankingEventV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiveId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetRankOfRankingEventV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetRankOfRankingEventV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetRankOfRankingEventV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetRankOfRankingEventV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiveId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string live_id = 1;
 * @return {string}
 */
proto.jp.singcolor.GetRankOfRankingEventV1Request.prototype.getLiveId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetRankOfRankingEventV1Request} returns this
 */
proto.jp.singcolor.GetRankOfRankingEventV1Request.prototype.setLiveId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetRankOfRankingEventV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetRankOfRankingEventV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetRankOfRankingEventV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetRankOfRankingEventV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            liveId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            rankingEventStatus: jspb.Message.getFieldWithDefault(msg, 3, 0),
            rank: jspb.Message.getFieldWithDefault(msg, 4, 0),
            rankTextImageUrl: jspb.Message.getFieldWithDefault(msg, 8, ""),
            secondsUntilEnd: jspb.Message.getFieldWithDefault(msg, 5, 0),
            secondsUntilNextEventStart: jspb.Message.getFieldWithDefault(msg, 9, 0),
            acquiredLiveScore: jspb.Message.getFieldWithDefault(msg, 6, 0),
            thumbnailImageUrl: jspb.Message.getFieldWithDefault(msg, 7, ""),
            iconClickTransitionDestination: jspb.Message.getFieldWithDefault(msg, 10, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetRankOfRankingEventV1Response}
 */
proto.jp.singcolor.GetRankOfRankingEventV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetRankOfRankingEventV1Response;
    return proto.jp.singcolor.GetRankOfRankingEventV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetRankOfRankingEventV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetRankOfRankingEventV1Response}
 */
proto.jp.singcolor.GetRankOfRankingEventV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiveId(value);
                break;
            case 3:
                var value = /** @type {!proto.jp.singcolor.RankingEventStatus} */ (reader.readEnum());
                msg.setRankingEventStatus(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setRank(value);
                break;
            case 8:
                var value = /** @type {string} */ (reader.readString());
                msg.setRankTextImageUrl(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setSecondsUntilEnd(value);
                break;
            case 9:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setSecondsUntilNextEventStart(value);
                break;
            case 6:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setAcquiredLiveScore(value);
                break;
            case 7:
                var value = /** @type {string} */ (reader.readString());
                msg.setThumbnailImageUrl(value);
                break;
            case 10:
                var value = /** @type {!proto.jp.singcolor.GetRankOfRankingEventV1Response.IconClickTransitionDestination} */ (reader.readEnum());
                msg.setIconClickTransitionDestination(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetRankOfRankingEventV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetRankOfRankingEventV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetRankOfRankingEventV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetRankOfRankingEventV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiveId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getRankingEventStatus();
    if (f !== 0.0) {
        writer.writeEnum(3, f);
    }
    f = message.getRank();
    if (f !== 0) {
        writer.writeInt64(4, f);
    }
    f = message.getRankTextImageUrl();
    if (f.length > 0) {
        writer.writeString(8, f);
    }
    f = message.getSecondsUntilEnd();
    if (f !== 0) {
        writer.writeInt64(5, f);
    }
    f = message.getSecondsUntilNextEventStart();
    if (f !== 0) {
        writer.writeInt64(9, f);
    }
    f = message.getAcquiredLiveScore();
    if (f !== 0) {
        writer.writeInt64(6, f);
    }
    f = message.getThumbnailImageUrl();
    if (f.length > 0) {
        writer.writeString(7, f);
    }
    f = message.getIconClickTransitionDestination();
    if (f !== 0.0) {
        writer.writeEnum(10, f);
    }
};
/**
 * @enum {number}
 */
proto.jp.singcolor.GetRankOfRankingEventV1Response.IconClickTransitionDestination = {
    NONE: 0,
    RANKING_PAGE_PEARL_CUP_TAB: 1,
    RANKING_OF_EVENT_PAGE: 2
};
/**
 * optional string live_id = 1;
 * @return {string}
 */
proto.jp.singcolor.GetRankOfRankingEventV1Response.prototype.getLiveId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetRankOfRankingEventV1Response} returns this
 */
proto.jp.singcolor.GetRankOfRankingEventV1Response.prototype.setLiveId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional RankingEventStatus ranking_event_status = 3;
 * @return {!proto.jp.singcolor.RankingEventStatus}
 */
proto.jp.singcolor.GetRankOfRankingEventV1Response.prototype.getRankingEventStatus = function () {
    return /** @type {!proto.jp.singcolor.RankingEventStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {!proto.jp.singcolor.RankingEventStatus} value
 * @return {!proto.jp.singcolor.GetRankOfRankingEventV1Response} returns this
 */
proto.jp.singcolor.GetRankOfRankingEventV1Response.prototype.setRankingEventStatus = function (value) {
    return jspb.Message.setProto3EnumField(this, 3, value);
};
/**
 * optional int64 rank = 4;
 * @return {number}
 */
proto.jp.singcolor.GetRankOfRankingEventV1Response.prototype.getRank = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetRankOfRankingEventV1Response} returns this
 */
proto.jp.singcolor.GetRankOfRankingEventV1Response.prototype.setRank = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * optional string rank_text_image_url = 8;
 * @return {string}
 */
proto.jp.singcolor.GetRankOfRankingEventV1Response.prototype.getRankTextImageUrl = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetRankOfRankingEventV1Response} returns this
 */
proto.jp.singcolor.GetRankOfRankingEventV1Response.prototype.setRankTextImageUrl = function (value) {
    return jspb.Message.setProto3StringField(this, 8, value);
};
/**
 * optional int64 seconds_until_end = 5;
 * @return {number}
 */
proto.jp.singcolor.GetRankOfRankingEventV1Response.prototype.getSecondsUntilEnd = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetRankOfRankingEventV1Response} returns this
 */
proto.jp.singcolor.GetRankOfRankingEventV1Response.prototype.setSecondsUntilEnd = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
/**
 * optional int64 seconds_until_next_event_start = 9;
 * @return {number}
 */
proto.jp.singcolor.GetRankOfRankingEventV1Response.prototype.getSecondsUntilNextEventStart = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetRankOfRankingEventV1Response} returns this
 */
proto.jp.singcolor.GetRankOfRankingEventV1Response.prototype.setSecondsUntilNextEventStart = function (value) {
    return jspb.Message.setProto3IntField(this, 9, value);
};
/**
 * optional int64 acquired_live_score = 6;
 * @return {number}
 */
proto.jp.singcolor.GetRankOfRankingEventV1Response.prototype.getAcquiredLiveScore = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetRankOfRankingEventV1Response} returns this
 */
proto.jp.singcolor.GetRankOfRankingEventV1Response.prototype.setAcquiredLiveScore = function (value) {
    return jspb.Message.setProto3IntField(this, 6, value);
};
/**
 * optional string thumbnail_image_url = 7;
 * @return {string}
 */
proto.jp.singcolor.GetRankOfRankingEventV1Response.prototype.getThumbnailImageUrl = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetRankOfRankingEventV1Response} returns this
 */
proto.jp.singcolor.GetRankOfRankingEventV1Response.prototype.setThumbnailImageUrl = function (value) {
    return jspb.Message.setProto3StringField(this, 7, value);
};
/**
 * optional IconClickTransitionDestination icon_click_transition_destination = 10;
 * @return {!proto.jp.singcolor.GetRankOfRankingEventV1Response.IconClickTransitionDestination}
 */
proto.jp.singcolor.GetRankOfRankingEventV1Response.prototype.getIconClickTransitionDestination = function () {
    return /** @type {!proto.jp.singcolor.GetRankOfRankingEventV1Response.IconClickTransitionDestination} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};
/**
 * @param {!proto.jp.singcolor.GetRankOfRankingEventV1Response.IconClickTransitionDestination} value
 * @return {!proto.jp.singcolor.GetRankOfRankingEventV1Response} returns this
 */
proto.jp.singcolor.GetRankOfRankingEventV1Response.prototype.setIconClickTransitionDestination = function (value) {
    return jspb.Message.setProto3EnumField(this, 10, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.SendHeartfulMentionV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.SendHeartfulMentionV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.SendHeartfulMentionV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.SendHeartfulMentionV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            heartfulMentionId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.SendHeartfulMentionV1Request}
 */
proto.jp.singcolor.SendHeartfulMentionV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.SendHeartfulMentionV1Request;
    return proto.jp.singcolor.SendHeartfulMentionV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.SendHeartfulMentionV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.SendHeartfulMentionV1Request}
 */
proto.jp.singcolor.SendHeartfulMentionV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setHeartfulMentionId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.SendHeartfulMentionV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.SendHeartfulMentionV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.SendHeartfulMentionV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.SendHeartfulMentionV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getHeartfulMentionId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string heartful_mention_id = 1;
 * @return {string}
 */
proto.jp.singcolor.SendHeartfulMentionV1Request.prototype.getHeartfulMentionId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.SendHeartfulMentionV1Request} returns this
 */
proto.jp.singcolor.SendHeartfulMentionV1Request.prototype.setHeartfulMentionId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.SendHeartfulMentionV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.SendHeartfulMentionV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.SendHeartfulMentionV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.SendHeartfulMentionV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            heartfulMention: (f = msg.getHeartfulMention()) && lives_entity_pb.HeartfulMention.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.SendHeartfulMentionV1Response}
 */
proto.jp.singcolor.SendHeartfulMentionV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.SendHeartfulMentionV1Response;
    return proto.jp.singcolor.SendHeartfulMentionV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.SendHeartfulMentionV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.SendHeartfulMentionV1Response}
 */
proto.jp.singcolor.SendHeartfulMentionV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new lives_entity_pb.HeartfulMention;
                reader.readMessage(value, lives_entity_pb.HeartfulMention.deserializeBinaryFromReader);
                msg.setHeartfulMention(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.SendHeartfulMentionV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.SendHeartfulMentionV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.SendHeartfulMentionV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.SendHeartfulMentionV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getHeartfulMention();
    if (f != null) {
        writer.writeMessage(1, f, lives_entity_pb.HeartfulMention.serializeBinaryToWriter);
    }
};
/**
 * optional HeartfulMention heartful_mention = 1;
 * @return {?proto.jp.singcolor.HeartfulMention}
 */
proto.jp.singcolor.SendHeartfulMentionV1Response.prototype.getHeartfulMention = function () {
    return /** @type{?proto.jp.singcolor.HeartfulMention} */ (jspb.Message.getWrapperField(this, lives_entity_pb.HeartfulMention, 1));
};
/**
 * @param {?proto.jp.singcolor.HeartfulMention|undefined} value
 * @return {!proto.jp.singcolor.SendHeartfulMentionV1Response} returns this
*/
proto.jp.singcolor.SendHeartfulMentionV1Response.prototype.setHeartfulMention = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.SendHeartfulMentionV1Response} returns this
 */
proto.jp.singcolor.SendHeartfulMentionV1Response.prototype.clearHeartfulMention = function () {
    return this.setHeartfulMention(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.SendHeartfulMentionV1Response.prototype.hasHeartfulMention = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.PlayYoutubeV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.PlayYoutubeV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.PlayYoutubeV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.PlayYoutubeV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            liveId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            videoId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            title: jspb.Message.getFieldWithDefault(msg, 3, ""),
            author: jspb.Message.getFieldWithDefault(msg, 4, ""),
            adjustmentDelay: jspb.Message.getFieldWithDefault(msg, 5, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.PlayYoutubeV1Request}
 */
proto.jp.singcolor.PlayYoutubeV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.PlayYoutubeV1Request;
    return proto.jp.singcolor.PlayYoutubeV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.PlayYoutubeV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.PlayYoutubeV1Request}
 */
proto.jp.singcolor.PlayYoutubeV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiveId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setVideoId(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setTitle(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setAuthor(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setAdjustmentDelay(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.PlayYoutubeV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.PlayYoutubeV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.PlayYoutubeV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.PlayYoutubeV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiveId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getVideoId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getTitle();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
    f = message.getAuthor();
    if (f.length > 0) {
        writer.writeString(4, f);
    }
    f = message.getAdjustmentDelay();
    if (f !== 0) {
        writer.writeInt64(5, f);
    }
};
/**
 * optional string live_id = 1;
 * @return {string}
 */
proto.jp.singcolor.PlayYoutubeV1Request.prototype.getLiveId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.PlayYoutubeV1Request} returns this
 */
proto.jp.singcolor.PlayYoutubeV1Request.prototype.setLiveId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string video_id = 2;
 * @return {string}
 */
proto.jp.singcolor.PlayYoutubeV1Request.prototype.getVideoId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.PlayYoutubeV1Request} returns this
 */
proto.jp.singcolor.PlayYoutubeV1Request.prototype.setVideoId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional string title = 3;
 * @return {string}
 */
proto.jp.singcolor.PlayYoutubeV1Request.prototype.getTitle = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.PlayYoutubeV1Request} returns this
 */
proto.jp.singcolor.PlayYoutubeV1Request.prototype.setTitle = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * optional string author = 4;
 * @return {string}
 */
proto.jp.singcolor.PlayYoutubeV1Request.prototype.getAuthor = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.PlayYoutubeV1Request} returns this
 */
proto.jp.singcolor.PlayYoutubeV1Request.prototype.setAuthor = function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
};
/**
 * optional int64 adjustment_delay = 5;
 * @return {number}
 */
proto.jp.singcolor.PlayYoutubeV1Request.prototype.getAdjustmentDelay = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.PlayYoutubeV1Request} returns this
 */
proto.jp.singcolor.PlayYoutubeV1Request.prototype.setAdjustmentDelay = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.PlayYoutubeV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.PlayYoutubeV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.PlayYoutubeV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.PlayYoutubeV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.PlayYoutubeV1Response}
 */
proto.jp.singcolor.PlayYoutubeV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.PlayYoutubeV1Response;
    return proto.jp.singcolor.PlayYoutubeV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.PlayYoutubeV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.PlayYoutubeV1Response}
 */
proto.jp.singcolor.PlayYoutubeV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.PlayYoutubeV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.PlayYoutubeV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.PlayYoutubeV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.PlayYoutubeV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.PickUpSongRandomlyV1Request.repeatedFields_ = [3];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.PickUpSongRandomlyV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.PickUpSongRandomlyV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.PickUpSongRandomlyV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.PickUpSongRandomlyV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            liveId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            pickUpNumber: jspb.Message.getFieldWithDefault(msg, 2, 0),
            excludeSongIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.PickUpSongRandomlyV1Request}
 */
proto.jp.singcolor.PickUpSongRandomlyV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.PickUpSongRandomlyV1Request;
    return proto.jp.singcolor.PickUpSongRandomlyV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.PickUpSongRandomlyV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.PickUpSongRandomlyV1Request}
 */
proto.jp.singcolor.PickUpSongRandomlyV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiveId(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setPickUpNumber(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.addExcludeSongIds(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.PickUpSongRandomlyV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.PickUpSongRandomlyV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.PickUpSongRandomlyV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.PickUpSongRandomlyV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiveId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getPickUpNumber();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getExcludeSongIdsList();
    if (f.length > 0) {
        writer.writeRepeatedString(3, f);
    }
};
/**
 * optional string live_id = 1;
 * @return {string}
 */
proto.jp.singcolor.PickUpSongRandomlyV1Request.prototype.getLiveId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.PickUpSongRandomlyV1Request} returns this
 */
proto.jp.singcolor.PickUpSongRandomlyV1Request.prototype.setLiveId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional int64 pick_up_number = 2;
 * @return {number}
 */
proto.jp.singcolor.PickUpSongRandomlyV1Request.prototype.getPickUpNumber = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.PickUpSongRandomlyV1Request} returns this
 */
proto.jp.singcolor.PickUpSongRandomlyV1Request.prototype.setPickUpNumber = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * repeated string exclude_song_ids = 3;
 * @return {!Array<string>}
 */
proto.jp.singcolor.PickUpSongRandomlyV1Request.prototype.getExcludeSongIdsList = function () {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};
/**
 * @param {!Array<string>} value
 * @return {!proto.jp.singcolor.PickUpSongRandomlyV1Request} returns this
 */
proto.jp.singcolor.PickUpSongRandomlyV1Request.prototype.setExcludeSongIdsList = function (value) {
    return jspb.Message.setField(this, 3, value || []);
};
/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.PickUpSongRandomlyV1Request} returns this
 */
proto.jp.singcolor.PickUpSongRandomlyV1Request.prototype.addExcludeSongIds = function (value, opt_index) {
    return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.PickUpSongRandomlyV1Request} returns this
 */
proto.jp.singcolor.PickUpSongRandomlyV1Request.prototype.clearExcludeSongIdsList = function () {
    return this.setExcludeSongIdsList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.PickUpSongRandomlyV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.PickUpSongRandomlyV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.PickUpSongRandomlyV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.PickUpSongRandomlyV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            song: (f = msg.getSong()) && karaoke_entity_pb.Song.toObject(includeInstance, f),
            resetRandom: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.PickUpSongRandomlyV1Response}
 */
proto.jp.singcolor.PickUpSongRandomlyV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.PickUpSongRandomlyV1Response;
    return proto.jp.singcolor.PickUpSongRandomlyV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.PickUpSongRandomlyV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.PickUpSongRandomlyV1Response}
 */
proto.jp.singcolor.PickUpSongRandomlyV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new karaoke_entity_pb.Song;
                reader.readMessage(value, karaoke_entity_pb.Song.deserializeBinaryFromReader);
                msg.setSong(value);
                break;
            case 2:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setResetRandom(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.PickUpSongRandomlyV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.PickUpSongRandomlyV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.PickUpSongRandomlyV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.PickUpSongRandomlyV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getSong();
    if (f != null) {
        writer.writeMessage(1, f, karaoke_entity_pb.Song.serializeBinaryToWriter);
    }
    f = message.getResetRandom();
    if (f) {
        writer.writeBool(2, f);
    }
};
/**
 * optional Song song = 1;
 * @return {?proto.jp.singcolor.Song}
 */
proto.jp.singcolor.PickUpSongRandomlyV1Response.prototype.getSong = function () {
    return /** @type{?proto.jp.singcolor.Song} */ (jspb.Message.getWrapperField(this, karaoke_entity_pb.Song, 1));
};
/**
 * @param {?proto.jp.singcolor.Song|undefined} value
 * @return {!proto.jp.singcolor.PickUpSongRandomlyV1Response} returns this
*/
proto.jp.singcolor.PickUpSongRandomlyV1Response.prototype.setSong = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.PickUpSongRandomlyV1Response} returns this
 */
proto.jp.singcolor.PickUpSongRandomlyV1Response.prototype.clearSong = function () {
    return this.setSong(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.PickUpSongRandomlyV1Response.prototype.hasSong = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional bool reset_random = 2;
 * @return {boolean}
 */
proto.jp.singcolor.PickUpSongRandomlyV1Response.prototype.getResetRandom = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.PickUpSongRandomlyV1Response} returns this
 */
proto.jp.singcolor.PickUpSongRandomlyV1Response.prototype.setResetRandom = function (value) {
    return jspb.Message.setProto3BooleanField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.BanListenerCommentByModeratorV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.BanListenerCommentByModeratorV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.BanListenerCommentByModeratorV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.BanListenerCommentByModeratorV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            listenerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            liverId: jspb.Message.getFieldWithDefault(msg, 2, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.BanListenerCommentByModeratorV1Request}
 */
proto.jp.singcolor.BanListenerCommentByModeratorV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.BanListenerCommentByModeratorV1Request;
    return proto.jp.singcolor.BanListenerCommentByModeratorV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.BanListenerCommentByModeratorV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.BanListenerCommentByModeratorV1Request}
 */
proto.jp.singcolor.BanListenerCommentByModeratorV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setListenerId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiverId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.BanListenerCommentByModeratorV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.BanListenerCommentByModeratorV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.BanListenerCommentByModeratorV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.BanListenerCommentByModeratorV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getListenerId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getLiverId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
};
/**
 * optional string listener_id = 1;
 * @return {string}
 */
proto.jp.singcolor.BanListenerCommentByModeratorV1Request.prototype.getListenerId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.BanListenerCommentByModeratorV1Request} returns this
 */
proto.jp.singcolor.BanListenerCommentByModeratorV1Request.prototype.setListenerId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string liver_id = 2;
 * @return {string}
 */
proto.jp.singcolor.BanListenerCommentByModeratorV1Request.prototype.getLiverId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.BanListenerCommentByModeratorV1Request} returns this
 */
proto.jp.singcolor.BanListenerCommentByModeratorV1Request.prototype.setLiverId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.BanListenerCommentByModeratorV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.BanListenerCommentByModeratorV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.BanListenerCommentByModeratorV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.BanListenerCommentByModeratorV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.BanListenerCommentByModeratorV1Response}
 */
proto.jp.singcolor.BanListenerCommentByModeratorV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.BanListenerCommentByModeratorV1Response;
    return proto.jp.singcolor.BanListenerCommentByModeratorV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.BanListenerCommentByModeratorV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.BanListenerCommentByModeratorV1Response}
 */
proto.jp.singcolor.BanListenerCommentByModeratorV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.BanListenerCommentByModeratorV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.BanListenerCommentByModeratorV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.BanListenerCommentByModeratorV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.BanListenerCommentByModeratorV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UnbanListenerCommentByModeratorV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UnbanListenerCommentByModeratorV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UnbanListenerCommentByModeratorV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UnbanListenerCommentByModeratorV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            listenerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            liverId: jspb.Message.getFieldWithDefault(msg, 2, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UnbanListenerCommentByModeratorV1Request}
 */
proto.jp.singcolor.UnbanListenerCommentByModeratorV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UnbanListenerCommentByModeratorV1Request;
    return proto.jp.singcolor.UnbanListenerCommentByModeratorV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UnbanListenerCommentByModeratorV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UnbanListenerCommentByModeratorV1Request}
 */
proto.jp.singcolor.UnbanListenerCommentByModeratorV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setListenerId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiverId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UnbanListenerCommentByModeratorV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UnbanListenerCommentByModeratorV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UnbanListenerCommentByModeratorV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UnbanListenerCommentByModeratorV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getListenerId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getLiverId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
};
/**
 * optional string listener_id = 1;
 * @return {string}
 */
proto.jp.singcolor.UnbanListenerCommentByModeratorV1Request.prototype.getListenerId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UnbanListenerCommentByModeratorV1Request} returns this
 */
proto.jp.singcolor.UnbanListenerCommentByModeratorV1Request.prototype.setListenerId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string liver_id = 2;
 * @return {string}
 */
proto.jp.singcolor.UnbanListenerCommentByModeratorV1Request.prototype.getLiverId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UnbanListenerCommentByModeratorV1Request} returns this
 */
proto.jp.singcolor.UnbanListenerCommentByModeratorV1Request.prototype.setLiverId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UnbanListenerCommentByModeratorV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UnbanListenerCommentByModeratorV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UnbanListenerCommentByModeratorV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UnbanListenerCommentByModeratorV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UnbanListenerCommentByModeratorV1Response}
 */
proto.jp.singcolor.UnbanListenerCommentByModeratorV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UnbanListenerCommentByModeratorV1Response;
    return proto.jp.singcolor.UnbanListenerCommentByModeratorV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UnbanListenerCommentByModeratorV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UnbanListenerCommentByModeratorV1Response}
 */
proto.jp.singcolor.UnbanListenerCommentByModeratorV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UnbanListenerCommentByModeratorV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UnbanListenerCommentByModeratorV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UnbanListenerCommentByModeratorV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UnbanListenerCommentByModeratorV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.BanListenerReqCaptureByModeratorV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.BanListenerReqCaptureByModeratorV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.BanListenerReqCaptureByModeratorV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.BanListenerReqCaptureByModeratorV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            listenerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            liverId: jspb.Message.getFieldWithDefault(msg, 2, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.BanListenerReqCaptureByModeratorV1Request}
 */
proto.jp.singcolor.BanListenerReqCaptureByModeratorV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.BanListenerReqCaptureByModeratorV1Request;
    return proto.jp.singcolor.BanListenerReqCaptureByModeratorV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.BanListenerReqCaptureByModeratorV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.BanListenerReqCaptureByModeratorV1Request}
 */
proto.jp.singcolor.BanListenerReqCaptureByModeratorV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setListenerId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiverId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.BanListenerReqCaptureByModeratorV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.BanListenerReqCaptureByModeratorV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.BanListenerReqCaptureByModeratorV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.BanListenerReqCaptureByModeratorV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getListenerId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getLiverId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
};
/**
 * optional string listener_id = 1;
 * @return {string}
 */
proto.jp.singcolor.BanListenerReqCaptureByModeratorV1Request.prototype.getListenerId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.BanListenerReqCaptureByModeratorV1Request} returns this
 */
proto.jp.singcolor.BanListenerReqCaptureByModeratorV1Request.prototype.setListenerId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string liver_id = 2;
 * @return {string}
 */
proto.jp.singcolor.BanListenerReqCaptureByModeratorV1Request.prototype.getLiverId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.BanListenerReqCaptureByModeratorV1Request} returns this
 */
proto.jp.singcolor.BanListenerReqCaptureByModeratorV1Request.prototype.setLiverId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.BanListenerReqCaptureByModeratorV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.BanListenerReqCaptureByModeratorV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.BanListenerReqCaptureByModeratorV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.BanListenerReqCaptureByModeratorV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.BanListenerReqCaptureByModeratorV1Response}
 */
proto.jp.singcolor.BanListenerReqCaptureByModeratorV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.BanListenerReqCaptureByModeratorV1Response;
    return proto.jp.singcolor.BanListenerReqCaptureByModeratorV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.BanListenerReqCaptureByModeratorV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.BanListenerReqCaptureByModeratorV1Response}
 */
proto.jp.singcolor.BanListenerReqCaptureByModeratorV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.BanListenerReqCaptureByModeratorV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.BanListenerReqCaptureByModeratorV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.BanListenerReqCaptureByModeratorV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.BanListenerReqCaptureByModeratorV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UnbanListenerReqCaptureByModeratorV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UnbanListenerReqCaptureByModeratorV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UnbanListenerReqCaptureByModeratorV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UnbanListenerReqCaptureByModeratorV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            listenerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            liverId: jspb.Message.getFieldWithDefault(msg, 2, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UnbanListenerReqCaptureByModeratorV1Request}
 */
proto.jp.singcolor.UnbanListenerReqCaptureByModeratorV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UnbanListenerReqCaptureByModeratorV1Request;
    return proto.jp.singcolor.UnbanListenerReqCaptureByModeratorV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UnbanListenerReqCaptureByModeratorV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UnbanListenerReqCaptureByModeratorV1Request}
 */
proto.jp.singcolor.UnbanListenerReqCaptureByModeratorV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setListenerId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiverId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UnbanListenerReqCaptureByModeratorV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UnbanListenerReqCaptureByModeratorV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UnbanListenerReqCaptureByModeratorV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UnbanListenerReqCaptureByModeratorV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getListenerId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getLiverId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
};
/**
 * optional string listener_id = 1;
 * @return {string}
 */
proto.jp.singcolor.UnbanListenerReqCaptureByModeratorV1Request.prototype.getListenerId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UnbanListenerReqCaptureByModeratorV1Request} returns this
 */
proto.jp.singcolor.UnbanListenerReqCaptureByModeratorV1Request.prototype.setListenerId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string liver_id = 2;
 * @return {string}
 */
proto.jp.singcolor.UnbanListenerReqCaptureByModeratorV1Request.prototype.getLiverId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UnbanListenerReqCaptureByModeratorV1Request} returns this
 */
proto.jp.singcolor.UnbanListenerReqCaptureByModeratorV1Request.prototype.setLiverId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UnbanListenerReqCaptureByModeratorV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UnbanListenerReqCaptureByModeratorV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UnbanListenerReqCaptureByModeratorV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UnbanListenerReqCaptureByModeratorV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UnbanListenerReqCaptureByModeratorV1Response}
 */
proto.jp.singcolor.UnbanListenerReqCaptureByModeratorV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UnbanListenerReqCaptureByModeratorV1Response;
    return proto.jp.singcolor.UnbanListenerReqCaptureByModeratorV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UnbanListenerReqCaptureByModeratorV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UnbanListenerReqCaptureByModeratorV1Response}
 */
proto.jp.singcolor.UnbanListenerReqCaptureByModeratorV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UnbanListenerReqCaptureByModeratorV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UnbanListenerReqCaptureByModeratorV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UnbanListenerReqCaptureByModeratorV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UnbanListenerReqCaptureByModeratorV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
/**
 * @enum {number}
 */
proto.jp.singcolor.StreamingQuality = {
    STREAMING_QUALITY_UNKNOWN: 0,
    STREAMING_QUALITY_LOW: 1,
    STREAMING_QUALITY_MIDDLE: 2,
    STREAMING_QUALITY_HIGH: 3
};
goog.object.extend(exports, proto.jp.singcolor);
