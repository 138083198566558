/**
 * @fileoverview gRPC-Web generated client stub for jp.singcolor
 * @enhanceable
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck
import * as grpcWeb from 'grpc-web';
import * as coins_v1_pb from './coins_v1_pb';
var CoinsV1Client = /** @class */ (function () {
    function CoinsV1Client(hostname, credentials, options) {
        this.methodDescriptorGetPurchasableCoinPlansV1 = new grpcWeb.MethodDescriptor('/jp.singcolor.CoinsV1/GetPurchasableCoinPlansV1', grpcWeb.MethodType.UNARY, coins_v1_pb.GetPurchasableCoinPlansV1Request, coins_v1_pb.GetPurchasableCoinPlansV1Response, function (request) {
            return request.serializeBinary();
        }, coins_v1_pb.GetPurchasableCoinPlansV1Response.deserializeBinary);
        this.methodDescriptorGetPurchasableWebCoinPlansV1 = new grpcWeb.MethodDescriptor('/jp.singcolor.CoinsV1/GetPurchasableWebCoinPlansV1', grpcWeb.MethodType.UNARY, coins_v1_pb.GetPurchasableWebCoinPlansV1Request, coins_v1_pb.GetPurchasableWebCoinPlansV1Response, function (request) {
            return request.serializeBinary();
        }, coins_v1_pb.GetPurchasableWebCoinPlansV1Response.deserializeBinary);
        this.methodDescriptorBeginPurchaseAndVerifyReceiptV1 = new grpcWeb.MethodDescriptor('/jp.singcolor.CoinsV1/BeginPurchaseAndVerifyReceiptV1', grpcWeb.MethodType.UNARY, coins_v1_pb.BeginPurchaseAndVerifyReceiptV1Request, coins_v1_pb.BeginPurchaseAndVerifyReceiptV1Response, function (request) {
            return request.serializeBinary();
        }, coins_v1_pb.BeginPurchaseAndVerifyReceiptV1Response.deserializeBinary);
        this.methodDescriptorCompletePurchaseV1 = new grpcWeb.MethodDescriptor('/jp.singcolor.CoinsV1/CompletePurchaseV1', grpcWeb.MethodType.UNARY, coins_v1_pb.CompletePurchaseV1Request, coins_v1_pb.CompletePurchaseV1Response, function (request) {
            return request.serializeBinary();
        }, coins_v1_pb.CompletePurchaseV1Response.deserializeBinary);
        this.methodDescriptorPurchaseWebCoinV2 = new grpcWeb.MethodDescriptor('/jp.singcolor.CoinsV1/PurchaseWebCoinV2', grpcWeb.MethodType.UNARY, coins_v1_pb.PurchaseWebCoinV2Request, coins_v1_pb.PurchaseWebCoinV2Response, function (request) {
            return request.serializeBinary();
        }, coins_v1_pb.PurchaseWebCoinV2Response.deserializeBinary);
        this.methodDescriptorGetStripeCustomerPortalUrlV1 = new grpcWeb.MethodDescriptor('/jp.singcolor.CoinsV1/GetStripeCustomerPortalUrlV1', grpcWeb.MethodType.UNARY, coins_v1_pb.GetStripeCustomerPortalUrlV1Request, coins_v1_pb.GetStripeCustomerPortalUrlV1Response, function (request) {
            return request.serializeBinary();
        }, coins_v1_pb.GetStripeCustomerPortalUrlV1Response.deserializeBinary);
        this.methodDescriptorListJoinableMembershipLiversV1 = new grpcWeb.MethodDescriptor('/jp.singcolor.CoinsV1/ListJoinableMembershipLiversV1', grpcWeb.MethodType.UNARY, coins_v1_pb.ListJoinableMembershipLiversV1Request, coins_v1_pb.ListJoinableMembershipLiversV1Response, function (request) {
            return request.serializeBinary();
        }, coins_v1_pb.ListJoinableMembershipLiversV1Response.deserializeBinary);
        this.methodDescriptorJoinMembershipV1 = new grpcWeb.MethodDescriptor('/jp.singcolor.CoinsV1/JoinMembershipV1', grpcWeb.MethodType.UNARY, coins_v1_pb.JoinMembershipV1Request, coins_v1_pb.JoinMembershipV1Response, function (request) {
            return request.serializeBinary();
        }, coins_v1_pb.JoinMembershipV1Response.deserializeBinary);
        this.methodDescriptorUpdateMembershipQuantityV1 = new grpcWeb.MethodDescriptor('/jp.singcolor.CoinsV1/UpdateMembershipQuantityV1', grpcWeb.MethodType.UNARY, coins_v1_pb.UpdateMembershipQuantityV1Request, coins_v1_pb.UpdateMembershipQuantityV1Response, function (request) {
            return request.serializeBinary();
        }, coins_v1_pb.UpdateMembershipQuantityV1Response.deserializeBinary);
        this.methodDescriptorListMembershipPlansForJoinV1 = new grpcWeb.MethodDescriptor('/jp.singcolor.CoinsV1/ListMembershipPlansForJoinV1', grpcWeb.MethodType.UNARY, coins_v1_pb.ListMembershipPlansForJoinV1Request, coins_v1_pb.ListMembershipPlansForJoinV1Response, function (request) {
            return request.serializeBinary();
        }, coins_v1_pb.ListMembershipPlansForJoinV1Response.deserializeBinary);
        this.methodDescriptorListMembershipPlansForChangeQuantityV1 = new grpcWeb.MethodDescriptor('/jp.singcolor.CoinsV1/ListMembershipPlansForChangeQuantityV1', grpcWeb.MethodType.UNARY, coins_v1_pb.ListMembershipPlansForChangeQuantityV1Request, coins_v1_pb.ListMembershipPlansForChangeQuantityV1Response, function (request) {
            return request.serializeBinary();
        }, coins_v1_pb.ListMembershipPlansForChangeQuantityV1Response.deserializeBinary);
        this.methodDescriptorListMembershipPlansForIncreaseQuantityV1 = new grpcWeb.MethodDescriptor('/jp.singcolor.CoinsV1/ListMembershipPlansForIncreaseQuantityV1', grpcWeb.MethodType.UNARY, coins_v1_pb.ListMembershipPlansForIncreaseQuantityV1Request, coins_v1_pb.ListMembershipPlansForIncreaseQuantityV1Response, function (request) {
            return request.serializeBinary();
        }, coins_v1_pb.ListMembershipPlansForIncreaseQuantityV1Response.deserializeBinary);
        this.methodDescriptorListActiveListenerMembershipPlansV1 = new grpcWeb.MethodDescriptor('/jp.singcolor.CoinsV1/ListActiveListenerMembershipPlansV1', grpcWeb.MethodType.UNARY, coins_v1_pb.ListActiveListenerMembershipPlansV1Request, coins_v1_pb.ListActiveListenerMembershipPlansV1Response, function (request) {
            return request.serializeBinary();
        }, coins_v1_pb.ListActiveListenerMembershipPlansV1Response.deserializeBinary);
        this.methodDescriptorListMembershipListenersV1 = new grpcWeb.MethodDescriptor('/jp.singcolor.CoinsV1/ListMembershipListenersV1', grpcWeb.MethodType.UNARY, coins_v1_pb.ListMembershipListenersV1Request, coins_v1_pb.ListMembershipListenersV1Response, function (request) {
            return request.serializeBinary();
        }, coins_v1_pb.ListMembershipListenersV1Response.deserializeBinary);
        this.methodDescriptorListWillJoinMembershipListenersV1 = new grpcWeb.MethodDescriptor('/jp.singcolor.CoinsV1/ListWillJoinMembershipListenersV1', grpcWeb.MethodType.UNARY, coins_v1_pb.ListWillJoinMembershipListenersV1Request, coins_v1_pb.ListWillJoinMembershipListenersV1Response, function (request) {
            return request.serializeBinary();
        }, coins_v1_pb.ListWillJoinMembershipListenersV1Response.deserializeBinary);
        if (!options)
            options = {};
        if (!credentials)
            credentials = {};
        options['format'] = 'binary';
        this.client_ = new grpcWeb.GrpcWebClientBase(options);
        this.hostname_ = hostname;
        this.credentials_ = credentials;
        this.options_ = options;
    }
    CoinsV1Client.prototype.getPurchasableCoinPlansV1 = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.CoinsV1/GetPurchasableCoinPlansV1', request, metadata || {}, this.methodDescriptorGetPurchasableCoinPlansV1, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.CoinsV1/GetPurchasableCoinPlansV1', request, metadata || {}, this.methodDescriptorGetPurchasableCoinPlansV1);
    };
    CoinsV1Client.prototype.getPurchasableWebCoinPlansV1 = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.CoinsV1/GetPurchasableWebCoinPlansV1', request, metadata || {}, this.methodDescriptorGetPurchasableWebCoinPlansV1, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.CoinsV1/GetPurchasableWebCoinPlansV1', request, metadata || {}, this.methodDescriptorGetPurchasableWebCoinPlansV1);
    };
    CoinsV1Client.prototype.beginPurchaseAndVerifyReceiptV1 = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.CoinsV1/BeginPurchaseAndVerifyReceiptV1', request, metadata || {}, this.methodDescriptorBeginPurchaseAndVerifyReceiptV1, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.CoinsV1/BeginPurchaseAndVerifyReceiptV1', request, metadata || {}, this.methodDescriptorBeginPurchaseAndVerifyReceiptV1);
    };
    CoinsV1Client.prototype.completePurchaseV1 = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.CoinsV1/CompletePurchaseV1', request, metadata || {}, this.methodDescriptorCompletePurchaseV1, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.CoinsV1/CompletePurchaseV1', request, metadata || {}, this.methodDescriptorCompletePurchaseV1);
    };
    CoinsV1Client.prototype.purchaseWebCoinV2 = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.CoinsV1/PurchaseWebCoinV2', request, metadata || {}, this.methodDescriptorPurchaseWebCoinV2, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.CoinsV1/PurchaseWebCoinV2', request, metadata || {}, this.methodDescriptorPurchaseWebCoinV2);
    };
    CoinsV1Client.prototype.getStripeCustomerPortalUrlV1 = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.CoinsV1/GetStripeCustomerPortalUrlV1', request, metadata || {}, this.methodDescriptorGetStripeCustomerPortalUrlV1, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.CoinsV1/GetStripeCustomerPortalUrlV1', request, metadata || {}, this.methodDescriptorGetStripeCustomerPortalUrlV1);
    };
    CoinsV1Client.prototype.listJoinableMembershipLiversV1 = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.CoinsV1/ListJoinableMembershipLiversV1', request, metadata || {}, this.methodDescriptorListJoinableMembershipLiversV1, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.CoinsV1/ListJoinableMembershipLiversV1', request, metadata || {}, this.methodDescriptorListJoinableMembershipLiversV1);
    };
    CoinsV1Client.prototype.joinMembershipV1 = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.CoinsV1/JoinMembershipV1', request, metadata || {}, this.methodDescriptorJoinMembershipV1, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.CoinsV1/JoinMembershipV1', request, metadata || {}, this.methodDescriptorJoinMembershipV1);
    };
    CoinsV1Client.prototype.updateMembershipQuantityV1 = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.CoinsV1/UpdateMembershipQuantityV1', request, metadata || {}, this.methodDescriptorUpdateMembershipQuantityV1, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.CoinsV1/UpdateMembershipQuantityV1', request, metadata || {}, this.methodDescriptorUpdateMembershipQuantityV1);
    };
    CoinsV1Client.prototype.listMembershipPlansForJoinV1 = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.CoinsV1/ListMembershipPlansForJoinV1', request, metadata || {}, this.methodDescriptorListMembershipPlansForJoinV1, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.CoinsV1/ListMembershipPlansForJoinV1', request, metadata || {}, this.methodDescriptorListMembershipPlansForJoinV1);
    };
    CoinsV1Client.prototype.listMembershipPlansForChangeQuantityV1 = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.CoinsV1/ListMembershipPlansForChangeQuantityV1', request, metadata || {}, this.methodDescriptorListMembershipPlansForChangeQuantityV1, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.CoinsV1/ListMembershipPlansForChangeQuantityV1', request, metadata || {}, this.methodDescriptorListMembershipPlansForChangeQuantityV1);
    };
    CoinsV1Client.prototype.listMembershipPlansForIncreaseQuantityV1 = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.CoinsV1/ListMembershipPlansForIncreaseQuantityV1', request, metadata || {}, this.methodDescriptorListMembershipPlansForIncreaseQuantityV1, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.CoinsV1/ListMembershipPlansForIncreaseQuantityV1', request, metadata || {}, this.methodDescriptorListMembershipPlansForIncreaseQuantityV1);
    };
    CoinsV1Client.prototype.listActiveListenerMembershipPlansV1 = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.CoinsV1/ListActiveListenerMembershipPlansV1', request, metadata || {}, this.methodDescriptorListActiveListenerMembershipPlansV1, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.CoinsV1/ListActiveListenerMembershipPlansV1', request, metadata || {}, this.methodDescriptorListActiveListenerMembershipPlansV1);
    };
    CoinsV1Client.prototype.listMembershipListenersV1 = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.CoinsV1/ListMembershipListenersV1', request, metadata || {}, this.methodDescriptorListMembershipListenersV1, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.CoinsV1/ListMembershipListenersV1', request, metadata || {}, this.methodDescriptorListMembershipListenersV1);
    };
    CoinsV1Client.prototype.listWillJoinMembershipListenersV1 = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.CoinsV1/ListWillJoinMembershipListenersV1', request, metadata || {}, this.methodDescriptorListWillJoinMembershipListenersV1, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.CoinsV1/ListWillJoinMembershipListenersV1', request, metadata || {}, this.methodDescriptorListWillJoinMembershipListenersV1);
    };
    return CoinsV1Client;
}());
export { CoinsV1Client };
