/**
 * @fileoverview gRPC-Web generated client stub for jp.singcolor
 * @enhanceable
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck
import * as grpcWeb from 'grpc-web';
import * as ope_user_v1_pb from './ope_user_v1_pb';
var OpeUsersV1Client = /** @class */ (function () {
    function OpeUsersV1Client(hostname, credentials, options) {
        this.methodDescriptorOpeGetCoinBalance = new grpcWeb.MethodDescriptor('/jp.singcolor.OpeUsersV1/OpeGetCoinBalance', grpcWeb.MethodType.UNARY, ope_user_v1_pb.OpeGetCoinBalanceRequest, ope_user_v1_pb.OpeGetCoinBalanceResponse, function (request) {
            return request.serializeBinary();
        }, ope_user_v1_pb.OpeGetCoinBalanceResponse.deserializeBinary);
        this.methodDescriptorOpeAddCoin = new grpcWeb.MethodDescriptor('/jp.singcolor.OpeUsersV1/OpeAddCoin', grpcWeb.MethodType.UNARY, ope_user_v1_pb.OpeAddCoinRequest, ope_user_v1_pb.OpeAddCoinResponse, function (request) {
            return request.serializeBinary();
        }, ope_user_v1_pb.OpeAddCoinResponse.deserializeBinary);
        this.methodDescriptorUpdateUserFlag = new grpcWeb.MethodDescriptor('/jp.singcolor.OpeUsersV1/UpdateUserFlag', grpcWeb.MethodType.UNARY, ope_user_v1_pb.UpdateUserFlagV1Request, ope_user_v1_pb.UpdateUserFlagV1Response, function (request) {
            return request.serializeBinary();
        }, ope_user_v1_pb.UpdateUserFlagV1Response.deserializeBinary);
        this.methodDescriptorGetUserBanHistory = new grpcWeb.MethodDescriptor('/jp.singcolor.OpeUsersV1/GetUserBanHistory', grpcWeb.MethodType.UNARY, ope_user_v1_pb.GetUserBanHistoryV1Request, ope_user_v1_pb.GetUserBanHistoryV1Response, function (request) {
            return request.serializeBinary();
        }, ope_user_v1_pb.GetUserBanHistoryV1Response.deserializeBinary);
        this.methodDescriptorBanUser = new grpcWeb.MethodDescriptor('/jp.singcolor.OpeUsersV1/BanUser', grpcWeb.MethodType.UNARY, ope_user_v1_pb.BanUserV1Request, ope_user_v1_pb.BanUserV1Response, function (request) {
            return request.serializeBinary();
        }, ope_user_v1_pb.BanUserV1Response.deserializeBinary);
        this.methodDescriptorForceUnbanUser = new grpcWeb.MethodDescriptor('/jp.singcolor.OpeUsersV1/ForceUnbanUser', grpcWeb.MethodType.UNARY, ope_user_v1_pb.ForceUnbanUserV1Request, ope_user_v1_pb.ForceUnbanUserV1Response, function (request) {
            return request.serializeBinary();
        }, ope_user_v1_pb.ForceUnbanUserV1Response.deserializeBinary);
        this.methodDescriptorGetUserProfileScreening = new grpcWeb.MethodDescriptor('/jp.singcolor.OpeUsersV1/GetUserProfileScreening', grpcWeb.MethodType.UNARY, ope_user_v1_pb.GetUserProfileScreeningV1Request, ope_user_v1_pb.GetUserProfileScreeningV1Response, function (request) {
            return request.serializeBinary();
        }, ope_user_v1_pb.GetUserProfileScreeningV1Response.deserializeBinary);
        this.methodDescriptorListUserProfileScreenings = new grpcWeb.MethodDescriptor('/jp.singcolor.OpeUsersV1/ListUserProfileScreenings', grpcWeb.MethodType.UNARY, ope_user_v1_pb.ListUserProfileScreeningsV1Request, ope_user_v1_pb.ListUserProfileScreeningsV1Response, function (request) {
            return request.serializeBinary();
        }, ope_user_v1_pb.ListUserProfileScreeningsV1Response.deserializeBinary);
        this.methodDescriptorConfirmUserProfileScreenings = new grpcWeb.MethodDescriptor('/jp.singcolor.OpeUsersV1/ConfirmUserProfileScreenings', grpcWeb.MethodType.UNARY, ope_user_v1_pb.ConfirmUserProfileScreeningsV1Request, ope_user_v1_pb.ConfirmUserProfileScreeningsV1Response, function (request) {
            return request.serializeBinary();
        }, ope_user_v1_pb.ConfirmUserProfileScreeningsV1Response.deserializeBinary);
        this.methodDescriptorMaskUserProfile = new grpcWeb.MethodDescriptor('/jp.singcolor.OpeUsersV1/MaskUserProfile', grpcWeb.MethodType.UNARY, ope_user_v1_pb.MaskUserProfileV1Request, ope_user_v1_pb.MaskUserProfileV1Response, function (request) {
            return request.serializeBinary();
        }, ope_user_v1_pb.MaskUserProfileV1Response.deserializeBinary);
        this.methodDescriptorGetStatisticsCurrentDiamond = new grpcWeb.MethodDescriptor('/jp.singcolor.OpeUsersV1/GetStatisticsCurrentDiamond', grpcWeb.MethodType.UNARY, ope_user_v1_pb.OpeGetStatisticsCurrentDiamondV1Request, ope_user_v1_pb.OpeGetStatisticsCurrentDiamondV1Response, function (request) {
            return request.serializeBinary();
        }, ope_user_v1_pb.OpeGetStatisticsCurrentDiamondV1Response.deserializeBinary);
        this.methodDescriptorGetMonthlyStatistics = new grpcWeb.MethodDescriptor('/jp.singcolor.OpeUsersV1/GetMonthlyStatistics', grpcWeb.MethodType.UNARY, ope_user_v1_pb.OpeGetMonthlyStatisticsV1Request, ope_user_v1_pb.OpeGetMonthlyStatisticsV1Response, function (request) {
            return request.serializeBinary();
        }, ope_user_v1_pb.OpeGetMonthlyStatisticsV1Response.deserializeBinary);
        this.methodDescriptorGetUserDetail = new grpcWeb.MethodDescriptor('/jp.singcolor.OpeUsersV1/GetUserDetail', grpcWeb.MethodType.UNARY, ope_user_v1_pb.GetUserDetailV1Request, ope_user_v1_pb.GetUserDetailV1Response, function (request) {
            return request.serializeBinary();
        }, ope_user_v1_pb.GetUserDetailV1Response.deserializeBinary);
        this.methodDescriptorListLivePermittedTwitterAccountV1 = new grpcWeb.MethodDescriptor('/jp.singcolor.OpeUsersV1/ListLivePermittedTwitterAccountV1', grpcWeb.MethodType.UNARY, ope_user_v1_pb.ListLivePermittedTwitterAccountV1Request, ope_user_v1_pb.ListLivePermittedTwitterAccountV1Response, function (request) {
            return request.serializeBinary();
        }, ope_user_v1_pb.ListLivePermittedTwitterAccountV1Response.deserializeBinary);
        this.methodDescriptorCreateLivePermissionTwitterAccountV1 = new grpcWeb.MethodDescriptor('/jp.singcolor.OpeUsersV1/CreateLivePermissionTwitterAccountV1', grpcWeb.MethodType.UNARY, ope_user_v1_pb.CreateLivePermissionTwitterAccountV1Request, ope_user_v1_pb.CreateLivePermissionTwitterAccountV1Response, function (request) {
            return request.serializeBinary();
        }, ope_user_v1_pb.CreateLivePermissionTwitterAccountV1Response.deserializeBinary);
        this.methodDescriptorDeleteLivePermissionTwitterAccountV1 = new grpcWeb.MethodDescriptor('/jp.singcolor.OpeUsersV1/DeleteLivePermissionTwitterAccountV1', grpcWeb.MethodType.UNARY, ope_user_v1_pb.DeleteLivePermissionTwitterAccountV1Request, ope_user_v1_pb.DeleteLivePermissionTwitterAccountV1Response, function (request) {
            return request.serializeBinary();
        }, ope_user_v1_pb.DeleteLivePermissionTwitterAccountV1Response.deserializeBinary);
        this.methodDescriptorRegisterBankTransferToken = new grpcWeb.MethodDescriptor('/jp.singcolor.OpeUsersV1/RegisterBankTransferToken', grpcWeb.MethodType.UNARY, ope_user_v1_pb.RegisterBankTransferTokenV1Request, ope_user_v1_pb.RegisterBankTransferTokenV1Response, function (request) {
            return request.serializeBinary();
        }, ope_user_v1_pb.RegisterBankTransferTokenV1Response.deserializeBinary);
        this.methodDescriptorListLeagueResetCompensations = new grpcWeb.MethodDescriptor('/jp.singcolor.OpeUsersV1/ListLeagueResetCompensations', grpcWeb.MethodType.UNARY, ope_user_v1_pb.ListLeagueResetCompensationsV1Request, ope_user_v1_pb.ListLeagueResetCompensationsV1Response, function (request) {
            return request.serializeBinary();
        }, ope_user_v1_pb.ListLeagueResetCompensationsV1Response.deserializeBinary);
        this.methodDescriptorCreateLeagueResetCompensation = new grpcWeb.MethodDescriptor('/jp.singcolor.OpeUsersV1/CreateLeagueResetCompensation', grpcWeb.MethodType.UNARY, ope_user_v1_pb.CreateLeagueResetCompensationV1Request, ope_user_v1_pb.CreateLeagueResetCompensationV1Response, function (request) {
            return request.serializeBinary();
        }, ope_user_v1_pb.CreateLeagueResetCompensationV1Response.deserializeBinary);
        this.methodDescriptorDeleteLeagueResetCompensation = new grpcWeb.MethodDescriptor('/jp.singcolor.OpeUsersV1/DeleteLeagueResetCompensation', grpcWeb.MethodType.UNARY, ope_user_v1_pb.DeleteLeagueResetCompensationV1Request, ope_user_v1_pb.DeleteLeagueResetCompensationV1Response, function (request) {
            return request.serializeBinary();
        }, ope_user_v1_pb.DeleteLeagueResetCompensationV1Response.deserializeBinary);
        this.methodDescriptorMaskLiveBackground = new grpcWeb.MethodDescriptor('/jp.singcolor.OpeUsersV1/MaskLiveBackground', grpcWeb.MethodType.UNARY, ope_user_v1_pb.MaskLiveBackgroundV1Request, ope_user_v1_pb.MaskLiveBackgroundV1Response, function (request) {
            return request.serializeBinary();
        }, ope_user_v1_pb.MaskLiveBackgroundV1Response.deserializeBinary);
        this.methodDescriptorListUserDevices = new grpcWeb.MethodDescriptor('/jp.singcolor.OpeUsersV1/ListUserDevices', grpcWeb.MethodType.UNARY, ope_user_v1_pb.ListUserDevicesV1Request, ope_user_v1_pb.ListUserDevicesV1Response, function (request) {
            return request.serializeBinary();
        }, ope_user_v1_pb.ListUserDevicesV1Response.deserializeBinary);
        this.methodDescriptorDeleteUserDevice = new grpcWeb.MethodDescriptor('/jp.singcolor.OpeUsersV1/DeleteUserDevice', grpcWeb.MethodType.UNARY, ope_user_v1_pb.DeleteUserDeviceV1Request, ope_user_v1_pb.DeleteUserDeviceV1Response, function (request) {
            return request.serializeBinary();
        }, ope_user_v1_pb.DeleteUserDeviceV1Response.deserializeBinary);
        this.methodDescriptorListDevicesByUserID = new grpcWeb.MethodDescriptor('/jp.singcolor.OpeUsersV1/ListDevicesByUserID', grpcWeb.MethodType.UNARY, ope_user_v1_pb.ListDevicesByUserIDRequest, ope_user_v1_pb.ListDevicesByUserIDResponse, function (request) {
            return request.serializeBinary();
        }, ope_user_v1_pb.ListDevicesByUserIDResponse.deserializeBinary);
        this.methodDescriptorSendSystemMessage = new grpcWeb.MethodDescriptor('/jp.singcolor.OpeUsersV1/SendSystemMessage', grpcWeb.MethodType.UNARY, ope_user_v1_pb.SendSystemMessageV1Request, ope_user_v1_pb.SendSystemMessageV1Response, function (request) {
            return request.serializeBinary();
        }, ope_user_v1_pb.SendSystemMessageV1Response.deserializeBinary);
        this.methodDescriptorPassLivePermissionFirstReview = new grpcWeb.MethodDescriptor('/jp.singcolor.OpeUsersV1/PassLivePermissionFirstReview', grpcWeb.MethodType.UNARY, ope_user_v1_pb.PassLivePermissionFirstReviewV1Request, ope_user_v1_pb.PassLivePermissionFirstReviewV1Response, function (request) {
            return request.serializeBinary();
        }, ope_user_v1_pb.PassLivePermissionFirstReviewV1Response.deserializeBinary);
        this.methodDescriptorPassLivePermissionSecondReview = new grpcWeb.MethodDescriptor('/jp.singcolor.OpeUsersV1/PassLivePermissionSecondReview', grpcWeb.MethodType.UNARY, ope_user_v1_pb.PassLivePermissionSecondReviewV1Request, ope_user_v1_pb.PassLivePermissionSecondReviewV1Response, function (request) {
            return request.serializeBinary();
        }, ope_user_v1_pb.PassLivePermissionSecondReviewV1Response.deserializeBinary);
        this.methodDescriptorListReservedLiverMembershipBenefits = new grpcWeb.MethodDescriptor('/jp.singcolor.OpeUsersV1/ListReservedLiverMembershipBenefits', grpcWeb.MethodType.UNARY, ope_user_v1_pb.ListReservedLiverMembershipBenefitsV1Request, ope_user_v1_pb.ListReservedLiverMembershipBenefitsV1Response, function (request) {
            return request.serializeBinary();
        }, ope_user_v1_pb.ListReservedLiverMembershipBenefitsV1Response.deserializeBinary);
        this.methodDescriptorOpeRequestWithdrawV1 = new grpcWeb.MethodDescriptor('/jp.singcolor.OpeUsersV1/OpeRequestWithdrawV1', grpcWeb.MethodType.UNARY, ope_user_v1_pb.OpeRequestWithdrawV1Request, ope_user_v1_pb.OpeRequestWithdrawV1Response, function (request) {
            return request.serializeBinary();
        }, ope_user_v1_pb.OpeRequestWithdrawV1Response.deserializeBinary);
        this.methodDescriptorRevokeLivePermissionV1 = new grpcWeb.MethodDescriptor('/jp.singcolor.OpeUsersV1/RevokeLivePermissionV1', grpcWeb.MethodType.UNARY, ope_user_v1_pb.RevokeLivePermissionV1Request, ope_user_v1_pb.RevokeLivePermissionV1Response, function (request) {
            return request.serializeBinary();
        }, ope_user_v1_pb.RevokeLivePermissionV1Response.deserializeBinary);
        if (!options)
            options = {};
        if (!credentials)
            credentials = {};
        options['format'] = 'binary';
        this.client_ = new grpcWeb.GrpcWebClientBase(options);
        this.hostname_ = hostname;
        this.credentials_ = credentials;
        this.options_ = options;
    }
    OpeUsersV1Client.prototype.opeGetCoinBalance = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpeUsersV1/OpeGetCoinBalance', request, metadata || {}, this.methodDescriptorOpeGetCoinBalance, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpeUsersV1/OpeGetCoinBalance', request, metadata || {}, this.methodDescriptorOpeGetCoinBalance);
    };
    OpeUsersV1Client.prototype.opeAddCoin = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpeUsersV1/OpeAddCoin', request, metadata || {}, this.methodDescriptorOpeAddCoin, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpeUsersV1/OpeAddCoin', request, metadata || {}, this.methodDescriptorOpeAddCoin);
    };
    OpeUsersV1Client.prototype.updateUserFlag = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpeUsersV1/UpdateUserFlag', request, metadata || {}, this.methodDescriptorUpdateUserFlag, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpeUsersV1/UpdateUserFlag', request, metadata || {}, this.methodDescriptorUpdateUserFlag);
    };
    OpeUsersV1Client.prototype.getUserBanHistory = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpeUsersV1/GetUserBanHistory', request, metadata || {}, this.methodDescriptorGetUserBanHistory, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpeUsersV1/GetUserBanHistory', request, metadata || {}, this.methodDescriptorGetUserBanHistory);
    };
    OpeUsersV1Client.prototype.banUser = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpeUsersV1/BanUser', request, metadata || {}, this.methodDescriptorBanUser, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpeUsersV1/BanUser', request, metadata || {}, this.methodDescriptorBanUser);
    };
    OpeUsersV1Client.prototype.forceUnbanUser = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpeUsersV1/ForceUnbanUser', request, metadata || {}, this.methodDescriptorForceUnbanUser, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpeUsersV1/ForceUnbanUser', request, metadata || {}, this.methodDescriptorForceUnbanUser);
    };
    OpeUsersV1Client.prototype.getUserProfileScreening = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpeUsersV1/GetUserProfileScreening', request, metadata || {}, this.methodDescriptorGetUserProfileScreening, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpeUsersV1/GetUserProfileScreening', request, metadata || {}, this.methodDescriptorGetUserProfileScreening);
    };
    OpeUsersV1Client.prototype.listUserProfileScreenings = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpeUsersV1/ListUserProfileScreenings', request, metadata || {}, this.methodDescriptorListUserProfileScreenings, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpeUsersV1/ListUserProfileScreenings', request, metadata || {}, this.methodDescriptorListUserProfileScreenings);
    };
    OpeUsersV1Client.prototype.confirmUserProfileScreenings = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpeUsersV1/ConfirmUserProfileScreenings', request, metadata || {}, this.methodDescriptorConfirmUserProfileScreenings, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpeUsersV1/ConfirmUserProfileScreenings', request, metadata || {}, this.methodDescriptorConfirmUserProfileScreenings);
    };
    OpeUsersV1Client.prototype.maskUserProfile = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpeUsersV1/MaskUserProfile', request, metadata || {}, this.methodDescriptorMaskUserProfile, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpeUsersV1/MaskUserProfile', request, metadata || {}, this.methodDescriptorMaskUserProfile);
    };
    OpeUsersV1Client.prototype.getStatisticsCurrentDiamond = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpeUsersV1/GetStatisticsCurrentDiamond', request, metadata || {}, this.methodDescriptorGetStatisticsCurrentDiamond, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpeUsersV1/GetStatisticsCurrentDiamond', request, metadata || {}, this.methodDescriptorGetStatisticsCurrentDiamond);
    };
    OpeUsersV1Client.prototype.getMonthlyStatistics = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpeUsersV1/GetMonthlyStatistics', request, metadata || {}, this.methodDescriptorGetMonthlyStatistics, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpeUsersV1/GetMonthlyStatistics', request, metadata || {}, this.methodDescriptorGetMonthlyStatistics);
    };
    OpeUsersV1Client.prototype.getUserDetail = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpeUsersV1/GetUserDetail', request, metadata || {}, this.methodDescriptorGetUserDetail, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpeUsersV1/GetUserDetail', request, metadata || {}, this.methodDescriptorGetUserDetail);
    };
    OpeUsersV1Client.prototype.listLivePermittedTwitterAccountV1 = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpeUsersV1/ListLivePermittedTwitterAccountV1', request, metadata || {}, this.methodDescriptorListLivePermittedTwitterAccountV1, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpeUsersV1/ListLivePermittedTwitterAccountV1', request, metadata || {}, this.methodDescriptorListLivePermittedTwitterAccountV1);
    };
    OpeUsersV1Client.prototype.createLivePermissionTwitterAccountV1 = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpeUsersV1/CreateLivePermissionTwitterAccountV1', request, metadata || {}, this.methodDescriptorCreateLivePermissionTwitterAccountV1, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpeUsersV1/CreateLivePermissionTwitterAccountV1', request, metadata || {}, this.methodDescriptorCreateLivePermissionTwitterAccountV1);
    };
    OpeUsersV1Client.prototype.deleteLivePermissionTwitterAccountV1 = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpeUsersV1/DeleteLivePermissionTwitterAccountV1', request, metadata || {}, this.methodDescriptorDeleteLivePermissionTwitterAccountV1, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpeUsersV1/DeleteLivePermissionTwitterAccountV1', request, metadata || {}, this.methodDescriptorDeleteLivePermissionTwitterAccountV1);
    };
    OpeUsersV1Client.prototype.registerBankTransferToken = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpeUsersV1/RegisterBankTransferToken', request, metadata || {}, this.methodDescriptorRegisterBankTransferToken, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpeUsersV1/RegisterBankTransferToken', request, metadata || {}, this.methodDescriptorRegisterBankTransferToken);
    };
    OpeUsersV1Client.prototype.listLeagueResetCompensations = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpeUsersV1/ListLeagueResetCompensations', request, metadata || {}, this.methodDescriptorListLeagueResetCompensations, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpeUsersV1/ListLeagueResetCompensations', request, metadata || {}, this.methodDescriptorListLeagueResetCompensations);
    };
    OpeUsersV1Client.prototype.createLeagueResetCompensation = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpeUsersV1/CreateLeagueResetCompensation', request, metadata || {}, this.methodDescriptorCreateLeagueResetCompensation, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpeUsersV1/CreateLeagueResetCompensation', request, metadata || {}, this.methodDescriptorCreateLeagueResetCompensation);
    };
    OpeUsersV1Client.prototype.deleteLeagueResetCompensation = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpeUsersV1/DeleteLeagueResetCompensation', request, metadata || {}, this.methodDescriptorDeleteLeagueResetCompensation, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpeUsersV1/DeleteLeagueResetCompensation', request, metadata || {}, this.methodDescriptorDeleteLeagueResetCompensation);
    };
    OpeUsersV1Client.prototype.maskLiveBackground = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpeUsersV1/MaskLiveBackground', request, metadata || {}, this.methodDescriptorMaskLiveBackground, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpeUsersV1/MaskLiveBackground', request, metadata || {}, this.methodDescriptorMaskLiveBackground);
    };
    OpeUsersV1Client.prototype.listUserDevices = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpeUsersV1/ListUserDevices', request, metadata || {}, this.methodDescriptorListUserDevices, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpeUsersV1/ListUserDevices', request, metadata || {}, this.methodDescriptorListUserDevices);
    };
    OpeUsersV1Client.prototype.deleteUserDevice = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpeUsersV1/DeleteUserDevice', request, metadata || {}, this.methodDescriptorDeleteUserDevice, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpeUsersV1/DeleteUserDevice', request, metadata || {}, this.methodDescriptorDeleteUserDevice);
    };
    OpeUsersV1Client.prototype.listDevicesByUserID = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpeUsersV1/ListDevicesByUserID', request, metadata || {}, this.methodDescriptorListDevicesByUserID, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpeUsersV1/ListDevicesByUserID', request, metadata || {}, this.methodDescriptorListDevicesByUserID);
    };
    OpeUsersV1Client.prototype.sendSystemMessage = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpeUsersV1/SendSystemMessage', request, metadata || {}, this.methodDescriptorSendSystemMessage, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpeUsersV1/SendSystemMessage', request, metadata || {}, this.methodDescriptorSendSystemMessage);
    };
    OpeUsersV1Client.prototype.passLivePermissionFirstReview = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpeUsersV1/PassLivePermissionFirstReview', request, metadata || {}, this.methodDescriptorPassLivePermissionFirstReview, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpeUsersV1/PassLivePermissionFirstReview', request, metadata || {}, this.methodDescriptorPassLivePermissionFirstReview);
    };
    OpeUsersV1Client.prototype.passLivePermissionSecondReview = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpeUsersV1/PassLivePermissionSecondReview', request, metadata || {}, this.methodDescriptorPassLivePermissionSecondReview, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpeUsersV1/PassLivePermissionSecondReview', request, metadata || {}, this.methodDescriptorPassLivePermissionSecondReview);
    };
    OpeUsersV1Client.prototype.listReservedLiverMembershipBenefits = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpeUsersV1/ListReservedLiverMembershipBenefits', request, metadata || {}, this.methodDescriptorListReservedLiverMembershipBenefits, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpeUsersV1/ListReservedLiverMembershipBenefits', request, metadata || {}, this.methodDescriptorListReservedLiverMembershipBenefits);
    };
    OpeUsersV1Client.prototype.opeRequestWithdrawV1 = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpeUsersV1/OpeRequestWithdrawV1', request, metadata || {}, this.methodDescriptorOpeRequestWithdrawV1, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpeUsersV1/OpeRequestWithdrawV1', request, metadata || {}, this.methodDescriptorOpeRequestWithdrawV1);
    };
    OpeUsersV1Client.prototype.revokeLivePermissionV1 = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpeUsersV1/RevokeLivePermissionV1', request, metadata || {}, this.methodDescriptorRevokeLivePermissionV1, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpeUsersV1/RevokeLivePermissionV1', request, metadata || {}, this.methodDescriptorRevokeLivePermissionV1);
    };
    return OpeUsersV1Client;
}());
export { OpeUsersV1Client };
