"use strict";
// source: karaoke_v1.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();
var karaoke_entity_pb = require('./karaoke_entity_pb.js');
goog.object.extend(proto, karaoke_entity_pb);
var users_entity_pb = require('./users_entity_pb.js');
goog.object.extend(proto, users_entity_pb);
goog.exportSymbol('proto.jp.singcolor.GetSongByXingJoysoundWebInfoV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.GetSongByXingJoysoundWebInfoV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.GetSongRandomlyV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.GetSongRandomlyV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.GetSongV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.GetSongV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListFavoriteSongsV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListFavoriteSongsV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListLatestPopularSongsV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListLatestPopularSongsV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListListenerFavoriteSongCategoriesV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListListenerFavoriteSongCategoriesV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListListenerFavoriteSongsV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListListenerFavoriteSongsV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListRecentlyAvailableSongsV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListRecentlyAvailableSongsV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListRequestedHistoryV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListRequestedHistoryV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListSingersV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListSingersV1Request.ListSingersSort', null, global);
goog.exportSymbol('proto.jp.singcolor.ListSingersV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListSingingHistoryV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListSingingHistoryV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListSongsV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListSongsV1Request.ListSongsSort', null, global);
goog.exportSymbol('proto.jp.singcolor.ListSongsV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListUserFavoriteSongCategoriesV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListUserFavoriteSongCategoriesV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListWorldPopularSongsV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListWorldPopularSongsV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.RequestToAddNewSongV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.RequestToAddNewSongV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.SearchSingersV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.SearchSingersV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.SearchSongsV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.SearchSongsV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateFavoriteSingersV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateFavoriteSingersV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateFavoriteSongV2Request', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateFavoriteSongV2Response', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateListenerFavoriteSongCategoryV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateListenerFavoriteSongCategoryV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateListenerFavoriteSongOrderV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateListenerFavoriteSongOrderV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateListenerFavoriteSongV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateListenerFavoriteSongV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateListenerFavoriteSongV2Request', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateListenerFavoriteSongV2Response', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateUserFavoriteSongCategoryV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateUserFavoriteSongCategoryV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateUserFavoriteSongOrderV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateUserFavoriteSongOrderV1Response', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListSingersV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListSingersV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListSingersV1Request.displayName = 'proto.jp.singcolor.ListSingersV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListSingersV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListSingersV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListSingersV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListSingersV1Response.displayName = 'proto.jp.singcolor.ListSingersV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateFavoriteSingersV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.UpdateFavoriteSingersV1Request.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.UpdateFavoriteSingersV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateFavoriteSingersV1Request.displayName = 'proto.jp.singcolor.UpdateFavoriteSingersV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateFavoriteSingersV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.UpdateFavoriteSingersV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.UpdateFavoriteSingersV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateFavoriteSingersV1Response.displayName = 'proto.jp.singcolor.UpdateFavoriteSingersV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListSongsV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListSongsV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListSongsV1Request.displayName = 'proto.jp.singcolor.ListSongsV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListSongsV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListSongsV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListSongsV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListSongsV1Response.displayName = 'proto.jp.singcolor.ListSongsV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.SearchSongsV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.SearchSongsV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.SearchSongsV1Request.displayName = 'proto.jp.singcolor.SearchSongsV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.SearchSongsV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.SearchSongsV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.SearchSongsV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.SearchSongsV1Response.displayName = 'proto.jp.singcolor.SearchSongsV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.SearchSingersV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.SearchSingersV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.SearchSingersV1Request.displayName = 'proto.jp.singcolor.SearchSingersV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.SearchSingersV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.SearchSingersV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.SearchSingersV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.SearchSingersV1Response.displayName = 'proto.jp.singcolor.SearchSingersV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListLatestPopularSongsV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListLatestPopularSongsV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListLatestPopularSongsV1Request.displayName = 'proto.jp.singcolor.ListLatestPopularSongsV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListLatestPopularSongsV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListLatestPopularSongsV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListLatestPopularSongsV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListLatestPopularSongsV1Response.displayName = 'proto.jp.singcolor.ListLatestPopularSongsV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListWorldPopularSongsV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListWorldPopularSongsV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListWorldPopularSongsV1Request.displayName = 'proto.jp.singcolor.ListWorldPopularSongsV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListWorldPopularSongsV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListWorldPopularSongsV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListWorldPopularSongsV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListWorldPopularSongsV1Response.displayName = 'proto.jp.singcolor.ListWorldPopularSongsV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListFavoriteSongsV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListFavoriteSongsV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListFavoriteSongsV1Request.displayName = 'proto.jp.singcolor.ListFavoriteSongsV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListFavoriteSongsV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListFavoriteSongsV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListFavoriteSongsV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListFavoriteSongsV1Response.displayName = 'proto.jp.singcolor.ListFavoriteSongsV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListSingingHistoryV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListSingingHistoryV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListSingingHistoryV1Request.displayName = 'proto.jp.singcolor.ListSingingHistoryV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListSingingHistoryV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListSingingHistoryV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListSingingHistoryV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListSingingHistoryV1Response.displayName = 'proto.jp.singcolor.ListSingingHistoryV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListRequestedHistoryV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListRequestedHistoryV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListRequestedHistoryV1Request.displayName = 'proto.jp.singcolor.ListRequestedHistoryV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListRequestedHistoryV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListRequestedHistoryV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListRequestedHistoryV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListRequestedHistoryV1Response.displayName = 'proto.jp.singcolor.ListRequestedHistoryV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListListenerFavoriteSongsV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListListenerFavoriteSongsV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListListenerFavoriteSongsV1Request.displayName = 'proto.jp.singcolor.ListListenerFavoriteSongsV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListListenerFavoriteSongsV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListListenerFavoriteSongsV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListListenerFavoriteSongsV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListListenerFavoriteSongsV1Response.displayName = 'proto.jp.singcolor.ListListenerFavoriteSongsV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListRecentlyAvailableSongsV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListRecentlyAvailableSongsV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListRecentlyAvailableSongsV1Request.displayName = 'proto.jp.singcolor.ListRecentlyAvailableSongsV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListRecentlyAvailableSongsV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListRecentlyAvailableSongsV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListRecentlyAvailableSongsV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListRecentlyAvailableSongsV1Response.displayName = 'proto.jp.singcolor.ListRecentlyAvailableSongsV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetSongV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetSongV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetSongV1Request.displayName = 'proto.jp.singcolor.GetSongV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetSongV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetSongV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetSongV1Response.displayName = 'proto.jp.singcolor.GetSongV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateListenerFavoriteSongV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateListenerFavoriteSongV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateListenerFavoriteSongV1Request.displayName = 'proto.jp.singcolor.UpdateListenerFavoriteSongV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateListenerFavoriteSongV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateListenerFavoriteSongV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateListenerFavoriteSongV1Response.displayName = 'proto.jp.singcolor.UpdateListenerFavoriteSongV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetSongByXingJoysoundWebInfoV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetSongByXingJoysoundWebInfoV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetSongByXingJoysoundWebInfoV1Request.displayName = 'proto.jp.singcolor.GetSongByXingJoysoundWebInfoV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetSongByXingJoysoundWebInfoV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetSongByXingJoysoundWebInfoV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetSongByXingJoysoundWebInfoV1Response.displayName = 'proto.jp.singcolor.GetSongByXingJoysoundWebInfoV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.RequestToAddNewSongV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.RequestToAddNewSongV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.RequestToAddNewSongV1Request.displayName = 'proto.jp.singcolor.RequestToAddNewSongV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.RequestToAddNewSongV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.RequestToAddNewSongV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.RequestToAddNewSongV1Response.displayName = 'proto.jp.singcolor.RequestToAddNewSongV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetSongRandomlyV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.GetSongRandomlyV1Request.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.GetSongRandomlyV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetSongRandomlyV1Request.displayName = 'proto.jp.singcolor.GetSongRandomlyV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetSongRandomlyV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetSongRandomlyV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetSongRandomlyV1Response.displayName = 'proto.jp.singcolor.GetSongRandomlyV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateListenerFavoriteSongOrderV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateListenerFavoriteSongOrderV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateListenerFavoriteSongOrderV1Request.displayName = 'proto.jp.singcolor.UpdateListenerFavoriteSongOrderV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateListenerFavoriteSongOrderV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateListenerFavoriteSongOrderV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateListenerFavoriteSongOrderV1Response.displayName = 'proto.jp.singcolor.UpdateListenerFavoriteSongOrderV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateListenerFavoriteSongCategoryV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateListenerFavoriteSongCategoryV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateListenerFavoriteSongCategoryV1Request.displayName = 'proto.jp.singcolor.UpdateListenerFavoriteSongCategoryV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateListenerFavoriteSongCategoryV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateListenerFavoriteSongCategoryV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateListenerFavoriteSongCategoryV1Response.displayName = 'proto.jp.singcolor.UpdateListenerFavoriteSongCategoryV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateUserFavoriteSongOrderV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateUserFavoriteSongOrderV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateUserFavoriteSongOrderV1Request.displayName = 'proto.jp.singcolor.UpdateUserFavoriteSongOrderV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateUserFavoriteSongOrderV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateUserFavoriteSongOrderV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateUserFavoriteSongOrderV1Response.displayName = 'proto.jp.singcolor.UpdateUserFavoriteSongOrderV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateUserFavoriteSongCategoryV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateUserFavoriteSongCategoryV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateUserFavoriteSongCategoryV1Request.displayName = 'proto.jp.singcolor.UpdateUserFavoriteSongCategoryV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateUserFavoriteSongCategoryV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateUserFavoriteSongCategoryV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateUserFavoriteSongCategoryV1Response.displayName = 'proto.jp.singcolor.UpdateUserFavoriteSongCategoryV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListListenerFavoriteSongCategoriesV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListListenerFavoriteSongCategoriesV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListListenerFavoriteSongCategoriesV1Request.displayName = 'proto.jp.singcolor.ListListenerFavoriteSongCategoriesV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListListenerFavoriteSongCategoriesV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListListenerFavoriteSongCategoriesV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListListenerFavoriteSongCategoriesV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListListenerFavoriteSongCategoriesV1Response.displayName = 'proto.jp.singcolor.ListListenerFavoriteSongCategoriesV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListUserFavoriteSongCategoriesV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListUserFavoriteSongCategoriesV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListUserFavoriteSongCategoriesV1Request.displayName = 'proto.jp.singcolor.ListUserFavoriteSongCategoriesV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListUserFavoriteSongCategoriesV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListUserFavoriteSongCategoriesV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListUserFavoriteSongCategoriesV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListUserFavoriteSongCategoriesV1Response.displayName = 'proto.jp.singcolor.ListUserFavoriteSongCategoriesV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateFavoriteSongV2Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateFavoriteSongV2Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateFavoriteSongV2Request.displayName = 'proto.jp.singcolor.UpdateFavoriteSongV2Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateFavoriteSongV2Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateFavoriteSongV2Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateFavoriteSongV2Response.displayName = 'proto.jp.singcolor.UpdateFavoriteSongV2Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateListenerFavoriteSongV2Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateListenerFavoriteSongV2Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateListenerFavoriteSongV2Request.displayName = 'proto.jp.singcolor.UpdateListenerFavoriteSongV2Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateListenerFavoriteSongV2Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateListenerFavoriteSongV2Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateListenerFavoriteSongV2Response.displayName = 'proto.jp.singcolor.UpdateListenerFavoriteSongV2Response';
}
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListSingersV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListSingersV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListSingersV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListSingersV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            page: jspb.Message.getFieldWithDefault(msg, 1, 0),
            limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
            sort: jspb.Message.getFieldWithDefault(msg, 3, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListSingersV1Request}
 */
proto.jp.singcolor.ListSingersV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListSingersV1Request;
    return proto.jp.singcolor.ListSingersV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListSingersV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListSingersV1Request}
 */
proto.jp.singcolor.ListSingersV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setPage(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setLimit(value);
                break;
            case 3:
                var value = /** @type {!proto.jp.singcolor.ListSingersV1Request.ListSingersSort} */ (reader.readEnum());
                msg.setSort(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListSingersV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListSingersV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListSingersV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListSingersV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPage();
    if (f !== 0) {
        writer.writeUint64(1, f);
    }
    f = message.getLimit();
    if (f !== 0) {
        writer.writeUint64(2, f);
    }
    f = message.getSort();
    if (f !== 0.0) {
        writer.writeEnum(3, f);
    }
};
/**
 * @enum {number}
 */
proto.jp.singcolor.ListSingersV1Request.ListSingersSort = {
    LIST_SINGER_SORT_UNSPECIFIED: 0,
    LIST_SINGER_SORT_POPULAR: 1,
    LIST_SINGER_SORT_KANA: 2,
    LIST_SINGER_SORT_RECOMMEND: 3
};
/**
 * optional uint64 page = 1;
 * @return {number}
 */
proto.jp.singcolor.ListSingersV1Request.prototype.getPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListSingersV1Request} returns this
 */
proto.jp.singcolor.ListSingersV1Request.prototype.setPage = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
};
/**
 * optional uint64 limit = 2;
 * @return {number}
 */
proto.jp.singcolor.ListSingersV1Request.prototype.getLimit = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListSingersV1Request} returns this
 */
proto.jp.singcolor.ListSingersV1Request.prototype.setLimit = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional ListSingersSort sort = 3;
 * @return {!proto.jp.singcolor.ListSingersV1Request.ListSingersSort}
 */
proto.jp.singcolor.ListSingersV1Request.prototype.getSort = function () {
    return /** @type {!proto.jp.singcolor.ListSingersV1Request.ListSingersSort} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {!proto.jp.singcolor.ListSingersV1Request.ListSingersSort} value
 * @return {!proto.jp.singcolor.ListSingersV1Request} returns this
 */
proto.jp.singcolor.ListSingersV1Request.prototype.setSort = function (value) {
    return jspb.Message.setProto3EnumField(this, 3, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListSingersV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListSingersV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListSingersV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListSingersV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListSingersV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            singersList: jspb.Message.toObjectList(msg.getSingersList(), karaoke_entity_pb.Singer.toObject, includeInstance),
            currentPage: jspb.Message.getFieldWithDefault(msg, 2, 0),
            prevPage: jspb.Message.getFieldWithDefault(msg, 3, 0),
            nextPage: jspb.Message.getFieldWithDefault(msg, 4, 0),
            totalPage: jspb.Message.getFieldWithDefault(msg, 5, 0),
            hasNext: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListSingersV1Response}
 */
proto.jp.singcolor.ListSingersV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListSingersV1Response;
    return proto.jp.singcolor.ListSingersV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListSingersV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListSingersV1Response}
 */
proto.jp.singcolor.ListSingersV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new karaoke_entity_pb.Singer;
                reader.readMessage(value, karaoke_entity_pb.Singer.deserializeBinaryFromReader);
                msg.addSingers(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setCurrentPage(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setPrevPage(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setNextPage(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setTotalPage(value);
                break;
            case 6:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setHasNext(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListSingersV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListSingersV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListSingersV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListSingersV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getSingersList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, karaoke_entity_pb.Singer.serializeBinaryToWriter);
    }
    f = message.getCurrentPage();
    if (f !== 0) {
        writer.writeUint64(2, f);
    }
    f = message.getPrevPage();
    if (f !== 0) {
        writer.writeUint64(3, f);
    }
    f = message.getNextPage();
    if (f !== 0) {
        writer.writeUint64(4, f);
    }
    f = message.getTotalPage();
    if (f !== 0) {
        writer.writeUint64(5, f);
    }
    f = message.getHasNext();
    if (f) {
        writer.writeBool(6, f);
    }
};
/**
 * repeated Singer singers = 1;
 * @return {!Array<!proto.jp.singcolor.Singer>}
 */
proto.jp.singcolor.ListSingersV1Response.prototype.getSingersList = function () {
    return /** @type{!Array<!proto.jp.singcolor.Singer>} */ (jspb.Message.getRepeatedWrapperField(this, karaoke_entity_pb.Singer, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.Singer>} value
 * @return {!proto.jp.singcolor.ListSingersV1Response} returns this
*/
proto.jp.singcolor.ListSingersV1Response.prototype.setSingersList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.Singer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.Singer}
 */
proto.jp.singcolor.ListSingersV1Response.prototype.addSingers = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.Singer, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListSingersV1Response} returns this
 */
proto.jp.singcolor.ListSingersV1Response.prototype.clearSingersList = function () {
    return this.setSingersList([]);
};
/**
 * optional uint64 current_page = 2;
 * @return {number}
 */
proto.jp.singcolor.ListSingersV1Response.prototype.getCurrentPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListSingersV1Response} returns this
 */
proto.jp.singcolor.ListSingersV1Response.prototype.setCurrentPage = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional uint64 prev_page = 3;
 * @return {number}
 */
proto.jp.singcolor.ListSingersV1Response.prototype.getPrevPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListSingersV1Response} returns this
 */
proto.jp.singcolor.ListSingersV1Response.prototype.setPrevPage = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional uint64 next_page = 4;
 * @return {number}
 */
proto.jp.singcolor.ListSingersV1Response.prototype.getNextPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListSingersV1Response} returns this
 */
proto.jp.singcolor.ListSingersV1Response.prototype.setNextPage = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * optional uint64 total_page = 5;
 * @return {number}
 */
proto.jp.singcolor.ListSingersV1Response.prototype.getTotalPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListSingersV1Response} returns this
 */
proto.jp.singcolor.ListSingersV1Response.prototype.setTotalPage = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
/**
 * optional bool has_next = 6;
 * @return {boolean}
 */
proto.jp.singcolor.ListSingersV1Response.prototype.getHasNext = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.ListSingersV1Response} returns this
 */
proto.jp.singcolor.ListSingersV1Response.prototype.setHasNext = function (value) {
    return jspb.Message.setProto3BooleanField(this, 6, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.UpdateFavoriteSingersV1Request.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateFavoriteSingersV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateFavoriteSingersV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateFavoriteSingersV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateFavoriteSingersV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            singerIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateFavoriteSingersV1Request}
 */
proto.jp.singcolor.UpdateFavoriteSingersV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateFavoriteSingersV1Request;
    return proto.jp.singcolor.UpdateFavoriteSingersV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateFavoriteSingersV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateFavoriteSingersV1Request}
 */
proto.jp.singcolor.UpdateFavoriteSingersV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.addSingerIds(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateFavoriteSingersV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateFavoriteSingersV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateFavoriteSingersV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateFavoriteSingersV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getSingerIdsList();
    if (f.length > 0) {
        writer.writeRepeatedString(1, f);
    }
};
/**
 * repeated string singer_ids = 1;
 * @return {!Array<string>}
 */
proto.jp.singcolor.UpdateFavoriteSingersV1Request.prototype.getSingerIdsList = function () {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};
/**
 * @param {!Array<string>} value
 * @return {!proto.jp.singcolor.UpdateFavoriteSingersV1Request} returns this
 */
proto.jp.singcolor.UpdateFavoriteSingersV1Request.prototype.setSingerIdsList = function (value) {
    return jspb.Message.setField(this, 1, value || []);
};
/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.UpdateFavoriteSingersV1Request} returns this
 */
proto.jp.singcolor.UpdateFavoriteSingersV1Request.prototype.addSingerIds = function (value, opt_index) {
    return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.UpdateFavoriteSingersV1Request} returns this
 */
proto.jp.singcolor.UpdateFavoriteSingersV1Request.prototype.clearSingerIdsList = function () {
    return this.setSingerIdsList([]);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.UpdateFavoriteSingersV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateFavoriteSingersV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateFavoriteSingersV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateFavoriteSingersV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateFavoriteSingersV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            singersList: jspb.Message.toObjectList(msg.getSingersList(), karaoke_entity_pb.Singer.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateFavoriteSingersV1Response}
 */
proto.jp.singcolor.UpdateFavoriteSingersV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateFavoriteSingersV1Response;
    return proto.jp.singcolor.UpdateFavoriteSingersV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateFavoriteSingersV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateFavoriteSingersV1Response}
 */
proto.jp.singcolor.UpdateFavoriteSingersV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new karaoke_entity_pb.Singer;
                reader.readMessage(value, karaoke_entity_pb.Singer.deserializeBinaryFromReader);
                msg.addSingers(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateFavoriteSingersV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateFavoriteSingersV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateFavoriteSingersV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateFavoriteSingersV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getSingersList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, karaoke_entity_pb.Singer.serializeBinaryToWriter);
    }
};
/**
 * repeated Singer singers = 1;
 * @return {!Array<!proto.jp.singcolor.Singer>}
 */
proto.jp.singcolor.UpdateFavoriteSingersV1Response.prototype.getSingersList = function () {
    return /** @type{!Array<!proto.jp.singcolor.Singer>} */ (jspb.Message.getRepeatedWrapperField(this, karaoke_entity_pb.Singer, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.Singer>} value
 * @return {!proto.jp.singcolor.UpdateFavoriteSingersV1Response} returns this
*/
proto.jp.singcolor.UpdateFavoriteSingersV1Response.prototype.setSingersList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.Singer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.Singer}
 */
proto.jp.singcolor.UpdateFavoriteSingersV1Response.prototype.addSingers = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.Singer, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.UpdateFavoriteSingersV1Response} returns this
 */
proto.jp.singcolor.UpdateFavoriteSingersV1Response.prototype.clearSingersList = function () {
    return this.setSingersList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListSongsV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListSongsV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListSongsV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListSongsV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            pageToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
            limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
            sort: jspb.Message.getFieldWithDefault(msg, 3, 0),
            singerId: jspb.Message.getFieldWithDefault(msg, 4, ""),
            liverId: jspb.Message.getFieldWithDefault(msg, 5, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListSongsV1Request}
 */
proto.jp.singcolor.ListSongsV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListSongsV1Request;
    return proto.jp.singcolor.ListSongsV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListSongsV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListSongsV1Request}
 */
proto.jp.singcolor.ListSongsV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setPageToken(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setLimit(value);
                break;
            case 3:
                var value = /** @type {!proto.jp.singcolor.ListSongsV1Request.ListSongsSort} */ (reader.readEnum());
                msg.setSort(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setSingerId(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiverId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListSongsV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListSongsV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListSongsV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListSongsV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPageToken();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getLimit();
    if (f !== 0) {
        writer.writeUint64(2, f);
    }
    f = message.getSort();
    if (f !== 0.0) {
        writer.writeEnum(3, f);
    }
    f = message.getSingerId();
    if (f.length > 0) {
        writer.writeString(4, f);
    }
    f = message.getLiverId();
    if (f.length > 0) {
        writer.writeString(5, f);
    }
};
/**
 * @enum {number}
 */
proto.jp.singcolor.ListSongsV1Request.ListSongsSort = {
    LIST_SONGS_SORT_UNSPECIFIED: 0,
    LIST_SONGS_SORT_POPULAR: 3,
    LIST_SONGS_SORT_KANA_AND_POPULAR: 4,
    LIST_SONGS_SORT_PUBLISHED_AT_DESC_AND_POPULAR: 5,
    LIST_SONGS_SORT_BPM_AND_POPULAR: 6
};
/**
 * optional string page_token = 1;
 * @return {string}
 */
proto.jp.singcolor.ListSongsV1Request.prototype.getPageToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListSongsV1Request} returns this
 */
proto.jp.singcolor.ListSongsV1Request.prototype.setPageToken = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional uint64 limit = 2;
 * @return {number}
 */
proto.jp.singcolor.ListSongsV1Request.prototype.getLimit = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListSongsV1Request} returns this
 */
proto.jp.singcolor.ListSongsV1Request.prototype.setLimit = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional ListSongsSort sort = 3;
 * @return {!proto.jp.singcolor.ListSongsV1Request.ListSongsSort}
 */
proto.jp.singcolor.ListSongsV1Request.prototype.getSort = function () {
    return /** @type {!proto.jp.singcolor.ListSongsV1Request.ListSongsSort} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {!proto.jp.singcolor.ListSongsV1Request.ListSongsSort} value
 * @return {!proto.jp.singcolor.ListSongsV1Request} returns this
 */
proto.jp.singcolor.ListSongsV1Request.prototype.setSort = function (value) {
    return jspb.Message.setProto3EnumField(this, 3, value);
};
/**
 * optional string singer_id = 4;
 * @return {string}
 */
proto.jp.singcolor.ListSongsV1Request.prototype.getSingerId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListSongsV1Request} returns this
 */
proto.jp.singcolor.ListSongsV1Request.prototype.setSingerId = function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
};
/**
 * optional string liver_id = 5;
 * @return {string}
 */
proto.jp.singcolor.ListSongsV1Request.prototype.getLiverId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListSongsV1Request} returns this
 */
proto.jp.singcolor.ListSongsV1Request.prototype.setLiverId = function (value) {
    return jspb.Message.setProto3StringField(this, 5, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListSongsV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListSongsV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListSongsV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListSongsV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListSongsV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            songsList: jspb.Message.toObjectList(msg.getSongsList(), karaoke_entity_pb.Song.toObject, includeInstance),
            nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
            hasNext: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListSongsV1Response}
 */
proto.jp.singcolor.ListSongsV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListSongsV1Response;
    return proto.jp.singcolor.ListSongsV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListSongsV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListSongsV1Response}
 */
proto.jp.singcolor.ListSongsV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new karaoke_entity_pb.Song;
                reader.readMessage(value, karaoke_entity_pb.Song.deserializeBinaryFromReader);
                msg.addSongs(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setNextPageToken(value);
                break;
            case 3:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setHasNext(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListSongsV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListSongsV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListSongsV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListSongsV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getSongsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, karaoke_entity_pb.Song.serializeBinaryToWriter);
    }
    f = message.getNextPageToken();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getHasNext();
    if (f) {
        writer.writeBool(3, f);
    }
};
/**
 * repeated Song songs = 1;
 * @return {!Array<!proto.jp.singcolor.Song>}
 */
proto.jp.singcolor.ListSongsV1Response.prototype.getSongsList = function () {
    return /** @type{!Array<!proto.jp.singcolor.Song>} */ (jspb.Message.getRepeatedWrapperField(this, karaoke_entity_pb.Song, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.Song>} value
 * @return {!proto.jp.singcolor.ListSongsV1Response} returns this
*/
proto.jp.singcolor.ListSongsV1Response.prototype.setSongsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.Song=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.Song}
 */
proto.jp.singcolor.ListSongsV1Response.prototype.addSongs = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.Song, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListSongsV1Response} returns this
 */
proto.jp.singcolor.ListSongsV1Response.prototype.clearSongsList = function () {
    return this.setSongsList([]);
};
/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.jp.singcolor.ListSongsV1Response.prototype.getNextPageToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListSongsV1Response} returns this
 */
proto.jp.singcolor.ListSongsV1Response.prototype.setNextPageToken = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional bool has_next = 3;
 * @return {boolean}
 */
proto.jp.singcolor.ListSongsV1Response.prototype.getHasNext = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.ListSongsV1Response} returns this
 */
proto.jp.singcolor.ListSongsV1Response.prototype.setHasNext = function (value) {
    return jspb.Message.setProto3BooleanField(this, 3, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.SearchSongsV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.SearchSongsV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.SearchSongsV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.SearchSongsV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            page: jspb.Message.getFieldWithDefault(msg, 1, 0),
            limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
            keyword: jspb.Message.getFieldWithDefault(msg, 3, ""),
            liverId: jspb.Message.getFieldWithDefault(msg, 4, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.SearchSongsV1Request}
 */
proto.jp.singcolor.SearchSongsV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.SearchSongsV1Request;
    return proto.jp.singcolor.SearchSongsV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.SearchSongsV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.SearchSongsV1Request}
 */
proto.jp.singcolor.SearchSongsV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setPage(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setLimit(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setKeyword(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiverId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.SearchSongsV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.SearchSongsV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.SearchSongsV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.SearchSongsV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPage();
    if (f !== 0) {
        writer.writeUint64(1, f);
    }
    f = message.getLimit();
    if (f !== 0) {
        writer.writeUint64(2, f);
    }
    f = message.getKeyword();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
    f = message.getLiverId();
    if (f.length > 0) {
        writer.writeString(4, f);
    }
};
/**
 * optional uint64 page = 1;
 * @return {number}
 */
proto.jp.singcolor.SearchSongsV1Request.prototype.getPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.SearchSongsV1Request} returns this
 */
proto.jp.singcolor.SearchSongsV1Request.prototype.setPage = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
};
/**
 * optional uint64 limit = 2;
 * @return {number}
 */
proto.jp.singcolor.SearchSongsV1Request.prototype.getLimit = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.SearchSongsV1Request} returns this
 */
proto.jp.singcolor.SearchSongsV1Request.prototype.setLimit = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional string keyword = 3;
 * @return {string}
 */
proto.jp.singcolor.SearchSongsV1Request.prototype.getKeyword = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.SearchSongsV1Request} returns this
 */
proto.jp.singcolor.SearchSongsV1Request.prototype.setKeyword = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * optional string liver_id = 4;
 * @return {string}
 */
proto.jp.singcolor.SearchSongsV1Request.prototype.getLiverId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.SearchSongsV1Request} returns this
 */
proto.jp.singcolor.SearchSongsV1Request.prototype.setLiverId = function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.SearchSongsV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.SearchSongsV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.SearchSongsV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.SearchSongsV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.SearchSongsV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            songsList: jspb.Message.toObjectList(msg.getSongsList(), karaoke_entity_pb.Song.toObject, includeInstance),
            currentPage: jspb.Message.getFieldWithDefault(msg, 2, 0),
            prevPage: jspb.Message.getFieldWithDefault(msg, 3, 0),
            nextPage: jspb.Message.getFieldWithDefault(msg, 4, 0),
            totalPage: jspb.Message.getFieldWithDefault(msg, 5, 0),
            hasNext: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.SearchSongsV1Response}
 */
proto.jp.singcolor.SearchSongsV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.SearchSongsV1Response;
    return proto.jp.singcolor.SearchSongsV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.SearchSongsV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.SearchSongsV1Response}
 */
proto.jp.singcolor.SearchSongsV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new karaoke_entity_pb.Song;
                reader.readMessage(value, karaoke_entity_pb.Song.deserializeBinaryFromReader);
                msg.addSongs(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setCurrentPage(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setPrevPage(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setNextPage(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setTotalPage(value);
                break;
            case 6:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setHasNext(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.SearchSongsV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.SearchSongsV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.SearchSongsV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.SearchSongsV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getSongsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, karaoke_entity_pb.Song.serializeBinaryToWriter);
    }
    f = message.getCurrentPage();
    if (f !== 0) {
        writer.writeUint64(2, f);
    }
    f = message.getPrevPage();
    if (f !== 0) {
        writer.writeUint64(3, f);
    }
    f = message.getNextPage();
    if (f !== 0) {
        writer.writeUint64(4, f);
    }
    f = message.getTotalPage();
    if (f !== 0) {
        writer.writeUint64(5, f);
    }
    f = message.getHasNext();
    if (f) {
        writer.writeBool(6, f);
    }
};
/**
 * repeated Song songs = 1;
 * @return {!Array<!proto.jp.singcolor.Song>}
 */
proto.jp.singcolor.SearchSongsV1Response.prototype.getSongsList = function () {
    return /** @type{!Array<!proto.jp.singcolor.Song>} */ (jspb.Message.getRepeatedWrapperField(this, karaoke_entity_pb.Song, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.Song>} value
 * @return {!proto.jp.singcolor.SearchSongsV1Response} returns this
*/
proto.jp.singcolor.SearchSongsV1Response.prototype.setSongsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.Song=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.Song}
 */
proto.jp.singcolor.SearchSongsV1Response.prototype.addSongs = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.Song, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.SearchSongsV1Response} returns this
 */
proto.jp.singcolor.SearchSongsV1Response.prototype.clearSongsList = function () {
    return this.setSongsList([]);
};
/**
 * optional uint64 current_page = 2;
 * @return {number}
 */
proto.jp.singcolor.SearchSongsV1Response.prototype.getCurrentPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.SearchSongsV1Response} returns this
 */
proto.jp.singcolor.SearchSongsV1Response.prototype.setCurrentPage = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional uint64 prev_page = 3;
 * @return {number}
 */
proto.jp.singcolor.SearchSongsV1Response.prototype.getPrevPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.SearchSongsV1Response} returns this
 */
proto.jp.singcolor.SearchSongsV1Response.prototype.setPrevPage = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional uint64 next_page = 4;
 * @return {number}
 */
proto.jp.singcolor.SearchSongsV1Response.prototype.getNextPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.SearchSongsV1Response} returns this
 */
proto.jp.singcolor.SearchSongsV1Response.prototype.setNextPage = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * optional uint64 total_page = 5;
 * @return {number}
 */
proto.jp.singcolor.SearchSongsV1Response.prototype.getTotalPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.SearchSongsV1Response} returns this
 */
proto.jp.singcolor.SearchSongsV1Response.prototype.setTotalPage = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
/**
 * optional bool has_next = 6;
 * @return {boolean}
 */
proto.jp.singcolor.SearchSongsV1Response.prototype.getHasNext = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.SearchSongsV1Response} returns this
 */
proto.jp.singcolor.SearchSongsV1Response.prototype.setHasNext = function (value) {
    return jspb.Message.setProto3BooleanField(this, 6, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.SearchSingersV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.SearchSingersV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.SearchSingersV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.SearchSingersV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            page: jspb.Message.getFieldWithDefault(msg, 1, 0),
            limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
            keyword: jspb.Message.getFieldWithDefault(msg, 3, ""),
            liverId: jspb.Message.getFieldWithDefault(msg, 4, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.SearchSingersV1Request}
 */
proto.jp.singcolor.SearchSingersV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.SearchSingersV1Request;
    return proto.jp.singcolor.SearchSingersV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.SearchSingersV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.SearchSingersV1Request}
 */
proto.jp.singcolor.SearchSingersV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setPage(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setLimit(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setKeyword(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiverId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.SearchSingersV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.SearchSingersV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.SearchSingersV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.SearchSingersV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPage();
    if (f !== 0) {
        writer.writeUint64(1, f);
    }
    f = message.getLimit();
    if (f !== 0) {
        writer.writeUint64(2, f);
    }
    f = message.getKeyword();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
    f = message.getLiverId();
    if (f.length > 0) {
        writer.writeString(4, f);
    }
};
/**
 * optional uint64 page = 1;
 * @return {number}
 */
proto.jp.singcolor.SearchSingersV1Request.prototype.getPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.SearchSingersV1Request} returns this
 */
proto.jp.singcolor.SearchSingersV1Request.prototype.setPage = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
};
/**
 * optional uint64 limit = 2;
 * @return {number}
 */
proto.jp.singcolor.SearchSingersV1Request.prototype.getLimit = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.SearchSingersV1Request} returns this
 */
proto.jp.singcolor.SearchSingersV1Request.prototype.setLimit = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional string keyword = 3;
 * @return {string}
 */
proto.jp.singcolor.SearchSingersV1Request.prototype.getKeyword = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.SearchSingersV1Request} returns this
 */
proto.jp.singcolor.SearchSingersV1Request.prototype.setKeyword = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * optional string liver_id = 4;
 * @return {string}
 */
proto.jp.singcolor.SearchSingersV1Request.prototype.getLiverId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.SearchSingersV1Request} returns this
 */
proto.jp.singcolor.SearchSingersV1Request.prototype.setLiverId = function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.SearchSingersV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.SearchSingersV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.SearchSingersV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.SearchSingersV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.SearchSingersV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            singersList: jspb.Message.toObjectList(msg.getSingersList(), karaoke_entity_pb.Singer.toObject, includeInstance),
            currentPage: jspb.Message.getFieldWithDefault(msg, 2, 0),
            prevPage: jspb.Message.getFieldWithDefault(msg, 3, 0),
            nextPage: jspb.Message.getFieldWithDefault(msg, 4, 0),
            totalPage: jspb.Message.getFieldWithDefault(msg, 5, 0),
            hasNext: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.SearchSingersV1Response}
 */
proto.jp.singcolor.SearchSingersV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.SearchSingersV1Response;
    return proto.jp.singcolor.SearchSingersV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.SearchSingersV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.SearchSingersV1Response}
 */
proto.jp.singcolor.SearchSingersV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new karaoke_entity_pb.Singer;
                reader.readMessage(value, karaoke_entity_pb.Singer.deserializeBinaryFromReader);
                msg.addSingers(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setCurrentPage(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setPrevPage(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setNextPage(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setTotalPage(value);
                break;
            case 6:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setHasNext(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.SearchSingersV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.SearchSingersV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.SearchSingersV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.SearchSingersV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getSingersList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, karaoke_entity_pb.Singer.serializeBinaryToWriter);
    }
    f = message.getCurrentPage();
    if (f !== 0) {
        writer.writeUint64(2, f);
    }
    f = message.getPrevPage();
    if (f !== 0) {
        writer.writeUint64(3, f);
    }
    f = message.getNextPage();
    if (f !== 0) {
        writer.writeUint64(4, f);
    }
    f = message.getTotalPage();
    if (f !== 0) {
        writer.writeUint64(5, f);
    }
    f = message.getHasNext();
    if (f) {
        writer.writeBool(6, f);
    }
};
/**
 * repeated Singer singers = 1;
 * @return {!Array<!proto.jp.singcolor.Singer>}
 */
proto.jp.singcolor.SearchSingersV1Response.prototype.getSingersList = function () {
    return /** @type{!Array<!proto.jp.singcolor.Singer>} */ (jspb.Message.getRepeatedWrapperField(this, karaoke_entity_pb.Singer, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.Singer>} value
 * @return {!proto.jp.singcolor.SearchSingersV1Response} returns this
*/
proto.jp.singcolor.SearchSingersV1Response.prototype.setSingersList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.Singer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.Singer}
 */
proto.jp.singcolor.SearchSingersV1Response.prototype.addSingers = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.Singer, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.SearchSingersV1Response} returns this
 */
proto.jp.singcolor.SearchSingersV1Response.prototype.clearSingersList = function () {
    return this.setSingersList([]);
};
/**
 * optional uint64 current_page = 2;
 * @return {number}
 */
proto.jp.singcolor.SearchSingersV1Response.prototype.getCurrentPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.SearchSingersV1Response} returns this
 */
proto.jp.singcolor.SearchSingersV1Response.prototype.setCurrentPage = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional uint64 prev_page = 3;
 * @return {number}
 */
proto.jp.singcolor.SearchSingersV1Response.prototype.getPrevPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.SearchSingersV1Response} returns this
 */
proto.jp.singcolor.SearchSingersV1Response.prototype.setPrevPage = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional uint64 next_page = 4;
 * @return {number}
 */
proto.jp.singcolor.SearchSingersV1Response.prototype.getNextPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.SearchSingersV1Response} returns this
 */
proto.jp.singcolor.SearchSingersV1Response.prototype.setNextPage = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * optional uint64 total_page = 5;
 * @return {number}
 */
proto.jp.singcolor.SearchSingersV1Response.prototype.getTotalPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.SearchSingersV1Response} returns this
 */
proto.jp.singcolor.SearchSingersV1Response.prototype.setTotalPage = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
/**
 * optional bool has_next = 6;
 * @return {boolean}
 */
proto.jp.singcolor.SearchSingersV1Response.prototype.getHasNext = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.SearchSingersV1Response} returns this
 */
proto.jp.singcolor.SearchSingersV1Response.prototype.setHasNext = function (value) {
    return jspb.Message.setProto3BooleanField(this, 6, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListLatestPopularSongsV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListLatestPopularSongsV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListLatestPopularSongsV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListLatestPopularSongsV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            pageToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
            limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
            liverId: jspb.Message.getFieldWithDefault(msg, 3, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListLatestPopularSongsV1Request}
 */
proto.jp.singcolor.ListLatestPopularSongsV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListLatestPopularSongsV1Request;
    return proto.jp.singcolor.ListLatestPopularSongsV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListLatestPopularSongsV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListLatestPopularSongsV1Request}
 */
proto.jp.singcolor.ListLatestPopularSongsV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setPageToken(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setLimit(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiverId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListLatestPopularSongsV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListLatestPopularSongsV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListLatestPopularSongsV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListLatestPopularSongsV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPageToken();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getLimit();
    if (f !== 0) {
        writer.writeUint64(2, f);
    }
    f = message.getLiverId();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
};
/**
 * optional string page_token = 1;
 * @return {string}
 */
proto.jp.singcolor.ListLatestPopularSongsV1Request.prototype.getPageToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListLatestPopularSongsV1Request} returns this
 */
proto.jp.singcolor.ListLatestPopularSongsV1Request.prototype.setPageToken = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional uint64 limit = 2;
 * @return {number}
 */
proto.jp.singcolor.ListLatestPopularSongsV1Request.prototype.getLimit = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListLatestPopularSongsV1Request} returns this
 */
proto.jp.singcolor.ListLatestPopularSongsV1Request.prototype.setLimit = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional string liver_id = 3;
 * @return {string}
 */
proto.jp.singcolor.ListLatestPopularSongsV1Request.prototype.getLiverId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListLatestPopularSongsV1Request} returns this
 */
proto.jp.singcolor.ListLatestPopularSongsV1Request.prototype.setLiverId = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListLatestPopularSongsV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListLatestPopularSongsV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListLatestPopularSongsV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListLatestPopularSongsV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListLatestPopularSongsV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            songsList: jspb.Message.toObjectList(msg.getSongsList(), karaoke_entity_pb.Song.toObject, includeInstance),
            nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
            hasNext: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListLatestPopularSongsV1Response}
 */
proto.jp.singcolor.ListLatestPopularSongsV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListLatestPopularSongsV1Response;
    return proto.jp.singcolor.ListLatestPopularSongsV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListLatestPopularSongsV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListLatestPopularSongsV1Response}
 */
proto.jp.singcolor.ListLatestPopularSongsV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new karaoke_entity_pb.Song;
                reader.readMessage(value, karaoke_entity_pb.Song.deserializeBinaryFromReader);
                msg.addSongs(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setNextPageToken(value);
                break;
            case 3:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setHasNext(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListLatestPopularSongsV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListLatestPopularSongsV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListLatestPopularSongsV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListLatestPopularSongsV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getSongsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, karaoke_entity_pb.Song.serializeBinaryToWriter);
    }
    f = message.getNextPageToken();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getHasNext();
    if (f) {
        writer.writeBool(3, f);
    }
};
/**
 * repeated Song songs = 1;
 * @return {!Array<!proto.jp.singcolor.Song>}
 */
proto.jp.singcolor.ListLatestPopularSongsV1Response.prototype.getSongsList = function () {
    return /** @type{!Array<!proto.jp.singcolor.Song>} */ (jspb.Message.getRepeatedWrapperField(this, karaoke_entity_pb.Song, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.Song>} value
 * @return {!proto.jp.singcolor.ListLatestPopularSongsV1Response} returns this
*/
proto.jp.singcolor.ListLatestPopularSongsV1Response.prototype.setSongsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.Song=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.Song}
 */
proto.jp.singcolor.ListLatestPopularSongsV1Response.prototype.addSongs = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.Song, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListLatestPopularSongsV1Response} returns this
 */
proto.jp.singcolor.ListLatestPopularSongsV1Response.prototype.clearSongsList = function () {
    return this.setSongsList([]);
};
/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.jp.singcolor.ListLatestPopularSongsV1Response.prototype.getNextPageToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListLatestPopularSongsV1Response} returns this
 */
proto.jp.singcolor.ListLatestPopularSongsV1Response.prototype.setNextPageToken = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional bool has_next = 3;
 * @return {boolean}
 */
proto.jp.singcolor.ListLatestPopularSongsV1Response.prototype.getHasNext = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.ListLatestPopularSongsV1Response} returns this
 */
proto.jp.singcolor.ListLatestPopularSongsV1Response.prototype.setHasNext = function (value) {
    return jspb.Message.setProto3BooleanField(this, 3, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListWorldPopularSongsV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListWorldPopularSongsV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListWorldPopularSongsV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListWorldPopularSongsV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            pageToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
            limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
            liverId: jspb.Message.getFieldWithDefault(msg, 3, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListWorldPopularSongsV1Request}
 */
proto.jp.singcolor.ListWorldPopularSongsV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListWorldPopularSongsV1Request;
    return proto.jp.singcolor.ListWorldPopularSongsV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListWorldPopularSongsV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListWorldPopularSongsV1Request}
 */
proto.jp.singcolor.ListWorldPopularSongsV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setPageToken(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setLimit(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiverId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListWorldPopularSongsV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListWorldPopularSongsV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListWorldPopularSongsV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListWorldPopularSongsV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPageToken();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getLimit();
    if (f !== 0) {
        writer.writeUint64(2, f);
    }
    f = message.getLiverId();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
};
/**
 * optional string page_token = 1;
 * @return {string}
 */
proto.jp.singcolor.ListWorldPopularSongsV1Request.prototype.getPageToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListWorldPopularSongsV1Request} returns this
 */
proto.jp.singcolor.ListWorldPopularSongsV1Request.prototype.setPageToken = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional uint64 limit = 2;
 * @return {number}
 */
proto.jp.singcolor.ListWorldPopularSongsV1Request.prototype.getLimit = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListWorldPopularSongsV1Request} returns this
 */
proto.jp.singcolor.ListWorldPopularSongsV1Request.prototype.setLimit = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional string liver_id = 3;
 * @return {string}
 */
proto.jp.singcolor.ListWorldPopularSongsV1Request.prototype.getLiverId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListWorldPopularSongsV1Request} returns this
 */
proto.jp.singcolor.ListWorldPopularSongsV1Request.prototype.setLiverId = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListWorldPopularSongsV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListWorldPopularSongsV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListWorldPopularSongsV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListWorldPopularSongsV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListWorldPopularSongsV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            songsList: jspb.Message.toObjectList(msg.getSongsList(), karaoke_entity_pb.Song.toObject, includeInstance),
            nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
            hasNext: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListWorldPopularSongsV1Response}
 */
proto.jp.singcolor.ListWorldPopularSongsV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListWorldPopularSongsV1Response;
    return proto.jp.singcolor.ListWorldPopularSongsV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListWorldPopularSongsV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListWorldPopularSongsV1Response}
 */
proto.jp.singcolor.ListWorldPopularSongsV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new karaoke_entity_pb.Song;
                reader.readMessage(value, karaoke_entity_pb.Song.deserializeBinaryFromReader);
                msg.addSongs(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setNextPageToken(value);
                break;
            case 3:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setHasNext(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListWorldPopularSongsV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListWorldPopularSongsV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListWorldPopularSongsV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListWorldPopularSongsV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getSongsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, karaoke_entity_pb.Song.serializeBinaryToWriter);
    }
    f = message.getNextPageToken();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getHasNext();
    if (f) {
        writer.writeBool(3, f);
    }
};
/**
 * repeated Song songs = 1;
 * @return {!Array<!proto.jp.singcolor.Song>}
 */
proto.jp.singcolor.ListWorldPopularSongsV1Response.prototype.getSongsList = function () {
    return /** @type{!Array<!proto.jp.singcolor.Song>} */ (jspb.Message.getRepeatedWrapperField(this, karaoke_entity_pb.Song, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.Song>} value
 * @return {!proto.jp.singcolor.ListWorldPopularSongsV1Response} returns this
*/
proto.jp.singcolor.ListWorldPopularSongsV1Response.prototype.setSongsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.Song=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.Song}
 */
proto.jp.singcolor.ListWorldPopularSongsV1Response.prototype.addSongs = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.Song, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListWorldPopularSongsV1Response} returns this
 */
proto.jp.singcolor.ListWorldPopularSongsV1Response.prototype.clearSongsList = function () {
    return this.setSongsList([]);
};
/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.jp.singcolor.ListWorldPopularSongsV1Response.prototype.getNextPageToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListWorldPopularSongsV1Response} returns this
 */
proto.jp.singcolor.ListWorldPopularSongsV1Response.prototype.setNextPageToken = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional bool has_next = 3;
 * @return {boolean}
 */
proto.jp.singcolor.ListWorldPopularSongsV1Response.prototype.getHasNext = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.ListWorldPopularSongsV1Response} returns this
 */
proto.jp.singcolor.ListWorldPopularSongsV1Response.prototype.setHasNext = function (value) {
    return jspb.Message.setProto3BooleanField(this, 3, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListFavoriteSongsV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListFavoriteSongsV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListFavoriteSongsV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListFavoriteSongsV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            pageToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
            limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
            sort: jspb.Message.getFieldWithDefault(msg, 3, 0),
            liverId: jspb.Message.getFieldWithDefault(msg, 4, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListFavoriteSongsV1Request}
 */
proto.jp.singcolor.ListFavoriteSongsV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListFavoriteSongsV1Request;
    return proto.jp.singcolor.ListFavoriteSongsV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListFavoriteSongsV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListFavoriteSongsV1Request}
 */
proto.jp.singcolor.ListFavoriteSongsV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setPageToken(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setLimit(value);
                break;
            case 3:
                var value = /** @type {!proto.jp.singcolor.LiverAndListenerFavoriteSongsSort} */ (reader.readEnum());
                msg.setSort(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiverId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListFavoriteSongsV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListFavoriteSongsV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListFavoriteSongsV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListFavoriteSongsV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPageToken();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getLimit();
    if (f !== 0) {
        writer.writeUint64(2, f);
    }
    f = message.getSort();
    if (f !== 0.0) {
        writer.writeEnum(3, f);
    }
    f = message.getLiverId();
    if (f.length > 0) {
        writer.writeString(4, f);
    }
};
/**
 * optional string page_token = 1;
 * @return {string}
 */
proto.jp.singcolor.ListFavoriteSongsV1Request.prototype.getPageToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListFavoriteSongsV1Request} returns this
 */
proto.jp.singcolor.ListFavoriteSongsV1Request.prototype.setPageToken = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional uint64 limit = 2;
 * @return {number}
 */
proto.jp.singcolor.ListFavoriteSongsV1Request.prototype.getLimit = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListFavoriteSongsV1Request} returns this
 */
proto.jp.singcolor.ListFavoriteSongsV1Request.prototype.setLimit = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional LiverAndListenerFavoriteSongsSort sort = 3;
 * @return {!proto.jp.singcolor.LiverAndListenerFavoriteSongsSort}
 */
proto.jp.singcolor.ListFavoriteSongsV1Request.prototype.getSort = function () {
    return /** @type {!proto.jp.singcolor.LiverAndListenerFavoriteSongsSort} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {!proto.jp.singcolor.LiverAndListenerFavoriteSongsSort} value
 * @return {!proto.jp.singcolor.ListFavoriteSongsV1Request} returns this
 */
proto.jp.singcolor.ListFavoriteSongsV1Request.prototype.setSort = function (value) {
    return jspb.Message.setProto3EnumField(this, 3, value);
};
/**
 * optional string liver_id = 4;
 * @return {string}
 */
proto.jp.singcolor.ListFavoriteSongsV1Request.prototype.getLiverId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListFavoriteSongsV1Request} returns this
 */
proto.jp.singcolor.ListFavoriteSongsV1Request.prototype.setLiverId = function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListFavoriteSongsV1Response.repeatedFields_ = [1, 4];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListFavoriteSongsV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListFavoriteSongsV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListFavoriteSongsV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListFavoriteSongsV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            songsList: jspb.Message.toObjectList(msg.getSongsList(), karaoke_entity_pb.Song.toObject, includeInstance),
            nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
            hasNext: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
            songsWithCategoryList: jspb.Message.toObjectList(msg.getSongsWithCategoryList(), karaoke_entity_pb.SongWithCategory.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListFavoriteSongsV1Response}
 */
proto.jp.singcolor.ListFavoriteSongsV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListFavoriteSongsV1Response;
    return proto.jp.singcolor.ListFavoriteSongsV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListFavoriteSongsV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListFavoriteSongsV1Response}
 */
proto.jp.singcolor.ListFavoriteSongsV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new karaoke_entity_pb.Song;
                reader.readMessage(value, karaoke_entity_pb.Song.deserializeBinaryFromReader);
                msg.addSongs(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setNextPageToken(value);
                break;
            case 3:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setHasNext(value);
                break;
            case 4:
                var value = new karaoke_entity_pb.SongWithCategory;
                reader.readMessage(value, karaoke_entity_pb.SongWithCategory.deserializeBinaryFromReader);
                msg.addSongsWithCategory(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListFavoriteSongsV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListFavoriteSongsV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListFavoriteSongsV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListFavoriteSongsV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getSongsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, karaoke_entity_pb.Song.serializeBinaryToWriter);
    }
    f = message.getNextPageToken();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getHasNext();
    if (f) {
        writer.writeBool(3, f);
    }
    f = message.getSongsWithCategoryList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(4, f, karaoke_entity_pb.SongWithCategory.serializeBinaryToWriter);
    }
};
/**
 * repeated Song songs = 1;
 * @return {!Array<!proto.jp.singcolor.Song>}
 */
proto.jp.singcolor.ListFavoriteSongsV1Response.prototype.getSongsList = function () {
    return /** @type{!Array<!proto.jp.singcolor.Song>} */ (jspb.Message.getRepeatedWrapperField(this, karaoke_entity_pb.Song, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.Song>} value
 * @return {!proto.jp.singcolor.ListFavoriteSongsV1Response} returns this
*/
proto.jp.singcolor.ListFavoriteSongsV1Response.prototype.setSongsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.Song=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.Song}
 */
proto.jp.singcolor.ListFavoriteSongsV1Response.prototype.addSongs = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.Song, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListFavoriteSongsV1Response} returns this
 */
proto.jp.singcolor.ListFavoriteSongsV1Response.prototype.clearSongsList = function () {
    return this.setSongsList([]);
};
/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.jp.singcolor.ListFavoriteSongsV1Response.prototype.getNextPageToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListFavoriteSongsV1Response} returns this
 */
proto.jp.singcolor.ListFavoriteSongsV1Response.prototype.setNextPageToken = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional bool has_next = 3;
 * @return {boolean}
 */
proto.jp.singcolor.ListFavoriteSongsV1Response.prototype.getHasNext = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.ListFavoriteSongsV1Response} returns this
 */
proto.jp.singcolor.ListFavoriteSongsV1Response.prototype.setHasNext = function (value) {
    return jspb.Message.setProto3BooleanField(this, 3, value);
};
/**
 * repeated SongWithCategory songs_with_category = 4;
 * @return {!Array<!proto.jp.singcolor.SongWithCategory>}
 */
proto.jp.singcolor.ListFavoriteSongsV1Response.prototype.getSongsWithCategoryList = function () {
    return /** @type{!Array<!proto.jp.singcolor.SongWithCategory>} */ (jspb.Message.getRepeatedWrapperField(this, karaoke_entity_pb.SongWithCategory, 4));
};
/**
 * @param {!Array<!proto.jp.singcolor.SongWithCategory>} value
 * @return {!proto.jp.singcolor.ListFavoriteSongsV1Response} returns this
*/
proto.jp.singcolor.ListFavoriteSongsV1Response.prototype.setSongsWithCategoryList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 4, value);
};
/**
 * @param {!proto.jp.singcolor.SongWithCategory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.SongWithCategory}
 */
proto.jp.singcolor.ListFavoriteSongsV1Response.prototype.addSongsWithCategory = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.jp.singcolor.SongWithCategory, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListFavoriteSongsV1Response} returns this
 */
proto.jp.singcolor.ListFavoriteSongsV1Response.prototype.clearSongsWithCategoryList = function () {
    return this.setSongsWithCategoryList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListSingingHistoryV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListSingingHistoryV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListSingingHistoryV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListSingingHistoryV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            pageToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
            limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
            liverId: jspb.Message.getFieldWithDefault(msg, 3, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListSingingHistoryV1Request}
 */
proto.jp.singcolor.ListSingingHistoryV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListSingingHistoryV1Request;
    return proto.jp.singcolor.ListSingingHistoryV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListSingingHistoryV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListSingingHistoryV1Request}
 */
proto.jp.singcolor.ListSingingHistoryV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setPageToken(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setLimit(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiverId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListSingingHistoryV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListSingingHistoryV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListSingingHistoryV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListSingingHistoryV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPageToken();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getLimit();
    if (f !== 0) {
        writer.writeUint64(2, f);
    }
    f = message.getLiverId();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
};
/**
 * optional string page_token = 1;
 * @return {string}
 */
proto.jp.singcolor.ListSingingHistoryV1Request.prototype.getPageToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListSingingHistoryV1Request} returns this
 */
proto.jp.singcolor.ListSingingHistoryV1Request.prototype.setPageToken = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional uint64 limit = 2;
 * @return {number}
 */
proto.jp.singcolor.ListSingingHistoryV1Request.prototype.getLimit = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListSingingHistoryV1Request} returns this
 */
proto.jp.singcolor.ListSingingHistoryV1Request.prototype.setLimit = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional string liver_id = 3;
 * @return {string}
 */
proto.jp.singcolor.ListSingingHistoryV1Request.prototype.getLiverId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListSingingHistoryV1Request} returns this
 */
proto.jp.singcolor.ListSingingHistoryV1Request.prototype.setLiverId = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListSingingHistoryV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListSingingHistoryV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListSingingHistoryV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListSingingHistoryV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListSingingHistoryV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            songsList: jspb.Message.toObjectList(msg.getSongsList(), karaoke_entity_pb.Song.toObject, includeInstance),
            nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
            hasNext: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListSingingHistoryV1Response}
 */
proto.jp.singcolor.ListSingingHistoryV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListSingingHistoryV1Response;
    return proto.jp.singcolor.ListSingingHistoryV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListSingingHistoryV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListSingingHistoryV1Response}
 */
proto.jp.singcolor.ListSingingHistoryV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new karaoke_entity_pb.Song;
                reader.readMessage(value, karaoke_entity_pb.Song.deserializeBinaryFromReader);
                msg.addSongs(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setNextPageToken(value);
                break;
            case 3:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setHasNext(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListSingingHistoryV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListSingingHistoryV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListSingingHistoryV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListSingingHistoryV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getSongsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, karaoke_entity_pb.Song.serializeBinaryToWriter);
    }
    f = message.getNextPageToken();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getHasNext();
    if (f) {
        writer.writeBool(3, f);
    }
};
/**
 * repeated Song songs = 1;
 * @return {!Array<!proto.jp.singcolor.Song>}
 */
proto.jp.singcolor.ListSingingHistoryV1Response.prototype.getSongsList = function () {
    return /** @type{!Array<!proto.jp.singcolor.Song>} */ (jspb.Message.getRepeatedWrapperField(this, karaoke_entity_pb.Song, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.Song>} value
 * @return {!proto.jp.singcolor.ListSingingHistoryV1Response} returns this
*/
proto.jp.singcolor.ListSingingHistoryV1Response.prototype.setSongsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.Song=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.Song}
 */
proto.jp.singcolor.ListSingingHistoryV1Response.prototype.addSongs = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.Song, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListSingingHistoryV1Response} returns this
 */
proto.jp.singcolor.ListSingingHistoryV1Response.prototype.clearSongsList = function () {
    return this.setSongsList([]);
};
/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.jp.singcolor.ListSingingHistoryV1Response.prototype.getNextPageToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListSingingHistoryV1Response} returns this
 */
proto.jp.singcolor.ListSingingHistoryV1Response.prototype.setNextPageToken = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional bool has_next = 3;
 * @return {boolean}
 */
proto.jp.singcolor.ListSingingHistoryV1Response.prototype.getHasNext = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.ListSingingHistoryV1Response} returns this
 */
proto.jp.singcolor.ListSingingHistoryV1Response.prototype.setHasNext = function (value) {
    return jspb.Message.setProto3BooleanField(this, 3, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListRequestedHistoryV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListRequestedHistoryV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListRequestedHistoryV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListRequestedHistoryV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            pageToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
            limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
            liverId: jspb.Message.getFieldWithDefault(msg, 3, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListRequestedHistoryV1Request}
 */
proto.jp.singcolor.ListRequestedHistoryV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListRequestedHistoryV1Request;
    return proto.jp.singcolor.ListRequestedHistoryV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListRequestedHistoryV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListRequestedHistoryV1Request}
 */
proto.jp.singcolor.ListRequestedHistoryV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setPageToken(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setLimit(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiverId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListRequestedHistoryV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListRequestedHistoryV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListRequestedHistoryV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListRequestedHistoryV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPageToken();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getLimit();
    if (f !== 0) {
        writer.writeUint64(2, f);
    }
    f = message.getLiverId();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
};
/**
 * optional string page_token = 1;
 * @return {string}
 */
proto.jp.singcolor.ListRequestedHistoryV1Request.prototype.getPageToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListRequestedHistoryV1Request} returns this
 */
proto.jp.singcolor.ListRequestedHistoryV1Request.prototype.setPageToken = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional uint64 limit = 2;
 * @return {number}
 */
proto.jp.singcolor.ListRequestedHistoryV1Request.prototype.getLimit = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListRequestedHistoryV1Request} returns this
 */
proto.jp.singcolor.ListRequestedHistoryV1Request.prototype.setLimit = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional string liver_id = 3;
 * @return {string}
 */
proto.jp.singcolor.ListRequestedHistoryV1Request.prototype.getLiverId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListRequestedHistoryV1Request} returns this
 */
proto.jp.singcolor.ListRequestedHistoryV1Request.prototype.setLiverId = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListRequestedHistoryV1Response.repeatedFields_ = [1, 4];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListRequestedHistoryV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListRequestedHistoryV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListRequestedHistoryV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListRequestedHistoryV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            songsList: jspb.Message.toObjectList(msg.getSongsList(), karaoke_entity_pb.Song.toObject, includeInstance),
            nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
            hasNext: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
            liverRequestedSongHistoriesList: jspb.Message.toObjectList(msg.getLiverRequestedSongHistoriesList(), users_entity_pb.LiverRequestedSongHistory.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListRequestedHistoryV1Response}
 */
proto.jp.singcolor.ListRequestedHistoryV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListRequestedHistoryV1Response;
    return proto.jp.singcolor.ListRequestedHistoryV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListRequestedHistoryV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListRequestedHistoryV1Response}
 */
proto.jp.singcolor.ListRequestedHistoryV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new karaoke_entity_pb.Song;
                reader.readMessage(value, karaoke_entity_pb.Song.deserializeBinaryFromReader);
                msg.addSongs(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setNextPageToken(value);
                break;
            case 3:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setHasNext(value);
                break;
            case 4:
                var value = new users_entity_pb.LiverRequestedSongHistory;
                reader.readMessage(value, users_entity_pb.LiverRequestedSongHistory.deserializeBinaryFromReader);
                msg.addLiverRequestedSongHistories(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListRequestedHistoryV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListRequestedHistoryV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListRequestedHistoryV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListRequestedHistoryV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getSongsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, karaoke_entity_pb.Song.serializeBinaryToWriter);
    }
    f = message.getNextPageToken();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getHasNext();
    if (f) {
        writer.writeBool(3, f);
    }
    f = message.getLiverRequestedSongHistoriesList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(4, f, users_entity_pb.LiverRequestedSongHistory.serializeBinaryToWriter);
    }
};
/**
 * repeated Song songs = 1;
 * @return {!Array<!proto.jp.singcolor.Song>}
 */
proto.jp.singcolor.ListRequestedHistoryV1Response.prototype.getSongsList = function () {
    return /** @type{!Array<!proto.jp.singcolor.Song>} */ (jspb.Message.getRepeatedWrapperField(this, karaoke_entity_pb.Song, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.Song>} value
 * @return {!proto.jp.singcolor.ListRequestedHistoryV1Response} returns this
*/
proto.jp.singcolor.ListRequestedHistoryV1Response.prototype.setSongsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.Song=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.Song}
 */
proto.jp.singcolor.ListRequestedHistoryV1Response.prototype.addSongs = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.Song, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListRequestedHistoryV1Response} returns this
 */
proto.jp.singcolor.ListRequestedHistoryV1Response.prototype.clearSongsList = function () {
    return this.setSongsList([]);
};
/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.jp.singcolor.ListRequestedHistoryV1Response.prototype.getNextPageToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListRequestedHistoryV1Response} returns this
 */
proto.jp.singcolor.ListRequestedHistoryV1Response.prototype.setNextPageToken = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional bool has_next = 3;
 * @return {boolean}
 */
proto.jp.singcolor.ListRequestedHistoryV1Response.prototype.getHasNext = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.ListRequestedHistoryV1Response} returns this
 */
proto.jp.singcolor.ListRequestedHistoryV1Response.prototype.setHasNext = function (value) {
    return jspb.Message.setProto3BooleanField(this, 3, value);
};
/**
 * repeated LiverRequestedSongHistory liver_requested_song_histories = 4;
 * @return {!Array<!proto.jp.singcolor.LiverRequestedSongHistory>}
 */
proto.jp.singcolor.ListRequestedHistoryV1Response.prototype.getLiverRequestedSongHistoriesList = function () {
    return /** @type{!Array<!proto.jp.singcolor.LiverRequestedSongHistory>} */ (jspb.Message.getRepeatedWrapperField(this, users_entity_pb.LiverRequestedSongHistory, 4));
};
/**
 * @param {!Array<!proto.jp.singcolor.LiverRequestedSongHistory>} value
 * @return {!proto.jp.singcolor.ListRequestedHistoryV1Response} returns this
*/
proto.jp.singcolor.ListRequestedHistoryV1Response.prototype.setLiverRequestedSongHistoriesList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 4, value);
};
/**
 * @param {!proto.jp.singcolor.LiverRequestedSongHistory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.LiverRequestedSongHistory}
 */
proto.jp.singcolor.ListRequestedHistoryV1Response.prototype.addLiverRequestedSongHistories = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.jp.singcolor.LiverRequestedSongHistory, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListRequestedHistoryV1Response} returns this
 */
proto.jp.singcolor.ListRequestedHistoryV1Response.prototype.clearLiverRequestedSongHistoriesList = function () {
    return this.setLiverRequestedSongHistoriesList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListListenerFavoriteSongsV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListListenerFavoriteSongsV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListListenerFavoriteSongsV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListListenerFavoriteSongsV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            pageToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
            limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
            userId: jspb.Message.getFieldWithDefault(msg, 3, ""),
            liverId: jspb.Message.getFieldWithDefault(msg, 4, ""),
            sort: jspb.Message.getFieldWithDefault(msg, 5, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListListenerFavoriteSongsV1Request}
 */
proto.jp.singcolor.ListListenerFavoriteSongsV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListListenerFavoriteSongsV1Request;
    return proto.jp.singcolor.ListListenerFavoriteSongsV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListListenerFavoriteSongsV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListListenerFavoriteSongsV1Request}
 */
proto.jp.singcolor.ListListenerFavoriteSongsV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setPageToken(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setLimit(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiverId(value);
                break;
            case 5:
                var value = /** @type {!proto.jp.singcolor.LiverAndListenerFavoriteSongsSort} */ (reader.readEnum());
                msg.setSort(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListListenerFavoriteSongsV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListListenerFavoriteSongsV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListListenerFavoriteSongsV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListListenerFavoriteSongsV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPageToken();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getLimit();
    if (f !== 0) {
        writer.writeUint64(2, f);
    }
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
    f = message.getLiverId();
    if (f.length > 0) {
        writer.writeString(4, f);
    }
    f = message.getSort();
    if (f !== 0.0) {
        writer.writeEnum(5, f);
    }
};
/**
 * optional string page_token = 1;
 * @return {string}
 */
proto.jp.singcolor.ListListenerFavoriteSongsV1Request.prototype.getPageToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListListenerFavoriteSongsV1Request} returns this
 */
proto.jp.singcolor.ListListenerFavoriteSongsV1Request.prototype.setPageToken = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional uint64 limit = 2;
 * @return {number}
 */
proto.jp.singcolor.ListListenerFavoriteSongsV1Request.prototype.getLimit = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListListenerFavoriteSongsV1Request} returns this
 */
proto.jp.singcolor.ListListenerFavoriteSongsV1Request.prototype.setLimit = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional string user_id = 3;
 * @return {string}
 */
proto.jp.singcolor.ListListenerFavoriteSongsV1Request.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListListenerFavoriteSongsV1Request} returns this
 */
proto.jp.singcolor.ListListenerFavoriteSongsV1Request.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * optional string liver_id = 4;
 * @return {string}
 */
proto.jp.singcolor.ListListenerFavoriteSongsV1Request.prototype.getLiverId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListListenerFavoriteSongsV1Request} returns this
 */
proto.jp.singcolor.ListListenerFavoriteSongsV1Request.prototype.setLiverId = function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
};
/**
 * optional LiverAndListenerFavoriteSongsSort sort = 5;
 * @return {!proto.jp.singcolor.LiverAndListenerFavoriteSongsSort}
 */
proto.jp.singcolor.ListListenerFavoriteSongsV1Request.prototype.getSort = function () {
    return /** @type {!proto.jp.singcolor.LiverAndListenerFavoriteSongsSort} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {!proto.jp.singcolor.LiverAndListenerFavoriteSongsSort} value
 * @return {!proto.jp.singcolor.ListListenerFavoriteSongsV1Request} returns this
 */
proto.jp.singcolor.ListListenerFavoriteSongsV1Request.prototype.setSort = function (value) {
    return jspb.Message.setProto3EnumField(this, 5, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListListenerFavoriteSongsV1Response.repeatedFields_ = [1, 4];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListListenerFavoriteSongsV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListListenerFavoriteSongsV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListListenerFavoriteSongsV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListListenerFavoriteSongsV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            songsList: jspb.Message.toObjectList(msg.getSongsList(), karaoke_entity_pb.Song.toObject, includeInstance),
            nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
            hasNext: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
            songsWithCategoryList: jspb.Message.toObjectList(msg.getSongsWithCategoryList(), karaoke_entity_pb.SongWithCategory.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListListenerFavoriteSongsV1Response}
 */
proto.jp.singcolor.ListListenerFavoriteSongsV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListListenerFavoriteSongsV1Response;
    return proto.jp.singcolor.ListListenerFavoriteSongsV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListListenerFavoriteSongsV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListListenerFavoriteSongsV1Response}
 */
proto.jp.singcolor.ListListenerFavoriteSongsV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new karaoke_entity_pb.Song;
                reader.readMessage(value, karaoke_entity_pb.Song.deserializeBinaryFromReader);
                msg.addSongs(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setNextPageToken(value);
                break;
            case 3:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setHasNext(value);
                break;
            case 4:
                var value = new karaoke_entity_pb.SongWithCategory;
                reader.readMessage(value, karaoke_entity_pb.SongWithCategory.deserializeBinaryFromReader);
                msg.addSongsWithCategory(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListListenerFavoriteSongsV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListListenerFavoriteSongsV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListListenerFavoriteSongsV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListListenerFavoriteSongsV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getSongsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, karaoke_entity_pb.Song.serializeBinaryToWriter);
    }
    f = message.getNextPageToken();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getHasNext();
    if (f) {
        writer.writeBool(3, f);
    }
    f = message.getSongsWithCategoryList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(4, f, karaoke_entity_pb.SongWithCategory.serializeBinaryToWriter);
    }
};
/**
 * repeated Song songs = 1;
 * @return {!Array<!proto.jp.singcolor.Song>}
 */
proto.jp.singcolor.ListListenerFavoriteSongsV1Response.prototype.getSongsList = function () {
    return /** @type{!Array<!proto.jp.singcolor.Song>} */ (jspb.Message.getRepeatedWrapperField(this, karaoke_entity_pb.Song, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.Song>} value
 * @return {!proto.jp.singcolor.ListListenerFavoriteSongsV1Response} returns this
*/
proto.jp.singcolor.ListListenerFavoriteSongsV1Response.prototype.setSongsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.Song=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.Song}
 */
proto.jp.singcolor.ListListenerFavoriteSongsV1Response.prototype.addSongs = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.Song, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListListenerFavoriteSongsV1Response} returns this
 */
proto.jp.singcolor.ListListenerFavoriteSongsV1Response.prototype.clearSongsList = function () {
    return this.setSongsList([]);
};
/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.jp.singcolor.ListListenerFavoriteSongsV1Response.prototype.getNextPageToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListListenerFavoriteSongsV1Response} returns this
 */
proto.jp.singcolor.ListListenerFavoriteSongsV1Response.prototype.setNextPageToken = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional bool has_next = 3;
 * @return {boolean}
 */
proto.jp.singcolor.ListListenerFavoriteSongsV1Response.prototype.getHasNext = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.ListListenerFavoriteSongsV1Response} returns this
 */
proto.jp.singcolor.ListListenerFavoriteSongsV1Response.prototype.setHasNext = function (value) {
    return jspb.Message.setProto3BooleanField(this, 3, value);
};
/**
 * repeated SongWithCategory songs_with_category = 4;
 * @return {!Array<!proto.jp.singcolor.SongWithCategory>}
 */
proto.jp.singcolor.ListListenerFavoriteSongsV1Response.prototype.getSongsWithCategoryList = function () {
    return /** @type{!Array<!proto.jp.singcolor.SongWithCategory>} */ (jspb.Message.getRepeatedWrapperField(this, karaoke_entity_pb.SongWithCategory, 4));
};
/**
 * @param {!Array<!proto.jp.singcolor.SongWithCategory>} value
 * @return {!proto.jp.singcolor.ListListenerFavoriteSongsV1Response} returns this
*/
proto.jp.singcolor.ListListenerFavoriteSongsV1Response.prototype.setSongsWithCategoryList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 4, value);
};
/**
 * @param {!proto.jp.singcolor.SongWithCategory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.SongWithCategory}
 */
proto.jp.singcolor.ListListenerFavoriteSongsV1Response.prototype.addSongsWithCategory = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.jp.singcolor.SongWithCategory, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListListenerFavoriteSongsV1Response} returns this
 */
proto.jp.singcolor.ListListenerFavoriteSongsV1Response.prototype.clearSongsWithCategoryList = function () {
    return this.setSongsWithCategoryList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListRecentlyAvailableSongsV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListRecentlyAvailableSongsV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListRecentlyAvailableSongsV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListRecentlyAvailableSongsV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            pageToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
            limit: jspb.Message.getFieldWithDefault(msg, 2, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListRecentlyAvailableSongsV1Request}
 */
proto.jp.singcolor.ListRecentlyAvailableSongsV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListRecentlyAvailableSongsV1Request;
    return proto.jp.singcolor.ListRecentlyAvailableSongsV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListRecentlyAvailableSongsV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListRecentlyAvailableSongsV1Request}
 */
proto.jp.singcolor.ListRecentlyAvailableSongsV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setPageToken(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setLimit(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListRecentlyAvailableSongsV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListRecentlyAvailableSongsV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListRecentlyAvailableSongsV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListRecentlyAvailableSongsV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPageToken();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getLimit();
    if (f !== 0) {
        writer.writeUint64(2, f);
    }
};
/**
 * optional string page_token = 1;
 * @return {string}
 */
proto.jp.singcolor.ListRecentlyAvailableSongsV1Request.prototype.getPageToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListRecentlyAvailableSongsV1Request} returns this
 */
proto.jp.singcolor.ListRecentlyAvailableSongsV1Request.prototype.setPageToken = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional uint64 limit = 2;
 * @return {number}
 */
proto.jp.singcolor.ListRecentlyAvailableSongsV1Request.prototype.getLimit = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListRecentlyAvailableSongsV1Request} returns this
 */
proto.jp.singcolor.ListRecentlyAvailableSongsV1Request.prototype.setLimit = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListRecentlyAvailableSongsV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListRecentlyAvailableSongsV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListRecentlyAvailableSongsV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListRecentlyAvailableSongsV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListRecentlyAvailableSongsV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            songsList: jspb.Message.toObjectList(msg.getSongsList(), karaoke_entity_pb.Song.toObject, includeInstance),
            nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
            hasNext: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListRecentlyAvailableSongsV1Response}
 */
proto.jp.singcolor.ListRecentlyAvailableSongsV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListRecentlyAvailableSongsV1Response;
    return proto.jp.singcolor.ListRecentlyAvailableSongsV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListRecentlyAvailableSongsV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListRecentlyAvailableSongsV1Response}
 */
proto.jp.singcolor.ListRecentlyAvailableSongsV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new karaoke_entity_pb.Song;
                reader.readMessage(value, karaoke_entity_pb.Song.deserializeBinaryFromReader);
                msg.addSongs(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setNextPageToken(value);
                break;
            case 3:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setHasNext(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListRecentlyAvailableSongsV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListRecentlyAvailableSongsV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListRecentlyAvailableSongsV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListRecentlyAvailableSongsV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getSongsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, karaoke_entity_pb.Song.serializeBinaryToWriter);
    }
    f = message.getNextPageToken();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getHasNext();
    if (f) {
        writer.writeBool(3, f);
    }
};
/**
 * repeated Song songs = 1;
 * @return {!Array<!proto.jp.singcolor.Song>}
 */
proto.jp.singcolor.ListRecentlyAvailableSongsV1Response.prototype.getSongsList = function () {
    return /** @type{!Array<!proto.jp.singcolor.Song>} */ (jspb.Message.getRepeatedWrapperField(this, karaoke_entity_pb.Song, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.Song>} value
 * @return {!proto.jp.singcolor.ListRecentlyAvailableSongsV1Response} returns this
*/
proto.jp.singcolor.ListRecentlyAvailableSongsV1Response.prototype.setSongsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.Song=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.Song}
 */
proto.jp.singcolor.ListRecentlyAvailableSongsV1Response.prototype.addSongs = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.Song, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListRecentlyAvailableSongsV1Response} returns this
 */
proto.jp.singcolor.ListRecentlyAvailableSongsV1Response.prototype.clearSongsList = function () {
    return this.setSongsList([]);
};
/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.jp.singcolor.ListRecentlyAvailableSongsV1Response.prototype.getNextPageToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListRecentlyAvailableSongsV1Response} returns this
 */
proto.jp.singcolor.ListRecentlyAvailableSongsV1Response.prototype.setNextPageToken = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional bool has_next = 3;
 * @return {boolean}
 */
proto.jp.singcolor.ListRecentlyAvailableSongsV1Response.prototype.getHasNext = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.ListRecentlyAvailableSongsV1Response} returns this
 */
proto.jp.singcolor.ListRecentlyAvailableSongsV1Response.prototype.setHasNext = function (value) {
    return jspb.Message.setProto3BooleanField(this, 3, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetSongV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetSongV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetSongV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetSongV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            songId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetSongV1Request}
 */
proto.jp.singcolor.GetSongV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetSongV1Request;
    return proto.jp.singcolor.GetSongV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetSongV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetSongV1Request}
 */
proto.jp.singcolor.GetSongV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setSongId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetSongV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetSongV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetSongV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetSongV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getSongId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string song_id = 1;
 * @return {string}
 */
proto.jp.singcolor.GetSongV1Request.prototype.getSongId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetSongV1Request} returns this
 */
proto.jp.singcolor.GetSongV1Request.prototype.setSongId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetSongV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetSongV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetSongV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetSongV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            song: (f = msg.getSong()) && karaoke_entity_pb.Song.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetSongV1Response}
 */
proto.jp.singcolor.GetSongV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetSongV1Response;
    return proto.jp.singcolor.GetSongV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetSongV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetSongV1Response}
 */
proto.jp.singcolor.GetSongV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new karaoke_entity_pb.Song;
                reader.readMessage(value, karaoke_entity_pb.Song.deserializeBinaryFromReader);
                msg.setSong(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetSongV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetSongV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetSongV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetSongV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getSong();
    if (f != null) {
        writer.writeMessage(1, f, karaoke_entity_pb.Song.serializeBinaryToWriter);
    }
};
/**
 * optional Song song = 1;
 * @return {?proto.jp.singcolor.Song}
 */
proto.jp.singcolor.GetSongV1Response.prototype.getSong = function () {
    return /** @type{?proto.jp.singcolor.Song} */ (jspb.Message.getWrapperField(this, karaoke_entity_pb.Song, 1));
};
/**
 * @param {?proto.jp.singcolor.Song|undefined} value
 * @return {!proto.jp.singcolor.GetSongV1Response} returns this
*/
proto.jp.singcolor.GetSongV1Response.prototype.setSong = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetSongV1Response} returns this
 */
proto.jp.singcolor.GetSongV1Response.prototype.clearSong = function () {
    return this.setSong(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetSongV1Response.prototype.hasSong = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateListenerFavoriteSongV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateListenerFavoriteSongV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateListenerFavoriteSongV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateListenerFavoriteSongV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            songId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            isFavorite: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateListenerFavoriteSongV1Request}
 */
proto.jp.singcolor.UpdateListenerFavoriteSongV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateListenerFavoriteSongV1Request;
    return proto.jp.singcolor.UpdateListenerFavoriteSongV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateListenerFavoriteSongV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateListenerFavoriteSongV1Request}
 */
proto.jp.singcolor.UpdateListenerFavoriteSongV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setSongId(value);
                break;
            case 2:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setIsFavorite(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateListenerFavoriteSongV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateListenerFavoriteSongV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateListenerFavoriteSongV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateListenerFavoriteSongV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getSongId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getIsFavorite();
    if (f) {
        writer.writeBool(2, f);
    }
};
/**
 * optional string song_id = 1;
 * @return {string}
 */
proto.jp.singcolor.UpdateListenerFavoriteSongV1Request.prototype.getSongId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateListenerFavoriteSongV1Request} returns this
 */
proto.jp.singcolor.UpdateListenerFavoriteSongV1Request.prototype.setSongId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional bool is_favorite = 2;
 * @return {boolean}
 */
proto.jp.singcolor.UpdateListenerFavoriteSongV1Request.prototype.getIsFavorite = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.UpdateListenerFavoriteSongV1Request} returns this
 */
proto.jp.singcolor.UpdateListenerFavoriteSongV1Request.prototype.setIsFavorite = function (value) {
    return jspb.Message.setProto3BooleanField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateListenerFavoriteSongV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateListenerFavoriteSongV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateListenerFavoriteSongV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateListenerFavoriteSongV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            song: (f = msg.getSong()) && karaoke_entity_pb.Song.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateListenerFavoriteSongV1Response}
 */
proto.jp.singcolor.UpdateListenerFavoriteSongV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateListenerFavoriteSongV1Response;
    return proto.jp.singcolor.UpdateListenerFavoriteSongV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateListenerFavoriteSongV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateListenerFavoriteSongV1Response}
 */
proto.jp.singcolor.UpdateListenerFavoriteSongV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new karaoke_entity_pb.Song;
                reader.readMessage(value, karaoke_entity_pb.Song.deserializeBinaryFromReader);
                msg.setSong(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateListenerFavoriteSongV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateListenerFavoriteSongV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateListenerFavoriteSongV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateListenerFavoriteSongV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getSong();
    if (f != null) {
        writer.writeMessage(1, f, karaoke_entity_pb.Song.serializeBinaryToWriter);
    }
};
/**
 * optional Song song = 1;
 * @return {?proto.jp.singcolor.Song}
 */
proto.jp.singcolor.UpdateListenerFavoriteSongV1Response.prototype.getSong = function () {
    return /** @type{?proto.jp.singcolor.Song} */ (jspb.Message.getWrapperField(this, karaoke_entity_pb.Song, 1));
};
/**
 * @param {?proto.jp.singcolor.Song|undefined} value
 * @return {!proto.jp.singcolor.UpdateListenerFavoriteSongV1Response} returns this
*/
proto.jp.singcolor.UpdateListenerFavoriteSongV1Response.prototype.setSong = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.UpdateListenerFavoriteSongV1Response} returns this
 */
proto.jp.singcolor.UpdateListenerFavoriteSongV1Response.prototype.clearSong = function () {
    return this.setSong(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.UpdateListenerFavoriteSongV1Response.prototype.hasSong = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetSongByXingJoysoundWebInfoV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetSongByXingJoysoundWebInfoV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetSongByXingJoysoundWebInfoV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetSongByXingJoysoundWebInfoV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            xingSongId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            songTitle: jspb.Message.getFieldWithDefault(msg, 2, ""),
            singerName: jspb.Message.getFieldWithDefault(msg, 3, ""),
            songSelectionNumber: jspb.Message.getFieldWithDefault(msg, 4, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetSongByXingJoysoundWebInfoV1Request}
 */
proto.jp.singcolor.GetSongByXingJoysoundWebInfoV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetSongByXingJoysoundWebInfoV1Request;
    return proto.jp.singcolor.GetSongByXingJoysoundWebInfoV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetSongByXingJoysoundWebInfoV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetSongByXingJoysoundWebInfoV1Request}
 */
proto.jp.singcolor.GetSongByXingJoysoundWebInfoV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setXingSongId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setSongTitle(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setSingerName(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setSongSelectionNumber(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetSongByXingJoysoundWebInfoV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetSongByXingJoysoundWebInfoV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetSongByXingJoysoundWebInfoV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetSongByXingJoysoundWebInfoV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getXingSongId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getSongTitle();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getSingerName();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
    f = message.getSongSelectionNumber();
    if (f.length > 0) {
        writer.writeString(4, f);
    }
};
/**
 * optional string xing_song_id = 1;
 * @return {string}
 */
proto.jp.singcolor.GetSongByXingJoysoundWebInfoV1Request.prototype.getXingSongId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetSongByXingJoysoundWebInfoV1Request} returns this
 */
proto.jp.singcolor.GetSongByXingJoysoundWebInfoV1Request.prototype.setXingSongId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string song_title = 2;
 * @return {string}
 */
proto.jp.singcolor.GetSongByXingJoysoundWebInfoV1Request.prototype.getSongTitle = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetSongByXingJoysoundWebInfoV1Request} returns this
 */
proto.jp.singcolor.GetSongByXingJoysoundWebInfoV1Request.prototype.setSongTitle = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional string singer_name = 3;
 * @return {string}
 */
proto.jp.singcolor.GetSongByXingJoysoundWebInfoV1Request.prototype.getSingerName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetSongByXingJoysoundWebInfoV1Request} returns this
 */
proto.jp.singcolor.GetSongByXingJoysoundWebInfoV1Request.prototype.setSingerName = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * optional string song_selection_number = 4;
 * @return {string}
 */
proto.jp.singcolor.GetSongByXingJoysoundWebInfoV1Request.prototype.getSongSelectionNumber = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetSongByXingJoysoundWebInfoV1Request} returns this
 */
proto.jp.singcolor.GetSongByXingJoysoundWebInfoV1Request.prototype.setSongSelectionNumber = function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetSongByXingJoysoundWebInfoV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetSongByXingJoysoundWebInfoV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetSongByXingJoysoundWebInfoV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetSongByXingJoysoundWebInfoV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            song: (f = msg.getSong()) && karaoke_entity_pb.Song.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetSongByXingJoysoundWebInfoV1Response}
 */
proto.jp.singcolor.GetSongByXingJoysoundWebInfoV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetSongByXingJoysoundWebInfoV1Response;
    return proto.jp.singcolor.GetSongByXingJoysoundWebInfoV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetSongByXingJoysoundWebInfoV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetSongByXingJoysoundWebInfoV1Response}
 */
proto.jp.singcolor.GetSongByXingJoysoundWebInfoV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new karaoke_entity_pb.Song;
                reader.readMessage(value, karaoke_entity_pb.Song.deserializeBinaryFromReader);
                msg.setSong(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetSongByXingJoysoundWebInfoV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetSongByXingJoysoundWebInfoV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetSongByXingJoysoundWebInfoV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetSongByXingJoysoundWebInfoV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getSong();
    if (f != null) {
        writer.writeMessage(1, f, karaoke_entity_pb.Song.serializeBinaryToWriter);
    }
};
/**
 * optional Song song = 1;
 * @return {?proto.jp.singcolor.Song}
 */
proto.jp.singcolor.GetSongByXingJoysoundWebInfoV1Response.prototype.getSong = function () {
    return /** @type{?proto.jp.singcolor.Song} */ (jspb.Message.getWrapperField(this, karaoke_entity_pb.Song, 1));
};
/**
 * @param {?proto.jp.singcolor.Song|undefined} value
 * @return {!proto.jp.singcolor.GetSongByXingJoysoundWebInfoV1Response} returns this
*/
proto.jp.singcolor.GetSongByXingJoysoundWebInfoV1Response.prototype.setSong = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetSongByXingJoysoundWebInfoV1Response} returns this
 */
proto.jp.singcolor.GetSongByXingJoysoundWebInfoV1Response.prototype.clearSong = function () {
    return this.setSong(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetSongByXingJoysoundWebInfoV1Response.prototype.hasSong = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.RequestToAddNewSongV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.RequestToAddNewSongV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.RequestToAddNewSongV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.RequestToAddNewSongV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            xingSongId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            songTitle: jspb.Message.getFieldWithDefault(msg, 2, ""),
            xingSingerId: jspb.Message.getFieldWithDefault(msg, 3, ""),
            singerName: jspb.Message.getFieldWithDefault(msg, 4, ""),
            lyricist: jspb.Message.getFieldWithDefault(msg, 5, ""),
            composer: jspb.Message.getFieldWithDefault(msg, 6, ""),
            songSelectionNumber: jspb.Message.getFieldWithDefault(msg, 7, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.RequestToAddNewSongV1Request}
 */
proto.jp.singcolor.RequestToAddNewSongV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.RequestToAddNewSongV1Request;
    return proto.jp.singcolor.RequestToAddNewSongV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.RequestToAddNewSongV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.RequestToAddNewSongV1Request}
 */
proto.jp.singcolor.RequestToAddNewSongV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setXingSongId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setSongTitle(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setXingSingerId(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setSingerName(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());
                msg.setLyricist(value);
                break;
            case 6:
                var value = /** @type {string} */ (reader.readString());
                msg.setComposer(value);
                break;
            case 7:
                var value = /** @type {string} */ (reader.readString());
                msg.setSongSelectionNumber(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.RequestToAddNewSongV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.RequestToAddNewSongV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.RequestToAddNewSongV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.RequestToAddNewSongV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getXingSongId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getSongTitle();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getXingSingerId();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
    f = message.getSingerName();
    if (f.length > 0) {
        writer.writeString(4, f);
    }
    f = message.getLyricist();
    if (f.length > 0) {
        writer.writeString(5, f);
    }
    f = message.getComposer();
    if (f.length > 0) {
        writer.writeString(6, f);
    }
    f = message.getSongSelectionNumber();
    if (f.length > 0) {
        writer.writeString(7, f);
    }
};
/**
 * optional string xing_song_id = 1;
 * @return {string}
 */
proto.jp.singcolor.RequestToAddNewSongV1Request.prototype.getXingSongId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.RequestToAddNewSongV1Request} returns this
 */
proto.jp.singcolor.RequestToAddNewSongV1Request.prototype.setXingSongId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string song_title = 2;
 * @return {string}
 */
proto.jp.singcolor.RequestToAddNewSongV1Request.prototype.getSongTitle = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.RequestToAddNewSongV1Request} returns this
 */
proto.jp.singcolor.RequestToAddNewSongV1Request.prototype.setSongTitle = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional string xing_singer_id = 3;
 * @return {string}
 */
proto.jp.singcolor.RequestToAddNewSongV1Request.prototype.getXingSingerId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.RequestToAddNewSongV1Request} returns this
 */
proto.jp.singcolor.RequestToAddNewSongV1Request.prototype.setXingSingerId = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * optional string singer_name = 4;
 * @return {string}
 */
proto.jp.singcolor.RequestToAddNewSongV1Request.prototype.getSingerName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.RequestToAddNewSongV1Request} returns this
 */
proto.jp.singcolor.RequestToAddNewSongV1Request.prototype.setSingerName = function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
};
/**
 * optional string lyricist = 5;
 * @return {string}
 */
proto.jp.singcolor.RequestToAddNewSongV1Request.prototype.getLyricist = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.RequestToAddNewSongV1Request} returns this
 */
proto.jp.singcolor.RequestToAddNewSongV1Request.prototype.setLyricist = function (value) {
    return jspb.Message.setProto3StringField(this, 5, value);
};
/**
 * optional string composer = 6;
 * @return {string}
 */
proto.jp.singcolor.RequestToAddNewSongV1Request.prototype.getComposer = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.RequestToAddNewSongV1Request} returns this
 */
proto.jp.singcolor.RequestToAddNewSongV1Request.prototype.setComposer = function (value) {
    return jspb.Message.setProto3StringField(this, 6, value);
};
/**
 * optional string song_selection_number = 7;
 * @return {string}
 */
proto.jp.singcolor.RequestToAddNewSongV1Request.prototype.getSongSelectionNumber = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.RequestToAddNewSongV1Request} returns this
 */
proto.jp.singcolor.RequestToAddNewSongV1Request.prototype.setSongSelectionNumber = function (value) {
    return jspb.Message.setProto3StringField(this, 7, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.RequestToAddNewSongV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.RequestToAddNewSongV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.RequestToAddNewSongV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.RequestToAddNewSongV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            song: (f = msg.getSong()) && karaoke_entity_pb.Song.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.RequestToAddNewSongV1Response}
 */
proto.jp.singcolor.RequestToAddNewSongV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.RequestToAddNewSongV1Response;
    return proto.jp.singcolor.RequestToAddNewSongV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.RequestToAddNewSongV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.RequestToAddNewSongV1Response}
 */
proto.jp.singcolor.RequestToAddNewSongV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new karaoke_entity_pb.Song;
                reader.readMessage(value, karaoke_entity_pb.Song.deserializeBinaryFromReader);
                msg.setSong(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.RequestToAddNewSongV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.RequestToAddNewSongV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.RequestToAddNewSongV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.RequestToAddNewSongV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getSong();
    if (f != null) {
        writer.writeMessage(1, f, karaoke_entity_pb.Song.serializeBinaryToWriter);
    }
};
/**
 * optional Song song = 1;
 * @return {?proto.jp.singcolor.Song}
 */
proto.jp.singcolor.RequestToAddNewSongV1Response.prototype.getSong = function () {
    return /** @type{?proto.jp.singcolor.Song} */ (jspb.Message.getWrapperField(this, karaoke_entity_pb.Song, 1));
};
/**
 * @param {?proto.jp.singcolor.Song|undefined} value
 * @return {!proto.jp.singcolor.RequestToAddNewSongV1Response} returns this
*/
proto.jp.singcolor.RequestToAddNewSongV1Response.prototype.setSong = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.RequestToAddNewSongV1Response} returns this
 */
proto.jp.singcolor.RequestToAddNewSongV1Response.prototype.clearSong = function () {
    return this.setSong(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.RequestToAddNewSongV1Response.prototype.hasSong = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.GetSongRandomlyV1Request.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetSongRandomlyV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetSongRandomlyV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetSongRandomlyV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetSongRandomlyV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            excludeSongIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
            previousSongId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            liveId: jspb.Message.getFieldWithDefault(msg, 3, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetSongRandomlyV1Request}
 */
proto.jp.singcolor.GetSongRandomlyV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetSongRandomlyV1Request;
    return proto.jp.singcolor.GetSongRandomlyV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetSongRandomlyV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetSongRandomlyV1Request}
 */
proto.jp.singcolor.GetSongRandomlyV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.addExcludeSongIds(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setPreviousSongId(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiveId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetSongRandomlyV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetSongRandomlyV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetSongRandomlyV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetSongRandomlyV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getExcludeSongIdsList();
    if (f.length > 0) {
        writer.writeRepeatedString(1, f);
    }
    f = message.getPreviousSongId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getLiveId();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
};
/**
 * repeated string exclude_song_ids = 1;
 * @return {!Array<string>}
 */
proto.jp.singcolor.GetSongRandomlyV1Request.prototype.getExcludeSongIdsList = function () {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};
/**
 * @param {!Array<string>} value
 * @return {!proto.jp.singcolor.GetSongRandomlyV1Request} returns this
 */
proto.jp.singcolor.GetSongRandomlyV1Request.prototype.setExcludeSongIdsList = function (value) {
    return jspb.Message.setField(this, 1, value || []);
};
/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.GetSongRandomlyV1Request} returns this
 */
proto.jp.singcolor.GetSongRandomlyV1Request.prototype.addExcludeSongIds = function (value, opt_index) {
    return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.GetSongRandomlyV1Request} returns this
 */
proto.jp.singcolor.GetSongRandomlyV1Request.prototype.clearExcludeSongIdsList = function () {
    return this.setExcludeSongIdsList([]);
};
/**
 * optional string previous_song_id = 2;
 * @return {string}
 */
proto.jp.singcolor.GetSongRandomlyV1Request.prototype.getPreviousSongId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetSongRandomlyV1Request} returns this
 */
proto.jp.singcolor.GetSongRandomlyV1Request.prototype.setPreviousSongId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional string live_id = 3;
 * @return {string}
 */
proto.jp.singcolor.GetSongRandomlyV1Request.prototype.getLiveId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetSongRandomlyV1Request} returns this
 */
proto.jp.singcolor.GetSongRandomlyV1Request.prototype.setLiveId = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetSongRandomlyV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetSongRandomlyV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetSongRandomlyV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetSongRandomlyV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            song: (f = msg.getSong()) && karaoke_entity_pb.Song.toObject(includeInstance, f),
            resetRandom: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetSongRandomlyV1Response}
 */
proto.jp.singcolor.GetSongRandomlyV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetSongRandomlyV1Response;
    return proto.jp.singcolor.GetSongRandomlyV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetSongRandomlyV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetSongRandomlyV1Response}
 */
proto.jp.singcolor.GetSongRandomlyV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new karaoke_entity_pb.Song;
                reader.readMessage(value, karaoke_entity_pb.Song.deserializeBinaryFromReader);
                msg.setSong(value);
                break;
            case 2:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setResetRandom(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetSongRandomlyV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetSongRandomlyV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetSongRandomlyV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetSongRandomlyV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getSong();
    if (f != null) {
        writer.writeMessage(1, f, karaoke_entity_pb.Song.serializeBinaryToWriter);
    }
    f = message.getResetRandom();
    if (f) {
        writer.writeBool(2, f);
    }
};
/**
 * optional Song song = 1;
 * @return {?proto.jp.singcolor.Song}
 */
proto.jp.singcolor.GetSongRandomlyV1Response.prototype.getSong = function () {
    return /** @type{?proto.jp.singcolor.Song} */ (jspb.Message.getWrapperField(this, karaoke_entity_pb.Song, 1));
};
/**
 * @param {?proto.jp.singcolor.Song|undefined} value
 * @return {!proto.jp.singcolor.GetSongRandomlyV1Response} returns this
*/
proto.jp.singcolor.GetSongRandomlyV1Response.prototype.setSong = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetSongRandomlyV1Response} returns this
 */
proto.jp.singcolor.GetSongRandomlyV1Response.prototype.clearSong = function () {
    return this.setSong(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetSongRandomlyV1Response.prototype.hasSong = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional bool reset_random = 2;
 * @return {boolean}
 */
proto.jp.singcolor.GetSongRandomlyV1Response.prototype.getResetRandom = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.GetSongRandomlyV1Response} returns this
 */
proto.jp.singcolor.GetSongRandomlyV1Response.prototype.setResetRandom = function (value) {
    return jspb.Message.setProto3BooleanField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateListenerFavoriteSongOrderV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateListenerFavoriteSongOrderV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateListenerFavoriteSongOrderV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateListenerFavoriteSongOrderV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            previousSongId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            songId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            nextSongId: jspb.Message.getFieldWithDefault(msg, 3, ""),
            categoryId: jspb.Message.getFieldWithDefault(msg, 4, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateListenerFavoriteSongOrderV1Request}
 */
proto.jp.singcolor.UpdateListenerFavoriteSongOrderV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateListenerFavoriteSongOrderV1Request;
    return proto.jp.singcolor.UpdateListenerFavoriteSongOrderV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateListenerFavoriteSongOrderV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateListenerFavoriteSongOrderV1Request}
 */
proto.jp.singcolor.UpdateListenerFavoriteSongOrderV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setPreviousSongId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setSongId(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setNextSongId(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setCategoryId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateListenerFavoriteSongOrderV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateListenerFavoriteSongOrderV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateListenerFavoriteSongOrderV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateListenerFavoriteSongOrderV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPreviousSongId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getSongId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getNextSongId();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
    f = message.getCategoryId();
    if (f.length > 0) {
        writer.writeString(4, f);
    }
};
/**
 * optional string previous_song_id = 1;
 * @return {string}
 */
proto.jp.singcolor.UpdateListenerFavoriteSongOrderV1Request.prototype.getPreviousSongId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateListenerFavoriteSongOrderV1Request} returns this
 */
proto.jp.singcolor.UpdateListenerFavoriteSongOrderV1Request.prototype.setPreviousSongId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string song_id = 2;
 * @return {string}
 */
proto.jp.singcolor.UpdateListenerFavoriteSongOrderV1Request.prototype.getSongId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateListenerFavoriteSongOrderV1Request} returns this
 */
proto.jp.singcolor.UpdateListenerFavoriteSongOrderV1Request.prototype.setSongId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional string next_song_id = 3;
 * @return {string}
 */
proto.jp.singcolor.UpdateListenerFavoriteSongOrderV1Request.prototype.getNextSongId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateListenerFavoriteSongOrderV1Request} returns this
 */
proto.jp.singcolor.UpdateListenerFavoriteSongOrderV1Request.prototype.setNextSongId = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * optional string category_id = 4;
 * @return {string}
 */
proto.jp.singcolor.UpdateListenerFavoriteSongOrderV1Request.prototype.getCategoryId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateListenerFavoriteSongOrderV1Request} returns this
 */
proto.jp.singcolor.UpdateListenerFavoriteSongOrderV1Request.prototype.setCategoryId = function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateListenerFavoriteSongOrderV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateListenerFavoriteSongOrderV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateListenerFavoriteSongOrderV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateListenerFavoriteSongOrderV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateListenerFavoriteSongOrderV1Response}
 */
proto.jp.singcolor.UpdateListenerFavoriteSongOrderV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateListenerFavoriteSongOrderV1Response;
    return proto.jp.singcolor.UpdateListenerFavoriteSongOrderV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateListenerFavoriteSongOrderV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateListenerFavoriteSongOrderV1Response}
 */
proto.jp.singcolor.UpdateListenerFavoriteSongOrderV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateListenerFavoriteSongOrderV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateListenerFavoriteSongOrderV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateListenerFavoriteSongOrderV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateListenerFavoriteSongOrderV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateListenerFavoriteSongCategoryV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateListenerFavoriteSongCategoryV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateListenerFavoriteSongCategoryV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateListenerFavoriteSongCategoryV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            songId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            categoryId: jspb.Message.getFieldWithDefault(msg, 2, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateListenerFavoriteSongCategoryV1Request}
 */
proto.jp.singcolor.UpdateListenerFavoriteSongCategoryV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateListenerFavoriteSongCategoryV1Request;
    return proto.jp.singcolor.UpdateListenerFavoriteSongCategoryV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateListenerFavoriteSongCategoryV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateListenerFavoriteSongCategoryV1Request}
 */
proto.jp.singcolor.UpdateListenerFavoriteSongCategoryV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setSongId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setCategoryId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateListenerFavoriteSongCategoryV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateListenerFavoriteSongCategoryV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateListenerFavoriteSongCategoryV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateListenerFavoriteSongCategoryV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getSongId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getCategoryId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
};
/**
 * optional string song_id = 1;
 * @return {string}
 */
proto.jp.singcolor.UpdateListenerFavoriteSongCategoryV1Request.prototype.getSongId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateListenerFavoriteSongCategoryV1Request} returns this
 */
proto.jp.singcolor.UpdateListenerFavoriteSongCategoryV1Request.prototype.setSongId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string category_id = 2;
 * @return {string}
 */
proto.jp.singcolor.UpdateListenerFavoriteSongCategoryV1Request.prototype.getCategoryId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateListenerFavoriteSongCategoryV1Request} returns this
 */
proto.jp.singcolor.UpdateListenerFavoriteSongCategoryV1Request.prototype.setCategoryId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateListenerFavoriteSongCategoryV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateListenerFavoriteSongCategoryV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateListenerFavoriteSongCategoryV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateListenerFavoriteSongCategoryV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateListenerFavoriteSongCategoryV1Response}
 */
proto.jp.singcolor.UpdateListenerFavoriteSongCategoryV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateListenerFavoriteSongCategoryV1Response;
    return proto.jp.singcolor.UpdateListenerFavoriteSongCategoryV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateListenerFavoriteSongCategoryV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateListenerFavoriteSongCategoryV1Response}
 */
proto.jp.singcolor.UpdateListenerFavoriteSongCategoryV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateListenerFavoriteSongCategoryV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateListenerFavoriteSongCategoryV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateListenerFavoriteSongCategoryV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateListenerFavoriteSongCategoryV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateUserFavoriteSongOrderV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateUserFavoriteSongOrderV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateUserFavoriteSongOrderV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateUserFavoriteSongOrderV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            previousSongId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            songId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            nextSongId: jspb.Message.getFieldWithDefault(msg, 3, ""),
            categoryId: jspb.Message.getFieldWithDefault(msg, 4, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateUserFavoriteSongOrderV1Request}
 */
proto.jp.singcolor.UpdateUserFavoriteSongOrderV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateUserFavoriteSongOrderV1Request;
    return proto.jp.singcolor.UpdateUserFavoriteSongOrderV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateUserFavoriteSongOrderV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateUserFavoriteSongOrderV1Request}
 */
proto.jp.singcolor.UpdateUserFavoriteSongOrderV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setPreviousSongId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setSongId(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setNextSongId(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setCategoryId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateUserFavoriteSongOrderV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateUserFavoriteSongOrderV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateUserFavoriteSongOrderV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateUserFavoriteSongOrderV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPreviousSongId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getSongId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getNextSongId();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
    f = message.getCategoryId();
    if (f.length > 0) {
        writer.writeString(4, f);
    }
};
/**
 * optional string previous_song_id = 1;
 * @return {string}
 */
proto.jp.singcolor.UpdateUserFavoriteSongOrderV1Request.prototype.getPreviousSongId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateUserFavoriteSongOrderV1Request} returns this
 */
proto.jp.singcolor.UpdateUserFavoriteSongOrderV1Request.prototype.setPreviousSongId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string song_id = 2;
 * @return {string}
 */
proto.jp.singcolor.UpdateUserFavoriteSongOrderV1Request.prototype.getSongId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateUserFavoriteSongOrderV1Request} returns this
 */
proto.jp.singcolor.UpdateUserFavoriteSongOrderV1Request.prototype.setSongId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional string next_song_id = 3;
 * @return {string}
 */
proto.jp.singcolor.UpdateUserFavoriteSongOrderV1Request.prototype.getNextSongId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateUserFavoriteSongOrderV1Request} returns this
 */
proto.jp.singcolor.UpdateUserFavoriteSongOrderV1Request.prototype.setNextSongId = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * optional string category_id = 4;
 * @return {string}
 */
proto.jp.singcolor.UpdateUserFavoriteSongOrderV1Request.prototype.getCategoryId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateUserFavoriteSongOrderV1Request} returns this
 */
proto.jp.singcolor.UpdateUserFavoriteSongOrderV1Request.prototype.setCategoryId = function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateUserFavoriteSongOrderV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateUserFavoriteSongOrderV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateUserFavoriteSongOrderV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateUserFavoriteSongOrderV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateUserFavoriteSongOrderV1Response}
 */
proto.jp.singcolor.UpdateUserFavoriteSongOrderV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateUserFavoriteSongOrderV1Response;
    return proto.jp.singcolor.UpdateUserFavoriteSongOrderV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateUserFavoriteSongOrderV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateUserFavoriteSongOrderV1Response}
 */
proto.jp.singcolor.UpdateUserFavoriteSongOrderV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateUserFavoriteSongOrderV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateUserFavoriteSongOrderV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateUserFavoriteSongOrderV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateUserFavoriteSongOrderV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateUserFavoriteSongCategoryV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateUserFavoriteSongCategoryV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateUserFavoriteSongCategoryV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateUserFavoriteSongCategoryV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            songId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            categoryId: jspb.Message.getFieldWithDefault(msg, 2, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateUserFavoriteSongCategoryV1Request}
 */
proto.jp.singcolor.UpdateUserFavoriteSongCategoryV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateUserFavoriteSongCategoryV1Request;
    return proto.jp.singcolor.UpdateUserFavoriteSongCategoryV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateUserFavoriteSongCategoryV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateUserFavoriteSongCategoryV1Request}
 */
proto.jp.singcolor.UpdateUserFavoriteSongCategoryV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setSongId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setCategoryId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateUserFavoriteSongCategoryV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateUserFavoriteSongCategoryV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateUserFavoriteSongCategoryV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateUserFavoriteSongCategoryV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getSongId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getCategoryId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
};
/**
 * optional string song_id = 1;
 * @return {string}
 */
proto.jp.singcolor.UpdateUserFavoriteSongCategoryV1Request.prototype.getSongId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateUserFavoriteSongCategoryV1Request} returns this
 */
proto.jp.singcolor.UpdateUserFavoriteSongCategoryV1Request.prototype.setSongId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string category_id = 2;
 * @return {string}
 */
proto.jp.singcolor.UpdateUserFavoriteSongCategoryV1Request.prototype.getCategoryId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateUserFavoriteSongCategoryV1Request} returns this
 */
proto.jp.singcolor.UpdateUserFavoriteSongCategoryV1Request.prototype.setCategoryId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateUserFavoriteSongCategoryV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateUserFavoriteSongCategoryV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateUserFavoriteSongCategoryV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateUserFavoriteSongCategoryV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateUserFavoriteSongCategoryV1Response}
 */
proto.jp.singcolor.UpdateUserFavoriteSongCategoryV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateUserFavoriteSongCategoryV1Response;
    return proto.jp.singcolor.UpdateUserFavoriteSongCategoryV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateUserFavoriteSongCategoryV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateUserFavoriteSongCategoryV1Response}
 */
proto.jp.singcolor.UpdateUserFavoriteSongCategoryV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateUserFavoriteSongCategoryV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateUserFavoriteSongCategoryV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateUserFavoriteSongCategoryV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateUserFavoriteSongCategoryV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListListenerFavoriteSongCategoriesV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListListenerFavoriteSongCategoriesV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListListenerFavoriteSongCategoriesV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListListenerFavoriteSongCategoriesV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListListenerFavoriteSongCategoriesV1Request}
 */
proto.jp.singcolor.ListListenerFavoriteSongCategoriesV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListListenerFavoriteSongCategoriesV1Request;
    return proto.jp.singcolor.ListListenerFavoriteSongCategoriesV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListListenerFavoriteSongCategoriesV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListListenerFavoriteSongCategoriesV1Request}
 */
proto.jp.singcolor.ListListenerFavoriteSongCategoriesV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListListenerFavoriteSongCategoriesV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListListenerFavoriteSongCategoriesV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListListenerFavoriteSongCategoriesV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListListenerFavoriteSongCategoriesV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListListenerFavoriteSongCategoriesV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListListenerFavoriteSongCategoriesV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListListenerFavoriteSongCategoriesV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListListenerFavoriteSongCategoriesV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListListenerFavoriteSongCategoriesV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            categoriesList: jspb.Message.toObjectList(msg.getCategoriesList(), karaoke_entity_pb.LiverAndListenerFavoriteSongCategory.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListListenerFavoriteSongCategoriesV1Response}
 */
proto.jp.singcolor.ListListenerFavoriteSongCategoriesV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListListenerFavoriteSongCategoriesV1Response;
    return proto.jp.singcolor.ListListenerFavoriteSongCategoriesV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListListenerFavoriteSongCategoriesV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListListenerFavoriteSongCategoriesV1Response}
 */
proto.jp.singcolor.ListListenerFavoriteSongCategoriesV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new karaoke_entity_pb.LiverAndListenerFavoriteSongCategory;
                reader.readMessage(value, karaoke_entity_pb.LiverAndListenerFavoriteSongCategory.deserializeBinaryFromReader);
                msg.addCategories(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListListenerFavoriteSongCategoriesV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListListenerFavoriteSongCategoriesV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListListenerFavoriteSongCategoriesV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListListenerFavoriteSongCategoriesV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getCategoriesList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, karaoke_entity_pb.LiverAndListenerFavoriteSongCategory.serializeBinaryToWriter);
    }
};
/**
 * repeated LiverAndListenerFavoriteSongCategory categories = 1;
 * @return {!Array<!proto.jp.singcolor.LiverAndListenerFavoriteSongCategory>}
 */
proto.jp.singcolor.ListListenerFavoriteSongCategoriesV1Response.prototype.getCategoriesList = function () {
    return /** @type{!Array<!proto.jp.singcolor.LiverAndListenerFavoriteSongCategory>} */ (jspb.Message.getRepeatedWrapperField(this, karaoke_entity_pb.LiverAndListenerFavoriteSongCategory, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.LiverAndListenerFavoriteSongCategory>} value
 * @return {!proto.jp.singcolor.ListListenerFavoriteSongCategoriesV1Response} returns this
*/
proto.jp.singcolor.ListListenerFavoriteSongCategoriesV1Response.prototype.setCategoriesList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.LiverAndListenerFavoriteSongCategory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.LiverAndListenerFavoriteSongCategory}
 */
proto.jp.singcolor.ListListenerFavoriteSongCategoriesV1Response.prototype.addCategories = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.LiverAndListenerFavoriteSongCategory, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListListenerFavoriteSongCategoriesV1Response} returns this
 */
proto.jp.singcolor.ListListenerFavoriteSongCategoriesV1Response.prototype.clearCategoriesList = function () {
    return this.setCategoriesList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListUserFavoriteSongCategoriesV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListUserFavoriteSongCategoriesV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListUserFavoriteSongCategoriesV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListUserFavoriteSongCategoriesV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListUserFavoriteSongCategoriesV1Request}
 */
proto.jp.singcolor.ListUserFavoriteSongCategoriesV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListUserFavoriteSongCategoriesV1Request;
    return proto.jp.singcolor.ListUserFavoriteSongCategoriesV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListUserFavoriteSongCategoriesV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListUserFavoriteSongCategoriesV1Request}
 */
proto.jp.singcolor.ListUserFavoriteSongCategoriesV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListUserFavoriteSongCategoriesV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListUserFavoriteSongCategoriesV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListUserFavoriteSongCategoriesV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListUserFavoriteSongCategoriesV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListUserFavoriteSongCategoriesV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListUserFavoriteSongCategoriesV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListUserFavoriteSongCategoriesV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListUserFavoriteSongCategoriesV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListUserFavoriteSongCategoriesV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            categoriesList: jspb.Message.toObjectList(msg.getCategoriesList(), karaoke_entity_pb.LiverAndListenerFavoriteSongCategory.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListUserFavoriteSongCategoriesV1Response}
 */
proto.jp.singcolor.ListUserFavoriteSongCategoriesV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListUserFavoriteSongCategoriesV1Response;
    return proto.jp.singcolor.ListUserFavoriteSongCategoriesV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListUserFavoriteSongCategoriesV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListUserFavoriteSongCategoriesV1Response}
 */
proto.jp.singcolor.ListUserFavoriteSongCategoriesV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new karaoke_entity_pb.LiverAndListenerFavoriteSongCategory;
                reader.readMessage(value, karaoke_entity_pb.LiverAndListenerFavoriteSongCategory.deserializeBinaryFromReader);
                msg.addCategories(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListUserFavoriteSongCategoriesV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListUserFavoriteSongCategoriesV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListUserFavoriteSongCategoriesV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListUserFavoriteSongCategoriesV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getCategoriesList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, karaoke_entity_pb.LiverAndListenerFavoriteSongCategory.serializeBinaryToWriter);
    }
};
/**
 * repeated LiverAndListenerFavoriteSongCategory categories = 1;
 * @return {!Array<!proto.jp.singcolor.LiverAndListenerFavoriteSongCategory>}
 */
proto.jp.singcolor.ListUserFavoriteSongCategoriesV1Response.prototype.getCategoriesList = function () {
    return /** @type{!Array<!proto.jp.singcolor.LiverAndListenerFavoriteSongCategory>} */ (jspb.Message.getRepeatedWrapperField(this, karaoke_entity_pb.LiverAndListenerFavoriteSongCategory, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.LiverAndListenerFavoriteSongCategory>} value
 * @return {!proto.jp.singcolor.ListUserFavoriteSongCategoriesV1Response} returns this
*/
proto.jp.singcolor.ListUserFavoriteSongCategoriesV1Response.prototype.setCategoriesList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.LiverAndListenerFavoriteSongCategory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.LiverAndListenerFavoriteSongCategory}
 */
proto.jp.singcolor.ListUserFavoriteSongCategoriesV1Response.prototype.addCategories = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.LiverAndListenerFavoriteSongCategory, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListUserFavoriteSongCategoriesV1Response} returns this
 */
proto.jp.singcolor.ListUserFavoriteSongCategoriesV1Response.prototype.clearCategoriesList = function () {
    return this.setCategoriesList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateFavoriteSongV2Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateFavoriteSongV2Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateFavoriteSongV2Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateFavoriteSongV2Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            songId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            isFavorite: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateFavoriteSongV2Request}
 */
proto.jp.singcolor.UpdateFavoriteSongV2Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateFavoriteSongV2Request;
    return proto.jp.singcolor.UpdateFavoriteSongV2Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateFavoriteSongV2Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateFavoriteSongV2Request}
 */
proto.jp.singcolor.UpdateFavoriteSongV2Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setSongId(value);
                break;
            case 2:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setIsFavorite(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateFavoriteSongV2Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateFavoriteSongV2Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateFavoriteSongV2Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateFavoriteSongV2Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getSongId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getIsFavorite();
    if (f) {
        writer.writeBool(2, f);
    }
};
/**
 * optional string song_id = 1;
 * @return {string}
 */
proto.jp.singcolor.UpdateFavoriteSongV2Request.prototype.getSongId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateFavoriteSongV2Request} returns this
 */
proto.jp.singcolor.UpdateFavoriteSongV2Request.prototype.setSongId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional bool is_favorite = 2;
 * @return {boolean}
 */
proto.jp.singcolor.UpdateFavoriteSongV2Request.prototype.getIsFavorite = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.UpdateFavoriteSongV2Request} returns this
 */
proto.jp.singcolor.UpdateFavoriteSongV2Request.prototype.setIsFavorite = function (value) {
    return jspb.Message.setProto3BooleanField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateFavoriteSongV2Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateFavoriteSongV2Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateFavoriteSongV2Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateFavoriteSongV2Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            songWithCategory: (f = msg.getSongWithCategory()) && karaoke_entity_pb.SongWithCategory.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateFavoriteSongV2Response}
 */
proto.jp.singcolor.UpdateFavoriteSongV2Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateFavoriteSongV2Response;
    return proto.jp.singcolor.UpdateFavoriteSongV2Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateFavoriteSongV2Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateFavoriteSongV2Response}
 */
proto.jp.singcolor.UpdateFavoriteSongV2Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 2:
                var value = new karaoke_entity_pb.SongWithCategory;
                reader.readMessage(value, karaoke_entity_pb.SongWithCategory.deserializeBinaryFromReader);
                msg.setSongWithCategory(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateFavoriteSongV2Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateFavoriteSongV2Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateFavoriteSongV2Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateFavoriteSongV2Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getSongWithCategory();
    if (f != null) {
        writer.writeMessage(2, f, karaoke_entity_pb.SongWithCategory.serializeBinaryToWriter);
    }
};
/**
 * optional SongWithCategory song_with_category = 2;
 * @return {?proto.jp.singcolor.SongWithCategory}
 */
proto.jp.singcolor.UpdateFavoriteSongV2Response.prototype.getSongWithCategory = function () {
    return /** @type{?proto.jp.singcolor.SongWithCategory} */ (jspb.Message.getWrapperField(this, karaoke_entity_pb.SongWithCategory, 2));
};
/**
 * @param {?proto.jp.singcolor.SongWithCategory|undefined} value
 * @return {!proto.jp.singcolor.UpdateFavoriteSongV2Response} returns this
*/
proto.jp.singcolor.UpdateFavoriteSongV2Response.prototype.setSongWithCategory = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.UpdateFavoriteSongV2Response} returns this
 */
proto.jp.singcolor.UpdateFavoriteSongV2Response.prototype.clearSongWithCategory = function () {
    return this.setSongWithCategory(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.UpdateFavoriteSongV2Response.prototype.hasSongWithCategory = function () {
    return jspb.Message.getField(this, 2) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateListenerFavoriteSongV2Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateListenerFavoriteSongV2Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateListenerFavoriteSongV2Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateListenerFavoriteSongV2Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            songId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            isFavorite: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateListenerFavoriteSongV2Request}
 */
proto.jp.singcolor.UpdateListenerFavoriteSongV2Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateListenerFavoriteSongV2Request;
    return proto.jp.singcolor.UpdateListenerFavoriteSongV2Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateListenerFavoriteSongV2Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateListenerFavoriteSongV2Request}
 */
proto.jp.singcolor.UpdateListenerFavoriteSongV2Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setSongId(value);
                break;
            case 2:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setIsFavorite(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateListenerFavoriteSongV2Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateListenerFavoriteSongV2Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateListenerFavoriteSongV2Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateListenerFavoriteSongV2Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getSongId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getIsFavorite();
    if (f) {
        writer.writeBool(2, f);
    }
};
/**
 * optional string song_id = 1;
 * @return {string}
 */
proto.jp.singcolor.UpdateListenerFavoriteSongV2Request.prototype.getSongId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateListenerFavoriteSongV2Request} returns this
 */
proto.jp.singcolor.UpdateListenerFavoriteSongV2Request.prototype.setSongId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional bool is_favorite = 2;
 * @return {boolean}
 */
proto.jp.singcolor.UpdateListenerFavoriteSongV2Request.prototype.getIsFavorite = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.UpdateListenerFavoriteSongV2Request} returns this
 */
proto.jp.singcolor.UpdateListenerFavoriteSongV2Request.prototype.setIsFavorite = function (value) {
    return jspb.Message.setProto3BooleanField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateListenerFavoriteSongV2Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateListenerFavoriteSongV2Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateListenerFavoriteSongV2Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateListenerFavoriteSongV2Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            songWithCategory: (f = msg.getSongWithCategory()) && karaoke_entity_pb.SongWithCategory.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateListenerFavoriteSongV2Response}
 */
proto.jp.singcolor.UpdateListenerFavoriteSongV2Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateListenerFavoriteSongV2Response;
    return proto.jp.singcolor.UpdateListenerFavoriteSongV2Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateListenerFavoriteSongV2Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateListenerFavoriteSongV2Response}
 */
proto.jp.singcolor.UpdateListenerFavoriteSongV2Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 2:
                var value = new karaoke_entity_pb.SongWithCategory;
                reader.readMessage(value, karaoke_entity_pb.SongWithCategory.deserializeBinaryFromReader);
                msg.setSongWithCategory(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateListenerFavoriteSongV2Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateListenerFavoriteSongV2Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateListenerFavoriteSongV2Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateListenerFavoriteSongV2Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getSongWithCategory();
    if (f != null) {
        writer.writeMessage(2, f, karaoke_entity_pb.SongWithCategory.serializeBinaryToWriter);
    }
};
/**
 * optional SongWithCategory song_with_category = 2;
 * @return {?proto.jp.singcolor.SongWithCategory}
 */
proto.jp.singcolor.UpdateListenerFavoriteSongV2Response.prototype.getSongWithCategory = function () {
    return /** @type{?proto.jp.singcolor.SongWithCategory} */ (jspb.Message.getWrapperField(this, karaoke_entity_pb.SongWithCategory, 2));
};
/**
 * @param {?proto.jp.singcolor.SongWithCategory|undefined} value
 * @return {!proto.jp.singcolor.UpdateListenerFavoriteSongV2Response} returns this
*/
proto.jp.singcolor.UpdateListenerFavoriteSongV2Response.prototype.setSongWithCategory = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.UpdateListenerFavoriteSongV2Response} returns this
 */
proto.jp.singcolor.UpdateListenerFavoriteSongV2Response.prototype.clearSongWithCategory = function () {
    return this.setSongWithCategory(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.UpdateListenerFavoriteSongV2Response.prototype.hasSongWithCategory = function () {
    return jspb.Message.getField(this, 2) != null;
};
goog.object.extend(exports, proto.jp.singcolor);
